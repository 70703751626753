import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({

  rootContainer: {
    marginBottom: '20px',
    border: '1px solid #EFEFEF',
    padding: '4px',
    borderRadius: '8px',
  },
  todoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  todoContainerDone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textDecoration: 'line-through',
  },
  disableHorisontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  subtitle: {
    marginBottom: '6px',
  },
  input: {
    marginBottom: '20px',
  },
}));
