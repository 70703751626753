import React, { useContext } from 'react';
import {
  AppBar, Box, IconButton, Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';
import { DELETE_REFRESH_TOKEN, DELETE_TOKEN } from '../../graphql/mutations';
import Logo from '../../assets/images/Logo.svg';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { deleteToken as IDeleteToken } from '../../graphql/mutations/__generated__/deleteToken';
import { deleteRefreshToken as IDeleteRefreshToken } from '../../graphql/mutations/__generated__/deleteRefreshToken';
import { useStyles } from './styles';

const Header = () => {
  const [deleteRefreshToken] = useMutation<IDeleteRefreshToken>(DELETE_REFRESH_TOKEN);
  const [deleteToken] = useMutation<IDeleteToken>(DELETE_TOKEN);

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const classes = useStyles();

  const logOut = async () => {
    await deleteRefreshToken();
    await deleteToken();
    context?.setIsLoggedIn(false);
    localStorage.removeItem(LOCALE_STORAGE_PROJECT_ID);
    navigate('/login');
  };

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%);',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        height: '80px',
      }}
    >
      <Toolbar className={classes.toolbar}>
        <Box />
        <img
          src={Logo}
          alt="logo"
        />
        <Box>
          {context?.isLoggedIn
            && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={logOut}
            >
              <LoginIcon />
            </IconButton>
            )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
