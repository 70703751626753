import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  TableBody,
} from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import { LanguagesTableRow } from '../LanguagesTableRow';
import { HeadCell, ILanguagesTableProps } from './types';

const LanguagesTable: React.FC<ILanguagesTableProps> = ({
  data,
  setOpen,
  setDeleteOpen,
  referredLanguage,
  setChangeReferenceOpen,
  setReferenceToSelect,
}) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'reference',
      numeric: false,
      disablePadding: true,
      label: t('LANGUAGES_PAGE.REFERENCE_COLUMN'),
    },
    {
      id: 'language',
      numeric: false,
      disablePadding: false,
      label: t('LANGUAGES_PAGE.LANGUAGE_COLUMN'),
    },
    {
      id: 'languageCode',
      numeric: false,
      disablePadding: false,
      label: t('LANGUAGES_PAGE.LANGUAGE_CODE_COLUMN'),
    },
    {
      id: 'textOnly',
      numeric: false,
      disablePadding: false,
      label: t('LANGUAGES_PAGE.TEXT_ONLY_COLUMN'),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: t('LANGUAGES_PAGE.ACTIONS_COLUMN'),
    },
  ];

  const handleReferenceChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferenceToSelect(event.target.value);
    setChangeReferenceOpen(true);
  };

  return (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align="left" padding="none">
              <Typography variant="body2">{headCell.label}</Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((item) => (
          <LanguagesTableRow
            item={item?.node}
            onReferenceChange={handleReferenceChange}
            currentReference={referredLanguage}
            key={uuidv4()}
            setOpen={setOpen}
            setDeleteOpen={setDeleteOpen}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default LanguagesTable;
