import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { lightGray } from '../../../../constants/colors';
import { StyledButton } from '../../../../common/StyledButton';
import { Spinner } from '../../../../common/Spinner';
import { IRecordingRummaryDialogResultProps } from './types';

const RecordingRummaryDialogResult: React.FC<IRecordingRummaryDialogResultProps> = ({
  robovoiceGenerationStatusData,
  handleClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.hasFailed) {
    return (
      <Box className={classes.resultDialogWrapper}>
        <Typography variant="h3" className={classes.text}>{t('RECORDINGS_SUMMARY_PAGE.FIXING_IS_FINISHED')}</Typography>
        <Typography className={classes.text} color={lightGray}>
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nSucceededFiles}
          /
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nProcessedFiles}
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nSucceededFiles === 1
            ? ` ${t('RECORDINGS_SUMMARY_PAGE.FILE')}`
            : ` ${t('RECORDINGS_SUMMARY_PAGE.FILES')}`}
          {' '}
          {t('RECORDINGS_SUMMARY_PAGE.PROCESSED')}
        </Typography>
        <Typography className={classes.text} color={lightGray}>
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nFailedFiles}
          /
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nProcessedFiles}
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nFailedFiles === 1
            ? ` ${t('RECORDINGS_SUMMARY_PAGE.FILE')}`
            : ` ${t('RECORDINGS_SUMMARY_PAGE.FILES')}`}
          {' '}
          {t('RECORDINGS_SUMMARY_PAGE.FAILED')}
        </Typography>
        <Box className={classes.closeDialogButton}>
          <StyledButton
            width="74px"
            action={handleClose}
            variant="outlined"
            label={t('BUTTON.CLOSE')}
          />
        </Box>
      </Box>
    );
  }

  if (robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.isFinished
      && !robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.hasFailed) {
    return (
      <Box className={classes.resultDialogWrapper}>
        <Typography variant="h3" className={classes.text}>{t('RECORDINGS_SUMMARY_PAGE.FIXING_IS_FINISHED')}</Typography>
        <Typography className={classes.text} color={lightGray}>
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nSucceededFiles}
          /
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nProcessedFiles}
          {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nSucceededFiles === 1
            ? ` ${t('RECORDINGS_SUMMARY_PAGE.FILE')}`
            : ` ${t('RECORDINGS_SUMMARY_PAGE.FILES')}`}
          {' '}
          {t('RECORDINGS_SUMMARY_PAGE.PROCESSED')}
        </Typography>
        <Box className={classes.closeDialogButton}>
          <StyledButton
            width="74px"
            action={handleClose}
            variant="outlined"
            label={t('BUTTON.CLOSE')}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.resultDialogWrapper}>
      <Typography className={classes.loadingDialogText}>{t('RECORDINGS_SUMMARY_PAGE.PROCESSING')}</Typography>
      <Box className={classes.spinnerWrapper}>
        <Spinner />
      </Box>
      <Typography className={classes.loadingDialogText}>
        {(robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nProcessedFiles || 0)
            + (robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nFailedFiles || 0)}
        /
        {robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.nTotalFilesToProcess || '0'}
        {' '}
        {t('RECORDINGS_SUMMARY_PAGE.PROCESSED')}
      </Typography>
      <Box className={classes.canselDialogButton}>
        <StyledButton
          width="74px"
          action={handleClose}
          variant="outlined"
          label={t('BUTTON.CANCEL')}
        />
      </Box>
    </Box>
  );
};

export default RecordingRummaryDialogResult;
