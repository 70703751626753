import {
  Box,
  Checkbox,
  IconButton,
  Radio, TableCell, tableCellClasses, TableRow, Tooltip, Typography,
} from '@mui/material';
import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { ILanguagesTableRowProps } from './types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { useStyles } from './styles';
import { LangContext } from '../../../context/LangContext/LangContext';
import { FlagVisualisation } from '../../../common/FlagVisualisation';

const LanguagesTableRow:React.FC<ILanguagesTableRowProps> = ({
  item, onReferenceChange, currentReference, setOpen, setDeleteOpen,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const context = useContext(LangContext);
  return (
    <TableRow sx={{
      [`& .${tableCellClasses.root}`]: {
        paddingTop: '14px',
        paddingBottom: '14px',
      },
    }}
    >
      {item && (
        <>
          <TableCell padding="none">
            <Radio
              checked={currentReference === item.id}
              onChange={onReferenceChange}
              value={item.id}
              className={classes.button}
            />
          </TableCell>
          <TableCell padding="none">
            <FlagVisualisation optionFlag={item.languageFlag} />
            <Typography
              variant="subtitle2"
              display="inline"
              className={currentReference !== item.id
                ? classes.tableText
                : classes.selectedLanguage}
            >
              {item.name}
            </Typography>
          </TableCell>
          <TableCell padding="none">
            <Typography
              variant="subtitle2"
              display="inline"
              className={currentReference !== item.id
                ? classes.tableText
                : classes.selectedLanguage}
            >
              {item.languageCode}
            </Typography>
          </TableCell>
          <TableCell padding="none">
            <Checkbox
              checked={item.textOnly}
              disabled
              inputProps={{ 'aria-label': 'controlled' }}
              className={classes.button}
            />
          </TableCell>
          <TableCell padding="none">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {currentReference !== item.id && (
              <Tooltip title={t('LANGUAGES_PAGE.DELETE_LANGUAGE_TOOLTIP')} placement="top">
                <IconButton onClick={() => {
                  setDeleteOpen(true);
                  context?.setLanguageData({
                    __typename: 'LanguageNode',
                    id: item.id,
                    name: item.name,
                    languageCode: item.languageCode,
                    languageFlag: item.languageFlag,
                    textOnly: item.textOnly,
                  });
                }}
                >
                  <TrashIcon style={{ stroke: '#1F224C' }} />
                </IconButton>
              </Tooltip>
              )}

              <Tooltip title={t('LANGUAGES_PAGE.EDIT_LANGUAGE_TOOLTIP')} placement="top">
                <IconButton onClick={() => {
                  context?.setLanguageData({
                    __typename: 'LanguageNode',
                    id: item.id,
                    name: item.name,
                    languageCode: item.languageCode,
                    languageFlag: item.languageFlag,
                    textOnly: item.textOnly,
                  });
                  context?.setIsEditMode(true);
                  setOpen(true);
                }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        </>
      )}

    </TableRow>
  );
};
export default LanguagesTableRow;
