import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  uploadScriptContainerWrapper: {
    marginTop: '20px',
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    fontWeight: 600,
    fontSize: '12px',
    padding: '12px',
    borderColor: '#FF8E75',
    borderWidth: '1px',
    borderRadius: '8px',
    color: '#FF8E75',
    width: '210px',
    marginBottom: '10px',
    textTransform: 'none',
    '&:hover': {
      opacity: '0.6',
      borderColor: '#FF8E75',
    },
  },
});
