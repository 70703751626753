import React from 'react';

import { Box, Drawer, Toolbar } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { INavbarProps } from './types';

const NavBar: React.FC<INavbarProps> = ({ drawerList, drawerWidth }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          position: 'relative',
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        {drawerList}

      </Drawer>
    </Box>
  );
};
export default NavBar;
