import React, { useCallback, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Box, Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { useStyles } from './styles';
import { RevisionContext } from '../../../../context/RevisionContext/RevisionContext';
import { CREATE_SOUND_FILE } from '../../../../graphql/mutations';
import useUploadSoundFileToS3 from '../../../../hooks/useUploadSoundFileToS3';
import FileDropzone from '../../../../common/FileDropzone/FileDropzone';
import { audioFileExtension, MAX_SOUND_FILE_SIZE } from '../../../../constants/audioFile';
import { StyledButton } from '../../../../common/StyledButton';
import { IUploadSoundFileDialogProps } from './types';
import { base64Decoding } from '../../../../helpers';
import { GET_LINE } from '../../../../graphql/queries';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../../constants/localStorage';

const UploadSoundFileDialog: React.FC<IUploadSoundFileDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const { id } = useParams();
  const classes = useStyles();

  const context = useContext(RevisionContext);

  const [createSoundFile] = useMutation(CREATE_SOUND_FILE, {
    refetchQueries: [{ query: GET_LINE, variables: { id } }, 'line'],
  });

  const {
    presignedUrlLoading,
    handleDnD,
    droppedFileName,
    soundFileId,
    setDroppedFile,
  } = useUploadSoundFileToS3(open);

  useEffect(() => {
    if (context) {
      setDroppedFile(null);
    }
  }, [open]);

  const handlePrecessButton = useCallback(async () => {
    if (context?.revisionData.id && soundFileId && !presignedUrlLoading) {
      await createSoundFile({
        variables: {
          id: soundFileId,
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          localised_asset_revision: base64Decoding(context?.revisionData.id),
          isRobovoice: false,
        },
      });
      setOpen(false);
    }
  }, [context, soundFileId, presignedUrlLoading]);

  return (
    <Dialog open={open} onClose={handleDialogChange} fullWidth maxWidth="md">
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <FileDropzone
            handleDnD={handleDnD}
            maxSize={MAX_SOUND_FILE_SIZE}
            typeArr={[audioFileExtension]}
            droppedFileName={droppedFileName}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between' }}
          className={classes.disableHorisontalPadding}
        >
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={handleDialogChange}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label="Process"
            action={handlePrecessButton}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UploadSoundFileDialog;
