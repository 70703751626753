import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
  Checkbox,
} from '@mui/material';
import { useMutation } from '@apollo/client';

import { AuthContext } from '../../../context/AuthContext/AuthContext';
import { defaultTodoData } from '../../../constants/todos';
import { StyledButton } from '../../../common/StyledButton';
import { IAddTodoDialogProps } from './types';
import { useStyles } from './styles';
import { TodoContext } from '../../../context/TodoContext/TodoContext';
import { CREATE_TODO, UPDATE_TODO } from '../../../graphql/mutations';
import { createToDoItem as createToDoItemProps } from '../../../graphql/mutations/__generated__/createToDoItem';
import { updateToDoItem as updateToDoItemProps } from '../../../graphql/mutations/__generated__/updateToDoItem';
import { GET_ALL_TODOS } from '../../../graphql/queries';
import { base64Decoding } from '../../../helpers';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const AddTodoDialog: React.FC<IAddTodoDialogProps> = ({ handleDialogChange, open, setOpen }) => {
  const context = useContext(TodoContext);
  const [todoTitleValue, setTodoTitleValue] = useState(context?.todoData.title);
  const [dueDateValue, setDueDateValue] = useState(context?.todoData.dueDate);
  const [descriptionValue, setDescriptionValue] = useState(context?.todoData.description);
  const [isDoneValue, setIsDoneValue] = useState(context?.todoData.isDone);

  const authContext = useContext(AuthContext);

  const [createToDoItem] = useMutation<createToDoItemProps>(CREATE_TODO, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    refetchQueries: [
      { query: GET_ALL_TODOS, variables: { orderBy: '-dueDate' } },
      'getAllTodos',
    ],
  });

  const [updateToDoItem] = useMutation<updateToDoItemProps>(UPDATE_TODO, {
    refetchQueries: [
      { query: GET_ALL_TODOS, variables: { orderBy: '-dueDate' } },
      'getAllTodos',
    ],
  });

  const classes = useStyles();

  const resetState = () => {
    setTodoTitleValue('');
    setDueDateValue('');
    setDescriptionValue('');
    setIsDoneValue(false);
    context?.setTodoData(defaultTodoData);
  };

  const handleCreateTodo = (
    title: string| undefined,
    description: string| undefined,
    dueDate: string | undefined,
    isDone: boolean | undefined,
  ) => {
    createToDoItem({
      variables: {
        toDoItemCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          project: base64Decoding(authContext.currentProjectId || ''),
          title,
          description,
          dueDate: new Date(String(dueDate)),
          isDone,
        },
      },
    });
    resetState();
    setOpen(!open);
  };

  const handleUpdateTodo = (
    id: string | undefined,
    title: string| undefined,
    description: string| undefined,
    dueDate: string | undefined,
    isDone: boolean | undefined,
  ) => {
    updateToDoItem({
      variables: {
        toDoItemUpdateData: {
          id,
          title,
          description,
          dueDate: new Date(String(dueDate)),
          isDone,
        },
      },
    });
    resetState();
    setOpen(!open);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTodoTitleValue(event.target.value);
  };

  const handleDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDueDateValue(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
  };

  useEffect(() => {
    if (context) {
      setTodoTitleValue(context.todoData.title);
      setDescriptionValue(context.todoData.description);
      setDueDateValue(context.todoData.dueDate);
      setIsDoneValue(context.todoData.isDone);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="body1"
            >
              Title
            </Typography>
            <FormControl className={classes.input} sx={{ width: '80%' }}>
              <TextField
                value={todoTitleValue}
                placeholder="Title"
                onChange={handleTitleChange}
              />
            </FormControl>

            <Typography
              className={classes.subtitle}
              variant="body1"
            >
              Description
            </Typography>
            <FormControl className={classes.input} sx={{ width: '80%' }}>
              <TextField
                multiline
                rows={3}
                value={descriptionValue}
                placeholder="To do..."
                onChange={handleDescriptionChange}
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body1">Due on</Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                sx={{ width: '40%' }}
                id="due-date"
                fullWidth
                placeholder="Due date"
                type="date"
                value={dueDateValue}
                onChange={handleDueDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    padding: '12px 10px',
                  },
                }}
              />
            </FormControl>
            <Typography
              className={classes.subtitle}
              variant="body1"
            >
              Is it done?
            </Typography>
            <Checkbox value={isDoneValue} onChange={() => setIsDoneValue(!isDoneValue)} />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorisontalPadding}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={() => {
              resetState();
              handleDialogChange();
            }}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label={context?.isEditMode ? 'OK' : 'Add'}
            action={context?.isEditMode ? () => handleUpdateTodo(
              context?.todoData.id,
              todoTitleValue,
              descriptionValue,
              dueDateValue,
              isDoneValue,
            ) : () => {
              handleCreateTodo(
                todoTitleValue,
                descriptionValue,
                dueDateValue,
                isDoneValue,
              );
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default AddTodoDialog;
