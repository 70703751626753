import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { IUserManagementTableProps } from './types';
import { HeadCell } from '../../Line/LineTable/types';
import { UserManagementRow } from '../UserManagementRow';
import { admin } from '../../../constants/user';

const UserManagementTable: React.FC<IUserManagementTableProps> = ({
  data,
  setIsEditDialogOpen,
  setEditableUserData,
  setIsDeleteDialogOpen,
}) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: t('USER_MANAGEMENT_PAGE.TABLE.EMAIL'),
    },
    {
      id: 'role',
      numeric: true,
      disablePadding: false,
      label: t('USER_MANAGEMENT_PAGE.TABLE.ROLE'),
    },
    {
      id: 'permission-sets',
      numeric: true,
      disablePadding: false,
      label: t('USER_MANAGEMENT_PAGE.TABLE.PERMISSION_SETS'),
    },
    {
      id: 'actions',
      numeric: true,
      disablePadding: false,
      label: t('USER_MANAGEMENT_PAGE.TABLE.ACTIONS'),
    },
  ];

  const adminsCount = useMemo(
    () => data?.users.edges
      .filter((item) => item?.node?.permissionAttributeGroups.edges
        .filter((permission) => permission?.node?.name === admin).length).length,
    [data],
  );

  return (
    <Box sx={{ marginTop: '80px' }}>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                  <Typography variant="body2" sx={{ marginRight: '10px' }}>
                    {headCell.label}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data?.users.edges.map((item) => (
              <UserManagementRow
                key={uuidv4()}
                data={item?.node}
                adminsCount={adminsCount}
                setIsEditDialogOpen={setIsEditDialogOpen}
                setEditableUserData={setEditableUserData}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              />
            ))
          }
        </TableBody>
      </Table>
    </Box>
  );
};

export default UserManagementTable;
