import React from 'react';
import {
  Box, Dialog, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IDownloadDialogProps } from './types';
import { useStyles } from './styles';
import { StyledButton } from '../StyledButton';
import { Spinner } from '../Spinner';
import { lightGray } from '../../constants/colors';

const DownloadDialog: React.FC<IDownloadDialogProps> = ({
  isPolling,
  setIsPolling,
  stopPolling,
  isFailed,
}) => {
  const classes = useStyles();
  const handlePollingClose = () => {
    stopPolling();
    setIsPolling(false);
  };

  return (
    <Dialog open={isPolling} onClose={handlePollingClose} fullWidth maxWidth="xs">
      <Box className={classes.dialogWrapper}>
        <IconButton className={classes.closeDialogIconButton} onClick={handlePollingClose}>
          <CloseIcon />
        </IconButton>
        {
          isFailed
            ? (
              <>
                <Typography variant="h3" className={classes.errorText}>Something went wrong</Typography>
                <Typography className={classes.errorText} color={lightGray}>Please try again</Typography>
                <Box className={classes.closeDialogButton}>
                  <StyledButton
                    width="74px"
                    action={handlePollingClose}
                    variant="outlined"
                    label="Close"
                  />
                </Box>
              </>
            )
            : (
              <>
                <Typography color={lightGray} sx={{ fontWeight: '600' }}>Processing</Typography>
                <Box className={classes.spinnerWrapper}>
                  <Spinner />
                </Box>
                <Box className={classes.canselDialogButton}>
                  <StyledButton
                    width="74px"
                    action={handlePollingClose}
                    variant="outlined"
                    label="Cancel"
                  />
                </Box>
              </>
            )
        }
      </Box>
    </Dialog>
  );
};

export default DownloadDialog;
