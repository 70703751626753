import { makeStyles } from '@mui/styles';
import { secondary } from '../../../constants/colors';

export const useStyles = makeStyles({
  formWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'flex-start',
  },
  formField: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    marginBottom: '16px',
  },
  autocomplete: {
    minWidth: '200px',
    padding: '0px 0px',
    '& .MuiOutlinedInput-root': { padding: '0' },
    marginLeft: '10px',
  },
  comparativeSelect: {
    minWidth: '200px',
    marginLeft: '10px',
    '& .MuiSelect-select': { padding: '0' },
    '& .MuiInputBase-root': { padding: '7.5px 4px 7.5px 6px' },
  },
  selectOption: {
    padding: '10px 15px',
  },
  checkbox: {
    '&.Mui-checked': {
      color: secondary,
    },
  },
  numberInput: {
    minWidth: '200px',
    marginLeft: '10px',
    '& MuiInputBase-root': { padding: '0' },
  },
  buttonsWrapper: {
    marginTop: '40px',
    alignSelf: 'flex-end',
  },
});
