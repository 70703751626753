import { makeStyles } from '@mui/styles';
import { darkBlue, lightBlue, white } from '../../../constants/colors';

export const useStyles = makeStyles(({
  languageCell: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px 15px',
    minWidth: '100px',
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    '& svg': {
      path: {
        fill: '#000',
      },
    },
  },
  amount: {
  },
}));
