import React, {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box, CircularProgress, Link, TablePagination, TextField,
} from '@mui/material';
import { BooleanQueryVars, Order } from './types';
import {
  comparativeFiltersValue,
  initialSliderValue, MAX_RECORDING_IDS,
  tableType,
} from '../../../constants/recordings';

import {
  GET_ALL_CHARACTERS, GET_ALL_COMPARATIVE_REVISIONS, GET_ALL_CURRENT_COMPARATIVE_REVISIONS_IDS,
  GET_ALL_LANGUAGES,
  GET_ALL_SECTIONS,
} from '../../../graphql/queries';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { RecordingsFilters } from '../../../common/RecordingsFilters';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './style';
import {
  allCurrentLocalisedAssetComparativeRevisions,
} from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetComparativeRevisions';
import { ComparativeTable } from '../ComparativeTable';
import { ISelectedRevision } from '../../Recordings/GerenalRecordingsContainer/types';
import { FixRevisionContext } from '../../../context/FixRevisionContext/FixRevisionContext';
import {
  allCurrentLocalisedAssetComparativeRevisionsIds,
} from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetComparativeRevisionsIds';
import FixRecordingsDialog from '../../../common/FixRecordingsDialog/FixRecordingsDialog';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const ComparativeAnalysisContainer = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('localised_asset__line__string_id');
  const [order, setOrder] = useState<Order>('asc');
  const [isOpen, setIsOpen] = useState(false);
  const [searchStringValue, setSearchStringValue] = useState('');
  const [searchStringId, setSearchStringId] = useState('');
  const [selectedCharacter, setSelectedCharacter] = useState<(string | undefined)[]>([]);
  const [selectedSection, setSelectedSection] = useState<(string | undefined)[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<(string | undefined)[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<(string | undefined)[]>([]);
  const [selectedLoudness, setSelectedLoudness] = useState(initialSliderValue);
  const [
    comparativeSelectedSampleRate,
    setComparativeSelectedSampleRate,
  ] = useState<string>(comparativeFiltersValue);
  const [
    comparativeSelectedChannel,
    setComparativeSelectedChannel,
  ] = useState<string>(comparativeFiltersValue);
  const [
    comparativeSelectedBitDepth,
    setComparativeSelectedBitDepth,
  ] = useState<string>(comparativeFiltersValue);
  const [selectedPaddingFront, setSelectedPaddingFront] = useState(initialSliderValue);
  const [selectedPaddingBack, setSelectedPaddingBack] = useState(initialSliderValue);
  const [selectedLength, setSelectedLength] = useState(initialSliderValue);
  const [selectedPeakLevel, setSelectedPeakLevel] = useState(initialSliderValue);
  const [selectedRevisions, setSelectedRevisions] = useState<Array<ISelectedRevision>>([]);

  const classes = useStyles();
  const context = useContext(FixRevisionContext);

  const booleanQueryVars = useMemo(() => {
    const boolVars: BooleanQueryVars = {
      bitDepthEqualityFilter: comparativeSelectedBitDepth,
      channelEqualityFilter: comparativeSelectedChannel,
      sampleRateEqualityFilter: comparativeSelectedSampleRate,
    };

    Object.entries(boolVars).forEach(([key, value]) => {
      if (value === comparativeFiltersValue) {
        delete boolVars[key as keyof BooleanQueryVars];
      } else {
        boolVars[key as keyof BooleanQueryVars] = !!Number(value);
      }
    });

    return boolVars;
  }, [comparativeSelectedSampleRate, comparativeSelectedChannel, comparativeSelectedBitDepth]);

  const { data, loading, fetchMore } = useQuery<allCurrentLocalisedAssetComparativeRevisions>(
    GET_ALL_COMPARATIVE_REVISIONS,
    {
      variables: {
        offset: page * pageSize,
        first: pageSize,
        localisedAsset_Line_StringId_Icontains: searchStringId,
        characterId: selectedCharacter,
        sectionId: selectedSection,
        languageId: selectedLanguage,
        lengthDifferenceFilter: selectedLength,
        perceivedLoudnessDifferenceFilter: selectedLoudness,
        trailingSilenceBackDifferenceFilter: selectedPaddingBack,
        trailingSilenceFrontDifferenceFilter: selectedPaddingFront,
        peakLevelDifferenceFilter: selectedPeakLevel,
        orderBy,
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        ...booleanQueryVars,
      },
      onCompleted: () => setSelectedRevisions([]),
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(
    GET_ALL_CHARACTERS,
    {
      variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    },
  );

  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
  });

  const { data: languagesData, loading: languagesLoading } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const [getAllRevisionsIds, {
    data: allRevisionsIdsData,
    loading: allRevisionsIdsLoading,
  }] = useLazyQuery<allCurrentLocalisedAssetComparativeRevisionsIds>(GET_ALL_CURRENT_COMPARATIVE_REVISIONS_IDS, {
    onCompleted: async (resData) => {
      if (resData.allCurrentLocalisedAssetComparativeRevisions?.pageInfo.hasNextPage) {
        await getAllRevisionsIds({
          variables: {
            offset: MAX_RECORDING_IDS,
            first: MAX_RECORDING_IDS,
            localisedAsset_Line_StringId_Icontains: searchStringId,
            characterId: selectedCharacter,
            sectionId: selectedSection,
            languageId: selectedLanguage,
            lengthDifferenceFilter: selectedLength,
            perceivedLoudnessDifferenceFilter: selectedLoudness,
            trailingSilenceBackDifferenceFilter: selectedPaddingBack,
            trailingSilenceFrontDifferenceFilter: selectedPaddingFront,
            peakLevelDifferenceFilter: selectedPeakLevel,
            orderBy,
            projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
            ...booleanQueryVars,
          },
        });
      }
    },
  });

  // eslint-disable-next-line camelcase
  const sortRevisionsIds = useCallback((revisionsArray: Array<any>): Array<ISelectedRevision> => revisionsArray.reduce((filtered, edge) => {
    if (edge?.node?.id && edge?.node.localisedAsset?.line.script.id) {
      filtered.push({
        revisionId: edge.node.id,
        scriptId: edge?.node.localisedAsset?.line.script.id,
      });
    }
    return filtered;
  }, [] as Array<ISelectedRevision>), []);

  const getAndSetAllRevisionsIds = async () => {
    const res = await getAllRevisionsIds({
      variables: {
        first: MAX_RECORDING_IDS,
        localisedAsset_Line_StringId_Icontains: searchStringId,
        characterId: selectedCharacter,
        sectionId: selectedSection,
        languageId: selectedLanguage,
        lengthDifferenceFilter: selectedLength,
        perceivedLoudnessDifferenceFilter: selectedLoudness,
        trailingSilenceBackDifferenceFilter: selectedPaddingBack,
        trailingSilenceFrontDifferenceFilter: selectedPaddingFront,
        peakLevelDifferenceFilter: selectedPeakLevel,
        orderBy,
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        ...booleanQueryVars,
      },
    });
    const revisions = sortRevisionsIds(res.data?.allCurrentLocalisedAssetComparativeRevisions?.edges || []);
    setSelectedRevisions(revisions || []);
  };
  const handleSelectAll = async () => {
    if (!allRevisionsIdsData) {
      await getAndSetAllRevisionsIds();
    } else {
      const revisions = sortRevisionsIds(allRevisionsIdsData?.allCurrentLocalisedAssetComparativeRevisions?.edges || []);
      setSelectedRevisions(revisions || []);
    }
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStringValue(event.target.value);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
  const handleSearch = () => {
    setSearchStringId(searchStringValue);
  };

  const handleFiltersOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (allRevisionsIdsData) {
      getAndSetAllRevisionsIds().then();
    }
  }, [
    searchStringId,
    selectedCharacter,
    selectedSection,
    comparativeSelectedBitDepth,
    comparativeSelectedChannel,
    comparativeSelectedSampleRate,
    selectedLanguage,
    selectedLength,
    selectedLoudness,
    selectedPaddingBack,
    selectedPaddingFront,
    selectedPeakLevel,
  ]);

  useEffect(() => {
    if (!context?.open) {
      fetchMore({ variables: { offset: page * pageSize, first: pageSize, orderBy } }).then();
    }
  }, [context?.open]);

  return (
    <>
      <RecordingsFilters
        table={tableType.COMPARATIVE}
        isOpen={isOpen}
        characterOptions={charactersData?.allCharacters?.edges}
        sectionOptions={sectionsData?.allSections?.edges}
        languageOptions={languagesData?.allLanguages?.edges}
        setIsOpen={setIsOpen}
        setSelectedCharacter={setSelectedCharacter}
        setSelectedSection={setSelectedSection}
        setSelectedLanguage={setSelectedLanguage}
        setSelectedStatus={setSelectedStatus}
        setSelectedLoudness={setSelectedLoudness}
        setSelectedSampleRate={setComparativeSelectedSampleRate}
        setSelectedChannel={setComparativeSelectedChannel}
        setSelectedPaddingFront={setSelectedPaddingFront}
        setSelectedPaddingBack={setSelectedPaddingBack}
        setSelectedLength={setSelectedLength}
        setSelectedBitDepth={setComparativeSelectedBitDepth}
        setSelectedPeakLevel={setSelectedPeakLevel}
      />
      <Box className={classes.actionsContainer}>
        <Box className={classes.searchContainer}>
          <TextField
            sx={{ marginRight: '30px' }}
            value={searchStringValue}
            onChange={handleSearchChange}
            fullWidth
            size="small"
            label="Search..."
          />
          <StyledButton variant="contained" action={handleSearch} label="Search" />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledButton action={handleFiltersOpen} variant="contained" label="Filters" />
        </Box>
      </Box>
      <Box className={classes.fixSection}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {
          allRevisionsIdsLoading
            ? <CircularProgress className={classes.circularProgressWrapper} />
            : (
          /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <Link
                component="button"
                className={classes.linkButton}
                onClick={handleSelectAll}
              >
                Select all
              </Link>
            )
        }
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          className={classes.linkButton}
          onClick={() => (setSelectedRevisions([]))}
        >
          Unselect all
        </Link>
        <StyledButton
          variant="contained"
          disabled={!selectedRevisions.length || allRevisionsIdsLoading}
          label={`Process ${selectedRevisions.length} ${selectedRevisions.length === 1 ? 'line' : 'lines'}`}
          action={() => {
            context?.setOpen(true);
            context?.setIsGeneralView(false);
          }}
        />
      </Box>
      {data?.allCurrentLocalisedAssetComparativeRevisions?.edges
        && !loading
        && !charactersLoading
        && !sectionsLoading
        && !languagesLoading ? (
          <ComparativeTable
            data={data?.allCurrentLocalisedAssetComparativeRevisions?.edges}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            selectedRevisions={selectedRevisions}
            setSelectedRevisions={setSelectedRevisions}
          />

        ) : (
          <PageLoader />
        )}
      {data?.allCurrentLocalisedAssetComparativeRevisions?.totalCount && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          labelRowsPerPage=""
          component="div"
          count={data?.allCurrentLocalisedAssetComparativeRevisions?.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onMouseOver={() => fetchMore({ variables: { offset: (page + 1) * pageSize, first: pageSize, orderBy } })}
        />
      )}
      {!!selectedRevisions.length && context?.open && <FixRecordingsDialog selectedRevisions={selectedRevisions} />}
    </>
  );
};

export default ComparativeAnalysisContainer;
