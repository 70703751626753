import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Box, Dialog, DialogActions, DialogContent, FormControl, MenuItem, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { RevisionContext } from '../../../../context/RevisionContext/RevisionContext';
import { CREATE_SOUND_FILE, DELETE_SOUND_FILE, UPDATE_REVISION } from '../../../../graphql/mutations';
import {
  GET_LINE,
  GET_RECORDING_STATUS_BY_LANGUAGE, GET_RECORDINGS_AND_TEXT_DATA,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../../graphql/queries';
import useUploadSoundFileToS3 from '../../../../hooks/useUploadSoundFileToS3';
import { defaultRevisionData, statusOptions } from '../../../../constants/revisions';
import FileDropzone from '../../../../common/FileDropzone/FileDropzone';
import { audioFileExtension, MAX_SOUND_FILE_SIZE } from '../../../../constants/audioFile';
import { StyledButton } from '../../../../common/StyledButton';
import { IUpdateRevisionDialogProps } from './types';
import {
  updateLocalisedAssetRevision as updateLocalisedAssetRevisionProps,
} from '../../../../graphql/mutations/__generated__/updateLocalisedAssetRevision';
import { createSoundFile as createSoundFileType } from '../../../../graphql/mutations/__generated__/createSoundFile';
import CustomAudioPlayer from '../../../../common/CustomAudioPlayer/CustomAudioPlayer';
import { useAswFileLink } from '../../../../hooks';
import { base64Decoding } from '../../../../helpers';
import { deleteSoundfile as deleteSoundfileType } from '../../../../graphql/mutations/__generated__/deleteSoundfile';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../../constants/localStorage';

const UpdateRevisionDialog: React.FC<IUpdateRevisionDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
  lineData,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const classes = useStyles();
  const context = useContext(RevisionContext);

  const currentRevisionId = context?.revisionData?.id;
  const getArrayOfCurrentIds = lineData?.line?.localisedassetSet?.edges.map((item) => item?.node?.currentSelect?.id);
  const isCurrentRevision = getArrayOfCurrentIds?.includes(currentRevisionId);

  const [statusValue, setStatusValue] = useState(context?.revisionData?.status?.value);
  const [textValue, setTextValue] = useState(context?.revisionData.text);
  const [notesValue, setNotesValue] = useState(context?.revisionData.notes);

  const [updateLocalisedAssetRevision] = useMutation<updateLocalisedAssetRevisionProps>(UPDATE_REVISION, {
    refetchQueries: [{ query: GET_LINE, variables: { id } },
      { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      'line'],
  });

  const { awsResponse } = useAswFileLink(context?.revisionData?.soundFilePath || '');

  const [createSoundFile] = useMutation<createSoundFileType>(CREATE_SOUND_FILE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const [deleteSoundFile] = useMutation<deleteSoundfileType>(DELETE_SOUND_FILE, {
    onCompleted: (data) => {
      if (data.deleteSoundfile?.ok) {
        context?.setRevisionData((prevState) => ({
          ...prevState,
          isSoundFileExist: false,
          soundFilePath: '',
          soundFileId: '',
        }));
      }
    },
  });

  const {
    presignedUrlLoading,
    handleDnD,
    droppedFileName,
    soundFileId,
    setDroppedFile,
  } = useUploadSoundFileToS3(open);

  useEffect(() => {
    if (context) {
      setDroppedFile(null);
      setStatusValue(context.revisionData.status.value);
      setTextValue(context.revisionData.text);
      setNotesValue(context.revisionData.notes);
    }
  }, [open]);

  useEffect(() => {
    if (context?.revisionData.id && soundFileId && !presignedUrlLoading) {
      createSoundFile({
        variables: {
          createSoundFile: {
            id: soundFileId,
            projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
            localised_asset_revision: base64Decoding(context?.revisionData.id),
            isRobovoice: false,
          },
        },
      }).then();
    }
  }, [presignedUrlLoading, soundFileId]);

  const handleUpdateRevision = async (
    revisionId: string | undefined,
    status: string | undefined,
    text: string | undefined,
    notes: string | undefined,
    // eslint-disable-next-line camelcase
    revisionDate: Date,
    // eslint-disable-next-line camelcase
    sound_file_id: string | undefined,
    makeCurrent: boolean,
  ) => {
    if (!status) {
      return;
    }

    await updateLocalisedAssetRevision({
      variables: {
        localisedAssetRevisionUpdateData: {
          id: revisionId,
          status,
          text,
          notes,
          revisionDate,
          // eslint-disable-next-line camelcase
          sound_file_id,
        },
        makeCurrent,
      },
    });

    context?.setIsEditMode(false);
    context?.setRevisionData(defaultRevisionData);
    setOpen(!open);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesValue(event.target.value);
  };

  return (
    <Dialog open={open} onClose={handleDialogChange} fullWidth maxWidth="md">
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Box>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.STATUS_DESCRIPTION')}
              </Typography>
              <TextField
                id="revision-statuses"
                select
                sx={{ width: '100%' }}
                value={statusValue}
                onChange={(
                  _event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => setStatusValue(_event.target.value)}
                variant="outlined"
                size="small"
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.value} value={statusOption.value}>
                    {statusOption.placeholder}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.TEXT_DESCRIPTION')}
              </Typography>
              <FormControl className={classes.input} fullWidth>
                <TextField
                  multiline
                  rows={4}
                  value={textValue}
                  placeholder={t('LINES_PAGE.REVISION_PLACEHOLDER') || ''}
                  onChange={handleTextChange}
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.NOTES_DESCRIPTION')}
              </Typography>
              <FormControl className={classes.input} fullWidth>
                <TextField
                  multiline
                  rows={4}
                  value={notesValue}
                  placeholder={t('LINES_PAGE.NO_NOTES') || ''}
                  onChange={handleNotesChange}
                />
              </FormControl>
            </Box>
          </Box>
          {
            context?.revisionData.isSoundFileExist
              ? (
                <Box className={classes.soundPlayerSectionWrapper}>
                  <CustomAudioPlayer
                    isControlsDisabled
                    isTimeIndicatorsDisabled
                    awsFileLink={awsResponse}
                    fileExists={context?.revisionData.isSoundFileExist}
                  />
                  <StyledButton
                    variant="contained"
                    label="Delete"
                    action={() => deleteSoundFile({
                      variables: {
                        soundFileDeletionData: { id: base64Decoding(context?.revisionData.soundFileId) },
                      },
                    })}
                  />
                </Box>
              )
              : (
                <FileDropzone
                  handleDnD={handleDnD}
                  maxSize={MAX_SOUND_FILE_SIZE}
                  typeArr={[audioFileExtension]}
                  droppedFileName={droppedFileName}
                />
              )
          }
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between' }}
          className={classes.disableHorisontalPadding}
        >
          <StyledButton
            variant="outlined"
            width="210px"
            label={t('BUTTON.CANCEL')}
            action={handleDialogChange}
          />
          {isCurrentRevision ? (
            <StyledButton
              variant="contained"
              width="210px"
              label={t('BUTTON.SAVE')}
              action={
                    () => handleUpdateRevision(
                      context?.revisionData.id,
                      statusValue,
                      textValue,
                      notesValue,
                      new Date(),
                      undefined,
                      false,
                    )
                }
            />
          ) : (
            <>
              <StyledButton
                variant="outlined"
                width="210px"
                label={t('UPDATE_REVISION_DIALOG.DONT_MAKE_CURRENT_REVISION')}
                action={
                      () => handleUpdateRevision(
                        context?.revisionData.id,
                        statusValue,
                        textValue,
                        notesValue,
                        new Date(),
                        undefined,
                        false,
                      )
                    }
              />
              <StyledButton
                variant="contained"
                width="210px"
                label={t('UPDATE_REVISION_DIALOG.MAKE_CURRENT_REVISION')}
                action={
                      () => handleUpdateRevision(
                        context?.revisionData.id,
                        statusValue,
                        textValue,
                        notesValue,
                        new Date(),
                        undefined,
                        true,
                      )
                    }
              />
            </>
          )}

        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default UpdateRevisionDialog;
