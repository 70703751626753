import { Box, TextField, Typography } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import MenuProjectSample from '../../../assets/images/MenuProjectSample.png';
import { deleteProjectLogo } from '../../../graphql/mutations/__generated__/deleteProjectLogo';
import { base64Decoding, uploadFileToS3 } from '../../../helpers';
import { getUrlForUploadingLogo } from '../../../graphql/queries/__generated__/getUrlForUploadingLogo';
import { StyledButton } from '../../../common/StyledButton';
import { IProjectDetailsContainerProps } from './types';
import { useStyles } from './styles';
import { updateProject as updateProjectProps } from '../../../graphql/mutations/__generated__/updateProject';
import { UPDATE_PROJECT, UPDATE_PROJECT_LOGO, DELETE_PROJECT_LOGO } from '../../../graphql/mutations';
import { GET_PROJECT, GET_URL_FOR_UPLOADING_LOGO } from '../../../graphql/queries';
import { updateProjectLogo } from '../../../graphql/mutations/__generated__/updateProjectLogo';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';

const ProjectDetailsContainer: React.FC<IProjectDetailsContainerProps> = ({
  projectName,
  companyName,
  startDate,
  endDate,
  licenseKey,
  description,
  logoUrl,
}) => {
  const { t } = useTranslation();

  const [projectNameValue, setProjectNameValue] = useState(projectName);
  const [companyNameValue, setCompanyNameValue] = useState(companyName);
  const [startDateValue, setStartDateValue] = useState(startDate);
  const [endDateValue, setEndDateValue] = useState(endDate);
  const [licenseKeyValue, setLicenseKeyValue] = useState(licenseKey);
  const [descriptionValue, setDescriptionValue] = useState(description);
  const [fileExtension, setFileExtension] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isLogoHovered, setIsLogoHovered] = useState(false);

  const { id } = useParams();

  const inputFile = useRef<HTMLInputElement | null>(null);

  const [updateProject] = useMutation<updateProjectProps>(UPDATE_PROJECT, {
    refetchQueries: [
      { query: GET_PROJECT, variables: { id } },
      'project',
    ],
  });

  const [updateProjectLogoMutation] = useMutation<updateProjectLogo>(UPDATE_PROJECT_LOGO, {
    refetchQueries: [
      { query: GET_PROJECT, variables: { id } },
      'project',
    ],
  });

  const [deleteProjectLogoMutation] = useMutation<deleteProjectLogo>(DELETE_PROJECT_LOGO, {
    refetchQueries: [
      { query: GET_PROJECT, variables: { id } },
      'project',
    ],
  });

  const [updateLogo] = useLazyQuery<getUrlForUploadingLogo>(
    GET_URL_FOR_UPLOADING_LOGO,
    {
      variables: { projectId: base64Decoding(id || ''), extension: fileExtension },
      onCompleted: async (data) => {
        if (file && fileExtension) {
          try {
            await uploadFileToS3(file, data.getLogoPresignedUrlForUpload?.awsResponse || '');
            await updateProjectLogoMutation({
              variables: {
                projectId: base64Decoding(id || ''),
                logoId: data.getLogoPresignedUrlForUpload?.logoId,
                extension: fileExtension,
              },
              onCompleted: () => {
                setFile(null);
                setFileExtension('');
              },
            });
          } catch (error) {
            console.error(error);
          }
        }
      },
    },
  );

  const classes = useStyles();

  const handleProjectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectNameValue(event.target.value);
  };

  const handleCompanyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyNameValue(event.target.value);
  };

  const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStartDateValue(event.target.value);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEndDateValue(event.target.value);
  };

  const handleLicenseKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLicenseKeyValue(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
  };

  const handleUploadImage = () => {
    inputFile.current?.click();
  };

  const handleUpdateProject = () => {
    updateProject({
      variables: {
        projectUpdateData: {
          id,
          name: projectNameValue,
          description: descriptionValue,
          organisationName: companyNameValue,
          startDate: startDateValue,
          endDate: endDateValue,
        },
      },
    });
  };

  const handleUpdateLogoClick = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      setFileExtension(e.target.files[0].type.replace(/(.*)\//g, ''));
      setFile(e.target.files[0]);
      e.target.value = '';
      await updateLogo();
    }
  };

  return (
    <Box className={classes.rootContainer}>
      <Box className={classes.imageFieldsWrapper}>
        <Box className={classes.imageColumnContainer}>
          <Box
            className={classes.imageContainer}
            sx={{
              backgroundImage: `url(${logoUrl || MenuProjectSample})`,
            }}
            onMouseEnter={() => setIsLogoHovered(true)}
            onMouseLeave={() => setIsLogoHovered(false)}
          >
            {isLogoHovered && logoUrl && (
            <Box
              className={classes.imageHoverContainer}
              onClick={() => {
                if (logoUrl) {
                  deleteProjectLogoMutation({
                    variables: {
                      projectId: base64Decoding(id || ''),
                    },
                  });
                }
              }}
            >
              <Typography variant="body2" sx={{ color: '#fff' }}>
                <TrashIcon style={{ stroke: '#FFFFFF', marginTop: '4px' }} />
              </Typography>
            </Box>
            )}
          </Box>
          <StyledButton
            action={handleUploadImage}
            label={t('PROJECT_PAGE.UPLOAD_IMAGE') as string}
            margin="0 0 40px 0"
            width="210px"
            variant="contained"
          />
          <input
            type="file"
            id="file"
            ref={inputFile}
            accept=".gif,.jpg,.jpeg,.png,.bmp"
            style={{ display: 'none' }}
            onChange={handleUpdateLogoClick}
          />
        </Box>
        <Box className={classes.fieldsColumnContainer}>
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>{t('PROJECT_PAGE.PROJECT_NAME')}</Typography>
            <TextField
              fullWidth
              value={projectNameValue}
              onChange={handleProjectNameChange}
              placeholder={t('PROJECT_PAGE.PROJECT_NAME') as string}
              inputProps={{
                style: {
                  padding: '12px 10px',
                },
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>{t('PROJECT_PAGE.COMPANY_NAME')}</Typography>
            <TextField
              fullWidth
              value={companyNameValue}
              onChange={handleCompanyNameChange}
              placeholder={t('PROJECT_PAGE.COMPANY_NAME') as string}
              inputProps={{
                style: {
                  padding: '12px 10px',
                },
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography
              variant="body2"
              sx={{ marginBottom: '10px' }}
            >
              {t('PROJECT_PAGE.PROJECT_START_DATE')}
            </Typography>
            <TextField
              id="start-date"
              fullWidth
              placeholder={t('PROJECT_PAGE.PROJECT_START_DATE') as string}
              type="date"
              value={startDateValue}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  padding: '12px 10px',
                },
              }}
            />
          </Box>
          <Box className={classes.fieldContainer}>
            <Typography variant="body2" sx={{ marginBottom: '10px' }}>{t('PROJECT_PAGE.PROJECT_END_DATE')}</Typography>
            <TextField
              id="end-date"
              fullWidth
              placeholder={t('PROJECT_PAGE.PROJECT_END_DATE') as string}
              type="date"
              value={endDateValue}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  padding: '12px 10px',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.multilineFieldsContainer}>
        <Box className={classes.fieldContainer}>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{t('PROJECT_PAGE.PROJECT_DESCRIPTION')}</Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={descriptionValue}
            onChange={handleDescriptionChange}
            placeholder={t('PROJECT_PAGE.PROJECT_DESCRIPTION') as string}
            inputProps={{
              style: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box className={classes.fieldContainer}>
          <Typography variant="body2" sx={{ marginBottom: '10px' }}>{t('PROJECT_PAGE.PROJECT_LICENSE')}</Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            value={licenseKeyValue}
            disabled
            onChange={handleLicenseKeyChange}
            placeholder={t('PROJECT_PAGE.PROJECT_LICENSE') as string}
            inputProps={{
              style: {
                padding: '2px 10px',
              },
            }}
          />
        </Box>
        <Box sx={{ alignSelf: 'flex-end' }}>
          <StyledButton
            action={handleUpdateProject}
            width="150px"
            label={t('BUTTON.SAVE')}
            variant="contained"
          />
        </Box>
      </Box>
    </Box>
  );
};
export default ProjectDetailsContainer;
