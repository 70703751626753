import React, { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';
import { deleteRefreshToken as IDeleteRefreshToken } from '../../../graphql/mutations/__generated__/deleteRefreshToken';
import { DELETE_REFRESH_TOKEN, DELETE_TOKEN } from '../../../graphql/mutations';
import { deleteToken as IDeleteToken } from '../../../graphql/mutations/__generated__/deleteToken';
import { AuthContext } from '../../../context/AuthContext/AuthContext';

interface INoProjectDialogProps {
    open: boolean;
}

const NoProjectDialog: React.FC<INoProjectDialogProps> = ({ open }: INoProjectDialogProps) => {
  const classes = useStyles();

  const [deleteRefreshToken] = useMutation<IDeleteRefreshToken>(DELETE_REFRESH_TOKEN);
  const [deleteToken] = useMutation<IDeleteToken>(DELETE_TOKEN);

  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const logOut = async () => {
    await deleteRefreshToken();
    await deleteToken();
    context?.setIsLoggedIn(false);
    localStorage.removeItem(LOCALE_STORAGE_PROJECT_ID);
    navigate('/login');
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        logOut();
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent className={classes.disableHorizontalPadding}>
          <Typography>
            You’re not assigned to any projects, please contact your administrator.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorizontalPadding}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="Ok"
            action={() => logOut()}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default NoProjectDialog;
