import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  autocompleteContainer: {
    marginLeft: '42px',
    marginRight: '42px',
    marginBottom: '15px',
  },
  autocomplete: {
    width: '350px',
    padding: '0px 0px',
    '& .MuiOutlinedInput-root': { padding: '0' },
  },
  comparativeSelect: {
    width: '350px',
    '& .MuiSelect-select': { padding: '0' },
    '& .MuiInputBase-root': { padding: '7.5px 4px 7.5px 6px' },
  },
  selectOption: {
    padding: '10px 15px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '42px',
    marginRight: '42px',
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '32px 42px',
  },

  rootWrapper: {
    position: 'fixed',
    top: '80px',
    right: '50px',
    zIndex: '2',
  },
  sliderContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },

}));
