import { makeStyles } from '@mui/styles';
import { lightBlue, secondary, white } from '../../../constants/colors';

export const useStyles = makeStyles({
  whiteRow: {
    backgroundColor: white,
  },
  lightBlueRow: {
    backgroundColor: lightBlue,
  },
  tableWrapper: {
    boxSizing: 'border-box',
    maxHeight: '187px',
    minWidth: '331px',
    border: '1px solid #D9D9D9',
    borderRadius: '8px',
    marginTop: '13px',
    marginBottom: '31px',
  },
  flexCenterAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  checkbox: {
    '&.Mui-checked': {
      color: secondary,
    },
  },
});
