import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useContext } from 'react';

import { allLanguageNames } from '../../constants/languages';
import { LangContext } from '../../context/LangContext/LangContext';
import { ILanguageAutocompleteProps } from './types';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges_node } from '../../graphql/queries/__generated__/allLanguages';
import { FlagVisualisation } from '../FlagVisualisation';

const LanguageAutocomplete:React.FC<ILanguageAutocompleteProps> = ({ isFlagSelection, value, setValue }) => {
  const context = useContext(LangContext);

  const defaultProps = {
    options: allLanguageNames,
    // eslint-disable-next-line camelcase, max-len
    getOptionLabel: (option: allLanguages_allLanguages_edges_node) => (isFlagSelection ? option.languageFlag : option.name),
  };

  return (
    <Autocomplete
      {...defaultProps}
      autoHighlight
      disableClearable
      inputValue={isFlagSelection ? value?.languageFlag : value?.name}
      value={value}
      isOptionEqualToValue={(option, val) => (option.name === val.name || val.name === '')}
      onChange={(_event, newValue) => {
        setValue(newValue);
        if (newValue || newValue === '') {
          if (!isFlagSelection) {
            context?.setLanguageData({
              ...context?.languageData,
              name: newValue.name,
              languageCode: newValue.languageCode,
              languageFlag: newValue.languageFlag,
            });
          } else {
            context?.setLanguageData({
              ...context?.languageData,
              languageFlag: newValue.languageFlag,
            });
          }
        }
      }}
      // eslint-disable-next-line camelcase
      renderOption={(props, option: allLanguages_allLanguages_edges_node) => (
        <Box
          component="li"
          sx={{
            '& > img': { mr: 2, flexShrink: 0 },
          }}
          {...props}
        >
          <FlagVisualisation optionFlag={option.languageFlag} />
          {isFlagSelection ? option.languageFlag : option.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          inputProps={{
            ...params.inputProps,

          }}
        />
      )}
    />
  );
};
export default LanguageAutocomplete;
