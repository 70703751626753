import { Box } from '@mui/material';
import React from 'react';

import Todo from '../Todo/Todo';
import { ITodoListTableProps } from './types';

const TodoListTable:React.FC<ITodoListTableProps> = ({ todos }) => (
  <Box sx={{ overflowY: 'auto', width: '100%' }}>
    {todos.map((todo, index) => <Todo sequenceNumber={index + 1} key={todo?.node?.id} todo={todo?.node} />)}
  </Box>
);
export default TodoListTable;
