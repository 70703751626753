import React, { useState } from 'react';

import { defaultCharacterData } from '../../constants/characters';
import { ICharContextProps } from './types';

export const CharContext = React.createContext<ICharContextProps | null>(null);

const CharContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [characterData, setCharacterData] = useState(defaultCharacterData);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <CharContext.Provider value={{
      characterData,
      setCharacterData,
      isEditMode,
      setIsEditMode,
    }}
    >
      {children}
    </CharContext.Provider>
  );
};

export default CharContextProvider;
