import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    marginTop: '102px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 auto',
  },

});
