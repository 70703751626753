import React from 'react';
import { Button } from '@mui/material';
import { IShowMoreButtonProps } from './types';

const ShowMoreButton: React.FC<IShowMoreButtonProps> = ({ isShowMore, setState }): JSX.Element => (
  <Button
    size="small"
    variant="text"
    color="secondary"
    onClick={() => setState((prevState: boolean) => !prevState)}
  >
    {isShowMore ? 'Show less' : 'Show more'}
  </Button>
);

export default ShowMoreButton;
