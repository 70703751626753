import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import React from 'react';
import { useQuery } from '@apollo/client';
import { projectPrimaryLanguage } from '../../../graphql/queries/__generated__/projectPrimaryLanguage';
import { GET_ALL_LANGUAGES, GET_PRIMARY_LANGUAGE } from '../../../graphql/queries';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { useStyles } from './styles';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { ReactComponent as RecordingsIcon } from '../../../assets/icons/recordings-icon.svg';
import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

interface IDashboardStatsCardProps {
  withFlag?: boolean;
  title?: string;
  subtitle?: string;
  iconType?: 'recordings' | 'file';
  completedAmount?: number | null;
  totalAmount?: number | null;
}

const DashboardStatsCard: React.FC<IDashboardStatsCardProps> = ({
  withFlag, title, iconType, subtitle, completedAmount, totalAmount,
}) => {
  const classes = useStyles();

  const {
    data: scriptPrimLangData,
  } = useQuery<projectPrimaryLanguage>(GET_PRIMARY_LANGUAGE, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  const { data: languagesData } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const primaryLanguageId = scriptPrimLangData?.project?.currentScript?.primaryLanguage?.id;
  const primaryLanguage = languagesData?.allLanguages?.edges?.find(
    (edge: any) => edge?.node?.id === primaryLanguageId,
  )?.node;

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ padding: '16px !important' }}>
        <Box className={classes.cardWrapper}>
          <Box>
            <Box sx={{ marginBottom: '12px' }}>
              <Typography
                variant="subtitle2"
                display="inline"
                className={classes.languageCell}
                sx={{ fontWeight: 'bold' }}
              >
                {withFlag ? (
                  <>
                    <FlagVisualisation optionFlag={primaryLanguage?.languageFlag || ''} />
                    {primaryLanguage?.name}
                  </>
                ) : title}

              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2" className={classes.amount}>
                {subtitle}
                <br />
                <Typography sx={{ fontSize: '24px', fontWeight: 600 }}>
                  {typeof completedAmount === 'number' ? completedAmount : '--'}
                  {' '}
                  /
                  {' '}
                  {typeof totalAmount === 'number' ? totalAmount : '--'}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box>
            <div className={classes.iconWrapper}>
              {iconType === 'recordings' && <RecordingsIcon /> }
              {iconType === 'file' && <FileIcon /> }
            </div>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DashboardStatsCard;
