import React, {
  ChangeEvent, useContext, useEffect, useState,
} from 'react';
import {
  Box, Dialog, TextField, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useStyles } from './styles';
import { IResetPasswordDialogProps } from './types';
import { StyledButton } from '../StyledButton';
import { DELETE_REFRESH_TOKEN, DELETE_TOKEN, UPDATE_USER } from '../../graphql/mutations';
import { updateUser as userUpdateType } from '../../graphql/mutations/__generated__/updateUser';
import { UserContext } from '../../context/UserContext/UserContext';
import { deleteRefreshToken as IDeleteRefreshToken } from '../../graphql/mutations/__generated__/deleteRefreshToken';
import { deleteToken as IDeleteToken } from '../../graphql/mutations/__generated__/deleteToken';
import { AuthContext } from '../../context/AuthContext/AuthContext';

const ResetPasswordDialog: React.FC<IResetPasswordDialogProps> = ({ open }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, setUser } = useContext(UserContext);
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const passwordRegEx = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{9,}$/;

  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [deleteRefreshToken] = useMutation<IDeleteRefreshToken>(DELETE_REFRESH_TOKEN);
  const [deleteToken] = useMutation<IDeleteToken>(DELETE_TOKEN);
  const [updateUser, { data, loading, error }] = useMutation<userUpdateType>(UPDATE_USER);

  const handleLogOut = async () => {
    await deleteRefreshToken();
    await deleteToken();
    context?.setIsLoggedIn(false);
    setUser(null);
    navigate('/login');
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleRepeatPasswordChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRepeatPassword(event?.target?.value);
  };

  const handleSubmit = async () => {
    if (!password || !repeatPassword) {
      setErrorMessage(t('RESET_PASSWORD_DIALOG.PLEASE_FILL_THE_FIELDS') as string);
    } else if (!passwordRegEx.test(password)) {
      setErrorMessage(t('RESET_PASSWORD_DIALOG.REQUIREMENTS_HAVE_NOT_BEEN_MET') as string);
    } else if (password !== repeatPassword) {
      setErrorMessage(t('RESET_PASSWORD_DIALOG.PASSWORDS_DO_NOT_MATCH') as string);
    } else {
      await updateUser({
        variables: {
          userUpdateData: {
            id: user?.id || '',
            password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!loading && data?.updateUser?.User) {
      setUser((prevState: any) => ({ ...prevState, ...data.updateUser?.User }));
    }
  }, [data, loading]);

  return (
    <Dialog
      open={open}
      onClose={() => ({})}
      disableEscapeKeyDown={false}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogWrapper}>
        <Typography fontWeight={600} margin="20px 0 40px">
          {t('RESET_PASSWORD_DIALOG.RESET_PASSWORD')}
          :
        </Typography>
        <Typography>
          {t('RESET_PASSWORD_DIALOG.REQUIREMENTS')}
          :
        </Typography>
        <ul className={classes.listWrapper}>
          <li>{t('RESET_PASSWORD_DIALOG.MINIMUM_CHARACTERS')}</li>
          <li>{t('RESET_PASSWORD_DIALOG.SPECIAL_CHARACTER')}</li>
        </ul>
        <Box className={classes.passwordInputsWrapper}>
          <TextField
            id="outlined-password-input"
            label={t('RESET_PASSWORD_DIALOG.NEW_PASSWORD')}
            type="password"
            autoComplete="current-password"
            onChange={handlePasswordChange}
          />
          <TextField
            id="outlined-password-input"
            label={t('RESET_PASSWORD_DIALOG.CONFIRM_NEW_PASSWORD')}
            type="password"
            autoComplete="current-password"
            onChange={handleRepeatPasswordChange}
          />
          {
            errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
            )
          }
          {
            error && (
              <Typography variant="body2" color="error">
                {error.message}
              </Typography>
            )
          }
        </Box>
        <Box className={classes.buttonWrapper}>
          <StyledButton label={t('BUTTON.LOG_OUT')} variant="outlined" action={handleLogOut} />
          <StyledButton label={t('BUTTON.SUBMIT')} variant="contained" action={handleSubmit} />
        </Box>
      </Box>
    </Dialog>
  );
};

export default ResetPasswordDialog;
