import { Button } from '@mui/material';
import React from 'react';

import { useStyles } from './styles';
import { IStyledButtonProps } from './types';

const StyledButton: React.FC<IStyledButtonProps> = ({
  label,
  action,
  width,
  padding,
  margin,
  variant,
  startIcon,
  endIcon,
  textColor,
  fontSize,
  fontWeight,
  disabled,
  sx,
  cyTestId,
}) => {
  const classes = useStyles();
  const styledVariants = {
    outlined: classes.outlinedButton,
    contained: classes.button,
    text: classes.textButton,
  };
  return (
    <Button
      className={styledVariants[variant]}
      variant={variant}
      sx={{
        ...sx,
        width,
        padding,
        margin,
        color: `${textColor} !important`,
        fontSize: `${fontSize} !important`,
        fontWeight: `${fontWeight} !important`,
      }}
      onClick={action}
      disableElevation
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      data-cy={cyTestId}
      disableRipple
      disableFocusRipple
    >
      {label}
    </Button>
  );
};
export default StyledButton;
