import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import {
  GET_ALL_CHARACTERS,
  GET_ALL_LANGUAGES,
  GET_ALL_SECTIONS,
  SCRIPT_EXPORT_REQUEST_STATUS,
} from '../../../graphql/queries';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import DownloadScriptForm from '../DownloadScriptForm/DownloadScriptForm';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { EXPORT_SCRIPT } from '../../../graphql/mutations';
import DownloadDialog from '../../../common/DownloadDialog/DownloadDialog';
import { scriptExportRequestStatus } from '../../../graphql/queries/__generated__/scriptExportRequestStatus';
import { exportScript as exportScriptType } from '../../../graphql/mutations/__generated__/exportScript';
import { ExportScriptInput } from '../../../../__generated__/globalTypes';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const DownloadScriptContainer: React.FC = () => {
  const { t } = useTranslation();

  const [requestId, setRequestId] = useState<string>('');
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(GET_ALL_CHARACTERS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const { loading: languagesLoading, data: languagesData } = useQuery<allLanguages>(GET_ALL_LANGUAGES, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [exportScript] = useMutation<exportScriptType>(
    EXPORT_SCRIPT,
    {
      onCompleted: (data) => setRequestId(data?.exportScript?.request?.id || ''),
    },
  );
  const {
    data: scriptExportData, loading: scriptExportLoading, startPolling, stopPolling,
  } = useQuery<scriptExportRequestStatus>(
    SCRIPT_EXPORT_REQUEST_STATUS,
    {
      variables: { id: requestId },
      skip: !requestId,
    },
  );

  const downloadFileUrl = scriptExportData?.scriptExportRequestStatus?.s3DownloadLink || '';
  // const fileType = SCRIPT_FILE_TYPES[0];

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = downloadFileUrl;
    link.download = ''; // set the download attribute to the desired filename
    link.rel = 'noreferrer'; // set the rel attribute to noreferrer
    link.target = '_blank'; // set the target attribute to _blank
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (requestId) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [requestId]);

  useEffect(() => {
    if (!scriptExportLoading) {
      if (scriptExportData?.scriptExportRequestStatus?.isFinished) {
        stopPolling();
        setIsPolling(false);
        downloadFile();
      }
      if (!scriptExportData?.scriptExportRequestStatus) {
        stopPolling();
        setIsPolling(false);
      }
      if (scriptExportData?.scriptExportRequestStatus?.hasFailed) {
        stopPolling();
      }
    }
  }, [scriptExportData, scriptExportLoading]);

  const handleExportScript = (
    {
      projectId,
      languagesInSeparateFiles,
      charactersInSeparateFiles,
      sectionsInSeparateFiles,
      characterIds,
      languageIds,
      sectionIds,
    }: ExportScriptInput,
  ) => {
    if (!projectId) {
      return;
    }
    exportScript({
      variables: {
        exportScriptOptions: {
          projectId,
          characterIds,
          languageIds,
          sectionIds,
          languagesInSeparateFiles,
          charactersInSeparateFiles,
          sectionsInSeparateFiles,
          separatorCharacters: '_',
        },
      },
    });
  };

  if (charactersLoading || sectionsLoading || languagesLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: '40px', marginButton: '36px', fontWeight: 'bold' }}>
        {t('DOWNLOAD_SCRIPT_PAGE.TITLE')}
      </Typography>
      <DownloadScriptForm
        charactersList={charactersData?.allCharacters?.edges}
        sectionsList={sectionsData?.allSections?.edges}
        languagesList={languagesData?.allLanguages?.edges}
        exportScript={handleExportScript}
        projectId={localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || ''}
      />
      <DownloadDialog
        isPolling={isPolling}
        stopPolling={stopPolling}
        setIsPolling={setIsPolling}
        isFailed={scriptExportData?.scriptExportRequestStatus?.hasFailed || false}
      />
    </>
  );
};
export default DownloadScriptContainer;
