/* ========================================= */
/* RECORDINGS VALUES */
/* ========================================= */

import { BIT_DEPTH_VIEW_VALUES } from '../revisions';

export const RECORD_FILE_TYPES = ['zip'];

export const MAX_RECORDING_IDS = 20000;
export const maxRecordingSize = 5000;

export const recordingsSampleRateOptions = ['16000', '32000', '44100', '48000', '88200', '96000', '192000'];

export const recordingsChannelOptions = ['1', '2', '3', '4', '5', '6', '7', '8'];

export const recordingsBitDepthOptions: string[] = BIT_DEPTH_VIEW_VALUES;

export const initialSliderValue = '-inf,inf,false';

export const recordingsInitialData = {
  loudnessMin: -100,
  loudnessMax: 0,

  paddingMin: 0,
  paddingMax: 10,

  lengthMin: 0,
  lengthMax: 120,

  peakLevelMin: -100,
  peakLevelMax: 10,

};

export const tableType: { COMPARATIVE: 'comparative', RECORDINGS: 'recordings' } = {
  COMPARATIVE: 'comparative',
  RECORDINGS: 'recordings',
};

/* ========================================= */
/* COMPARATIVE VALUES */
/* ========================================= */

export const comparativeFiltersValue = 'all';
export const comparativeSampleRateOptions = [
  {
    value: comparativeFiltersValue,
    label: 'All',
  },
  {
    value: '1',
    label: 'The same',
  },
  {
    value: '0',
    label: 'Different',
  },
];

export const comparativeChannelOptions = [
  {
    value: comparativeFiltersValue,
    label: 'All',
  },
  {
    value: '1',
    label: 'The same',
  },
  {
    value: '0',
    label: 'Different',
  },
];

export const comparativeBitDepthOptions = [
  {
    value: comparativeFiltersValue,
    label: 'All',
  },
  {
    value: '1',
    label: 'The same',
  },
  {
    value: '0',
    label: 'Different',
  },
];

export const comparativeInitialData = {
  peakLevelMin: 0,
  peakLevelMax: 100,

  lengthMin: 0,
  lengthMax: 100,

  loudnessMin: 0,
  loudnessMax: 100,

  paddingMin: 0,
  paddingMax: 100,
};
