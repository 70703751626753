import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { getPresignedUrlForImport } from '../graphql/queries/__generated__/getPresignedUrlForImport';
import { GET_PRESIGNED_URL_FOR_IMPORT } from '../graphql/queries';
import { uploadFileToS3 } from '../helpers';
import { LOCALE_STORAGE_PROJECT_ID } from '../constants/localStorage';

const useUploadFileToS3 = () => {
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [
    getPresignedUrl,
    {
      loading: presignedUrlLoading,
      data: presignedUrlData,
    },
  ] = useLazyQuery<getPresignedUrlForImport>(
    GET_PRESIGNED_URL_FOR_IMPORT,
  );

  const handleDnD = useCallback((file: File) => {
    const regEx = /\.[^.]+$/;
    const match = file.name.match(regEx);
    if (match) {
      setDroppedFile(file);
      getPresignedUrl({
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          extension: match[0].replace('.', '') || '',
        },
      }).then();
    }
  }, []);

  useEffect(() => {
    if (!presignedUrlLoading && presignedUrlData) {
      uploadFileToS3(droppedFile, presignedUrlData.getPresignedUrlForImport?.awsResponse).then();
    }
  }, [presignedUrlData, presignedUrlLoading]);

  return {
    presignedUrlData, presignedUrlLoading, handleDnD, droppedFileName: droppedFile?.name,
  };
};

export default useUploadFileToS3;
