import { makeStyles } from '@mui/styles';
import { secondary } from '../../constants/colors';

export const useStyles = makeStyles({
  pageWrapper: {
    display: 'flex',
    height: '100vh',
    background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%)',
  },
  imageWrapper: {
    position: 'inherit',
    top: '-15%',
    left: '14vw',
    width: '15vw',
  },
  mainWrapper: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  errorCodeWrapper: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '13.5vw',
    lineHeight: '100%',
    color: secondary,
    textShadow: '5px 5px 250px rgba(255, 107, 64, 0.35)',
  },
  textWrapper: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '1.1vw',
  },
});
