import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { HeadCell, IRecordingsTableProps } from './types';
import { ReactComponent as TopRhombIcon } from '../../../assets/icons/top-rhomb-icon.svg';
import { ReactComponent as BottomRhombIcon } from '../../../assets/icons/bottom-rhomb-icon.svg';
import { RecordingsTableRow } from '../RecordingsTableRow';

const headCells: readonly HeadCell[] = [

  {
    id: 'localised_asset__line__string_id',
    numeric: false,
    disablePadding: true,
    label: 'Line ID',
  },
  {
    id: 'localised_asset__language__language_code',
    numeric: false,
    disablePadding: true,
    label: 'Language',
  },
  {
    id: 'audiofile',
    numeric: false,
    disablePadding: true,
    label: 'Listen',
  },
  {
    id: 'length_in_seconds',
    numeric: false,
    disablePadding: true,
    label: 'Length (s)',
  },
  {
    id: 'sound_file__sound_metadata__sample_rate',
    numeric: false,
    disablePadding: true,
    label: 'Sample Rate',
  },
  {
    id: 'sound_file__sound_metadata__number_of_channels',
    numeric: false,
    disablePadding: true,
    label: 'Channels',
  },
  {
    id: 'sound_file__sound_metadata__bit_depth',
    numeric: false,
    disablePadding: true,
    label: 'Bit Depth',
  },
  {
    id: 'sound_file__sound_metadata__perceived_loudness',
    numeric: false,
    disablePadding: true,
    label: 'Loudness (LKFS)',
  },
  {
    id: 'sound_file__sound_metadata__peak_level',
    numeric: false,
    disablePadding: true,
    label: 'Peak (dBFS)',
  },
  {
    id: 'trailing_silence_front_in_seconds',
    numeric: false,
    disablePadding: true,
    label: 'Padding Front (s)',
  },
  {
    id: 'trailing_silence_back_in_seconds',
    numeric: false,
    disablePadding: true,
    label: 'Padding Back (s)',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

const RecordingsTable: React.FC<IRecordingsTableProps> = ({
  data,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  selectedRevisions,
  setSelectedRevisions,
}) => {
  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(isAsc ? `-${property}` : property);
  };

  const sortTable = (property: string) => {
    handleRequestSort(property);
  };

  return (
    <div>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  { headCell.id !== 'audiofile'
                   && headCell.id !== 'actions'
                   && headCell.id !== 'type'
                   && headCell.id !== 'sample_rate'

                    ? (
                      <Button
                        onClick={() => {
                          sortTable(headCell.id);
                        }}
                        sx={{ textTransform: 'none' }}
                      >
                        <Typography variant="body2" sx={{ marginRight: '10px' }}>
                          {headCell.label}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <TopRhombIcon />
                          <BottomRhombIcon />
                        </Box>
                      </Button>
                    ) : (
                      <Typography variant="body2" sx={{ marginRight: '10px' }}>
                        {headCell.label}
                      </Typography>
                    )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((currentLocalisedAssetRevision, index) => (
            <RecordingsTableRow
              data={currentLocalisedAssetRevision?.node}
              key={uuidv4()}
              isEven={index % 2 === 0}
              selectedRevisions={selectedRevisions}
              setSelectedRevisions={setSelectedRevisions}
            />
          ))}
        </TableBody>
      </Table>

    </div>
  );
};
export default RecordingsTable;
