export const primary = '#191B40';
export const secondary = '#FF6B40';
export const secondaryLight = '#FF7752';
export const info = '#979EAB';
export const lightBlue = '#E0E7F6';
export const white = '#FFFFFF';
export const darkBlue = '#242856';
export const dark = '#1F224C';
export const lightGray = '#656A73';
export const dangerous = '#FF0000';
