import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import { GET_ALL_LANGUAGES, GET_PRIMARY_LANGUAGE } from '../../graphql/queries';
import { allLanguages } from '../../graphql/queries/__generated__/allLanguages';
import { LanguagesTable } from '../../components/Language/LanguagesTable';
import { StyledButton } from '../../common/StyledButton';
import { CreateLanguageDialog } from '../../components/Language/CreateLanguageDialog';
import { DeleteLanguageDialog } from '../../components/Language/DeleteLanguageDialog';
import { useStyles } from './styles';
import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';
import { sortLanguagesInAlphabeticalOrder } from '../../helpers';
import { projectPrimaryLanguage } from '../../graphql/queries/__generated__/projectPrimaryLanguage';
import ChangeReferenceLanguageDialog
  from '../../components/Language/ChangeReferenceLanguageDialog/ChangeReferenceLanguageDialog';

const Languages: React.FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [changeReferenceOpen, setChangeReferenceOpen] = useState(false);
  const [languageToSelect, setReferenceToSelect] = useState<string>();

  const classes = useStyles();

  const { data: scriptPrimLangData, loading: primLangLoading } = useQuery<projectPrimaryLanguage>(GET_PRIMARY_LANGUAGE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [referredLanguage, setReferredLanguage] = useState(
    scriptPrimLangData?.project?.currentScript?.primaryLanguage?.id,
  );

  useEffect(() => {
    if (!primLangLoading) {
      setReferredLanguage(scriptPrimLangData?.project?.currentScript?.primaryLanguage?.id);
    }
  }, [primLangLoading]);

  const handleDialogChange = () => {
    setOpen(!open);
  };
  const handleDeleteDialogChange = () => {
    setDeleteOpen(!deleteOpen);
  };
  const handleChangeReferenceDialogChange = () => {
    setChangeReferenceOpen(!changeReferenceOpen);
  };

  const { data: languagesData } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const {
    data: primaryLanguageData,
    refetch: refetchPrimaryLanguage,
    loading: primaryLanguageLoading,
  } = useQuery<projectPrimaryLanguage>(GET_PRIMARY_LANGUAGE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const refetchRefLanguage = () => {
    refetchPrimaryLanguage();
  };

  const primaryLanguageId = primaryLanguageData?.project?.currentScript?.primaryLanguage?.id;
  const primaryLanguageName = languagesData?.allLanguages?.edges?.find(
    (edge: any) => edge?.node?.id === primaryLanguageId,
  )?.node?.name;

  if (!data?.allLanguages?.edges) {
    return null;
  }

  const sortedLanguages = sortLanguagesInAlphabeticalOrder(data.allLanguages.edges, primaryLanguageName);

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />
        {loading ? <Typography variant="h5">Loading...</Typography> : (
          <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
            <Box className={classes.container}>
              <Typography variant="h5">{t('LANGUAGES_PAGE.TITLE')}</Typography>
              <StyledButton
                variant="contained"
                label={t('LANGUAGES_PAGE.ADD_LANGUAGE_BUTTON')}
                action={handleDialogChange}
              />
            </Box>
            {data?.allLanguages?.edges && (
            <LanguagesTable
              data={sortedLanguages}
              setOpen={setOpen}
              setDeleteOpen={setDeleteOpen}
              onChange={() => { refetchRefLanguage(); }}
              referredLanguage={referredLanguage}
              setChangeReferenceOpen={setChangeReferenceOpen}
              setReferenceToSelect={setReferenceToSelect}
            />
            )}
            <CreateLanguageDialog
              handleDialogChange={handleDialogChange}
              open={open}
              setOpen={setOpen}
            />
            <ChangeReferenceLanguageDialog
              handleDialogChange={handleChangeReferenceDialogChange}
              open={changeReferenceOpen}
              languageToSelect={languageToSelect}
              setReferredLanguage={setReferredLanguage}
            />
            <DeleteLanguageDialog
              handleDialogChange={handleDeleteDialogChange}
              open={deleteOpen}
              setOpen={setDeleteOpen}
            />
          </Box>
        )}
      </Box>
    </>
  );
};
export default Languages;
