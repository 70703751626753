import React, { useMemo } from 'react';
import {
  Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import {
  HeadCell, IRComparativeTableProps, RenderData, RenderNode,
} from './types';
import { ReactComponent as TopRhombIcon } from '../../../assets/icons/top-rhomb-icon.svg';
import { ReactComponent as BottomRhombIcon } from '../../../assets/icons/bottom-rhomb-icon.svg';
import { ComparativeTableRow } from '../ComparativeTableRow';

const headCells: readonly HeadCell[] = [

  {
    id: 'localised_asset__line__string_id',
    numeric: false,
    disablePadding: true,
    label: 'Line ID',
  },
  {
    id: 'localised_asset__language__language_code',
    numeric: false,
    disablePadding: true,
    label: 'Language',
  },
  {
    id: 'audiofile',
    numeric: false,
    disablePadding: true,
    label: 'Listen',
  },
  {
    id: 'length_difference',
    numeric: false,
    disablePadding: true,
    label: 'Length (s)',
  },
  {
    id: 'sample_rate_equal',
    numeric: false,
    disablePadding: true,
    label: 'Sample Rate',
  },
  {
    id: 'number_of_channels_equal',
    numeric: false,
    disablePadding: true,
    label: 'Channels',
  },
  {
    id: 'bit_depth_equal',
    numeric: false,
    disablePadding: true,
    label: 'Bit Depth',
  },
  {
    id: 'perceived_loudness_difference',
    numeric: false,
    disablePadding: true,
    label: 'Loudness (LKFS)',
  },
  {
    id: 'peak_level_difference',
    numeric: false,
    disablePadding: true,
    label: 'Peak (dBFS)',
  },
  {
    id: 'trailing_silence_front_difference',
    numeric: false,
    disablePadding: true,
    label: 'Padding Front (s)',
  },
  {
    id: 'trailing_silence_back_difference',
    numeric: false,
    disablePadding: true,
    label: 'Padding Back (s)',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Type',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: true,
    label: 'Actions',
  },
];

const ComparativeTable: React.FC<IRComparativeTableProps> = ({
  data,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  selectedRevisions,
  setSelectedRevisions,
}) => {
  const renderData = useMemo((): RenderNode[] => {
    const itemMap = data.reduce((acc, compareRevision) => {
      const lineId: string = compareRevision?.node?.localisedAsset?.line.stringId || '';
      const node: RenderNode = { node: compareRevision?.node, isEven: false };

      if (compareRevision && acc[lineId]) {
        acc[lineId].push(node);
      } else if (compareRevision) {
        acc[lineId] = [{ ...node, isReference: true }, node];
      }

      return acc;
    }, {} as RenderData);

    const entries = Object.entries(itemMap);

    return entries.flatMap(([_, value], index) => value.map((item) => ({ ...item, isEven: index % 2 === 0 })));
  }, [data]);

  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(isAsc ? `-${property}` : property);
  };

  const sortTable = (property: string) => {
    handleRequestSort(property);
  };

  return (
    <div>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {
                    headCell.id !== 'localised_asset__language__language_code'
                    && headCell.id !== 'audiofile'
                    && headCell.id !== 'actions'
                    && headCell.id !== 'type'
                    && headCell.id !== 'sample_rate'

                      ? (
                        <Button
                          onClick={() => {
                            sortTable(headCell.id);
                          }}
                          sx={{ textTransform: 'none' }}
                        >
                          <Typography variant="body2" sx={{ marginRight: '10px' }}>
                            {headCell.label}
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <TopRhombIcon />
                            <BottomRhombIcon />
                          </Box>
                        </Button>
                      ) : (
                        <Typography variant="body2" sx={{ marginRight: '10px' }}>
                          {headCell.label}
                        </Typography>
                      )
}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderData.map((ComparativeRevisionsData) => (
            <ComparativeTableRow
              key={uuidv4()}
              data={ComparativeRevisionsData.node}
              isEven={!!ComparativeRevisionsData.isEven}
              isReference={!!ComparativeRevisionsData.isReference}
              selectedRevisions={selectedRevisions}
              setSelectedRevisions={setSelectedRevisions}
            />
          ))}
        </TableBody>
      </Table>

    </div>
  );
};
export default ComparativeTable;
