import React, { useState } from 'react';

import { defaultSectionData } from '../../constants/sections';
import { ISectContextProps } from './types';

export const SectionContext = React.createContext<ISectContextProps | null>(null);

const SectionContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [sectionData, setSectionData] = useState(defaultSectionData);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <SectionContext.Provider value={{
      sectionData,
      setSectionData,
      isEditMode,
      setIsEditMode,
    }}
    >
      {children}
    </SectionContext.Provider>
  );
};

export default SectionContextProvider;
