import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  stepController: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    cursor: 'pointer',
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 700,
    marginBottom: '24px',
  },
  secondStepButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '600px',
    '@media (max-width: 1200px)': {
      width: '295px',

      '& button': {
        width: '120px',
      },
    },
  },
  formRow: {
    marginBottom: '20px',
    display: 'flex',
    gap: '24px',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
    },
  },
  selectField: {
    width: '282px',
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: '8px',
    '@media (max-width: 1200px)': {
      width: '295px',
    },
  },
  textField: {
    width: '282px',
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: '8px',
    '@media (max-width: 1200px)': {
      width: '295px',
    },
  },
  textareaField: {
    width: '562px',
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: '8px',
    resize: 'none',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: 1.5,
    padding: '12px',
    fontFamily: 'Roboto',
    '@media (max-width: 1200px)': {
      width: '295px',
    },
  },
  recaptchaWrapper: {
    marginTop: '20px',
  },
  recaptchaError: {
    color: '#D40000',
    fontSize: '12px',
  },

});
