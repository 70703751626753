import React from 'react';
import { Box, Slider, Typography } from '@mui/material';

import { IInvertibleSliderProps } from './types';

const InvertibleSlider: React.FC<IInvertibleSliderProps> = ({
  value, setValue, min, max, isInverted,
}) => {
  const valuetext = (val: number) => `${val}`;
  const handleChange = (_event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Typography sx={{ marginRight: '20px' }} variant="body2">{min}</Typography>
      <Slider
        getAriaLabel={() => 'example'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
        track={isInverted ? 'inverted' : 'normal'}
        min={min}
        max={max}
        step={0.1}
        color="secondary"
      />
      <Typography sx={{ marginLeft: '20px' }} variant="body2">{max}</Typography>
    </Box>
  );
};
export default InvertibleSlider;
