import { makeStyles } from '@mui/styles';
import { secondary } from '../../../../constants/colors';

export const useStyles = makeStyles({
  dialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '20px',
    borderRadius: '12px',
  },
  closeDialogIconButton: {
    alignSelf: 'flex-end',
    color: '#000',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&>div': {
      width: '90%',
    },
  },
  checkbox: {
    '&.Mui-checked': {
      color: secondary,
    },
  },
  permissionSetsWrapper: {
    marginTop: '30px',
  },
  checkboxWrapper: {
    paddingTop: '10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    '& p': {
      margin: '0 10px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '50px 0',
  },
  resultItemWrapper: {
    margin: '10px 0',
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  resultFieldWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '20px',
  },
});
