import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';

import { useParams } from 'react-router-dom';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { HeadCell, IRevisionTableProps } from './types';
import { RevisionTableRow } from '../RevisionTableRow';
import { updateLocalisedAsset as updateLocalisedAssetProps }
  from '../../../graphql/mutations/__generated__/updateLocalisedAsset';
import { UPDATE_CURRENT_REVISION } from '../../../graphql/mutations';
import {
  GET_LINE,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA, GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const headCells: readonly HeadCell[] = [

  {
    id: 'current',
    numeric: false,
    disablePadding: true,
    label: 'Current',
  },
  {
    id: 'revisionIndex',
    numeric: false,
    disablePadding: false,
    label: 'Revision #',
  },
  {
    id: 'line',
    numeric: false,
    disablePadding: false,
    label: 'Line',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'recording',
    numeric: false,
    disablePadding: false,
    label: 'Recording',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

const RevisionTable: React.FC<IRevisionTableProps> = ({
  data, currentSelectId, localisedAssetId, setShouldRefetch,
}) => {
  const [currentRevisionId, setCurrentRevisionId] = useState(currentSelectId);
  const { id } = useParams();

  const [updateLocalisedAsset] = useMutation<updateLocalisedAssetProps>(
    UPDATE_CURRENT_REVISION,
    {
      refetchQueries: [{ query: GET_LINE, variables: { id } },
        { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        'line'],
    },
  );

  const handleCurrentRevisionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateLocalisedAsset({
      variables: {
        localisedAssetUpdateData: {
          id: localisedAssetId,
          currentSelectId: event.target.value,
        },
      },
    });
    setCurrentRevisionId(event.target.value);
  };

  return (
    <div>
      {data
    && (
    <Table
      aria-labelledby="tableTitle"
      size="small"
    >
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding="none"
            >
              <Typography variant="body2" sx={{ marginRight: '5px' }}>
                {headCell.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.slice()
          .sort((a, b) => (b?.node?.revisionIndex || 0) - (a?.node?.revisionIndex || 0))
          .map((localisedRevisionAssetSet) => (
            <RevisionTableRow
              data={localisedRevisionAssetSet?.node}
              key={uuidv4()}
              currentRevisionId={currentRevisionId}
              onCurrentRevisionChange={handleCurrentRevisionChange}
              localisedAssetId={localisedAssetId}
              setShouldRefetch={setShouldRefetch}
            />
          ))}
      </TableBody>
    </Table>
    )}

    </div>
  );
};
export default RevisionTable;
