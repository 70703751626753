import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  toolbar: {
    marginLeft: '10vw',
    marginRight: '7vw',
    paddingTop: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-between',
  },
  headerText: {
    marginRight: '5vw',
  },
  disableLinkDecoration: {
    textDecoration: 'none',
  },

}));
