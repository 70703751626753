import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_SCRIPT_ID,
  RECORDING_STATUS_BY_LANGUAGE,
  ROBOVOICE_GENERATION_REQUEST_STATUS,
} from '../../../graphql/queries';
import { recordingStatusByLanguage } from '../../../graphql/queries/__generated__/recordingStatusByLanguage';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import RecordingsSummaryGraph from '../RecordingsSummaryGraph/RecordingsSummaryGraph';
import { RecordingsSummaryTable } from '../RecordingsSummaryTable';
import { GENERATE_ROBOVOICE_FOR_LANGUAGES } from '../../../graphql/mutations';
import { generateRobovoiceForLanguages } from '../../../graphql/mutations/__generated__/generateRobovoiceForLanguages';
import {
  robovoiceGenerationRequestStatus,
} from '../../../graphql/queries/__generated__/robovoiceGenerationRequestStatus';
import RecordingSummaryDialog from '../RecordingSummaryDialog/RecordingSummaryDialog';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const RecordingsSummaryContainer = () => {
  const { t } = useTranslation();
  const { data: scriptData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const scriptId = scriptData?.project?.scriptSet?.edges[0]?.node?.id;

  const { data, loading, refetch } = useQuery<recordingStatusByLanguage>(RECORDING_STATUS_BY_LANGUAGE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  const [
    generateRobovoiceForLanguage,
    { data: generatedRobovoiceData, loading: generatedRobovoiceLoading },
  ] = useMutation<generateRobovoiceForLanguages>(GENERATE_ROBOVOICE_FOR_LANGUAGES);

  const {
    data: robovoiceGenerationStatusData, loading: robovoiceGenerationStatusLoading, startPolling, stopPolling,
  } = useQuery<robovoiceGenerationRequestStatus>(ROBOVOICE_GENERATION_REQUEST_STATUS, {
    variables: {
      id: generatedRobovoiceData?.generateRobovoiceForLanguages?.request?.id,
    },
    skip: !generatedRobovoiceData?.generateRobovoiceForLanguages?.request?.id,
  });

  const handleGenerateRobovoiceForLanguages = async (languageIds: string[]) => {
    await generateRobovoiceForLanguage({
      variables: {
        robovoiceCreationData: {
          mode: 'OnlyWithoutRecording',
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          languageIds,
        },
      },
    });
  };
  const handlePollingClose = () => {
    setIsOpen(false);
    stopPolling();
    setShouldRefetch(true);
  };

  useEffect(() => {
    if (shouldRefetch && scriptId) {
      refetch({ variables: { scriptId } }).then(() => setShouldRefetch(false));
    }
  }, [shouldRefetch]);

  useEffect(() => {
    if (!generatedRobovoiceLoading && generatedRobovoiceData) {
      startPolling(1000);
      setIsOpen(true);
    }
    return () => {
      stopPolling();
      setShouldRefetch(true);
      setIsOpen(false);
    };
  }, [generatedRobovoiceLoading, generatedRobovoiceData]);

  useEffect(() => {
    if (!robovoiceGenerationStatusLoading
        && robovoiceGenerationStatusData?.robovoiceGenerationRequestStatus?.isFinished) {
      stopPolling();
      setShouldRefetch(true);
      setIsOpen(false);
    }
  }, [robovoiceGenerationStatusLoading, robovoiceGenerationStatusData]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ marginTop: '40px', marginButton: '36px', fontWeight: 'bold' }}>
        {t('RECORDINGS_SUMMARY_PAGE.RECORDINGS_SUMMARY')}
        <RecordingsSummaryGraph
          data={data}
          loading={loading}
          barLabels={data?.recordingStatusByLanguage?.map((edge) => edge?.language?.name || '') || []}
        />
      </Typography>

      <RecordingsSummaryTable
        data={data}
        handleGenerateRobovoiceForLanguages={handleGenerateRobovoiceForLanguages}
      />

      <RecordingSummaryDialog
        open={isOpen}
        robovoiceGenerationStatusData={robovoiceGenerationStatusData}
        handlePollingClose={handlePollingClose}
      />
    </Box>
  );
};

export default RecordingsSummaryContainer;
