import { makeStyles } from '@mui/styles';
import { secondaryLight } from '../../../constants/colors';

export const useStyles = makeStyles({
  operationSectionWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  linkButton: {
    marginLeft: '19px',
    color: secondaryLight,
  },
});
