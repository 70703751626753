import { makeStyles } from '@mui/styles';

import { white, secondary } from '../../constants/colors';

export const useStyles = makeStyles(({
  button: {
    borderColor: '#0063cc',
    color: white,
    fontWeight: 600,
    fontSize: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    background: `linear-gradient(79.65deg, #FF6B40 -23.68%, #FF8E75 109.32%), 
    linear-gradient(79.65deg, #FAAC7F -23.68%, #FF8E75 109.32%);`,
    borderRadius: '8px',
    textTransform: 'none',
  },
  outlinedButton: {
    backgroundColor: 'transparent',
    fontWeight: 600,
    fontSize: '12px',
    padding: '12px',
    borderColor: '#FF8E75',
    borderWidth: '1px',
    borderRadius: '8px',
    color: '#FF8E75',
    textTransform: 'none',
    '&:hover': {
      opacity: '0.6',
      borderColor: '#FF8E75',
    },
  },
  textButton: {
    color: secondary,
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
  },
}));
