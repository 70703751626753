import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { LineDetailsContainer } from '../../components/Line/LineDetailsContainer';
import { RevisionContext } from '../../context/RevisionContext/RevisionContext';
import { DeleteLineDialog } from '../../components/Line/DeleteLineDialog';
import { LineContext } from '../../context/LineContext/LineContext';
import { DeleteRevisionDialog } from '../../components/Line/RevisionDialogs/DeleteRevisionDialog';
import { CreateRevisionDialog } from '../../components/Line/RevisionDialogs/CreateRevisionDialog';
import { UpdateRevisionDialog } from '../../components/Line/RevisionDialogs/UpdateRevisionDialog';
import UploadSoundFileDialog from '../../components/Line/RevisionDialogs/UploadSoundFileDialog/UploadSoundFileDialog';
import { line } from '../../graphql/queries/__generated__/line';
import { GET_LINE } from '../../graphql/queries';

const LineDetails = () => {
  const revisionContext = useContext(RevisionContext);
  const lineContext = useContext(LineContext);

  const { id } = useParams();
  const { data } = useQuery<line>(GET_LINE, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />
        <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
          <LineDetailsContainer />
          {revisionContext && (
          <>
            {
              !revisionContext.isEditMode
                ? (
                  <CreateRevisionDialog
                    handleDialogChange={revisionContext?.handleDialogChange}
                    open={revisionContext?.open}
                    setOpen={revisionContext?.setOpen}
                  />
                )
                : (
                  <UpdateRevisionDialog
                    handleDialogChange={revisionContext?.handleDialogChange}
                    open={revisionContext?.open}
                    setOpen={revisionContext?.setOpen}
                    lineData={data}
                  />
                )
              }
            <DeleteRevisionDialog
              handleDialogChange={revisionContext?.handleDeleteDialogChange}
              open={revisionContext?.deleteOpen}
              setOpen={revisionContext?.setDeleteOpen}
            />
            <UploadSoundFileDialog
              handleDialogChange={revisionContext?.handleUploadSoundFileDialogChange}
              open={revisionContext?.uploadSoundFileOpen}
              setOpen={revisionContext?.setUploadSoundFileOpen}
            />
          </>
          )}
          {lineContext && (
          <DeleteLineDialog
            handleDialogChange={lineContext?.handleDialogChange}
            open={lineContext?.open}
            setOpen={lineContext?.setOpen}
          />
          )}
        </Box>
      </Box>
    </>
  );
};
export default LineDetails;
