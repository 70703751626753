import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%);',
    padding: '16px 120px',
    '@media (max-width: 768px)': {
      padding: '16px 24px',
      justifyContent: 'center',
    },
  },
  logoWrapper: {
    '@media (max-width: 768px)': {
      alignSelf: 'center',
      justifySelf: 'center',
    },
  },
  iconsWrapper: {
    '@media (max-width: 768px)': {
      marginLeft: 'auto',
    },
  },
  marginText: {
    marginRight: '50px',
  },
  marginIcon: {
    marginRight: '35px',
  },

}));
