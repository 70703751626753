import React, { useMemo } from 'react';
import { Box, CardContent } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { IRecordingsSummaryGraphProps } from './types';

const RecordingsSummaryGraph: React.FC<IRecordingsSummaryGraphProps> = ({ data, loading, barLabels }) => {
  const { t } = useTranslation();

  const preparedData = data?.recordingStatusByLanguage?.filter((edge) => edge?.language?.textOnly !== true);

  const barOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
    elements: {
      bar: {
        borderRadius: 12,
      },
    },
    maintainAspectRatio: false,
  };

  const barData = useMemo(() => {
    if (!loading && preparedData) {
      return {
        labels: barLabels,
        datasets: [
          {
            label: t('RECORDINGS_SUMMARY_PAGE.NONE'),
            data: preparedData?.map((lng) => lng?.nRecordingsNone || '') || [],
            backgroundColor: 'rgba(116, 153, 255,0.7)',
            stack: 'Stack 0',
          },
          {
            label: t('RECORDINGS_SUMMARY_PAGE.PLACEHOLDER'),
            data: preparedData?.map((lng) => lng?.nRecordingsPlaceholder || '') || [],
            backgroundColor: 'rgba(255,125,69,0.7)',
            stack: 'Stack 0',
          },
          {
            label: t('RECORDINGS_SUMMARY_PAGE.FINISHED'),
            data: preparedData?.map((lng) => lng?.nRecordingsFinished || '') || [],
            backgroundColor: '#FF7D45',
            stack: 'Stack 0',
          },
        ],
      };
    }
    return {
      labels: barLabels,
      datasets: [],
    };
  }, [data, loading, barLabels]);

  return (
    <Box>
      <CardContent sx={{ height: '40vh' }}>
        <Bar width="60%" height="100%" options={barOptions} data={barData} />
      </CardContent>
    </Box>
  );
};

export default RecordingsSummaryGraph;
