import React from 'react';
import {
  Box,
  Button,

} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';

import { useStyles } from './styles';
import { GET_ALL_LANGUAGES } from '../../graphql/queries';
import { allLanguages } from '../../graphql/queries/__generated__/allLanguages';
import { GENERATE_ROBOVOICE_FOR_LANGUAGES } from '../../graphql/mutations';
import { generateRobovoiceForLanguages } from '../../graphql/mutations/__generated__/generateRobovoiceForLanguages';
import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';

const PlaceholderRobovoice: React.FC = () => {
  const classes = useStyles();

  const { data: languagesData, loading: languagesLoading } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const [generatedRobovoices] = useMutation<generateRobovoiceForLanguages>(GENERATE_ROBOVOICE_FOR_LANGUAGES);

  const handleGenerateRobovoice = () => {
    if (languagesData && languagesData.allLanguages) {
      generatedRobovoices({
        variables: {
          robovoiceCreationData: {
            languageIds: languagesData.allLanguages.edges.map((x) => x?.node?.id),
          },
        },
      });
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />

        <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
          <Box className={classes.container} />
          <Button
            onClick={handleGenerateRobovoice}
          >
            Generate Robovoice
          </Button>
        </Box>
      </Box>
    </>
  );
};
export default PlaceholderRobovoice;
