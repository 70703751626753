import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line camelcase
import { allLanguages, allLanguages_allLanguages_edges } from '../../../graphql/queries/__generated__/allLanguages';
import {
  GET_ALL_LANGUAGES,
  SCRIPT_IMPORT_REQUEST_STATUS,
} from '../../../graphql/queries';
import { useStyles } from './styles';
import { SCRIPT_FILE_TYPES } from '../../../constants/scripts';
import { IMPORT_SCRIPT } from '../../../graphql/mutations';
import { importScript as importScriptType } from '../../../graphql/mutations/__generated__/importScript';
import UploadScriptForm from '../UploadScriptForm/UploadScriptForm';
import { useCurrentProject, useUploadFileToS3 } from '../../../hooks';
import { UploadScriptDialog } from '../UploadScriptDialog';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const UploadScriptContainer: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // eslint-disable-next-line camelcase
  const [selectedLanguages, setSelectedLanguages] = useState<(allLanguages_allLanguages_edges | null)[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<Array<string>>([SCRIPT_FILE_TYPES[0]]);

  const [requestId, setRequestId] = useState<string>('');
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const { loading: languagesLoading, data: languagesData } = useQuery<allLanguages>(GET_ALL_LANGUAGES, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [importScript] = useMutation<importScriptType>(IMPORT_SCRIPT, {
    onCompleted: (data) => setRequestId(data?.importScript?.request?.id || ''),
  });

  const { data: projectData, loading: projectLoading } = useCurrentProject();
  const {
    presignedUrlData, presignedUrlLoading, handleDnD, droppedFileName,
  } = useUploadFileToS3();

  const {
    data: scriptImportData, loading: scriptImportLoading, startPolling, stopPolling,
  } = useQuery(
    SCRIPT_IMPORT_REQUEST_STATUS,
    {
      variables: { id: requestId },
      skip: !requestId,
    },
  );

  // eslint-disable-next-line consistent-return
  const s3UploadData = useMemo(() => {
    if (presignedUrlData) {
      return JSON.parse(presignedUrlData.getPresignedUrlForImport?.awsResponse || '');
    }
  }, [presignedUrlData]);

  useEffect(() => {
    if (requestId) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [requestId]);

  useEffect(() => {
    if (!scriptImportLoading) {
      if (!scriptImportData?.scriptImportRequestStatus) {
        stopPolling();
        setIsPolling(false);
      }
      if (scriptImportData?.scriptImportRequestStatus?.isFinished) {
        stopPolling();
      }
    }
  }, [scriptImportData, scriptImportLoading]);

  const handleUploadScript = async () => {
    await importScript({
      variables: {
        importScriptOptions: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          languageIds: selectedLanguages.map((item) => item?.node?.id || null),
          s3FilePath: s3UploadData.fields.key,
        },
      },
    });
  };

  if (languagesLoading || presignedUrlLoading || projectLoading) {
    return <PageLoader />;
  }

  return (
    <Box className={classes.uploadScriptContainerWrapper}>
      <Typography variant="h5" sx={{ marginTop: '40px', marginButton: '36px', fontWeight: 'bold' }}>
        {t('Upload script')}
      </Typography>
      <UploadScriptForm
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        selectedFileTypes={selectedFileTypes}
        setSelectedFileTypes={setSelectedFileTypes}
        droppedFileName={droppedFileName}
        languagesList={languagesData?.allLanguages?.edges}
        handleDnD={handleDnD}
        handleUploadScript={handleUploadScript}
      />
      <UploadScriptDialog
        isPolling={isPolling}
        stopPolling={stopPolling}
        setIsPolling={setIsPolling}
        scriptImportData={scriptImportData}
      />
    </Box>
  );
};

export default UploadScriptContainer;
