import React, { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import { useNavigate } from 'react-router-dom';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { StyledButton } from '../../../common/StyledButton';
import { IDeleteLineDialogProps } from './types';
import { DELETE_LINE } from '../../../graphql/mutations';
import {
  GET_ALL_LINES,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { useStyles } from './styles';
import { LineContext } from '../../../context/LineContext/LineContext';
import { deleteLine as deleteLineProps } from '../../../graphql/mutations/__generated__/deleteLine';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const DeleteLineDialog: React.FC<IDeleteLineDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const context = useContext(LineContext);

  const classes = useStyles();
  const navigate = useNavigate();

  const [deleteLine] = useMutation<deleteLineProps>(DELETE_LINE, {
    refetchQueries: [
      { query: GET_ALL_LINES, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },

      { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      'allLines',
    ],
  });

  const handleDeleteLine = async (id:string | undefined) => {
    await deleteLine({ variables: { lineDeletionData: { id } } });
    setOpen(false);
    navigate('/script/lines');
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleDialogChange();
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent>
          <Typography>
            Are you sure you want to delete line
            {' '}
            {context?.stringId}
            {' '}
            and all its contents?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="No"
            action={() => {
              handleDialogChange();
            }}
          />
          <StyledButton
            width="210px"
            variant="contained"
            label="Yes"
            action={async () => handleDeleteLine(context?.lineId)}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteLineDialog;
