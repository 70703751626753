import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox, InputLabel,
  MenuItem, Select,
  SelectChangeEvent, TextField, Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IDownloadScriptFormProps } from './types';
import { useStyles } from './styles';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { SCRIPT_FILE_TYPES } from '../../../constants/scripts';
import { StyledButton } from '../../../common/StyledButton';
import { DownloadTable } from '../DownloadTable';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges } from '../../../graphql/queries/__generated__/allLanguages';
import DownloadTableOperationSection
  from '../../DownloadRecording/DownloadTableOperationSection/DownloadTableOperationSection';

const DownloadScriptForm: React.FC<IDownloadScriptFormProps> = ({
  charactersList,
  languagesList,
  sectionsList,
  exportScript,
  projectId,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const [selectedLanguages, setSelectedLanguages] = useState<(allLanguages_allLanguages_edges | null)[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<Array<string>>([SCRIPT_FILE_TYPES[0]]);
  const [selectedSections, setSelectedSections] = useState<Array<string>>([]);
  const [selectedCharacters, setSelectedCharacters] = useState<Array<string>>([]);

  const [languagesInSeparateFiles, setLanguagesInSeparateFiles] = useState<boolean>(false);
  const [charactersInSeparateFiles, setCharactersInSeparateFiles] = useState<boolean>(false);
  const [sectionsInSeparateFiles, setSectionsInSeparateFiles] = useState<boolean>(false);

  const classes = useStyles();

  const handleMultiSelectChange = useCallback((event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    setSelectedFileTypes(typeof value === 'string' ? value.split(',') : value);
  }, []);

  const handleDownloadButton = useCallback(() => {
    exportScript({
      projectId: projectId || '',
      languagesInSeparateFiles,
      charactersInSeparateFiles,
      sectionsInSeparateFiles,
      characterIds: selectedCharacters,
      languageIds: selectedLanguages.map((item) => item?.node?.id || null),
      sectionIds: selectedSections,
    });
  }, [
    projectId,
    languagesInSeparateFiles,
    charactersInSeparateFiles,
    sectionsInSeparateFiles,
    selectedCharacters,
    selectedLanguages,
    selectedSections,
  ]);

  const checkIfButtonDisabled = () => {
    if (selectedFileTypes.length === 0) {
      return true;
    }
    if (selectedLanguages.length === 0) {
      return true;
    }
    if (selectedSections.length === 0) {
      return true;
    }
    if (selectedCharacters.length === 0) {
      return true;
    }
    return false;
  };

  return (
    <Box className={classes.fieldsWrapper}>
      <Box className={classes.selectsWrapper}>
        <Box>
          <Typography>Languages</Typography>

          <InputLabel htmlFor="selected-languages" />
          <Autocomplete
            multiple
            id="filter-language-list"
            className={classes.select}
            options={languagesList || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            renderOption={(props, option) => (
              <MenuItem
                key={option?.node?.languageCode}
                value={option?.node?.id}
                {...props}
              >
                <FlagVisualisation
                  style={{ marginRight: '6px' }}
                  key={option?.node?.languageCode}
                  optionFlag={option?.node?.languageFlag || ''}
                />
                {option?.node?.name}
              </MenuItem>
            )}
            sx={{ width: '330px' }}
            value={selectedLanguages}
            onChange={(_event, newValue) => setSelectedLanguages(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select"
              />
            )}
          />

          <Tooltip title={t('DOWNLOAD_SCRIPT_PAGE.EACH_LANGUAGE_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px', marginTop: '29px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={languagesInSeparateFiles}
                onChange={() => setLanguagesInSeparateFiles((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_SCRIPT_PAGE.EACH_LANGUAGE_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>

        </Box>

        <Box>
          <Typography>Filetype</Typography>
          <InputLabel htmlFor="selected-file-type" />

          <Select
            id="selected-file-type"
            multiple
            value={selectedFileTypes}
            onChange={(e) => handleMultiSelectChange(e)}
            className={classes.select}
            sx={{ width: '163px' }}
          >
            {SCRIPT_FILE_TYPES?.map((fileType) => (
              <MenuItem key={fileType} value={fileType}>
                {fileType}
              </MenuItem>
            ))}
          </Select>

        </Box>
      </Box>
      <Box className={classes.tablesWrapper}>
        <Box className={classes.tableSection}>
          <Box className={classes.tableOperationSectionWrapper}>
            <Typography>Sections</Typography>

            <DownloadTableOperationSection
              list={sectionsList}
              setState={setSelectedSections}
            />
          </Box>
          <DownloadTable
            list={sectionsList}
            selectedItems={selectedSections}
            setSelectedItems={setSelectedSections}
          />

          <Tooltip title={t('DOWNLOAD_SCRIPT_PAGE.EACH_SECTION_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={sectionsInSeparateFiles}
                onChange={() => setSectionsInSeparateFiles((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_SCRIPT_PAGE.EACH_SECTION_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>
        </Box>
        <Box className={classes.tableSection}>
          <Box className={classes.tableOperationSectionWrapper}>
            <Typography>Characters</Typography>

            <DownloadTableOperationSection
              list={charactersList}
              setState={setSelectedCharacters}
            />
          </Box>
          <DownloadTable
            list={charactersList}
            selectedItems={selectedCharacters}
            setSelectedItems={setSelectedCharacters}
          />

          <Tooltip title={t('DOWNLOAD_SCRIPT_PAGE.EACH_CHARACTER_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={charactersInSeparateFiles}
                onChange={() => setCharactersInSeparateFiles((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_SCRIPT_PAGE.EACH_CHARACTER_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-begin', width: '100%' }}>
        <StyledButton
          width="210px"
          variant="contained"
          action={handleDownloadButton}
          disabled={checkIfButtonDisabled()}
          label={
            t('DOWNLOAD_SCRIPT_PAGE.DOWNLOAD_SCRIPT_BUTTON')
          }
        />
      </Box>
    </Box>
  );
};

export default DownloadScriptForm;
