import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent, MenuItem, Select,
  Typography,
} from '@mui/material';
import { useApolloClient, useQuery } from '@apollo/client';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';
import { GET_CURRENT_USER_PROJECTS } from '../../../graphql/queries';
import { UserContext } from '../../../context/UserContext/UserContext';

interface ISwitchProjectDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const SwitchProjectDialog: React.FC<ISwitchProjectDialogProps> = ({ open, setOpen }: ISwitchProjectDialogProps) => {
  const client = useApolloClient();
  const classes = useStyles();

  const userContext = useContext(UserContext);

  const [selectedProjectId, setSelectedProjectId] = useState<string>(localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '');

  useEffect(() => {
    setSelectedProjectId(localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '');
  }, [open]);

  const { data: userProjectsData } = useQuery<any>(GET_CURRENT_USER_PROJECTS);

  useEffect(() => {
    setSelectedProjectId(localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '');
  }, []);

  const submitForm = async () => {
    if (selectedProjectId !== localStorage.getItem(LOCALE_STORAGE_PROJECT_ID)) {
      localStorage.setItem(LOCALE_STORAGE_PROJECT_ID, selectedProjectId);
      userContext?.setProjectId(selectedProjectId);
      await client.resetStore().then(() => {
        setOpen(false);
        window.location.reload();
      }).catch(
        (error) => {
          console.log(error);
          window.location.reload();
        },
      ).finally(
        () => {
          setOpen(false);
        },
      );
    } else {
      setOpen(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent>
          <Typography>
            Select a project to continue
          </Typography>
          <Select
            value={selectedProjectId}
            onChange={(e) => {
              if (e.target.value) {
                setSelectedProjectId(e.target.value as string);
              }
            }}
            className={classes.selectField}
          >
            {userProjectsData?.currentUser?.projects?.edges?.map((project: any) => (
              <MenuItem key={project.node.name} value={project.node.id}>
                {project.node.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions className={classes.disableHorizontalPadding}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={() => {
              setOpen(false);
            }}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label="Switch"
            action={() => {
              submitForm();
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default SwitchProjectDialog;
