import { makeStyles } from '@mui/styles';
import { secondary } from '../../../../constants/colors';

export const useStyles = makeStyles({
  dialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '20px',
    borderRadius: '12px',
  },
  closeDialogIconButton: {
    alignSelf: 'flex-end',
    color: '#000',
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&>div': {
      width: '90%',
    },
  },
  textField: {
    width: '282px',
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: '8px',
    '@media (max-width: 1200px)': {
      width: '295px',
    },
  },
  permissionSetsWrapper: {
    marginTop: '30px',
  },
  checkboxWrapper: {
    paddingTop: '10px',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-start',
    '& p': {
      margin: '0 10px',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: '50px 0',
  },
  checkbox: {
    '&.Mui-checked': {
      color: secondary,
    },
  },
  resultDialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '20px 0 35px',
    '&>div': {
      margin: '10px 0',
    },
  },
  resultItemWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
