import React, {
  useContext, useMemo, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, ListItemIcon, Typography } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useTranslation } from 'react-i18next';
import MenuProjectSample from '../../assets/images/MenuProjectSample.png';
import { useStyles } from './styles';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-icon.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globe-icon.svg';
import { ReactComponent as RecordingsIcon } from '../../assets/icons/recordings-icon.svg';
import { ReactComponent as FileIcon } from '../../assets/icons/file-icon.svg';
import { ReactComponent as ChartIcon } from '../../assets/icons/chart-icon.svg';
import { NavListItem, CollapsibleListItem } from '../MenuListItem';
import { isUserAdmin, getIsChildActive } from '../../helpers';
import { useCurrentProject } from '../../hooks';
import { UserContext } from '../../context/UserContext/UserContext';
import SwitchProjectDialog from '../../components/Dashboard/SwitchProjectDialog/SwitchProjectDialog';
import { GET_CURRENT_USER_PROJECTS } from '../../graphql/queries';
import { StyledButton } from '../StyledButton';

const unauthorisedMenuItems = [{ label: 'Home', url: '/', icon: <HomeIcon /> }];

const MenuList = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { data: userProjectsData } = useQuery<any>(GET_CURRENT_USER_PROJECTS);
  const [openSwitchProjectDialog, setOpenSwitchProjectDialog] = useState<boolean>(false);

  const authorisedMenuItems = useMemo(() => {
    const menuItems = ([
      { label: t('SIDEBAR.DASHBOARD'), url: '/dashboard', icon: <ChartIcon /> },
      { label: t('SIDEBAR.LANGUAGES'), url: '/languages', icon: <GlobeIcon /> },
      {
        label: t('SIDEBAR.SCRIPT.SCRIPT'),
        icon: <FileIcon />,
        cyTestId: 'sidebar-script',
        children: [
          { label: t('SIDEBAR.SCRIPT.LINES'), url: '/script/lines' },
          { label: t('SIDEBAR.SCRIPT.SECTIONS'), url: '/script/sections' },
          { label: t('SIDEBAR.SCRIPT.CHARACTERS'), url: '/script/characters', cyTestId: 'sidebar-characters' },
        ],
      },
      {
        label: t('SIDEBAR.RECORDINGS.RECORDINGS'),
        url: '/recordings',
        icon: <RecordingsIcon />,
        children: [
          { label: t('SIDEBAR.RECORDINGS.OVERVIEW'), url: '/recordings/overview' },
          { label: t('SIDEBAR.RECORDINGS.GENERAL'), url: '/recordings/general' },
          { label: t('SIDEBAR.RECORDINGS.COMPARATIVE'), url: '/recordings/comparative' },

        ],
      },
      {
        label: t('SIDEBAR.DELIVERIES.DELIVERIES'),
        icon: <DownloadIcon />,
        children: [
          { label: t('SIDEBAR.DELIVERIES.UPLOAD_SCRIPT'), url: '/deliveries/upload-script' },
          { label: t('SIDEBAR.DELIVERIES.DOWNLOAD_SCRIPT'), url: '/deliveries/download-script' },
          { label: t('SIDEBAR.DELIVERIES.UPLOAD_RECORDINGS'), url: '/deliveries/upload-recordings' },
          { label: t('SIDEBAR.DELIVERIES.DOWNLOAD_RECORDINGS'), url: '/deliveries/download-recordings' },
        ],
      },
    ]);
    const userManagementSection = {
      label: t('SIDEBAR.USER_MANAGEMENT'), url: '/user-management', icon: <AccountCircleIcon fill="#979EAB;" />,
    };
    if (isUserAdmin(userContext?.user)) {
      menuItems.unshift(userManagementSection);
    }
    return menuItems;
  }, [userContext.user]);

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const classes = useStyles();
  const { pathname } = useLocation();

  const { data: projectData } = useCurrentProject();

  return (
    <div>
      <List sx={{ paddingTop: '12px', marginTop: '20px', paddingBottom: '20px' }}>
        {authContext?.isLoggedIn ? (
          <>

            <ListItem className={classes.listItemContainer} disablePadding>
              <ListItemButton
                classes={{ root: classes.listItemButton }}
                selected={pathname.includes('/project/')}
                onClick={() => navigate(`/project/${projectData?.project?.id}`)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
                >
                  <Box
                    sx={{
                      height: '154px',
                      width: '154px',
                      marginBottom: '20px',
                      borderRadius: '20px',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                      backgroundImage: `url(${projectData?.project?.logoUrl || MenuProjectSample})`,
                    }}
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ListItemText
                      primaryTypographyProps={{ style: { fontWeight: 700 } }}
                      primary={projectData?.project?.name || 'Project name'}
                    />
                  </Box>
                </Box>
              </ListItemButton>
            </ListItem>

            <div style={{
              overflowY: 'auto',
            }}
            >
              {authorisedMenuItems.map((item) => (
                item.children
                  ? (
                    <CollapsibleListItem
                      key={uuidv4()}
                      navItem={item}
                      pathname={pathname}
                      isListOpen={getIsChildActive(item)}
                      cypressTestId={item.cyTestId}
                    />
                  )
                  : <NavListItem key={uuidv4()} navItem={item} pathname={pathname} />
              ))}

              {userContext?.user?.email && (
              <Typography sx={{
                fontSize: '12px',
                marginLeft: '20px',
                marginBottom: '10px',
                marginTop: '20px',
              }}
              >
                {t('SIDEBAR.YOU_ARE_LOGGED_IN_TEXT')}
                <br />
                <b>

                  {userContext?.user?.email}
                </b>
              </Typography>
              )}

              {userProjectsData?.currentUser?.projects?.edges?.length > 1 && (
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: '5px',
              }}
              >
                <StyledButton
                  variant="outlined"
                  width="160px"
                  label={t('SIDEBAR.SWITCH_PROJECT')}
                  action={() => {
                    setOpenSwitchProjectDialog(true);
                  }}
                />
              </Box>
              )}
              <SwitchProjectDialog open={openSwitchProjectDialog} setOpen={setOpenSwitchProjectDialog} />

            </div>
          </>
        ) : (
          unauthorisedMenuItems.map((item) => (
            <ListItem key={uuidv4()} disablePadding>
              <ListItemButton
                selected={pathname === item.url}
                classes={{ root: classes.listItemButton }}
                onClick={() => navigate(item.url)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ style: { fontWeight: 700 } }}
                  primary={item.label}
                />
              </ListItemButton>
            </ListItem>
          ))
        )}
      </List>
    </div>
  );
};

export default MenuList;
