import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IChangeReferenceLanguageDialogProps } from './types';
import { base64Decoding } from '../../../helpers';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import {
  GET_ALL_LANGUAGES,
  GET_ALL_LINES, GET_PRIMARY_LANGUAGE, GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { updateScript as updateScriptTypes } from '../../../graphql/mutations/__generated__/updateScript';
import { UPDATE_SCRIPT } from '../../../graphql/mutations';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';

const ChangeReferenceLanguageDialog: React.FC<IChangeReferenceLanguageDialogProps> = ({
  languageToSelect, open,
  handleDialogChange,
  setReferredLanguage,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [updateScript] = useMutation<updateScriptTypes>(UPDATE_SCRIPT, {
    onCompleted: () => setReferredLanguage(languageToSelect),
  });

  const { data: scriptData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const handleReferenceChange = async () => {
    await updateScript({
      refetchQueries: [
        { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_PRIMARY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_ALL_LANGUAGES, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      ],
      variables: {
        scriptUpdateData: {
          id: scriptData?.project?.scriptSet.edges[0]?.node?.id,
          primary_language: base64Decoding(languageToSelect || ''),
        },
      },
    });
    handleDialogChange();
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleDialogChange();
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent>
          <Typography>
            {t('LANGUAGES_PAGE.CONFIRM_CHANGE_REFERENCE_LANGUAGE')}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <StyledButton
            width="210px"
            variant="outlined"
            label={t('BUTTON.CANCEL')}
            action={handleDialogChange}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label={t('BUTTON.SUBMIT')}
            action={handleReferenceChange}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ChangeReferenceLanguageDialog;
