import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({

  revisionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '32px',
  },

  language: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

});
