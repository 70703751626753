import React, { useState } from 'react';
import {
  Box, Checkbox, MenuItem, TextField, Typography,
} from '@mui/material';
import { IGeneralFixFormProps } from './types';
import { useStyles } from './styles';
import {
  recordingsChannelOptions,
  recordingsSampleRateOptions,
} from '../../../constants/recordings';
import { StyledButton } from '../../StyledButton';
import { FixUpFileData } from '../../../../__generated__/globalTypes';
import FixDialogResult from '../FixDialogResult/FixDialogResult';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const GeneralFixForm: React.FC<IGeneralFixFormProps> = ({
  isPolling,
  fixUpFilesLoading,
  setOpen,
  fixUpFiles,
  selectedRevisions,
  fixUpStatusData,
}) => {
  const [isNumberOfChannels, setIsNumberOfChannels] = useState<boolean>(false);
  const [isSampleRate, setIsSampleRate] = useState<boolean>(false);
  const [isLoudness, setIsLoudness] = useState<boolean>(false);
  const [isPaddingFront, setIsPaddingFront] = useState<boolean>(false);
  const [isPaddingBack, setIsPaddingBack] = useState<boolean>(false);

  const [numberOfChannels, setNumberOfChannels] = useState<string | undefined>(recordingsChannelOptions[0]);
  const [sampleRate, setSampleRate] = useState<string>(recordingsSampleRateOptions[0]);
  const [loudness, setLoudness] = useState<string>('0');
  const [paddingFront, setPaddingFront] = useState<number>(0);
  const [paddingBack, setPaddingBack] = useState<number>(0);

  const checkIfSomeFieldIsSelected = () => isNumberOfChannels
    || isSampleRate
    || isLoudness
    || isPaddingFront
    || isPaddingBack;

  const classes = useStyles();

  const handleProcessButton = () => {
    fixUpFiles({
      variables: {
        fixupFileCreationData: {
          fixupFileList: selectedRevisions.map((revision) => {
            const fixUpDTO: FixUpFileData = {
              localisedAssetRevisionId: revision.revisionId,
              soundFileId: revision?.soundFileId || '',
            };
            if (isNumberOfChannels) {
              fixUpDTO.targetNumberOfChannels = numberOfChannels
                ? Number(numberOfChannels)
                : null;
            }
            if (isSampleRate) fixUpDTO.targetSampleRate = sampleRate ? Number(sampleRate) : null;
            if (isLoudness) fixUpDTO.targetLoudness = loudness && Number(loudness) ? Number(loudness) : null;
            if (isPaddingFront) fixUpDTO.targetPaddingFront = paddingFront;
            if (isPaddingBack) fixUpDTO.targetPaddingBack = paddingBack;
            return fixUpDTO;
          }),
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
    }).then();
  };

  return (
    <Box sx={{ marginTop: '42px' }}>
      {
          isPolling || fixUpFilesLoading
          || fixUpStatusData?.fixUpRequestStatus?.isFinished
          || fixUpStatusData?.fixUpRequestStatus?.hasFailed
            ? (
              <FixDialogResult
                fixUpStatusData={fixUpStatusData}
                handleClose={() => setOpen(false)}
              />
            )
            : (
              <Box className={classes.formWrapper}>
                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isNumberOfChannels}
                    onChange={() => {
                      setIsNumberOfChannels((prevState) => !prevState);
                    }}
                  />
                  <Typography>Set number of channels:</Typography>

                  <TextField
                    id="number-of-channels"
                    select
                    className={classes.comparativeSelect}
                    value={numberOfChannels}
                    onChange={(_event) => {
                      setNumberOfChannels(_event.target.value);
                      if (!isNumberOfChannels) {
                        setIsNumberOfChannels(true);
                      }
                    }}
                  >
                    {recordingsChannelOptions.map((option) => (
                      <MenuItem className={classes.selectOption} key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isSampleRate}
                    onChange={() => setIsSampleRate((prevState) => !prevState)}
                  />
                  <Typography>Set sample rate:</Typography>

                  <TextField
                    id="sample-rate"
                    select
                    className={classes.comparativeSelect}
                    value={sampleRate}
                    onChange={(_event) => {
                      setSampleRate(_event.target.value);
                      if (!isSampleRate) {
                        setIsSampleRate(true);
                      }
                    }}
                  >
                    {recordingsSampleRateOptions.map((option) => (
                      <MenuItem className={classes.selectOption} key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isLoudness}
                    onChange={() => setIsLoudness((prevState) => !prevState)}
                  />
                  <Typography>Set loudness to:</Typography>

                  <TextField
                    id="loudness"
                    type="number"
                    className={classes.comparativeSelect}
                    value={loudness}
                    onChange={(_event) => {
                      setLoudness(_event.target.value);
                      if (!isLoudness) {
                        setIsLoudness(true);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                      max: '0',
                      style: { padding: 0 },
                    }}
                  />
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isPaddingFront}
                    onChange={() => setIsPaddingFront((prevState) => !prevState)}
                  />
                  <Typography>Set padding front to:</Typography>

                  <TextField
                    id="padding-front"
                    type="number"
                    className={classes.comparativeSelect}
                    value={paddingFront}
                    onChange={(_event) => {
                      setPaddingFront(Number(_event.target.value));
                      if (!isPaddingFront) {
                        setIsPaddingFront(true);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                      min: '0',
                      style: { padding: 0 },
                    }}
                  />
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isPaddingBack}
                    onChange={() => setIsPaddingBack((prevState) => !prevState)}
                  />
                  <Typography>Set padding back to:</Typography>

                  <TextField
                    id="padding-front"
                    type="number"
                    className={classes.comparativeSelect}
                    value={paddingBack}
                    onChange={(_event) => {
                      setPaddingBack(Number(_event.target.value));
                      if (!isPaddingBack) {
                        setIsPaddingBack(true);
                      }
                    }}
                    inputProps={{
                      inputMode: 'decimal',
                      min: '0',
                      style: { padding: 0 },
                    }}
                  />
                </Box>
                <Box className={classes.buttonsWrapper}>
                  <StyledButton margin="0 10px 0" variant="outlined" label="Cancel" action={() => setOpen(false)} />
                  <StyledButton
                    margin="0 10px 0"
                    variant="contained"
                    disabled={!checkIfSomeFieldIsSelected()}
                    label="Process"
                    action={handleProcessButton}
                  />
                </Box>
              </Box>
            )
        }
    </Box>
  );
};

export default GeneralFixForm;
