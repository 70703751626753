import React, { useContext } from 'react';
import {
  Box,
  Grid,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { ToastContainer } from 'react-toastify';
import { useWindowSize } from '../../../src/hooks';
import DemoForm from '../../common/DemoForm/DemoForm';
import Jellyfish from '../../assets/images/Jellyfish.svg';
import WhoAreWeSection from '../../assets/images/WhoAreWeSection.png';
import DeliverySection from '../../assets/images/DeliverySection.png';
import DeliveryImageMobile from '../../assets/images/homepage/delivery-image-mobile.png';
import FormDecoration from '../../assets/images/homepage/form-decoration.png';
import FormDecoration2 from '../../assets/images/homepage/form-decoration2.png';
import FormDecoration3 from '../../assets/images/homepage/form-decoration3.png';
import WhoWeAreDecoration from '../../assets/images/homepage/who-we-are-decoration.png';
import WelcomeDecoration from '../../assets/images/homepage/welcome-decoration.png';
import WelcomeDecoration2 from '../../assets/images/homepage/welcome-decoration2.png';
import FeaturesIcon1 from '../../assets/images/homepage/features-icon-1.svg';
import FeaturesIcon2 from '../../assets/images/homepage/features-icon-2.svg';
import FeaturesIcon3 from '../../assets/images/homepage/features-icon-3.svg';
import FeaturesIcon4 from '../../assets/images/homepage/features-icon-4.svg';
import FeaturesIcon5 from '../../assets/images/homepage/features-icon-5.svg';
import FeaturesIcon6 from '../../assets/images/homepage/features-icon-6.svg';
import FeaturesMobileIcon1 from '../../assets/images/homepage/features-mobile-icon-1.svg';
import FeaturesMobileIcon2 from '../../assets/images/homepage/features-mobile-icon-2.svg';
import FeaturesMobileIcon3 from '../../assets/images/homepage/features-mobile-icon-3.svg';
import FeaturesMobileIcon4 from '../../assets/images/homepage/features-mobile-icon-4.svg';
import FeaturesMobileIcon5 from '../../assets/images/homepage/features-mobile-icon-5.svg';
import FeaturesMobileIcon6 from '../../assets/images/homepage/features-mobile-icon-6.svg';
import FeaturesConnector1 from '../../assets/images/homepage/features-connector-1.svg';
import FeaturesConnector2 from '../../assets/images/homepage/features-connector-2.svg';
import WhoWeAreImageMobile from '../../assets/images/homepage/who-we-are-image-mobile.png';
import FeaturesMobileConnector from '../../assets/images/homepage/features-mobile-connector.svg';

import { StyledButton } from '../../common/StyledButton';
import { useStyles } from './styles';
import { Footer } from '../../common/Footer';
import { LandingHeader } from '../../common/LandingHeader';
import { AuthContext } from '../../context/AuthContext/AuthContext';

const Home = () => {
  document.body.style.background = '#fff';
  document.body.style.maxWidth = '100%';
  document.body.style.overflowX = 'hidden';

  const { t } = useTranslation();

  const { isMobile } = useWindowSize();

  const classes = useStyles();
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  return (
    <div>
      <LandingHeader />

      <Container maxWidth={false} className={classes.welcomeSection} sx={{ position: 'relative' }}>
        <img
          src={WelcomeDecoration}
          alt="decoration"
          className={classes.welcomeDecoration}
        />

        <img
          src={WelcomeDecoration2}
          alt="decoration"
          className={classes.welcomeDecoration2}
        />

        <Container className={classes.jellyfishSectionWrapper}>

          <Grid container className={classes.jellyfishSectionWrapperInner}>

            <Grid item xs={12} lg={6}>

              <Typography
                className={classes.welcomeText}
              >
                {t('WELCOME_SECTION.TEXT', { ns: 'homepage' })}
              </Typography>

              <Typography
                className={classes.welcomeSectionTitle}
              >
                {t('WELCOME_SECTION.TITLE', { ns: 'homepage' })}

              </Typography>

              <Typography className={classes.welcomeSectionText}>
                {t('WELCOME_SECTION.SUBTITLE', { ns: 'homepage' })}
              </Typography>

              <Box className={classes.buttonWrapper}>
                { authContext.isLoggedIn ? (
                  <StyledButton
                    variant="contained"
                    label="Go to dashboard"
                    action={() => navigate('/dashboard')}
                    padding="0 70px 0 70px"
                  />
                ) : (
                  <Link to="/#book-a-demo" className={classes.disableLinkDecoration}>
                    <StyledButton
                      variant="contained"
                      label="Request Demo"
                      /* eslint-disable-next-line @typescript-eslint/no-empty-function */
                      action={() => {}}
                      padding="0 70px 0 70px"
                    />
                  </Link>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box className={classes.jellyImageWrapper}>
                <img src={Jellyfish} alt="jellyfish" className={classes.jellyfishImage} />
              </Box>
            </Grid>
          </Grid>
        </Container>

      </Container>

      <Container>
        <Box className={classes.anchorContainer} id="who-are-we" />

        <Box className={classes.whoWeAreSection}>
          <img
            src={WhoWeAreDecoration}
            alt="decoration"
            style={{
              position: 'absolute', right: 0, bottom: 0, zIndex: 2,
            }}
          />
          <Typography variant="h2" className={classes.whoWeAreSectionTitle}>
            {t('WHO_WE_ARE_SECTION.TITLE', { ns: 'homepage' })}
          </Typography>

          <Typography variant="body1" className={classes.whoWeAreSectionText}>
            {t('WHO_WE_ARE_SECTION.SUBTITLE', { ns: 'homepage' })}
          </Typography>

          <img src={isMobile ? WhoWeAreImageMobile : WhoAreWeSection} alt="who are we" className={classes.whoWeAreSectionImage} />

        </Box>
      </Container>

      <Container maxWidth={false} className={classes.featuresSection}>
        <Box className={classes.anchorContainer} id="features" />

        <Container>

          <Typography variant="h2" className={classes.featuresSectionTitle}>
            {t('FEATURES_SECTION.TITLE', { ns: 'homepage' })}
          </Typography>

          {/* 1st feature card */}
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon1 : FeaturesIcon1} alt="icon" />
                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    <span className={classes.orangeText}>
                      Organized
                    </span>
                    <br />
                    Asset Management
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    Easily organize your audio assets by level or character.
                  </Typography>
                </div>

              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperRight}>
              <img src={FeaturesConnector1} alt="connector-icon" />
            </Grid>
          </Grid>

          <div className={classes.featuresMobileConnectorWrapper}>
            <img src={FeaturesMobileConnector} alt="connector-icon" />
          </div>
          {/* 2nd feature card */}
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperLeft}>
              <img src={FeaturesConnector2} alt="connector-icon" />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon2 : FeaturesIcon2} alt="icon" />
                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    <span className={classes.orangeText}>
                      Translation
                      {' '}
                    </span>
                    Tracking
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    Keep a tab on the status of all translations and recordings in the project.
                  </Typography>
                </div>

              </Box>
            </Grid>
          </Grid>

          <div className={classes.featuresMobileConnectorWrapper}>
            <img src={FeaturesMobileConnector} alt="connector-icon" />
          </div>

          {/* 3rd feature card */}
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon3 : FeaturesIcon3} alt="icon" />
                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    <span className={classes.orangeText}>
                      Revision
                      {' '}
                    </span>
                    Control
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    Always have access to your past revisions without losing track.
                  </Typography>
                </div>

              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperRight}>
              <img src={FeaturesConnector1} alt="connector-icon" />
            </Grid>
          </Grid>

          <div className={classes.featuresMobileConnectorWrapper}>
            <img src={FeaturesMobileConnector} alt="connector-icon" />
          </div>

          {/* 4th feature card */}
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperLeft}>
              <img src={FeaturesConnector2} alt="connector-icon" />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon4 : FeaturesIcon4} alt="icon" />

                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    Quality
                    {' '}
                    <span className={classes.orangeText}>
                      Assurance
                    </span>
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    With built-in QA features, ensure supreme quality of voice-over assets.
                  </Typography>
                </div>

              </Box>
            </Grid>
          </Grid>

          <div className={classes.featuresMobileConnectorWrapper}>
            <img src={FeaturesMobileConnector} alt="connector-icon" />
          </div>

          {/* 5th feature card */}
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon5 : FeaturesIcon5} alt="icon" />

                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    <span className={classes.orangeText}>
                      Automated
                      {' '}
                    </span>
                    Error
                    <br />
                    Detection and Fixes
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    Save hours of tedious work with Jellyfish detecting errors and inconsistencies in your audio assets, and fixing them with the click of a button.
                  </Typography>
                </div>

              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperRight}>
              <img src={FeaturesConnector1} alt="connector-icon" />
            </Grid>
          </Grid>

          <div className={classes.featuresMobileConnectorWrapper}>
            <img src={FeaturesMobileConnector} alt="connector-icon" />
          </div>

          {/* 6th feature card */}
          <Grid container>
            <Grid item xs={12} lg={6} className={classes.featuresConnectorWrapperLeft} />

            <Grid item xs={12} lg={6}>
              <Box className={classes.featuresCard}>
                <img src={isMobile ? FeaturesMobileIcon6 : FeaturesIcon6} alt="icon" />
                <div>
                  <Typography variant="h2" className={classes.featuresCardTitle}>
                    Denoise
                    {' '}
                    <span className={classes.orangeText}>
                      Recordings
                    </span>
                  </Typography>
                  <Typography variant="body1" className={classes.featuresCardText}>
                    Improve the clarity of your recordings by eliminating noise.
                  </Typography>
                </div>

              </Box>
            </Grid>
          </Grid>

        </Container>
      </Container>
      <Container>
        <Box className={classes.deliverySection}>
          <Typography className={classes.deliverySectionTitle}>
            {t('DELIVERY_SECTION.TITLE', { ns: 'homepage' })}
          </Typography>

          <img src={isMobile ? DeliveryImageMobile : DeliverySection} alt="who are we" className={classes.deliverySectionImage} />

        </Box>

      </Container>

      <Container maxWidth={false} className={classes.formSection}>

        <Box className={classes.contactForm}>
          <img
            src={FormDecoration}
            alt="decoration"
            style={{
              position: 'absolute', left: 0, top: '10px', zIndex: 2,
            }}
          />

          <img
            src={FormDecoration2}
            alt="decoration"
            style={{
              position: 'absolute', right: 0, top: '-100px', zIndex: 2,
            }}
          />

          <img
            src={FormDecoration3}
            alt="decoration"
            style={{
              position: 'absolute', right: 0, bottom: '0px', zIndex: 2,
            }}
          />
          <Box className={classes.anchorContainer} id="book-a-demo" />
          <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >

            <Typography variant="h2" className={classes.contactFormTitle}>
              {t('BOOK_A_DEMO_SECTION.TITLE', { ns: 'homepage' })}
            </Typography>

            <Typography className={classes.contactFormText}>
              {t('BOOK_A_DEMO_SECTION.SUBTITLE', { ns: 'homepage' })}
            </Typography>

            <DemoForm />

          </Container>

        </Box>
      </Container>

      <Footer />
      <ToastContainer />

    </div>
  );
};
export default Home;
