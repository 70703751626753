import React, { useCallback, useMemo } from 'react';
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  IconButton, Checkbox,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './styles';
import { IComparativeTableRowProps } from './types';
import { ReactComponent as InstallIcon } from '../../../assets/icons/install-icon.svg';
import { CustomAudioPlayer } from '../../../common/CustomAudioPlayer';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { useAswFileLink } from '../../../hooks';
import { convertBitDepthValue } from '../../../helpers';
import {
  // eslint-disable-next-line camelcase
  allCurrentLocalisedAssetComparativeRevisions_allCurrentLocalisedAssetComparativeRevisions_edges_node,
} from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetComparativeRevisions';

const ComparativeTableRow: React.FC<IComparativeTableRowProps> = ({
  data, isEven, isReference, selectedRevisions, setSelectedRevisions,
}) => {
  const classes = useStyles();

  const comparativeRowData = useMemo(() => {
    if (isReference) {
      return {
        stringId: data?.referenceAsset?.localisedAsset?.line?.stringId,
        language: {
          languageFlag: data?.referenceAsset?.localisedAsset?.language?.languageFlag,
          languageCode: data?.referenceAsset?.localisedAsset?.language?.languageCode,
        },
        soundFileData: {
          soundFilePath: data?.referenceAsset?.localisedAsset?.currentSelect?.soundFile?.soundFilePath,
          sampleRate: data?.referenceSampleRate,
          bitDepth: data?.referenceBitDepth,
          numberOfChannels: data?.referenceNumberOfChannels,
          length: data?.referenceLength,
          trailingSilenceBack: data?.referenceTrailingSilenceBack,
          trailingSilenceFront: data?.referenceTrailingSilenceFront,
          peakLevel: data?.referencePeakLevel,
          perceivedLoudness: data?.referencePerceivedLoudness,
        },
      };
    }
    return {
      stringId: data?.localisedAsset?.line?.stringId,
      language: {
        languageFlag: data?.localisedAsset?.language?.languageFlag,
        languageCode: data?.localisedAsset?.language?.languageCode,
      },
      soundFileData: {
        soundFilePath: data?.soundFile?.soundFilePath,
        sampleRate: data?.soundFile?.soundMetadata?.sampleRate,
        bitDepth: data?.soundFile?.soundMetadata?.bitDepth,
        numberOfChannels: data?.soundFile?.soundMetadata?.numberOfChannels,
        length: data?.soundFile?.soundMetadata?.length,
        trailingSilenceBack: data?.soundFile?.soundMetadata?.trailingSilenceBack,
        trailingSilenceFront: data?.soundFile?.soundMetadata?.trailingSilenceFront,
        peakLevel: data?.soundFile?.soundMetadata?.peakLevel,
        perceivedLoudness: data?.soundFile?.soundMetadata?.perceivedLoudness,
      },
    };
  }, [data]);

  const { awsResponse, fileExists } = useAswFileLink(comparativeRowData.soundFileData?.soundFilePath || '');

  const isTableItemActive = useCallback(
    (itemId: string | undefined): boolean => !!selectedRevisions.find(({ revisionId }) => revisionId === itemId),
    [selectedRevisions],
  );

  const handleTableItem = useCallback((
    // eslint-disable-next-line camelcase
    revision: allCurrentLocalisedAssetComparativeRevisions_allCurrentLocalisedAssetComparativeRevisions_edges_node
        | null
        | undefined,
  ) => {
    setSelectedRevisions((prevState) => {
      if (revision?.id && revision.soundFile?.id) {
        return isTableItemActive(revision.id)
          ? prevState.filter(({ revisionId }) => revision.id !== revisionId)
          : [...prevState, { revisionId: revision.id, scriptId: revision.localisedAsset?.line.script.id }];
      }
      return prevState;
    });
  }, [selectedRevisions]);

  return (
    <React.Fragment key={uuidv4()}>
      <TableRow className={isEven ? classes.lightBlueRow : classes.whiteRow}>
        <TableCell className={classes.tableText}>
          {
            !isReference && (
            <Checkbox
              className={classes.checkbox}
              checked={isTableItemActive(data?.id)}
              onClick={() => handleTableItem(data)}
            />
            )
          }
          <Typography variant="subtitle2" display="inline">
            {isReference ? comparativeRowData.stringId : ''}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FlagVisualisation optionFlag={comparativeRowData.language?.languageFlag || ''} />
            <Typography
              variant="subtitle2"
              display="inline"
              className={classes.tableText}
              sx={{ marginLeft: '5px' }}
              fontWeight={isReference ? 'bold' : 'normal'}
            >
              {comparativeRowData.language?.languageCode}
            </Typography>
          </Box>
        </TableCell>
        <TableCell padding="none">
          <CustomAudioPlayer
            awsFileLink={awsResponse}
            fileExists={fileExists}
            isControlsDisabled
            isTimeIndicatorsDisabled
            isMinimized
          />
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(comparativeRowData.soundFileData?.length)
                            / Number(comparativeRowData.soundFileData?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {comparativeRowData.soundFileData?.sampleRate}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {comparativeRowData.soundFileData?.numberOfChannels}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {convertBitDepthValue(comparativeRowData.soundFileData?.bitDepth)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {comparativeRowData.soundFileData?.perceivedLoudness?.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {comparativeRowData.soundFileData?.peakLevel?.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(comparativeRowData.soundFileData?.trailingSilenceFront)
                            / Number(comparativeRowData.soundFileData?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(comparativeRowData.soundFileData?.trailingSilenceBack)
                            / Number(comparativeRowData.soundFileData?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            .
            {comparativeRowData.soundFileData?.soundFilePath?.toString().split('.')[1] || ''}
          </Typography>
        </TableCell>
        <TableCell>
          {
              fileExists && awsResponse && (
              <a href={awsResponse} download rel="noreferrer" target="_blank">
                <IconButton>
                  <InstallIcon />
                </IconButton>
              </a>
              )
          }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default ComparativeTableRow;
