import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { allLanguageFlags } from '../../constants/languages';
import { ILanguageFlag } from '../../constants/languages/types';
import { IOptionAutocompleteProps } from './types';

const FlagVisualisation:React.FC<IOptionAutocompleteProps> = ({ optionFlag, ...restProps }) => (
  <>
    {allLanguageFlags.map((flag: ILanguageFlag) => {
      if (flag.languageFlag === optionFlag) {
        return (
          <img
            loading="lazy"
            width="24"
            src={flag.src}
            alt=""
            key={uuidv4()}
            {...restProps}
          />
        );
      }

      return '';
    })}
  </>
);
export default FlagVisualisation;
