import React from 'react';
import {
  IconButton, TableCell, TableRow, Tooltip, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IRecordingsSummaryTableRowProps } from './types';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { ReactComponent as RobovoicesIcon } from '../../../assets/icons/robovoices-icon.svg';
import { useStyles } from './styles';

const RecordingsSummaryTableRow: React.FC<IRecordingsSummaryTableRowProps> = ({
  languageData,
  handleGenerateRobovoiceForLanguages,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TableRow>
      <TableCell className={classes.alignItems}>
        <FlagVisualisation optionFlag={languageData?.language?.languageFlag || ''} />
        <Typography sx={{ marginLeft: '5px' }}>
          {languageData?.language?.name}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Typography>
          {languageData?.nRecordingsNone}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Typography>
          {languageData?.nRecordingsPlaceholder}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Typography>
          {languageData?.nRecordingsFinished}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Tooltip title={t('BUTTON.GENERATE_ROBOVOICE_FOR_LANGUAGE')}>
          <IconButton onClick={() => handleGenerateRobovoiceForLanguages([languageData?.language?.id || ''])}>
            <RobovoicesIcon style={{ stroke: '#1F224C' }} />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default RecordingsSummaryTableRow;
