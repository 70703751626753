import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const TotalLinesCard:React.FC<{linesCount: number | null | undefined}> = ({ linesCount }) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="subtitle2">{t('DASHBOARD.SCRIPT_LINES')}</Typography>
        <Typography variant="h3">{linesCount}</Typography>
      </CardContent>
    </Card>
  );
};

export default TotalLinesCard;
