import { makeStyles } from '@mui/styles';
import {
  darkBlue, lightBlue, secondary, white,
} from '../../../constants/colors';

export const useStyles = makeStyles(({
  tableText: {
    paddingTop: '16px',
    paddingBottom: '18px',
  },
  whiteRow: {
    backgroundColor: white,
  },
  lightBlueRow: {
    backgroundColor: lightBlue,

  },
  arrowDownIcon: {
    transform: 'rotate(180deg)',
  },
  darkBlueRow: {
    backgroundColor: darkBlue,
  },
  iconMargin: {
    marginRight: '8px',
  },
  metaDataContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  metaDataText: {
    marginRight: '10px',
  },
  radio: {
    '&.Mui-checked': {
      color: '#FE7B5B',
    },
  },
  collapsedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  circularProgressWrapper: {
    color: secondary,
    width: '20px !important',
    height: '20px !important',
  },
}));
