import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { IDownloadTableOperationSectionProps } from './types';
import { StyledButton } from '../../../common/StyledButton';

const DownloadTableOperationSection: React.FC<IDownloadTableOperationSectionProps> = ({
  list,
  setState,
}) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const handleSelectAllButton = useCallback(() => {
    setState(list?.map((item) => item?.node?.id || '') || []);
  }, [list, setState]);

  const handleUnselectAllButton = useCallback(() => {
    setState([]);
  }, [setState]);

  return (
    <Box className={classes.operationSectionWrapper}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <StyledButton
        variant="contained"
        width="90px"
        fontSize="10px"
        label={t('RECORDINGS_GENERAL_PAGE.SELECT_ALL_BUTTON')}
        margin="0px 3px "
        action={() => handleSelectAllButton()}
      />
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <StyledButton
        variant="outlined"
        fontSize="10px"
        width="90px"
        label={t('RECORDINGS_GENERAL_PAGE.UNSELECT_ALL_BUTTON')}
        margin="0px 3px "
        action={() => handleUnselectAllButton()}
      />
    </Box>
  );
};

export default DownloadTableOperationSection;
