import React from 'react';
import { Box, Typography } from '@mui/material';

import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { CustomAudioPlayer } from '../../common/CustomAudioPlayer';
import sample from '../../mocks/audio/sample2.wav';
import { useAswFileLink } from '../../hooks';

const TestPlayer = () => {
  const { awsResponse, fileExists } = useAswFileLink(sample || '');
  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />
        <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
          <Typography variant="h3">TestPlayer</Typography>
          <CustomAudioPlayer
            awsFileLink={awsResponse}
            fileExists={fileExists}
            isControlsDisabled
            isTimeIndicatorsDisabled
          />
          <CustomAudioPlayer awsFileLink={awsResponse} fileExists={fileExists} isMinimized />
        </Box>
      </Box>
    </>
  );
};
export default TestPlayer;
