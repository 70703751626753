import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon, List } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useStyles } from './styles';
import { ICollapsibleListItemProps } from './types';

const CollapsibleListItem: React.FC<ICollapsibleListItemProps> = ({
  navItem, pathname, isListOpen, cypressTestId,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(isListOpen);
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Box>
      <ListItem
        key={uuidv4()}
        className={classes.listItemButton}
        onClick={() => setIsOpen((prevState) => !prevState)}
        data-cy={cypressTestId}
      >
        <ListItemIcon
          className={classes.listItemIcon}
        >
          {navItem.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ style: { fontWeight: 700 } }}
          primary={navItem.label}
        />
        {
            isOpen
              ? <ExpandLessIcon />
              : <ExpandMoreIcon />
          }
      </ListItem>
      {isOpen && (
      <List className={classes.childNavList}>
        {navItem.children.map((item) => (
          <ListItem key={uuidv4()} disablePadding>
            <ListItemButton
              classes={{ root: classes.childListItemButton }}
              selected={pathname === item.url}
              onClick={() => navigate(item.url)}
              data-cy={item?.cyTestId}
            >
              <ListItemText>
                {item.label}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      )}
    </Box>
  );
};

export default CollapsibleListItem;
