import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { StyledButton } from '../../../common/StyledButton';
import { ADD_LANGUAGE } from '../../../graphql/mutations';
import {
  GET_ALL_LANGUAGES,
  GET_LANGUAGES_COUNT,
  GET_LINE,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { IAddLanguageDialogProps } from './types';

import { useStyles } from './styles';
import { base64Decoding, getDifference } from '../../../helpers';
import { addLanguage as addLanguageProps } from '../../../graphql/mutations/__generated__/addLanguage';

import {
  allLanguages,
  // eslint-disable-next-line camelcase
  allLanguages_allLanguages_edges_node,
} from '../../../graphql/queries/__generated__/allLanguages';
import { line } from '../../../graphql/queries/__generated__/line';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const AddLanguageDialog: React.FC<IAddLanguageDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
  options,
  setOptions,
}) => {
  const { id } = useParams();

  const [
    languageValue,
    setLanguageValue,
  // eslint-disable-next-line camelcase
  ] = useState<(allLanguages_allLanguages_edges_node | null | undefined)>({
    __typename: 'LanguageNode',
    id: '',
    name: '',
    languageCode: '',
    languageFlag: '',
    textOnly: false,
  });
  // eslint-disable-next-line camelcase

  const {
    data: languagesData,
    loading: languagesLoading,
  } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );
  const { data: lineData, loading: lineLoading } = useQuery<line>(GET_LINE, { variables: { id } });

  const { data: scriptIdData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const scriptId = scriptIdData?.project?.scriptSet?.edges[0]?.node?.id;

  const [addLanguage] = useMutation<addLanguageProps>(ADD_LANGUAGE, {
    refetchQueries: [{ query: GET_LINE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_LANGUAGES_COUNT },
      { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { scriptId } },
      { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { scriptId } },
      'line'],
  });

  const classes = useStyles();

  const handleAddLanguage = async (
    languageId: string | undefined,
  ) => {
    await addLanguage({
      variables: {
        localisedAssetCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          line: base64Decoding(id || ''),
          language: base64Decoding(languageId || ''),
        },
      },
    });
    setOpen(!open);
    setLanguageValue({
      __typename: 'LanguageNode',
      id: '',
      name: '',
      languageCode: '',
      languageFlag: '',
      textOnly: false,
    });
  };

  useEffect(() => {
    if (languagesData?.allLanguages?.edges && lineData?.line?.localisedassetSet.edges) {
      setOptions(getDifference(languagesData?.allLanguages?.edges, lineData?.line?.localisedassetSet.edges));
    }
  }, [languagesData, lineData]);

  if (languagesLoading || lineLoading) {
    return <PageLoader />;
  }

  return (
    <Dialog open={open} onClose={handleDialogChange} fullWidth maxWidth="md">
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box sx={{ maxWidth: '40%' }}>
            <Typography className={classes.subtitle} variant="body2">
              Language
            </Typography>
            {languagesData?.allLanguages && lineData?.line && (
            <Autocomplete
              id="revision-statuses"
              options={options}
              getOptionLabel={(option) => option?.name || ''}
              // className={classes.autocomplete}
              isOptionEqualToValue={(option, val) => option?.id === val?.id
                || val?.id === ''}
              value={languageValue}
              onChange={(_event, newValue) => {
                setLanguageValue(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Language"
                />
              )}
               // eslint-disable-next-line camelcase
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                >
                  <FlagVisualisation optionFlag={option?.languageFlag || ''} />
                  {option?.name}
                </Box>
              )}
            />
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between' }}
          className={classes.disableHorisontalPadding}
        >
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={handleDialogChange}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label="Add"
            action={() => handleAddLanguage(languageValue?.id)}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default AddLanguageDialog;
