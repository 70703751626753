import React from 'react';
import { Box } from '@mui/material';

import { LoginForm } from '../../common/LoginForm';
import { LandingHeader } from '../../common/LandingHeader';
import { Footer } from '../../common/Footer';
import { useStyles } from './styles';

const Login = () => {
  document.body.style.background = `
  linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), 
  linear-gradient(0deg, #2B75F3 0%, #75A9FF 98.54%)
  `;
  document.body.style.backgroundSize = 'cover';
  document.body.style.backgroundRepeat = 'no-repeat';

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <LandingHeader />
      <Box className={classes.contentContainer}>
        <LoginForm />
      </Box>
      <Box sx={{ flexShrink: '0' }}>
        <Footer />
      </Box>
    </Box>
  );
};
export default Login;
