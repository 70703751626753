import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({

  autocomplete: {
    width: '15vw',
    padding: '0px 0px',
    '& .MuiOutlinedInput-root': { padding: '4.5px' },
  },
  textField: {
    width: '15vw',
    padding: '12px 0p',
  },
  detailsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerText: {
    marginBottom: '8px',
  },

});
