import { makeStyles } from '@mui/styles';
import { lightGray } from '../../../constants/colors';

export const useStyles = makeStyles({
  text: {
    color: 'primary',
    marginBottom: '20px',
    alignSelf: 'center',
  },
  canselDialogButton: {
    alignSelf: 'flex-end',
    padding: '0',
  },
  closeDialogButton: {
    alignSelf: 'center',
  },
  spinnerWrapper: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '0 20px',
    display: 'flex',
    flexFlow: 'wrap row',
    justifyContent: 'space-evenly',
  },
  resultDialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
  },
  loadingDialogText: {
    color: lightGray,
    fontWeight: '600',
  },
});
