import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER, GET_CURRENT_USER_PERMISSIONS } from '../../graphql/queries';
import { ICurrentUser, IUserContextProps } from './types';
import { getUserQuery } from '../../graphql/queries/__generated__/getUserQuery';
import { AuthContext } from '../AuthContext/AuthContext';
import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';
import {
  currentUserProjectPermissions,
} from '../../graphql/queries/__generated__/currentUserProjectPermissions';

export const UserContext = React.createContext<IUserContextProps>({
  user: null,
  setUser: () => ({}),
  getUser: () => ({}),
  projectId: null,
  setProjectId: () => ({}),
  open: false,
  handleDialogChange: () => ({}),
});

const UserContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [user, setUser] = useState<ICurrentUser | null>(null);
  const [projectId, setProjectId] = useState<string | null>(localStorage.getItem(LOCALE_STORAGE_PROJECT_ID));
  const { isLoggedIn } = useContext(AuthContext);

  const [open, setOpen] = useState(false);

  const {
    refetch: refetchUser,
    data: userData,
    loading: userLoading,
    error: userErrors,
  } = useQuery<getUserQuery>(GET_CURRENT_USER, {
  });
  const {
    refetch: refetchPermissions,
    data: permissionData,
    loading: permissionLoading,
    error: permissionErrors,
  } = useQuery<currentUserProjectPermissions>(GET_CURRENT_USER_PERMISSIONS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    fetchPolicy: 'network-only',
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  useEffect(() => {
    (async () => {
      await refetchUser();
      await refetchPermissions();
    })();
  }, [isLoggedIn]);

  useEffect(() => {
    if (!permissionLoading && !userLoading && permissionData?.currentUserProjectPermissions && userData?.currentUser) {
      setUser({ ...userData.currentUser, permissions: permissionData.currentUserProjectPermissions });
    } else if (userErrors || permissionErrors) {
      setUser(null);
    }
  }, [permissionData, permissionLoading, userLoading, userData, userErrors, permissionErrors]);

  const handleDialogChange = () => {
    setOpen(!open);
  };

  return (
    <UserContext.Provider value={{
      user,
      setUser,
      getUser: refetchUser,
      projectId,
      setProjectId,
      open,
      handleDialogChange,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
