import { ILanguageFlag } from './types';
import SouthAfricaFlag from '../../assets/flags/south-africa.svg';
import AlbaniaFlag from '../../assets/flags/albania.svg';
import ArabicUAEFlag from '../../assets/flags/arabic-uae.svg';
import ArabicSAFlag from '../../assets/flags/arabic-saudi-arabia.svg';
import ArabicEgyptFlag from '../../assets/flags/arabic-egypt.svg';
import ArabicLebanonFlag from '../../assets/flags/arabic-lebanon.svg';
import ArmeniaFlag from '../../assets/flags/armenia.svg';
import AzerbaijanFlag from '../../assets/flags/azerbaijan.svg';
import BelarusFlag from '../../assets/flags/belarus.svg';
import BangladeshFlag from '../../assets/flags/bangladesh.svg';
import BosniaFlag from '../../assets/flags/bosnia.svg';
import BulgariaFlag from '../../assets/flags/bulgaria.svg';
import CatalanFlag from '../../assets/flags/catalan.svg';
import ChineseSimplifiedFlag from '../../assets/flags/chinese-simplified.svg';
import ChineseFlag from '../../assets/flags/chinese-traditional.svg';
import CantoneseFlag from '../../assets/flags/cantonese.svg';
import CroatiaFlag from '../../assets/flags/croatia.svg';
import CzechFlag from '../../assets/flags/czech-republic.svg';
import DenmarkFlag from '../../assets/flags/denmark.svg';
import NetherlandsFlag from '../../assets/flags/netherlands.svg';
import USAFlag from '../../assets/flags/usa.svg';
import UKFlag from '../../assets/flags/uk.svg';
import EstoniaFlag from '../../assets/flags/estonia.svg';
import FinlandFlag from '../../assets/flags/finland.svg';
import FranceFlag from '../../assets/flags/france.svg';
import GeorgiaFlag from '../../assets/flags/georgia.svg';
import GermanyFlag from '../../assets/flags/germany.svg';
import AustriaFlag from '../../assets/flags/austria.svg';
import GreeceFlag from '../../assets/flags/greece.svg';
import IsraelFlag from '../../assets/flags/israel.svg';
import IndiaFlag from '../../assets/flags/india.svg';
import HungaryFlag from '../../assets/flags/hungary.svg';
import IcelandFlag from '../../assets/flags/iceland.svg';
import IndonesiaFlag from '../../assets/flags/indonesia.svg';
import IrelandFlag from '../../assets/flags/ireland.svg';
import ItalyFlag from '../../assets/flags/italy.svg';
import JapanFlag from '../../assets/flags/japan.svg';
import KazakhstanFlag from '../../assets/flags/kazakhstan.svg';
import CongoFlag from '../../assets/flags/congo.svg';
import KoreaFlag from '../../assets/flags/korea.svg';
import LatviaFlag from '../../assets/flags/latvia.svg';
import LithuaniaFlag from '../../assets/flags/lithuania.svg';
import LuxembourgFlag from '../../assets/flags/luxembourg.svg';
import MacedoniaFlag from '../../assets/flags/macedonia.svg';
import MaltaFlag from '../../assets/flags/malta.svg';
import MongoliaFlag from '../../assets/flags/mongolia.svg';
import NepalFlag from '../../assets/flags/nepal.svg';
import NorwayFlag from '../../assets/flags/norway.svg';
import IranFlag from '../../assets/flags/iran.svg';
import PolandFlag from '../../assets/flags/poland.svg';
import PortugalFlag from '../../assets/flags/portugal.svg';
import PunjabFlag from '../../assets/flags/punjab.svg';
import RomaniaFlag from '../../assets/flags/romania.svg';
import RussiaFlag from '../../assets/flags/russia.svg';
import SerbiaFlag from '../../assets/flags/serbia.svg';
import SlovakiaFlag from '../../assets/flags/slovakia.svg';
import SloveniaFlag from '../../assets/flags/slovenia.svg';
import SomaliaFlag from '../../assets/flags/somalia.svg';
import SpainFlag from '../../assets/flags/spain.svg';
import ColumbiaFlag from '../../assets/flags/columbia.svg';
import MexicoFlag from '../../assets/flags/mexico.svg';
import KenyaFlag from '../../assets/flags/kenya.svg';
import SwedenFlag from '../../assets/flags/sweden.svg';
import TamilFlag from '../../assets/flags/tamil.svg';
import ThailandFlag from '../../assets/flags/thailand.svg';
import TurkeyFlag from '../../assets/flags/turkey.svg';
import UkraineFlag from '../../assets/flags/ukraine.svg';
import VietnamFlag from '../../assets/flags/vietnam.svg';
import WalesFlag from '../../assets/flags/wales.svg';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges_node } from '../../graphql/queries/__generated__/allLanguages';

// eslint-disable-next-line camelcase
export const allLanguageNames: allLanguages_allLanguages_edges_node[] = [
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Afrikaans',
    languageFlag: 'ZA',
    languageCode: 'af',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Albanian',
    languageFlag: 'AL',
    languageCode: 'sq',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Arabic (UAE)',
    languageFlag: 'AE',
    languageCode: 'ar-uae',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Arabic (Saudi Arabia)',
    languageFlag: 'SA',
    languageCode: 'ar-sa',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Arabic (Egypt)',
    languageFlag: 'EG',
    languageCode: 'ar-eg',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Arabic (Lebanon)',
    languageFlag: 'LB',
    languageCode: 'ar-le',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Armenian',
    languageFlag: 'AM',
    languageCode: 'hy',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Azerbaijani',
    languageFlag: 'AZ',
    languageCode: 'az',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Belarusian',
    languageFlag: 'BY',
    languageCode: 'be',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Bengali',
    languageFlag: 'BD',
    languageCode: 'bn',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Bosnian',
    languageFlag: 'BA',
    languageCode: 'bs',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Bulgarian',
    languageFlag: 'BG',
    languageCode: 'bg',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Catalan',
    languageFlag: 'ES-CT',
    languageCode: 'ca',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Chinese (Simplified)',
    languageFlag: 'CN-S',
    languageCode: 'zh-Hans',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Chinese (Traditional)',
    languageFlag: 'CN',
    languageCode: 'zh-Hant',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Cantonese',
    languageFlag: 'HK',
    languageCode: 'yu',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Croatian',
    languageFlag: 'HR',
    languageCode: 'hr',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Czech',
    languageFlag: 'CZ',
    languageCode: 'cs',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Danish',
    languageFlag: 'DK',
    languageCode: 'da',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Dutch',
    languageFlag: 'NL',
    languageCode: 'nl',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'English (USA)',
    languageFlag: 'US',
    languageCode: 'en',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'English (UK)',
    languageFlag: 'GB',
    languageCode: 'en-GB',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Estonian',
    languageFlag: 'EE',
    languageCode: 'et',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Finnish',
    languageFlag: 'FI',
    languageCode: 'fi',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'French (France)',
    languageFlag: 'FR',
    languageCode: 'fr',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'French (Canada)',
    languageFlag: 'CA',
    languageCode: 'fr-CA',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Georgian',
    languageFlag: 'GE',
    languageCode: 'ka',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'German (Germany)',
    languageFlag: 'DE',
    languageCode: 'de',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'German (Austria)',
    languageFlag: 'AT',
    languageCode: 'de-AU',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Greek',
    languageFlag: 'GR',
    languageCode: 'el',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Hebrew',
    languageFlag: 'IL',
    languageCode: 'he',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Hindi',
    languageFlag: 'IN',
    languageCode: 'hi',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Hungarian',
    languageFlag: 'HU',
    languageCode: 'hu',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Icelandic',
    languageFlag: 'IS',
    languageCode: 'is',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Indonesian',
    languageFlag: 'ID',
    languageCode: 'id',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Irish',
    languageFlag: 'IE',
    languageCode: 'ga',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Italian',
    languageFlag: 'IT',
    languageCode: 'it',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Japanese',
    languageFlag: 'JP',
    languageCode: 'ja',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Kazakh',
    languageFlag: 'KZ',
    languageCode: 'kk',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Kongo',
    languageFlag: 'CG',
    languageCode: 'kg',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Korean',
    languageFlag: 'KR',
    languageCode: 'ko',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Latvian',
    languageFlag: 'LV',
    languageCode: 'lv',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Lithuanian',
    languageFlag: 'LT',
    languageCode: 'ln',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Luxembourgish',
    languageFlag: 'LU',
    languageCode: 'lb',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Macedonian',
    languageFlag: 'MK',
    languageCode: 'mk',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Maltese',
    languageFlag: 'MT',
    languageCode: 'mt',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Mongolian',
    languageFlag: 'MN',
    languageCode: 'mn',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Nepali',
    languageFlag: 'NP',
    languageCode: 'ne',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Norwegian',
    languageFlag: 'NO',
    languageCode: 'no',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Persian',
    languageFlag: 'IR',
    languageCode: 'fa',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Polish',
    languageFlag: 'PL',
    languageCode: 'pl',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Portuguese',
    languageFlag: 'PT',
    languageCode: 'pt',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Punjabi, Panjabi',
    languageFlag: 'PN',
    languageCode: 'pa',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Romanian',
    languageFlag: 'RO',
    languageCode: 'ro',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Russian',
    languageFlag: 'RU',
    languageCode: 'ru',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Serbian',
    languageFlag: 'RS',
    languageCode: 'sr',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Slovak',
    languageFlag: 'SK',
    languageCode: 'sk',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Slovenian',
    languageFlag: 'SI',
    languageCode: 'sl',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Somali',
    languageFlag: 'SO',
    languageCode: 'so',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Spanish (Spain)',
    languageFlag: 'ES',
    languageCode: 'es',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Spanish (South-America)',
    languageFlag: 'CO',
    languageCode: 'es-LA',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Spanish (Mexico)',
    languageFlag: 'MX',
    languageCode: 'es-MEX',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Swahili',
    languageFlag: 'KE',
    languageCode: 'sw',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Swedish',
    languageFlag: 'SE',
    languageCode: 'sv',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Tamil',
    languageFlag: 'LK',
    languageCode: 'ta',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Thai',
    languageFlag: 'TH',
    languageCode: 'th',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Turkish',
    languageFlag: 'TR',
    languageCode: 'tr',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Ukrainian',
    languageFlag: 'UA',
    languageCode: 'ua',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Vietnamese',
    languageFlag: 'VN',
    languageCode: 'vi',
    textOnly: false,
  },
  {
    __typename: 'LanguageNode',
    id: '',
    name: 'Welsh',
    languageFlag: 'WL',
    languageCode: 'cy',
    textOnly: false,
  },
];

export const allLanguageFlags: ILanguageFlag[] = [
  { languageFlag: 'ZA', src: SouthAfricaFlag },
  { languageFlag: 'AL', src: AlbaniaFlag },
  { languageFlag: 'AE', src: ArabicUAEFlag },
  { languageFlag: 'SA', src: ArabicSAFlag },
  { languageFlag: 'EG', src: ArabicEgyptFlag },
  { languageFlag: 'LB', src: ArabicLebanonFlag },
  { languageFlag: 'AM', src: ArmeniaFlag },
  { languageFlag: 'AZ', src: AzerbaijanFlag },
  { languageFlag: 'BY', src: BelarusFlag },
  { languageFlag: 'BD', src: BangladeshFlag },
  { languageFlag: 'BA', src: BosniaFlag },
  { languageFlag: 'BG', src: BulgariaFlag },
  { languageFlag: 'ES-CT', src: CatalanFlag },
  { languageFlag: 'CN-S', src: ChineseSimplifiedFlag },
  { languageFlag: 'CN', src: ChineseFlag },
  { languageFlag: 'HK', src: CantoneseFlag },
  { languageFlag: 'HR', src: CroatiaFlag },
  { languageFlag: 'CZ', src: CzechFlag },
  { languageFlag: 'DK', src: DenmarkFlag },
  { languageFlag: 'NL', src: NetherlandsFlag },
  { languageFlag: 'US', src: USAFlag },
  { languageFlag: 'GB', src: UKFlag },
  { languageFlag: 'EE', src: EstoniaFlag },
  { languageFlag: 'FI', src: FinlandFlag },
  { languageFlag: 'FR', src: FranceFlag },
  { languageFlag: 'CA', src: FranceFlag },
  { languageFlag: 'GE', src: GeorgiaFlag },
  { languageFlag: 'DE', src: GermanyFlag },
  { languageFlag: 'AT', src: AustriaFlag },
  { languageFlag: 'GR', src: GreeceFlag },
  { languageFlag: 'IL', src: IsraelFlag },
  { languageFlag: 'IN', src: IndiaFlag },
  { languageFlag: 'HU', src: HungaryFlag },
  { languageFlag: 'IS', src: IcelandFlag },
  { languageFlag: 'ID', src: IndonesiaFlag },
  { languageFlag: 'IE', src: IrelandFlag },
  { languageFlag: 'IT', src: ItalyFlag },
  { languageFlag: 'JP', src: JapanFlag },
  { languageFlag: 'KZ', src: KazakhstanFlag },
  { languageFlag: 'CG', src: CongoFlag },
  { languageFlag: 'KR', src: KoreaFlag },
  { languageFlag: 'LV', src: LatviaFlag },
  { languageFlag: 'LT', src: LithuaniaFlag },
  { languageFlag: 'LU', src: LuxembourgFlag },
  { languageFlag: 'MK', src: MacedoniaFlag },
  { languageFlag: 'MT', src: MaltaFlag },
  { languageFlag: 'MN', src: MongoliaFlag },
  { languageFlag: 'NP', src: NepalFlag },
  { languageFlag: 'NO', src: NorwayFlag },
  { languageFlag: 'IR', src: IranFlag },
  { languageFlag: 'PL', src: PolandFlag },
  { languageFlag: 'PT', src: PortugalFlag },
  { languageFlag: 'PN', src: PunjabFlag },
  { languageFlag: 'RO', src: RomaniaFlag },
  { languageFlag: 'RU', src: RussiaFlag },
  { languageFlag: 'RS', src: SerbiaFlag },
  { languageFlag: 'SK', src: SlovakiaFlag },
  { languageFlag: 'SI', src: SloveniaFlag },
  { languageFlag: 'SO', src: SomaliaFlag },
  { languageFlag: 'ES', src: SpainFlag },
  { languageFlag: 'CO', src: ColumbiaFlag },
  { languageFlag: 'MX', src: MexicoFlag },
  { languageFlag: 'KE', src: KenyaFlag },
  { languageFlag: 'SE', src: SwedenFlag },
  { languageFlag: 'LK', src: TamilFlag },
  { languageFlag: 'TH', src: ThailandFlag },
  { languageFlag: 'TR', src: TurkeyFlag },
  { languageFlag: 'UA', src: UkraineFlag },
  { languageFlag: 'VN', src: VietnamFlag },
  { languageFlag: 'WL', src: WalesFlag },
];
// eslint-disable-next-line camelcase
export const defaultLanguageData: allLanguages_allLanguages_edges_node = {
  __typename: 'LanguageNode',
  id: '',
  name: '',
  languageCode: '',
  languageFlag: '',
  textOnly: false,
};
