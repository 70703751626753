import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { CREATE_SECTION, UPDATE_SECTION } from '../../../graphql/mutations';
import { GET_ALL_SECTIONS, GET_SCRIPT_ID, GET_SECTIONS_COUNT } from '../../../graphql/queries';
import { base64Decoding } from '../../../helpers';
import { ICreateSectionDialogProps } from './types';
import { useStyles } from './styles';
import { createSection as createSectionProps } from '../../../graphql/mutations/__generated__/createSection';
import { updateSection as updateSectionProps } from '../../../graphql/mutations/__generated__/updateSection';
import { SectionContext } from '../../../context/SectionContext/SectionContext';
import { defaultSectionData } from '../../../constants/sections';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const CreateSectionDialog: React.FC<ICreateSectionDialogProps> = ({
  handleDialogChange, open, setOpen,
}) => {
  const context = useContext(SectionContext);
  const [sectionNameValue, setSectionNameValue] = useState(context?.sectionData.name);
  const [descriptionValue, setDescriptionValue] = useState(context?.sectionData?.description);
  const [notesValue, setNotesValue] = useState(context?.sectionData.notes);

  const classes = useStyles();
  const { data: scriptIdData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [createSection] = useMutation<createSectionProps>(CREATE_SECTION, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    refetchQueries: [
      {
        query: GET_ALL_SECTIONS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_SECTIONS_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allSections',
    ],
  });

  const [updateSection] = useMutation <updateSectionProps>(UPDATE_SECTION, {
    refetchQueries: [
      {
        query: GET_ALL_SECTIONS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allSections',
    ],
  });

  const handleCreateSection = (
    name: string| undefined,
    description: string| undefined,
    notes: string | undefined,
    scriptId: string,
  ) => {
    createSection({
      variables: {
        sectionCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          name,
          description,
          notes,
          script: base64Decoding(scriptId),
        },
      },
    });

    context?.setSectionData(defaultSectionData);
    setOpen(!open);
  };

  const handleUpdateSection = (
    id: string | undefined,
    name: string| undefined,
    description: string| undefined,
    notes: string | undefined,
  ) => {
    updateSection({
      variables: {
        sectionUpdateData: {
          id,
          name,
          description,
          notes,
        },
      },
    });

    context?.setSectionData(defaultSectionData);
    setOpen(!open);
  };

  const handleSectNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSectionNameValue(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
  };
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesValue(event.target.value);
  };

  useEffect(() => {
    if (context) {
      setSectionNameValue(context.sectionData.name);
      setDescriptionValue(context.sectionData.description);
      setNotesValue(context.sectionData.notes);
    }
  }, [open]);
  return (

    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="body2"
            >
              Section
            </Typography>
            <FormControl className={classes.input} sx={{ width: '80%' }}>
              <TextField
                value={sectionNameValue}
                placeholder="Section name..."
                error={sectionNameValue === ''}
                helperText={sectionNameValue === '' ? 'Section name is required' : ''}
                onChange={handleSectNameChange}
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body2">Description</Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                multiline
                rows={4}
                value={descriptionValue}
                placeholder="Section description..."
                onChange={handleDescriptionChange}
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body2">Notes</Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                multiline
                rows={4}
                value={notesValue}
                placeholder="Section notes..."
                onChange={handleNotesChange}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorisontalPadding}>
          <StyledButton
            width="210px"
            variant="outlined"
            label="Cancel"
            action={handleDialogChange}
          />
          <StyledButton
            variant={sectionNameValue === '' ? 'outlined' : 'contained'}
            width="210px"
            disabled={sectionNameValue === ''}
            label={context?.isEditMode ? 'OK' : 'Add'}
            action={context?.isEditMode ? () => handleUpdateSection(
              context?.sectionData.id,
              sectionNameValue,
              descriptionValue,
              notesValue,
            ) : () => {
              if (scriptIdData?.project?.scriptSet.edges[0]?.node?.id) {
                handleCreateSection(
                  sectionNameValue,
                  descriptionValue,
                  notesValue,
                  scriptIdData?.project?.scriptSet.edges[0]?.node?.id,
                );
              }
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateSectionDialog;
