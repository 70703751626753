import { makeStyles } from '@mui/styles';
import { info, secondary } from '../../constants/colors';

export const useStyles = makeStyles(({
  listItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  projectPicture: {
    width: 210,
    height: 208,
    backgroundColor: '#D9D9D9',
  },

  listItemButton: {
    color: info,
    paddingBottom: '20px',
    '&.Mui-selected': {
      color: secondary,
      backgroundColor: 'transparent',
    },
  },
  listItemIcon: {
    fill: info,
    // stroke: info,
    minWidth: 'auto',
    marginRight: '20px',
  },
}));
