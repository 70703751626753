import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    marginLeft: '120px',
    marginRight: '120px',
    display: 'flex',
    marginBottom: '110px',

    '@media (max-width: 1200px)': {
      marginLeft: '0px',
      marginRight: '0px',

    },
  },
  mainContainer: {
    margin: '0 auto',
    color: '#232755',
    width: '100vw',
    maxWidth: '1440px',

    '@media (max-width: 1200px)': {
      margin: '110px 0px 0px 0px',
    },
  },
  jellyImageWrapper: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  jellyfishImage: {
    marginRight: '65px',
    width: '90%',
    zIndex: 3,
    '@media (max-width: 1200px)': {
      width: '80%',
      marginRight: '0px',
    },
  },
  productContainer: {
    width: '18vw',
    height: '18vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginRight: '30px',
    borderRadius: '8px',
  },
  anchorContainer: {
    marginTop: '-100px',
    position: 'absolute',
    visibility: 'hidden',
  },
  jellyfishSectionWrapper: {
    marginTop: '100px',
    marginBottom: '64px',
  },
  jellyfishSectionWrapperInner: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  whoWeAreSection: {
    width: '100%',
    backgroundColor: '#F1F3FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '32px',
    marginBottom: '64px',
    position: 'relative',
    zIndex: 2,
    overflow: 'hidden',
    '@media (max-width: 1200px)': {
      marginTop: '0px',
    },
  },
  whoWeAreSectionTitle: {
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '44px',
    fontWeight: 900,
    marginTop: '64px',
    marginBottom: '24px',
    zIndex: 3,

    '@media (max-width: 1200px)': {
      fontSize: '32px',
      lineHeight: '32px',
    },
  },
  whoWeAreSectionText: {
    fontSize: '20px',
    lineHeight: '150%',
    textAlign: 'center',
    marginBottom: '64px',
    marginLeft: '115px',
    marginRight: '115px',
    maxWidth: '960px',
    zIndex: 3,

    '@media (max-width: 1200px)': {
      marginLeft: '32px',
      marginRight: '32px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },

  whoWeAreSectionImage: {
    width: '90%',
    zIndex: 100,
    '@media (max-width: 1200px)': {
      marginRight: '-32px',
    },
  },

  deliverySectionImage: {
    width: '70%',
    zIndex: 100,
    '@media (max-width: 1200px)': {
      marginRight: '-32px',
      width: '90%',
    },
  },
  welcomeDecoration: {
    position: 'absolute',
    left: '-110px',
    top: 0,
    zIndex: 2,
    '@media (max-width: 1200px)': {
      left: '0px',
    },
  },
  welcomeDecoration2: {
    position: 'absolute',
    right: '-110px',
    bottom: 0,
    zIndex: 2,
    '@media (max-width: 1200px)': {
      right: '0px',
    },
  },

  deliverySection: {
    width: '100%',
    backgroundColor: '#F1F3FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '32px',
    marginBottom: '92px',
  },
  deliverySectionTitle: {
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '32px',
    fontWeight: 700,
    margin: '64px 220px 34px',
    textAlign: 'center',
    lineHeight: '48px',

    '@media (max-width: 1200px)': {
      fontSize: '24px',
      lineHeight: '32px',
      margin: '64px 32px 34px',
      textAlign: 'start',
    },
  },
  contactForm: {
    width: '100vw',
    backgroundColor: '#F1F3FF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '@media (max-width: 1200px)': {
      marginLeft: '0px',
    },
  },
  contactFormTitle: {
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '44px',
    fontWeight: 900,
    marginTop: '64px',
    marginBottom: '24px',
    zIndex: 3,

  },
  contactFormText: {
    fontSize: '20px',
    lineHeight: '150%',
    textAlign: 'center',
    marginBottom: '24px',
    maxWidth: '726px',
    zIndex: 3,
  },
  accordion: {
    background: 'transparent',
    boxShadow: 'none',
    marginBottom: '50px',
  },
  textField: {
    backgroundColor: '#FFFF',
    outline: 'none',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  heading: {
    marginBottom: '75px',
    textAlign: 'center',
  },
  subheading: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  welcomeText: {
    marginBottom: '12px',
    textAlign: 'start',
    paddingTop: '110px',
    fontSize: '36px',
    fontWeight: '500',
    '@media (max-width: 1200px)': {
      fontSize: '24px',
      paddingTop: '70px',
    },
  },
  welcomeSectionTitle: {
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '58px',
    fontWeight: '700',
    lineHeight: '76px',
    letterSpacing: '0.01em',
    marginBottom: '28px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1200px)': {
      fontSize: '28px',
      lineHeight: '40px',
    },
  },
  welcomeSectionText: {
    fontSize: '20px',
    marginBottom: '28px',
    whiteSpace: 'pre-wrap',
    '@media (max-width: 1200px)': {
      fontSize: '16px',
    },
    fontFamily: 'Open Sans',
  },
  buttonWrapper: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        width: '100%',
      },
    },
  },

  text: {
    marginBottom: '30px',
  },
  welcomeSection: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  formSection: {
    padding: '0px !important',
    position: 'relative',
  },

  featuresSection: {
    padding: '0px',
    marginBottom: '64px',
  },
  featuresSectionTitle: {
    background: 'linear-gradient(269.69deg, #FFCC18 -3.11%, #FA9F59 100%), linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%), #FFFFFF',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '44px',
    fontWeight: 900,
    marginBottom: '112px',
    zIndex: 3,
    textAlign: 'center',
    '@media (max-width: 1200px)': {
      fontSize: '32px',
      lineHeight: '32px',
      marginBottom: '48px',
    },
  },
  featuresConnectorWrapperRight: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-end',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  featuresConnectorWrapperLeft: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'flex-end',
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  featuresMobileConnectorWrapper: {
    display: 'none',
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  featuresCard: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '42px',
    backgroundColor: '#F1F3FF',
    borderRadius: '32px',
    padding: '36px 24px 36px 0',

    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      padding: '24px',
      gap: '18px',

      '& img': {
        width: '100px',
        height: '100px',
      },
    },
  },

  featuresCardTitle: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '32px',
    marginBottom: '24px',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
      lineHeight: '32px',
    },
  },

  orangeText: {
    color: '#FF6B40',
  },

  featuresCardText: {
    fontSize: '20px',
    lineHeight: '30px',

    '@media (max-width: 1200px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  disableLinkDecoration: {
    textDecoration: 'none',
  },

});
