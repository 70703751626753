import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  tableText: {
    paddingTop: '16px',
    paddingLeft: '10px',
    paddingBottom: '14px',
  },
  button: {
    '&.Mui-checked': {
      color: '#FE7B5B',
    },
  },
  selectedLanguage: {
    paddingTop: '16px',
    paddingLeft: '10px',
    paddingBottom: '14px',
    fontWeight: '600',
  },
}));
