import React, { useContext } from 'react';
import {
  Box, Checkbox, IconButton, Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { format } from 'date-fns';

import classNames from 'classnames';
import { ITodoProps } from './types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { TodoContext } from '../../../context/TodoContext/TodoContext';
import { DELETE_TODO, UPDATE_TODO } from '../../../graphql/mutations';
import { deleteToDoItem as deleteToDoItemProps } from '../../../graphql/mutations/__generated__/deleteToDoItem';
import { GET_ALL_TODOS } from '../../../graphql/queries';
import { useStyles } from './styles';
import { updateToDoItem as updateToDoItemProps } from '../../../graphql/mutations/__generated__/updateToDoItem';

const Todo:React.FC<ITodoProps> = ({ todo }) => {
  const context = useContext(TodoContext);

  const [deleteToDoItem] = useMutation<deleteToDoItemProps>(DELETE_TODO, {
    refetchQueries: [
      { query: GET_ALL_TODOS, variables: { orderBy: '-dueDate' } },
      'getAllTodos',
    ],
  });

  const [updateToDoItem] = useMutation<updateToDoItemProps>(UPDATE_TODO, {
    refetchQueries: [
      { query: GET_ALL_TODOS, variables: { orderBy: '-dueDate' } },
      'getAllTodos',
    ],
  });

  const classes = useStyles();

  const handleDeleteTodo = () => {
    deleteToDoItem({
      variables: {
        toDoItemDeletionData: {
          id: todo?.id,
        },
      },
    });
  };

  const handleUpdateTodo = (isDone: boolean) => {
    updateToDoItem({
      variables: {
        toDoItemUpdateData: {
          id: todo?.id,
          isDone,
        },
      },
    });
  };
  return (
    <Box className={classes.rootContainer}>
      <Box className={classNames(classes.todoContainer, {
        [classes.todoContainerDone]: todo?.isDone,
      })}
      >
        <Checkbox
          value={todo?.isDone}
          checked={todo?.isDone}
          onChange={() => handleUpdateTodo(!todo?.isDone)}
        />
        <Box sx={{
          display: 'flex', justifyContent: 'space-between', width: '15vw', alignItems: 'center',
        }}
        >
          <Typography
            variant="body2"
            sx={{ marginRight: '10px', fontWeight: 600 }}
          >
            {todo?.title}
          </Typography>
          {todo?.dueDate && (
          <Typography variant="body2" sx={{ marginRight: '10px' }}>
            {format(new Date(todo?.dueDate), 'MM/dd/yyyy')}
          </Typography>
          )}

        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton onClick={() => {
            context?.setTodoData({
              id: todo?.id || '',
              description: todo?.description || '',
              title: todo?.title || '',
              isDone: todo?.isDone || false,
              dueDate: todo?.dueDate,
            });
            context?.setIsEditMode(true);
            context?.setOpen(true);
          }}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={handleDeleteTodo}>
            <TrashIcon style={{ stroke: '#1F224C' }} />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ marginBottom: '0' }}>
          {todo?.description}
        </Typography>
      </Box>
    </Box>
  );
};
export default Todo;
