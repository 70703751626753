import React, { useContext, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Router } from './router';

import { VERIFY_TOKEN } from './graphql/mutations';
import { verifyToken as IVerifyToken } from './graphql/mutations/__generated__/verifyToken';
import { AuthContext } from './context/AuthContext/AuthContext';
import { UserContext } from './context/UserContext/UserContext';
import { ResetPasswordDialog } from './common/ResetPasswordDialog';
import { PageLoader } from './common/PageLoader';

const App: React.FC = () => {
  const [verifyToken] = useMutation<IVerifyToken>(VERIFY_TOKEN);
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  useEffect(() => {
    verifyToken()
      .then(() => {
        authContext.setIsLoggedIn(true);
      })
      .catch(() => {
        authContext.setIsLoggedIn(false);
      });
  }, []);

  if (!authContext.isVerfied) {
    return <PageLoader />;
  }

  return (
    <BrowserRouter>
      <Router />
      <ResetPasswordDialog open={userContext?.user?.requiresPasswordReset || false} />
    </BrowserRouter>
  );
};
export default App;
