import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../context/UserContext/UserContext';
import { GET_PROJECT, GET_USERS_AND_PERMISSIONS } from '../../../graphql/queries';
import {
  getUsersAndPermissionsForProject,
} from '../../../graphql/queries/__generated__/getUsersAndPermissionsForProject';
import { project } from '../../../graphql/queries/__generated__/project';
import { UserManagementTable } from '../UserManagementTable';
import { useStyles } from './styles';
import { StyledButton } from '../../../common/StyledButton';
import AddUserDialog from '../UserManagmentDialogs/AddUserDialog/AddUserDialog';
import { EditUserDialog } from '../UserManagmentDialogs/EditUserDialog';
import DeleteUserDialog from '../UserManagmentDialogs/DeleteUserDialog/DeleteUserDialog';
import { PageLoader } from '../../../common/PageLoader';

const UserManagementContainer = () => {
  const { projectId } = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [editableUserData, setEditableUserData] = useState<{
      email: string;
      id: string;
      permissionSets: string[]
  } | null>(null);

  const { data, loading } = useQuery<getUsersAndPermissionsForProject>(
    GET_USERS_AND_PERMISSIONS,
    {
      variables: {
        projectId,
      },
      skip: !projectId,
    },
  );

  const { data: projectData, loading: projectLoading } = useQuery<project>(GET_PROJECT, {
    variables: { id: projectId },
    skip: !projectId,
  });

  const handleAddUser = () => {
    setIsCreateDialogOpen(true);
  };

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Box>
      <Box className={classes.flexAlign}>
        <Typography variant="h5" sx={{ marginButton: '36px', fontWeight: 'bold' }}>
          {projectData?.project?.name || ''}
        </Typography>
        <StyledButton label={t('BUTTON.INVITE_USER_TO_PROJECT')} variant="outlined" action={handleAddUser} />
      </Box>
      <UserManagementTable
        data={data?.project}
        setIsEditDialogOpen={setIsEditDialogOpen}
        setEditableUserData={setEditableUserData}
        setIsDeleteDialogOpen={setIsDeleteDialogOpen}
      />
      <AddUserDialog open={isCreateDialogOpen} setOpen={setIsCreateDialogOpen} />
      <EditUserDialog open={isEditDialogOpen} setOpen={setIsEditDialogOpen} editableUserData={editableUserData} />
      <DeleteUserDialog open={isDeleteDialogOpen} setOpen={setIsDeleteDialogOpen} editableUserData={editableUserData} />
    </Box>
  );
};

export default UserManagementContainer;
