export const LOCATIONS_SELECT: string[] = [
  'Europe',
  'United Kingdom',
  'North America',
  'Japan',
  'China',
  'Asia (other)',
  'South America',
  'Africa',
  'Australia',
  'Other',
];

export const JOB_FUNCTIONS_SELECT = [
  'Sound Designer',
  'Audio Programmer',
  'Script Writer',
  'Narrative Lead',
  'Audio Lead',
  'Producer',
  'Product Manager',
  'Outsourcing Manager',
  'Other',
];

export const SENIORITY_LEVELS_SELECT = [
  'Junior',
  'Mid-level',
  'Senior',
  'Lead',
];

export const REFERRAL_SOURCES_SELECT = [
  'Google',
  'LinkedIn',
  'A game/audio developer’s conference',
  'Via a friend/colleague',
  'Other',
];

export const USER_PREFERENCES_SELECT = [
  'I am looking for a script writing and management tool.',
  'I want to have all my audio assets neatly in one place.',
  'I am looking for a tool that automatically translates my script to different languages.',
  'I am looking for a tool to QA my dialogue recordings and fix the issues.',
];
