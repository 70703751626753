import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import { LineTableRow } from '../LineTableRow';
import { HeadCell, ILineTableProps } from './types';
import { ReactComponent as TopRhombIcon } from '../../../assets/icons/top-rhomb-icon.svg';
import { ReactComponent as BottomRhombIcon } from '../../../assets/icons/bottom-rhomb-icon.svg';

const LineTable: React.FC<ILineTableProps> = ({
  data,
  order,
  orderBy,
  setOrder,
  setOrderBy,
}) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [

    {
      id: 'stringId',
      numeric: false,
      disablePadding: true,
      label: t('LINES_PAGE.LINE_ID_COLUMN'),
    },
    {
      id: 'character__name',
      numeric: false,
      disablePadding: false,
      label: t('LINES_PAGE.CHARACTER_COLUMN'),
    },
    {
      id: 'section__name',
      numeric: false,
      disablePadding: false,
      label: t('LINES_PAGE.SECTION_COLUMN'),
    },
    {
      id: 'languages',
      numeric: false,
      disablePadding: false,
      label: t('LINES_PAGE.LANGUAGES_COLUMN'),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: t('LINES_PAGE.ACTIONS_COLUMN'),
    },
  ];
  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(isAsc ? `-${property}` : property);
  };

  const sortTable = (property: string) => {
    handleRequestSort(property);
  };

  return (
    <div>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            <TableCell padding="none" />
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  { headCell.id === 'stringId'
                   || headCell.id === 'character__name'
                   || headCell.id === 'section__name'

                    ? (
                      <Button
                        onClick={() => {
                          sortTable(headCell.id);
                        }}
                        sx={{ textTransform: 'none' }}
                      >
                        <Typography variant="body2" sx={{ marginRight: '10px' }}>
                          {headCell.label}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <TopRhombIcon />
                          <BottomRhombIcon />
                        </Box>
                      </Button>
                    ) : (
                      <Typography variant="body2" sx={{ marginRight: '10px' }}>
                        {headCell.label}
                      </Typography>
                    )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((allLinesEdge, index) => (
            <LineTableRow
              data={allLinesEdge}
              key={uuidv4()}
              isEven={index % 2 === 0}
            />
          ))}
        </TableBody>
      </Table>

    </div>
  );
};
export default LineTable;
