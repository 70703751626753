import React, { useState } from 'react';

import { defaultTodoData } from '../../constants/todos';
import { ITodoContextProps } from './types';

export const TodoContext = React.createContext<ITodoContextProps | null>(null);

const TodoContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [todoData, setTodoData] = useState(defaultTodoData);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDialogChange = () => {
    setOpen(!open);
  };

  return (
    <TodoContext.Provider value={{
      todoData,
      setTodoData,
      isEditMode,
      open,
      setOpen,
      setIsEditMode,
      handleDialogChange,
    }}
    >
      {children}
    </TodoContext.Provider>
  );
};

export default TodoContextProvider;
