import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ITotalScriptsSectionsLanguagesCardProps } from './types';

const TotalScriptsSectionsLanguagesCard: React.FC<ITotalScriptsSectionsLanguagesCardProps> = ({
  sectionsCount,
  languagesCount,
  charactersCount,
}) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginRight: '1vw' }}>
            <Typography variant="subtitle2">
              {t('DASHBOARD.LANGUAGES')}
            </Typography>
            <Typography variant="h3">{languagesCount}</Typography>
          </Box>
          <Box sx={{ marginRight: '1vw' }}>
            <Typography variant="subtitle2">
              {t('DASHBOARD.CHARACTERS')}
            </Typography>
            <Typography variant="h3">
              {charactersCount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="subtitle2">
              {t('DASHBOARD.SECTIONS')}
            </Typography>
            <Typography variant="h3">{sectionsCount}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalScriptsSectionsLanguagesCard;
