import React from 'react';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { IRecordingsSummaryTableProps } from './types';
import { HeadCell } from '../../Line/LineTable/types';
import { RecordingsSummaryTableRow } from '../RecordingsSummaryTableRow';

const RecordingsSummaryTable: React.FC<IRecordingsSummaryTableProps> = ({
  data,
  handleGenerateRobovoiceForLanguages,
}) => {
  const { t } = useTranslation();

  const preparedData = data?.recordingStatusByLanguage?.filter((edge) => edge?.language?.textOnly !== true);

  const headCells: readonly HeadCell[] = [
    {
      id: 'language',
      numeric: false,
      disablePadding: true,
      label: t('RECORDINGS_SUMMARY_PAGE.LANGUAGE'),
      width: '40%',
    },
    {
      id: 'none',
      numeric: true,
      disablePadding: false,
      label: t('RECORDINGS_SUMMARY_PAGE.NONE'),
    },
    {
      id: 'placeholder',
      numeric: true,
      disablePadding: false,
      label: t('RECORDINGS_SUMMARY_PAGE.RECORDINGS'),
    },
    {
      id: 'finished',
      numeric: true,
      disablePadding: false,
      label: t('RECORDINGS_SUMMARY_PAGE.FINISHED'),
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: t('RECORDINGS_SUMMARY_PAGE.ACTIONS_COLUMN'),
    },
  ];
  return (
    <Box>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="none"
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                  <Typography variant="body2" sx={{ marginRight: '10px' }}>
                    {headCell.label}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {preparedData?.map((languageData) => (
            <RecordingsSummaryTableRow
              key={uuidv4()}
              languageData={languageData}
              handleGenerateRobovoiceForLanguages={handleGenerateRobovoiceForLanguages}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default RecordingsSummaryTable;
