import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';

import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import {
  GET_ALL_CHARACTERS,
  GET_ALL_LANGUAGES,
  GET_ALL_SECTIONS, RECORDING_EXPORT_REQUEST_STATUS,
} from '../../../graphql/queries';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { EXPORT_RECORDING } from '../../../graphql/mutations';
import { ExportRecordingInput } from '../../../../__generated__/globalTypes';
import { recordingExportRequestStatus } from '../../../graphql/queries/__generated__/recordingExportRequestStatus';
import { exportRecording as exportRecordingType } from '../../../graphql/mutations/__generated__/exportRecording';
import DownloadDialog from '../../../common/DownloadDialog/DownloadDialog';
import { DownloadRecordingForm } from '../DownloadRecordingForm';
import { PageLoader } from '../../../common/PageLoader';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const DownloadRecordingContainer: React.FC = () => {
  const { t } = useTranslation();

  const [requestId, setRequestId] = useState<string>('');
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(GET_ALL_CHARACTERS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const { loading: languagesLoading, data: languagesData } = useQuery<allLanguages>(GET_ALL_LANGUAGES, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [exportRecording] = useMutation<exportRecordingType>(
    EXPORT_RECORDING,
    {
      onCompleted: (data) => setRequestId(data?.exportRecording?.request?.id || ''),
    },
  );
  const {
    data: recordingExportData, loading: recordingExportLoading, startPolling, stopPolling,
  } = useQuery<recordingExportRequestStatus>(
    RECORDING_EXPORT_REQUEST_STATUS,
    {
      variables: { id: requestId },
      skip: !requestId,
    },
  );

  const downloadFileUrl = recordingExportData?.recordingExportRequestStatus?.s3DownloadLink || '';

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = downloadFileUrl;
    link.download = ''; // set the download attribute to the desired filename
    link.rel = 'noreferrer'; // set the rel attribute to noreferrer
    link.target = '_blank'; // set the target attribute to _blank
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (requestId) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [requestId]);

  useEffect(() => {
    if (!recordingExportLoading) {
      if (recordingExportData?.recordingExportRequestStatus?.hasFailed) {
        stopPolling();
      } else if (recordingExportData?.recordingExportRequestStatus?.isFinished) {
        stopPolling();
        setIsPolling(false);
        downloadFile();
      }
      if (!recordingExportData?.recordingExportRequestStatus) {
        stopPolling();
        setIsPolling(false);
      }
    }
  }, [recordingExportData, recordingExportLoading]);

  const handleExportRecording = (
    {
      projectId,
      languagesInSeparateFolders,
      charactersInSeparateFolders,
      sectionsInSeparateFolders,
      characterIds,
      languageIds,
      sectionIds,
      usesLanguagePrefix,
      usesLanguageSuffix,
    }: ExportRecordingInput,
  ) => {
    if (!projectId) {
      return;
    }
    exportRecording({
      variables: {
        exportScriptOptions: {
          projectId,
          characterIds,
          languageIds,
          sectionIds,
          usesLanguagePrefix,
          usesLanguageSuffix,
          languagesInSeparateFolders,
          charactersInSeparateFolders,
          sectionsInSeparateFolders,
          separatorCharacters: '_',
        },
      },
    });
  };

  if (charactersLoading || sectionsLoading || languagesLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: '40px', marginButton: '36px', fontWeight: 'bold' }}>
        {t('DOWNLOAD_RECORDINGS_PAGE.TITLE')}
      </Typography>
      <DownloadRecordingForm
        charactersList={charactersData?.allCharacters?.edges}
        sectionsList={sectionsData?.allSections?.edges}
        languagesList={languagesData?.allLanguages?.edges}
        exportRecording={handleExportRecording}
        projectId={localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || ''}
      />
      <DownloadDialog
        isPolling={isPolling}
        stopPolling={stopPolling}
        setIsPolling={setIsPolling}
        isFailed={recordingExportData?.recordingExportRequestStatus?.hasFailed || false}
      />
    </>
  );
};
export default DownloadRecordingContainer;
