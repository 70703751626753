import React, { useState } from 'react';
import {
  Box, Checkbox, Typography,
} from '@mui/material';
import { IComparativeFixFormProps } from './types';
import { useStyles } from '../GeneralFixForm/styles';
import FixDialogResult from '../FixDialogResult/FixDialogResult';
import { StyledButton } from '../../StyledButton';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const ComparativeFixForm: React.FC<IComparativeFixFormProps> = ({
  isPolling,
  fixUpFilesLoading,
  setOpen,
  fixUpFilesEquate,
  selectedRevisions,
  fixUpStatusData,
}) => {
  const [isNumberOfChannelsEqual, setIsNumberOfChannelsEqual] = useState<boolean>(false);
  const [isSampleRateEqual, setIsSampleRateEqual] = useState<boolean>(false);
  const [isLoudnessEqual, setIsLoudnessEqual] = useState<boolean>(false);
  const [isPaddingFrontEqual, setIsPaddingFrontEqual] = useState<boolean>(false);
  const [isPaddingBackEqual, setIsPaddingBackEqual] = useState<boolean>(false);

  const checkIfSomeFieldIsSelected = () => isNumberOfChannelsEqual
    || isSampleRateEqual
    || isLoudnessEqual
    || isPaddingFrontEqual
    || isPaddingBackEqual;

  const classes = useStyles();

  const handleProcessButton = () => {
    fixUpFilesEquate({
      variables: {
        fixupFileCreationData: {
          fixNumberOfChannels: isNumberOfChannelsEqual,
          fixSampleRate: isSampleRateEqual,
          fixLoudness: isLoudnessEqual,
          fixPaddingFront: isPaddingFrontEqual,
          fixPaddingBack: isPaddingBackEqual,
          ...selectedRevisions.reduce((acc, revision) => {
            acc.fixupAssetRevisionIds.push(revision.revisionId);
            return acc;
          }, {
            fixupAssetRevisionIds: [] as Array<string>,
          }),
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
    }).then();
  };

  return (
    <Box sx={{ marginTop: '42px' }}>
      {
          isPolling || fixUpFilesLoading
          || fixUpStatusData?.fixUpRequestStatus?.isFinished
          || fixUpStatusData?.fixUpRequestStatus?.hasFailed
            ? (
              <FixDialogResult
                fixUpStatusData={fixUpStatusData}
                handleClose={() => setOpen(false)}
              />
            )
            : (
              <Box className={classes.formWrapper}>
                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isNumberOfChannelsEqual}
                    onChange={() => {
                      setIsNumberOfChannelsEqual((prevState) => !prevState);
                    }}
                  />
                  <Typography>Equate number of channels to source language</Typography>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isSampleRateEqual}
                    onChange={() => setIsSampleRateEqual((prevState) => !prevState)}
                  />
                  <Typography>Equate sample rate to source language</Typography>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isLoudnessEqual}
                    onChange={() => setIsLoudnessEqual((prevState) => !prevState)}
                  />
                  <Typography>Equate loudness to source language</Typography>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isPaddingFrontEqual}
                    onChange={() => setIsPaddingFrontEqual((prevState) => !prevState)}
                  />
                  <Typography>Equate padding front to source language</Typography>
                </Box>

                <Box className={classes.formField}>
                  <Checkbox
                    className={classes.checkbox}
                    checked={isPaddingBackEqual}
                    onChange={() => setIsPaddingBackEqual((prevState) => !prevState)}
                  />
                  <Typography>Equate padding back to source language</Typography>
                </Box>
                <Box className={classes.buttonsWrapper}>
                  <StyledButton margin="0 10px 0" variant="outlined" label="Cancel" action={() => setOpen(false)} />
                  <StyledButton
                    margin="0 10px 0"
                    variant="contained"
                    disabled={!checkIfSomeFieldIsSelected()}
                    label="Process"
                    action={handleProcessButton}
                  />
                </Box>
              </Box>
            )
        }
    </Box>
  );
};

export default ComparativeFixForm;
