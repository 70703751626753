import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ListItemButton from '@mui/material/ListItemButton';
import { ListItemIcon } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { useStyles } from './styles';
import { INavListItemProps } from './types';

const NavListItem: React.FC<INavListItemProps> = ({ navItem, pathname }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <ListItem key={uuidv4()} disablePadding>
      <ListItemButton
        classes={{ root: classes.listItemButton }}
        selected={pathname === navItem.url}
        onClick={() => navigate(navItem.url)}
      >
        {navItem.url === '/upload' ? (
          <ListItemIcon
            className={
                pathname === navItem.url
                  ? classes.iconUploadSelected
                  : classes.iconUpload
            }
          >
            {navItem.icon}
          </ListItemIcon>
        ) : (
          <ListItemIcon
            className={
                pathname === navItem.url
                  ? classes.listItemIconSelected
                  : classes.listItemIcon
            }
          >
            {navItem.icon}
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{ style: { fontWeight: 700 } }}
          primary={navItem.label}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default NavListItem;
