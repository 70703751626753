import {
  Checkbox, Table, TableBody, TableCell, TableContainer, TableRow,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useStyles } from './styles';
import { IDownloadTableProps } from './types';

const DownloadTable: React.FC<IDownloadTableProps> = ({ list, selectedItems, setSelectedItems }) => {
  const classes = useStyles();

  const isTableItemActive = useCallback(
    (itemId: string | undefined):boolean => !!selectedItems.find((sectionId) => sectionId === itemId),
    [selectedItems],
  );

  const handleTableItem = useCallback((itemId: string | undefined) => {
    if (itemId) {
      // eslint-disable-next-line no-unused-expressions
      isTableItemActive(itemId)
        ? setSelectedItems((prevState) => prevState.filter((item) => item !== itemId))
        : setSelectedItems((prevState) => [...prevState, itemId]);
    }
  }, [selectedItems]);

  return (
    <TableContainer className={classes.tableWrapper}>
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {list?.map((edge, index) => (
            <TableRow
              role="checkbox"
              key={edge?.node?.id}
              className={index % 2 === 0 ? classes.lightBlueRow : classes.whiteRow}
              sx={{ textAlign: 'center' }}
            >
              <TableCell
                key={edge?.node?.id}
                className={classes.flexCenterAlign}
                align="left"
                padding="none"
              >
                <Checkbox
                  className={classes.checkbox}
                  checked={isTableItemActive(edge?.node?.id)}
                  onChange={() => handleTableItem(edge?.node?.id)}
                />

                <span>{edge?.node?.name}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DownloadTable;
