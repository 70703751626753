import React from 'react';
import {
  Box, Dialog, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IUploadScriptDialogProps } from './types';
import { useStyles } from './styles';
import { StyledButton } from '../../../common/StyledButton';
import { Spinner } from '../../../common/Spinner';
import { lightGray } from '../../../constants/colors';

const UploadScriptDialog: React.FC<IUploadScriptDialogProps> = ({
  isPolling,
  setIsPolling,
  stopPolling,
  scriptImportData,
}) => {
  const classes = useStyles();
  const isFailed = scriptImportData?.scriptImportRequestStatus?.hasFailed;
  const isFinished = scriptImportData?.scriptImportRequestStatus?.isFinished;
  const handlePollingClose = async (reload = false) => {
    stopPolling();
    setIsPolling(false);

    if (reload) {
      // TODO: This is a very hacky solution to re-fetch dashboard data, this should be improved.
      window.location.reload();
    }
  };

  return (
    <Dialog open={isPolling} onClose={() => handlePollingClose()} fullWidth maxWidth="xs">
      <Box className={classes.dialogWrapper}>
        <IconButton className={classes.closeDialogIconButton} onClick={() => handlePollingClose()}>
          <CloseIcon />
        </IconButton>
        {
            isFailed && isFinished
              ? (
                <>
                  <Typography variant="h3" className={classes.errorText}>Something went wrong</Typography>
                  <Typography className={classes.errorText} color={lightGray}>Please try again</Typography>
                  <Box className={classes.closeDialogButton}>
                    <StyledButton
                      width="74px"
                      action={handlePollingClose}
                      variant="outlined"
                      label="Close"
                    />
                  </Box>
                </>
              )
              : null
          }
        {
            isFinished && !isFailed
              ? (
                <>
                  <Typography variant="h3" className={classes.errorText}>Upload finished</Typography>
                  <Typography className={classes.errorText} color={lightGray}>
                    Your upload has been successful
                  </Typography>
                  <Box className={classes.closeDialogButton}>
                    <StyledButton
                      width="74px"
                      action={() => handlePollingClose(true)}
                      variant="outlined"
                      label="Close"
                    />
                  </Box>
                </>
              )
              : null
          }
        {
            !isFinished && !isFailed
              ? (
                <>
                  <Typography color={lightGray} sx={{ fontWeight: '600' }}>Processing</Typography>
                  <Box className={classes.spinnerWrapper}>
                    <Spinner />
                  </Box>
                  <Box className={classes.canselDialogButton}>
                    <StyledButton
                      width="74px"
                      action={() => handlePollingClose()}
                      variant="outlined"
                      label="Cancel"
                    />
                  </Box>
                </>
              )
              : null
          }
      </Box>
    </Dialog>
  );
};

export default UploadScriptDialog;
