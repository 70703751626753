import React, { useState } from 'react';

import { ILineContextProps } from './types';

export const LineContext = React.createContext<ILineContextProps | null>(null);

const LineContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [lineId, setLineId] = useState('');
  const [stringId, setStringId] = useState('');
  const [open, setOpen] = useState(false);

  const handleDialogChange = () => {
    setOpen(!open);
  };

  return (
    <LineContext.Provider value={{
      lineId,
      setLineId,
      open,
      setOpen,
      stringId,
      setStringId,
      handleDialogChange,
    }}
    >
      {children}
    </LineContext.Provider>
  );
};

export default LineContextProvider;
