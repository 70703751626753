import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Box, Dialog, IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation, useQuery } from '@apollo/client';
import { IFixRecordingsDialog } from './types';
import { useStyles } from './styles';
import { FixRevisionContext } from '../../context/FixRevisionContext/FixRevisionContext';
import GeneralFixForm from './GeneralFixForm/GeneralFixForm';
import { FIX_UP_FILES, FIX_UP_TO_EQUAL_FILES } from '../../graphql/mutations';
import { fixUpFiles as fixUpFilesType } from '../../graphql/mutations/__generated__/fixUpFiles';
import { fixUpRequestStatus as fixUpRequestStatusType } from '../../graphql/queries/__generated__/fixUpRequestStatus';
import { FIX_UP_REQUEST_STATUS } from '../../graphql/queries';
import { fixUpFilesEquate as fixUpFilesEquateType } from '../../graphql/mutations/__generated__/fixUpFilesEquate';
import { ComparativeFixForm } from './ComparativeFixForm';

const FixRecordingsDialog: React.FC<IFixRecordingsDialog> = ({ selectedRevisions }) => {
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const {
    isGeneralView, open, setOpen, setIsGeneralView,
  } = useContext(FixRevisionContext);

  const [
    fixUpFiles,
    {
      loading: fixUpFilesLoading,
      data: fixUpFilesData,
    },
  ] = useMutation<fixUpFilesType>(FIX_UP_FILES);

  const [
    fixUpFilesEquate,
    {
      loading: fixUpFilesEquateLoading,
      data: fixUpFilesEquateData,
    },
  ] = useMutation<fixUpFilesEquateType>(FIX_UP_TO_EQUAL_FILES);

  const {
    data: fixUpStatusData, loading: fixUpStatusLoading, startPolling, stopPolling,
  } = useQuery<fixUpRequestStatusType>(FIX_UP_REQUEST_STATUS, {
    variables: {
      id: isGeneralView
        ? fixUpFilesData?.fixUpFiles?.request?.id
        : fixUpFilesEquateData?.fixUpFilesEquate?.request?.id,
    },
    skip: isGeneralView
      ? !fixUpFilesData?.fixUpFiles?.request?.id
      : !fixUpFilesEquateData?.fixUpFilesEquate?.request?.id,
    fetchPolicy: 'network-only',
  });

  const classes = useStyles();

  useEffect(() => {
    if (!fixUpFilesLoading && fixUpFilesData && isGeneralView) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [fixUpFilesLoading, fixUpFilesData]);

  useEffect(() => {
    if (!fixUpFilesEquateLoading && fixUpFilesEquateData && !isGeneralView) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [fixUpFilesEquateLoading, fixUpFilesEquateData]);

  useEffect(() => {
    if (!fixUpStatusLoading) {
      if (fixUpStatusData?.fixUpRequestStatus?.hasFailed || fixUpStatusData?.fixUpRequestStatus?.isFinished) {
        stopPolling();
        setIsPolling(false);
      }
      if (!fixUpStatusData?.fixUpRequestStatus) {
        stopPolling();
        setIsPolling(false);
      }
    }
  }, [fixUpStatusData, fixUpStatusLoading]);

  const handlePollingClose = () => {
    stopPolling();
    setIsPolling(false);
    setIsGeneralView(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handlePollingClose}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogWrapper}>
        <IconButton
          className={classes.closeDialogIconButton}
          onClick={() => setOpen(false)}
        >
          <CloseIcon />
        </IconButton>
        <Typography fontWeight={600}>Fix</Typography>
        {
            isGeneralView
              ? (
                <GeneralFixForm
                  isPolling={isPolling}
                  fixUpFilesLoading={fixUpFilesLoading}
                  fixUpStatusData={fixUpStatusData}
                  setOpen={setOpen}
                  fixUpFiles={fixUpFiles}
                  selectedRevisions={selectedRevisions}
                />
              )
              : (
                <ComparativeFixForm
                  isPolling={isPolling}
                  fixUpFilesLoading={fixUpFilesEquateLoading}
                  fixUpStatusData={fixUpStatusData}
                  setOpen={setOpen}
                  fixUpFilesEquate={fixUpFilesEquate}
                  selectedRevisions={selectedRevisions}
                />
              )
          }
      </Box>
    </Dialog>
  );
};
export default FixRecordingsDialog;
