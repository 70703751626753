import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Recordings } from '../pages/Recordings';
import { Dashboard } from '../pages/Dashboard';
import { Home } from '../pages/Home';
import { Login } from '../pages/Login';
import { Script } from '../pages/Script';
import { Languages } from '../pages/Languages';
import { Deliveries } from '../pages/Deliveries';
import { TestPlayer } from '../pages/TestPlayer';
import { AuthContext } from '../context/AuthContext/AuthContext';
import { LineDetails } from '../pages/LineDetails';
import { Upload } from '../pages/Upload';
import { ProjectDetails } from '../pages/ProjectDetails';
import { Workspace } from '../pages/Workspace';
import { PlaceholderRobovoice } from '../pages/PlaceholderRobovoice';
import { Download } from '../pages/Download';
import { LineTableContainer } from '../components/Line/LineTableContainer';
import { SectionTableContainer } from '../components/Section/SectionTableContainer';
import { CharacterTableContainer } from '../components/Character/CharacterTableContainer';
import { GeneralRecordingsContainer } from '../components/Recordings/GerenalRecordingsContainer';
import { ComparativeAnalysisContainer } from '../components/ComparativeAnalysis/ComparativeAnalysisContainer';
import DownloadScriptContainer from '../components/DownloadScript/DownloadScriptContainer/DownloadScriptContainer';
import { UploadScriptContainer } from '../components/UploadScript/UploadScriptContainer';
import { DownloadRecordingContainer } from '../components/DownloadRecording/DownloadRecordingContainer';
import { UploadRecordContainer } from '../components/UploadRecord/UploadRecordContainer';
import { NotFound } from '../pages/NotFound';
import { RecordingsSummaryContainer } from '../components/RecordingsSummary/RecordingsSummaryContainer';
import UserManagement from '../pages/UserManagement/UserManagement';
import { UserContext } from '../context/UserContext/UserContext';

const Router:React.FC = () => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  return (
    <Routes>
      {!authContext?.isLoggedIn && (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/login2" element={<Workspace />} />
        </>
      )}
      {authContext?.isLoggedIn && (
        <>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/script/lines" element={<Script section={<LineTableContainer />} />} />
          <Route path="/script/sections" element={<Script section={<SectionTableContainer />} />} />
          <Route path="/script/characters" element={<Script section={<CharacterTableContainer />} />} />
          <Route path="/lines/:id" element={<LineDetails />} />
          <Route path="/languages" element={<Languages />} />
          <Route path="/deliveries" element={<Deliveries />} />
          <Route path="/project/:id" element={<ProjectDetails />} />
          <Route path="/deliveries/upload-script" element={<Upload section={<UploadScriptContainer />} />} />
          <Route path="/deliveries/upload-recordings" element={<Upload section={<UploadRecordContainer />} />} />
          <Route path="/deliveries/download-script" element={<Download section={<DownloadScriptContainer />} />} />
          <Route path="/deliveries/download-recordings" element={<Download section={<DownloadRecordingContainer />} />} />
          <Route path="/recordings/overview" element={<Recordings section={<RecordingsSummaryContainer />} />} />
          <Route path="/recordings/general" element={<Recordings section={<GeneralRecordingsContainer />} />} />
          <Route path="/recordings/comparative" element={<Recordings section={<ComparativeAnalysisContainer />} />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/test-audio-player" element={<TestPlayer />} />
          <Route path="/placeholder-robovoice" element={<PlaceholderRobovoice />} />
        </>
      )}
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
      <Route path="/404" element={<NotFound />} />
    </Routes>
  );
};
export default Router;
