import React, { useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import { StyledButton } from '../StyledButton';
import { white } from '../../constants/colors';
import { useStyles } from './styles';

const WorkspaceForm = () => {
  const classes = useStyles();
  const {
    handleSubmit, control,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'onBlur' });

  const onSubmit = async (data:unknown) => {
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    localStorage.setItem('workspace', JSON.stringify(data.domain));
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    window.location.href = `https://${data.domain}.jellyfish-localization.com`;
  };

  useEffect(() => {
    const subDomainStorage = localStorage.getItem('workspace');
    if (subDomainStorage) {
      setValue('domain', JSON.parse(subDomainStorage));
    }
  }, []);
  return (
    <Box className={classes.container}>
      <Typography
        variant="h2"
        color={white}
        sx={{
          textAlign: 'center',
          marginBottom: '35px',
        }}
      >
        Workspace
      </Typography>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
          <Controller
            name="domain"
            control={control}
            rules={{
              required: 'Domain required',
              pattern: {
                value: /^[a-z0-9]*$/,
                message: 'Invalid domain',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value || ''}
                label="Domain"
                type="text"
                inputProps={{
                  style: {
                    backgroundColor: white,
                    outline: 'none',
                    borderRadius: '8px',
                  },
                }}
                variant="filled"
              />
            )}
          />
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {errors.domain && <Typography sx={{ color: '#D40000' }}>{errors.domain.message}</Typography>}
        </Box>
        <Box className={classes.actionsContainer}>
          <StyledButton
            variant="contained"
            width="200px"
            label="To workspace"
            margin="0px 50px 0px 0px"
            action={handleSubmit(onSubmit)}
          />
        </Box>
      </form>
    </Box>
  );
};
export default WorkspaceForm;
