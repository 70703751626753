import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line camelcase
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { allLanguages, allLanguages_allLanguages_edges } from '../../../graphql/queries/__generated__/allLanguages';
import {
  GET_ALL_LANGUAGES, GET_RECORDINGS_AND_TEXT_DATA, RECORDING_IMPORT_REQUEST_STATUS,
} from '../../../graphql/queries';
import { useStyles } from './styles';
import { IMPORT_RECORDING } from '../../../graphql/mutations';
import { UploadRecordingForm } from '../UploadRecordingForm';
import { useCurrentProject } from '../../../hooks';
import { UploadRecordingDialog } from '../UploadRecordingDialog';
import useUploadFileToS3 from '../../../hooks/useUploadFileToS3';
import { importRecording as importRecordingType } from '../../../graphql/mutations/__generated__/importRecording';
import { recordingImportRequestStatus } from '../../../graphql/queries/__generated__/recordingImportRequestStatus';
import { RECORD_FILE_TYPES } from '../../../constants/recordings';
import { PageLoader } from '../../../common/PageLoader';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const UploadRecordingContainer: React.FC = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  // eslint-disable-next-line camelcase
  const [selectedLanguages, setSelectedLanguages] = useState<(allLanguages_allLanguages_edges | null)[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<Array<string>>([RECORD_FILE_TYPES[0]]);

  const [requestId, setRequestId] = useState<string>('');
  const [isPolling, setIsPolling] = useState<boolean>(false);

  const { loading: languagesLoading, data: languagesData } = useQuery<allLanguages>(GET_ALL_LANGUAGES, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [importRecording] = useMutation<importRecordingType>(IMPORT_RECORDING, {
    onCompleted: (data) => setRequestId(data.importRecording?.request?.id || ''),
    refetchQueries: [{ query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } }],
  });

  const { data: projectData, loading: projectLoading } = useCurrentProject();
  const {
    presignedUrlData, presignedUrlLoading, handleDnD, droppedFileName,
  } = useUploadFileToS3();

  const {
    data: recordingImportData, loading: recordingImportLoading, startPolling, stopPolling,
  } = useQuery<recordingImportRequestStatus>(
    RECORDING_IMPORT_REQUEST_STATUS,
    {
      variables: { id: requestId },
      skip: !requestId,
    },
  );

  // eslint-disable-next-line consistent-return
  const s3UploadData = useMemo(() => {
    if (presignedUrlData) {
      return JSON.parse(presignedUrlData.getPresignedUrlForImport?.awsResponse || '');
    }
  }, [presignedUrlData]);

  useEffect(() => {
    if (requestId) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => stopPolling();
  }, [requestId]);

  useEffect(() => {
    if (!recordingImportLoading) {
      if (!recordingImportData?.recordingImportRequestStatus) {
        stopPolling();
        setIsPolling(false);
      }
      if (recordingImportData?.recordingImportRequestStatus?.isFinished) {
        stopPolling();
      }
    }
  }, [recordingImportData, recordingImportLoading]);

  const handleUploadRecording = async () => {
    await importRecording({
      variables: {
        importRecordingOptions: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          languageIds: selectedLanguages.map((item) => item?.node?.id || null),
          s3FilePath: s3UploadData.fields.key,
        },
      },
    });
  };

  if (languagesLoading || presignedUrlLoading || projectLoading) {
    return <PageLoader />;
  }

  return (
    <Box className={classes.uploadScriptContainerWrapper}>
      <Typography variant="h5" sx={{ marginTop: '40px', marginButton: '36px', fontWeight: 'bold' }}>
        {t('UPLOAD_RECORDINGS_PAGE.TITLE')}
      </Typography>
      <UploadRecordingForm
        selectedLanguages={selectedLanguages}
        setSelectedLanguages={setSelectedLanguages}
        selectedFileTypes={selectedFileTypes}
        setSelectedFileTypes={setSelectedFileTypes}
        droppedFileName={droppedFileName}
        languagesList={languagesData?.allLanguages?.edges}
        handleDnD={handleDnD}
        handleUploadRecording={handleUploadRecording}
      />
      <UploadRecordingDialog
        isPolling={isPolling}
        stopPolling={stopPolling}
        setIsPolling={setIsPolling}
        recordingImportData={recordingImportData}
      />
    </Box>
  );
};

export default UploadRecordingContainer;
