import React, { useState } from 'react';
import { IFixRevisionContext } from './types';

export const FixRevisionContext = React.createContext<IFixRevisionContext>({
  open: false,
  setOpen: () => ({}),
  isGeneralView: false,
  setIsGeneralView: () => ({}),
});

const FixRevisionProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [isGeneralView, setIsGeneralView] = useState<boolean>(true);
  const [open, setOpen] = useState(false);

  return (
    <FixRevisionContext.Provider value={{
      open,
      setOpen,
      isGeneralView,
      setIsGeneralView,
    }}
    >
      {children}
    </FixRevisionContext.Provider>
  );
};

export default FixRevisionProvider;
