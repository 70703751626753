import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  dialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '20px',
    borderRadius: '12px',
  },
  closeDialogIconButton: {
    alignSelf: 'flex-end',
    color: '#000',
  },
});
