import React, { useContext } from 'react';
import {
  AppBar, Box, Button, Toolbar, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { HashLink as Link } from 'react-router-hash-link';

import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/Logo.svg';
import { DELETE_REFRESH_TOKEN, DELETE_TOKEN } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { deleteToken as IDeleteToken } from '../../graphql/mutations/__generated__/deleteToken';
import { deleteRefreshToken as IDeleteRefreshToken } from '../../graphql/mutations/__generated__/deleteRefreshToken';
import { StyledButton } from '../StyledButton';
import { useStyles } from './styles';
import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';

const LandingHeader = () => {
  const { t } = useTranslation();
  const [deleteRefreshToken] = useMutation<IDeleteRefreshToken>(DELETE_REFRESH_TOKEN);
  const [deleteToken] = useMutation<IDeleteToken>(DELETE_TOKEN);

  const classes = useStyles();
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const location = useLocation();

  const logOut = async () => {
    await deleteRefreshToken();
    await deleteToken();
    localStorage.removeItem(LOCALE_STORAGE_PROJECT_ID);
    context?.setIsLoggedIn(false);
    navigate('/login');
  };

  const moveToTop = () => {
    if (location.pathname.includes('/login')) {
      navigate('/');
    } else {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Button onClick={moveToTop}>
          <img
            src={Logo}
            alt="logo"
            className={classes.logoImage}
          />
        </Button>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex' }} className={classes.linksWrapper}>
            <Link to="/#who-are-we" className={classes.disableLinkDecoration}>
              <Typography
                className={classes.headerText}
                color="white"
              >
                {t('HEADER.LINKS.WHO_WE_ARE', { ns: 'homepage' })}
              </Typography>
            </Link>
            <Link to="/#features" className={classes.disableLinkDecoration}>
              <Typography
                className={classes.headerText}
                color="white"
              >
                {t('HEADER.LINKS.FEATURES', { ns: 'homepage' })}
              </Typography>
            </Link>
            <Link to="/#book-a-demo" className={classes.disableLinkDecoration}>
              <Typography
                sx={{ marginRight: '8vw' }}
                color="white"
              >
                {t('HEADER.LINKS.BOOK_A_DEMO', { ns: 'homepage' })}
              </Typography>
            </Link>
          </Box>
          {context?.isLoggedIn ? (
            <Button color="inherit" onClick={logOut}>
              Log out
            </Button>
          ) : (
            <StyledButton variant="contained" label="Sign in" action={() => navigate('/login')} />
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default LandingHeader;
