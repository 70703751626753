export const defaultRevisionData = {
  status: { placeholder: 'Awaiting text', value: 'AWAITING_TEXT' },
  text: '',
  notes: '',
  localised_asset: '',
  id: '',
  revisionIndex: 0,
  isSoundFileExist: false,
  soundFilePath: '',
  soundFileId: '',
};

export const statusOptions = [
  { placeholder: 'Awaiting text', value: 'AWAITING_TEXT' },
  { placeholder: 'Placeholder text', value: 'PLACEHOLDER_TEXT' },
  { placeholder: 'Text ready', value: 'TEXT_READY' },
  { placeholder: 'Awaiting recording', value: 'AWAITING_RECORDING' },
  { placeholder: 'Placeholder recording', value: 'PLACEHOLDER_RECORDING' },
  { placeholder: 'Recording done', value: 'RECORDING_DONE' },
];

export const MAX_CHARACTERS = 70;

export const BIT_DEPTH_VIEW_VALUES = ['Unknown', 'Int8', 'Int16', 'Int24', 'Int32', 'Float32', 'Float64'];

export const BIT_DEPTH_STRING_INDEX = 'A_';
