import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { LineRevisionContainer } from '../LineRevisionContainer';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { GET_ALL_CHARACTERS, GET_ALL_SECTIONS, GET_LINE } from '../../../graphql/queries';
import { line } from '../../../graphql/queries/__generated__/line';
import { LineDetailsHeader } from '../LineDetailsHeader';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import { AddLanguageDialog } from '../AddLanguageDialog';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges_node } from '../../../graphql/queries/__generated__/allLanguages';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const LineDetailsContainer = () => {
  const { id } = useParams();
  const { data, loading, refetch: refetchLine } = useQuery<line>(GET_LINE, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });
  const { data: sectionsData, loading: sectionsLoading } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const { data: charactersData, loading: charactersLoading } = useQuery<allCharacters>(GET_ALL_CHARACTERS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });
  const [open, setOpen] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  // eslint-disable-next-line camelcase
  const [options, setOptions] = useState<(allLanguages_allLanguages_edges_node | null | undefined)[]>([{
    __typename: 'LanguageNode',
    id: '',
    name: '',
    languageCode: '',
    languageFlag: '',
    textOnly: false,
  }]);

  useEffect(() => {
    if (shouldRefetch) {
      refetchLine({ variables: { id } }).then(() => setShouldRefetch(false));
    }
  }, [shouldRefetch]);

  const handleDialogChange = () => {
    setOpen(!open);
  };

  return loading || sectionsLoading || charactersLoading ? (
    <PageLoader />
  ) : (
    <>
      { data?.line?.section && data.line.character && (
      <LineDetailsHeader
        setOpen={setOpen}
        initialLineId={data?.line?.stringId || ''}
        sectionOptions={sectionsData?.allSections?.edges || undefined}
        characterOptions={charactersData?.allCharacters?.edges || undefined}
        initialSection={{ __typename: 'SectionNodeEdge', node: data?.line?.section }}
        initialCharacter={{ __typename: 'CharacterNodeEdge', node: data?.line?.character }}
        isNewLanguagesExist={options.length > 0}
      />
      )}
      <Box>
        {data?.line?.localisedassetSet.edges.map((localisedAsset) => (
          <LineRevisionContainer key={uuidv4()} data={localisedAsset?.node} setShouldRefetch={setShouldRefetch} />
        ))}
      </Box>
      <AddLanguageDialog
        open={open}
        setOpen={setOpen}
        handleDialogChange={handleDialogChange}
        options={options}
        setOptions={setOptions}
      />
    </>
  );
};
export default LineDetailsContainer;
