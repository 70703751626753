import React from 'react';
import {
  Box, Dialog, IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IRecordingSummaryDialogProps } from './types';
import { useStyles } from './styles';
import { RecordingRummaryDialogResult } from './RecordingRummaryDialogResult';

const RecordingSummaryDialog: React.FC<IRecordingSummaryDialogProps> = ({
  open,
  handlePollingClose,
  robovoiceGenerationStatusData,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handlePollingClose}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogWrapper}>
        <IconButton
          className={classes.closeDialogIconButton}
          onClick={handlePollingClose}
        >
          <CloseIcon />
        </IconButton>
        <RecordingRummaryDialogResult
          robovoiceGenerationStatusData={robovoiceGenerationStatusData}
          handleClose={handlePollingClose}
        />
      </Box>
    </Dialog>
  );
};

export default RecordingSummaryDialog;
