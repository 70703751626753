import React, { useCallback, useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';

import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { IDeleteUserDialogProps } from './types';
import { StyledButton } from '../../../../common/StyledButton';
import {
  removeUserFromProject as removeUserFromProjectType,
} from '../../../../graphql/mutations/__generated__/removeUserFromProject';
import { REMOVE_USER_FROM_PROJECT } from '../../../../graphql/mutations';
import { GET_USERS_AND_PERMISSIONS } from '../../../../graphql/queries';
import { UserContext } from '../../../../context/UserContext/UserContext';

const DeleteUserDialog: React.FC<IDeleteUserDialogProps> = ({ open, setOpen, editableUserData }) => {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const { t } = useTranslation();

  const [removeUserFromProject] = useMutation<removeUserFromProjectType>(REMOVE_USER_FROM_PROJECT, {
    onCompleted: (data) => data.removeUserFromProject?.ok && setOpen(false),
    refetchQueries: [
      { query: GET_USERS_AND_PERMISSIONS, variables: { projectId: userContext?.projectId } },
    ],
  });

  const handleRemoveUser = useCallback((email: string) => {
    removeUserFromProject({
      variables: {
        removeUserFromProjectData: { email, projectId: userContext?.projectId },
      },
    }).then();
  }, [userContext?.projectId]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent>
          <Typography>
            {t('USER_MANAGEMENT_PAGE.DIALOG.SURE_TO_REMOVE_USER')}
            {' '}
            {editableUserData?.email}
            ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="No"
            action={() => setOpen(false)}
          />
          <StyledButton
            width="210px"
            variant="contained"
            label="Yes"
            action={() => handleRemoveUser(editableUserData?.email || '')}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteUserDialog;
