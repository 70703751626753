import {
  Card, CardContent, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useQuery } from '@apollo/client';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import {
  getTextStatusByLanguage, getTextStatusByLanguage_textStatusByLanguage,
} from '../../../graphql/queries/__generated__/getTextStatusByLanguage';
import {
  GET_ALL_LANGUAGES,
  GET_PRIMARY_LANGUAGE,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import {
  getRecordingStatusByLanguage, getRecordingStatusByLanguage_recordingStatusByLanguage,
} from '../../../graphql/queries/__generated__/getRecordingStatusByLanguage';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import {
  sortRecordingsByLanguageInAlphabeticalOrder,
  sortTextStatusByLanguageInAlphabeticalOrder,
} from '../../../helpers';
import { projectPrimaryLanguage } from '../../../graphql/queries/__generated__/projectPrimaryLanguage';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';

export const barOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  plugins: {
    legend: {
      position: 'top' as const,
    },
  },
  elements: {
    bar: {
      borderRadius: 12,
    },
  },
  maintainAspectRatio: false,
};

const ProjectOverviewCard = () => {
  const { t } = useTranslation();

  const [barLabels, setBarLabels] = React.useState<string[]>([]);
  const [textStatusData, setTextStatusData] = React.useState<(getTextStatusByLanguage_textStatusByLanguage | null)[] | null>(null);
  const [recordingStatusData, setRecordingStatusData] = React.useState<(getRecordingStatusByLanguage_recordingStatusByLanguage | null)[] | null>(null);

  const { data: languagesData } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const {
    data: primaryLanguageData,
  } = useQuery<projectPrimaryLanguage>(GET_PRIMARY_LANGUAGE, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  const primaryLanguageId = primaryLanguageData?.project?.currentScript?.primaryLanguage?.id;
  const primaryLanguageName = languagesData?.allLanguages?.edges?.find(
    (edge: any) => edge?.node?.id === primaryLanguageId,
  )?.node?.name;

  const [getTextStatusByLanguageQuery] = useLazyQuery<getTextStatusByLanguage>(GET_TEXT_STATUS_BY_LANGUAGE, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    onCompleted: (data) => {
      const sorted = sortTextStatusByLanguageInAlphabeticalOrder(data?.textStatusByLanguage, primaryLanguageName);
      setTextStatusData(sorted);
    },
  });

  const [getRecordingStatusByLanguageQuery] = useLazyQuery<getRecordingStatusByLanguage>(GET_RECORDING_STATUS_BY_LANGUAGE, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    onCompleted: (data) => {
      const sorted = sortRecordingsByLanguageInAlphabeticalOrder(data.recordingStatusByLanguage, primaryLanguageName);
      setRecordingStatusData(sorted);
    },
  });

  useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    onCompleted: async () => {
      await getTextStatusByLanguageQuery();
      await getRecordingStatusByLanguageQuery();
    },
  });

  useEffect(() => {
    if (textStatusData) {
      const labels = textStatusData?.map((edge) => edge?.language?.name || '') || [];
      setBarLabels(labels);
    }
  }, [textStatusData]);

  const barData = {
    labels: barLabels,
    datasets: [
      {
        label: t('DASHBOARD.GRAPH_LABELS.TEXT_FINISHED'),
        data: textStatusData?.map((lng) => lng?.nTextReady || '') || [],
        backgroundColor: '#7482FF',
        borderColor: '#7482FF',
        stack: 'Stack 0',
      },
      {
        label: t('DASHBOARD.GRAPH_LABELS.TEXT_PLACEHOLDER'),
        data: textStatusData?.map((lng) => lng?.nTextPlaceholder || '') || [],
        backgroundColor: 'rgba(116,130,255,0.7)',
        borderColor: 'rgba(116,130,255,0.7)',
        stack: 'Stack 0',
      },
      {
        label: t('DASHBOARD.GRAPH_LABELS.TEXT_UNFINISHED'),
        data: textStatusData?.map((lng) => lng?.nTextNone || '') || [],
        backgroundColor: 'rgba(116,130,255,0.15)',
        borderColor: 'rgba(116,130,255,0.15)',
        stack: 'Stack 0',
      },
      {
        label: t('DASHBOARD.GRAPH_LABELS.RECORDINGS_FINISHED'),
        data: recordingStatusData?.map((lng) => lng?.nRecordingsFinished || '') || [],
        backgroundColor: '#FF7D45',
        borderColor: '#FF7D45',
        stack: 'Stack 1',

      },
      {
        label: t('DASHBOARD.GRAPH_LABELS.RECORDINGS_PLACEHOLDER'),
        data: recordingStatusData?.map((lng) => lng?.nRecordingsPlaceholder || '') || [],
        backgroundColor: 'rgba(255,125,69,0.7)',
        borderColor: 'rgba(255,125,69,0.7)',
        stack: 'Stack 1',
      },
      {
        label: t('DASHBOARD.GRAPH_LABELS.RECORDINGS_UNFINISHED'),
        data: recordingStatusData?.map((lng) => lng?.nRecordingsNone || '') || [],
        backgroundColor: 'rgba(255,125,69,0.15)',
        stack: 'Stack 1',
      },
    ],
  };

  return (
    <Card sx={{
      padding: '24px 24px 0px 24px',
    }}
    >
      <Typography variant="h3">
        {t('DASHBOARD.PROJECT_OVERVIEW')}
      </Typography>
      <CardContent sx={{
        height: '40vh',
      }}
      >

        <Bar width="100%" height="100%" options={barOptions} data={barData} />
      </CardContent>
    </Card>
  );
};

export default ProjectOverviewCard;
