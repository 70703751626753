import { Box, IconButton } from '@mui/material';
import React from 'react';

import Logo from '../../assets/images/Logo.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter-icon.svg';
import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();

  return (
    <footer>
      <Box className={classes.footerContainer}>
        <Box className={classes.logoWrapper}>
          <img src={Logo} alt="logo" />
        </Box>

        <Box className={classes.iconsWrapper} sx={{ display: 'flex' }}>
          <IconButton>
            <TwitterIcon />
          </IconButton>
        </Box>
      </Box>
    </footer>
  );
};
export default Footer;
