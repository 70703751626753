import React, { useCallback, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox, InputLabel,
  MenuItem,
  TextField, Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IDownloadRecordingFormProps } from './types';
import { useStyles } from './styles';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { SCRIPT_FILE_TYPES } from '../../../constants/scripts';
import { StyledButton } from '../../../common/StyledButton';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges } from '../../../graphql/queries/__generated__/allLanguages';
import { DownloadTable } from '../../DownloadScript/DownloadTable';
import DownloadTableOperationSection from '../DownloadTableOperationSection/DownloadTableOperationSection';

const DownloadScriptForm: React.FC<IDownloadRecordingFormProps> = ({
  charactersList,
  languagesList,
  sectionsList,
  projectId,
  exportRecording,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const [selectedLanguages, setSelectedLanguages] = useState<(allLanguages_allLanguages_edges | null)[]>([]);
  const [selectedFileTypes, setSelectedFileTypes] = useState<Array<string>>([SCRIPT_FILE_TYPES[0]]);
  const [selectedSections, setSelectedSections] = useState<Array<string>>([]);
  const [selectedCharacters, setSelectedCharacters] = useState<Array<string>>([]);

  const [languagesInSeparateFolders, setLanguagesInSeparateFolders] = useState<boolean>(false);
  const [charactersInSeparateFolders, setCharactersInSeparateFolders] = useState<boolean>(false);
  const [sectionsInSeparateFolders, setSectionsInSeparateFolders] = useState<boolean>(false);

  const [usesLanguagePrefix, setUsesLanguagePrefix] = useState<boolean>(false);
  const [usesLanguageSuffix, setUsesLanguageSuffix] = useState<boolean>(false);

  const classes = useStyles();

  const handleDownloadButton = useCallback(() => {
    exportRecording({
      projectId: projectId || '',
      languagesInSeparateFolders,
      charactersInSeparateFolders,
      sectionsInSeparateFolders,
      usesLanguagePrefix,
      usesLanguageSuffix,
      characterIds: selectedCharacters,
      languageIds: selectedLanguages.map((item) => item?.node?.id || null),
      sectionIds: selectedSections,
    });
  }, [
    projectId,
    languagesInSeparateFolders,
    charactersInSeparateFolders,
    sectionsInSeparateFolders,
    usesLanguagePrefix,
    usesLanguageSuffix,
    selectedCharacters,
    selectedLanguages,
    selectedSections,
  ]);

  const checkIfButtonDisabled = () => {
    if (selectedLanguages.length === 0) {
      return true;
    }

    if (selectedFileTypes.length === 0) {
      return true;
    }

    if (selectedSections.length === 0) {
      return true;
    }

    if (selectedCharacters.length === 0) {
      return true;
    }

    return false;
  };

  return (
    <Box className={classes.fieldsWrapper}>
      <Box className={classes.selectBlockWrapper}>
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>{t('DOWNLOAD_RECORDINGS_PAGE.LANGUAGES')}</Typography>

          <InputLabel htmlFor="selected-languages" />
          <Autocomplete
            multiple
            id="filter-language-list"
            className={classes.select}
            options={languagesList || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            renderOption={(props, option) => (
              <MenuItem
                key={option?.node?.languageCode}
                value={option?.node?.id}
                {...props}
              >
                <FlagVisualisation
                  style={{ marginRight: '6px' }}
                  key={option?.node?.languageCode}
                  optionFlag={option?.node?.languageFlag || ''}
                />
                {option?.node?.name}
              </MenuItem>
            )}
            sx={{ width: '330px' }}
            value={selectedLanguages}
            onChange={(_event, newValue) => setSelectedLanguages(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select"
              />
            )}
          />

          <Typography sx={{ marginTop: '24px', marginBottom: '16px' }}>
            {t('DOWNLOAD_RECORDINGS_PAGE.SAVE_DIFFERENT_LANGUAGES') }
          </Typography>

          <Tooltip title={t('DOWNLOAD_RECORDINGS_PAGE.IN_DIFF_FILES_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={languagesInSeparateFolders}
                onChange={() => setLanguagesInSeparateFolders((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_RECORDINGS_PAGE.IN_DIFF_FILES_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>

          <Tooltip title={t('DOWNLOAD_RECORDINGS_PAGE.WITH_LANGUAGE_PREFIX_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={usesLanguagePrefix}
                onChange={() => setUsesLanguagePrefix((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_RECORDINGS_PAGE.WITH_LANGUAGE_PREFIX_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>

          <Tooltip title={t('DOWNLOAD_RECORDINGS_PAGE.WITH_LANGUAGE_SUFFIX_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={usesLanguageSuffix}
                onChange={() => setUsesLanguageSuffix((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_RECORDINGS_PAGE.WITH_LANGUAGE_SUFFIX_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>
        </Box>

      </Box>
      <Box className={classes.tablesWrapper}>
        <Box className={classes.tableSection}>
          <Box className={classes.tableOperationSectionWrapper}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('DOWNLOAD_RECORDINGS_PAGE.SECTIONS')}</Typography>

            <DownloadTableOperationSection
              list={sectionsList}
              setState={setSelectedSections}
            />
          </Box>

          <DownloadTable
            list={sectionsList}
            selectedItems={selectedSections}
            setSelectedItems={setSelectedSections}
          />

          <Tooltip title={t('DOWNLOAD_RECORDINGS_PAGE.SECTIONS_IN_DIFF_FOLDERS_CHECKBOX_TOOLTIP')}>
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={sectionsInSeparateFolders}
                onChange={() => setSectionsInSeparateFolders((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_RECORDINGS_PAGE.SECTIONS_IN_DIFF_FOLDERS_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>

        </Box>
        <Box className={classes.tableSection}>
          <Box className={classes.tableOperationSectionWrapper}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('DOWNLOAD_RECORDINGS_PAGE.CHARACTERS')}</Typography>

            <DownloadTableOperationSection
              list={charactersList}
              setState={setSelectedCharacters}
            />
          </Box>

          <DownloadTable
            list={charactersList}
            selectedItems={selectedCharacters}
            setSelectedItems={setSelectedCharacters}
          />

          <Tooltip title={t('DOWNLOAD_RECORDINGS_PAGE.CHARACTERS_IN_DIFF_FOLDERS_CHECKBOX_TOOLTIP')}>
            <Typography className={classes.flexCenterAlign} sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={charactersInSeparateFolders}
                onChange={() => setCharactersInSeparateFolders((prevValue) => !prevValue)}
              />
              <span>
                {t('DOWNLOAD_RECORDINGS_PAGE.CHARACTERS_IN_DIFF_FOLDERS_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-begin', width: '100%' }}>
        <StyledButton
          width="210px"
          variant="contained"
          action={handleDownloadButton}
          disabled={checkIfButtonDisabled()}
          label={t('DOWNLOAD_RECORDINGS_PAGE.DOWNLOAD_RECORDINGS_BUTTON')}
        />
      </Box>
    </Box>
  );
};

export default DownloadScriptForm;
