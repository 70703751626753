import {
  Box, Card, CardContent, Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { StyledButton } from '../../../common/StyledButton';
import { TodoContext } from '../../../context/TodoContext/TodoContext';

import { TodoListTable } from '../TodoListTable';
import { ITodoListWidgetProps } from './types';

const TodoListWidget: React.FC<ITodoListWidgetProps> = ({ todos }) => {
  const context = useContext(TodoContext);

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            margin: '0px 0px 20px 0px',
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 500,
            }}
          >
            To do list
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
        }}
        >
          <StyledButton
            action={() => {
              context?.setOpen(true);
            }}
            label="Add task"
            variant="contained"
            width="100%"
            margin="0px 0px 18px 0px"
          />
          {todos.length ? <TodoListTable todos={todos} /> : <Typography>No todos in the list</Typography>}

        </Box>
      </CardContent>

    </Card>
  );
};
export default TodoListWidget;
