import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  IconButton, MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { StyledButton } from '../StyledButton';
import { IRecordingsFiltersProps } from './types';
import { useStyles } from './styles';
// eslint-disable-next-line camelcase
import { allCharacters_allCharacters_edges } from '../../graphql/queries/__generated__/allCharacters';
// eslint-disable-next-line camelcase
import { allSections_allSections_edges } from '../../graphql/queries/__generated__/allSections';
// eslint-disable-next-line camelcase
import { allLanguages_allLanguages_edges } from '../../graphql/queries/__generated__/allLanguages';
import { statusOptions } from '../../constants/revisions';
import { IStatus } from '../../context/RevisionContext/types';
import { InvertibleSlider } from '../InvertibleSlider';
import {
  addInvertedValue, makeFilteringString, replaceValueByInf, replaceValueForPadding,
} from '../../helpers';
import {
  recordingsSampleRateOptions,
  recordingsChannelOptions,
  recordingsBitDepthOptions,
  comparativeSampleRateOptions,
  comparativeChannelOptions,
  comparativeBitDepthOptions,
  initialSliderValue,
  recordingsInitialData,
  comparativeInitialData,
  tableType,
} from '../../constants/recordings';

const useInitialValue = (table: string) => {
  if (table === tableType.COMPARATIVE) {
    return comparativeInitialData;
  }
  return recordingsInitialData;
};

const RecordingsFilters: React.FC<IRecordingsFiltersProps> = ({
  isOpen,
  table,
  setIsOpen,
  characterOptions,
  setSelectedCharacter,
  sectionOptions,
  setSelectedSection,
  languageOptions,
  setSelectedLanguage,
  setSelectedStatus,
  setSelectedLoudness,
  setSelectedPaddingFront,
  setSelectedPaddingBack,
  setSelectedLength,
  setSelectedBitDepth,
  setSelectedSampleRate,
  setSelectedPeakLevel,
  setSelectedChannel,
}) => {
  const classes = useStyles();
  const {
    loudnessMin, loudnessMax,
    paddingMin, paddingMax,
    lengthMin, lengthMax,
    peakLevelMin, peakLevelMax,
  } = useInitialValue(table);

  const [
    comparativeSelectedChannelLocal,
    setComparativeSelectedChannelLocal,
  ] = useState<string>(comparativeChannelOptions[0].value);
  const [
    comparativeSelectedSampleRateLocal,
    setComparativeSelectedSampleRateLocal,
  ] = useState<string>(comparativeSampleRateOptions[0].value);
  const [
    comparativeSelectedBitDepthLocal,
    setComparativeSelectedBitDepthLocal,
  ] = useState<string>(comparativeBitDepthOptions[0].value);

  const [
    recordingsSelectedChannelLocal,
    setRecordingsSelectedChannelLocal,
  ] = useState<(string | null)[]>([]);
  const [
    recordingsSelectedSampleRateLocal,
    setRecordingsSelectedSampleRateLocal,
  ] = useState<(string | null)[]>([]);
  const [
    recordingsSelectedBitDepthLocal,
    setRecordingsSelectedBitDepthLocal,
  ] = useState<(string)[]>([]);

  const [
    selectedCharacterLocal,
    setSelectedCharacterLocal,
    // eslint-disable-next-line camelcase
  ] = useState<(allCharacters_allCharacters_edges | null)[]>([]);

  const [
    selectedSectionLocal,
    setSelectedSectionLocal,
    // eslint-disable-next-line camelcase
  ] = useState<(allSections_allSections_edges | null)[]>([]);

  const [
    selectedLanguageLocal,
    setSelectedLanguageLocal,
    // eslint-disable-next-line camelcase
  ] = useState<(allLanguages_allLanguages_edges | null)[]>([]);

  const [selectedStatusLocal, setSelectedStatusLocal] = useState<(IStatus | null)[]>([]);

  const [selectedLoudnessLocal, setSelectedLoudnessLocal] = useState([loudnessMin, loudnessMax]);

  const [selectedPaddingFrontLocal, setSelectedPaddingFrontLocal] = useState([
    paddingMin,
    paddingMax,
  ]);
  const [selectedPaddingBackLocal, setSelectedPaddingBackLocal] = useState([
    paddingMin,
    paddingMax,
  ]);

  const [selectedLengthLocal, setSelectedLengthLocal] = useState([lengthMin, lengthMax]);
  const [selectedPeakLevelLocal, setSelectedPeakLevelLocal] = useState([peakLevelMin, peakLevelMax]);
  const [loudnessTrack, setLoudnessTrack] = useState(false);
  const [paddingFrontTrack, setPaddingFrontTrack] = useState(false);
  const [paddingBackTrack, setPaddingBackTrack] = useState(false);
  const [lengthTrack, setLengthTrack] = useState(false);
  const [peakLevelTrack, setPeakLevelTrack] = useState(false);

  const applyFilter = () => {
    setSelectedCharacter(selectedCharacterLocal.map((option) => option?.node?.id));
    setSelectedSection(selectedSectionLocal.map((option) => option?.node?.id));
    setSelectedLanguage(selectedLanguageLocal.map((option) => option?.node?.id));
    setSelectedStatus(selectedStatusLocal.map((option) => option?.value));
    setSelectedLoudness(
      addInvertedValue(
        replaceValueByInf(selectedLoudnessLocal, loudnessMin, loudnessMax),
        loudnessTrack,
      ),
    );
    setSelectedPaddingFront(
      addInvertedValue(
        replaceValueForPadding(selectedPaddingFrontLocal, paddingMin, paddingMax),
        paddingFrontTrack,
      ),
    );
    setSelectedPaddingBack(
      addInvertedValue(
        replaceValueForPadding(selectedPaddingBackLocal, paddingMin, paddingMax),
        paddingBackTrack,
      ),
    );
    setSelectedLength(
      addInvertedValue(
        replaceValueByInf(selectedLengthLocal, lengthMin, lengthMax),
        lengthTrack,
      ),
    );
    setSelectedPeakLevel(
      addInvertedValue(replaceValueByInf(selectedPeakLevelLocal, peakLevelMin, peakLevelMax), peakLevelTrack),
    );
    if (table === tableType.COMPARATIVE) {
      setSelectedBitDepth(comparativeSelectedBitDepthLocal);
      setSelectedSampleRate(comparativeSelectedSampleRateLocal);
      setSelectedChannel(comparativeSelectedChannelLocal);
    } else {
      const indexArr = recordingsSelectedBitDepthLocal.map((item) => recordingsBitDepthOptions.indexOf(item));
      setSelectedBitDepth(makeFilteringString(indexArr));
      setSelectedSampleRate(makeFilteringString(recordingsSelectedSampleRateLocal));
      setSelectedChannel(makeFilteringString(recordingsSelectedChannelLocal));
    }
  };

  const clearFilter = () => {
    if (table === tableType.COMPARATIVE) {
      setComparativeSelectedBitDepthLocal('');
      setComparativeSelectedSampleRateLocal('');
      setComparativeSelectedChannelLocal('');
    } else {
      setRecordingsSelectedBitDepthLocal([]);
      setRecordingsSelectedSampleRateLocal([]);
      setRecordingsSelectedChannelLocal([]);
    }

    setSelectedCharacterLocal([]);
    setSelectedSectionLocal([]);
    setSelectedLanguageLocal([]);
    setSelectedStatusLocal([]);
    setSelectedLoudnessLocal([loudnessMin, loudnessMax]);
    setSelectedLengthLocal([lengthMin, lengthMax]);
    setSelectedPaddingFrontLocal([paddingMin, paddingMax]);
    setSelectedPaddingBackLocal([paddingMin, paddingMax]);
    setSelectedPeakLevelLocal([peakLevelMin, peakLevelMax]);

    setSelectedCharacter([]);
    setSelectedSection([]);
    setSelectedLanguage([]);
    setSelectedStatus([]);
    setSelectedLoudness(initialSliderValue);
    setSelectedPaddingFront(initialSliderValue);
    setSelectedPaddingBack(initialSliderValue);
    setSelectedLength(initialSliderValue);
    setSelectedPeakLevel(initialSliderValue);
    setSelectedSampleRate('');
    setSelectedBitDepth('');
    setSelectedChannel('');
  };
  const handleFiltersClose = () => {
    setIsOpen(false);
  };

  return (
    <Paper
      elevation={3}
      sx={{ display: isOpen ? 'inherit' : 'none' }}
      className={classes.rootWrapper}
    >
      <Box
        sx={{
          overflow: 'auto',
          height: '60vh',
        }}
      >
        <Box className={classes.contentContainer}>
          <Typography variant="h3" sx={{ marginRight: '230px' }}>
            Filters
          </Typography>
          <IconButton onClick={handleFiltersClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Languages
          </Typography>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={languageOptions || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            className={classes.autocomplete}
            filterSelectedOptions
            value={selectedLanguageLocal}
            onChange={(_event, newValue) => {
              setSelectedLanguageLocal(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Language"
              />
            )}
          />
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Character
          </Typography>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={characterOptions || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            className={classes.autocomplete}
            filterSelectedOptions
            value={selectedCharacterLocal}
            onChange={(_event, newValue) => {
              setSelectedCharacterLocal(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Character"
              />
            )}
          />
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Section
          </Typography>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={sectionOptions || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            className={classes.autocomplete}
            filterSelectedOptions
            value={selectedSectionLocal}
            onChange={(_event, newValue) => {
              setSelectedSectionLocal(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Section"
              />
            )}
          />
        </Box>
        {/* <Box className={classes.autocompleteContainer}> */}
        {/*  <Typography variant="body1" sx={{ marginBottom: '10px' }}> */}
        {/*    Status */}
        {/*  </Typography> */}
        {/*  <Autocomplete */}
        {/*    multiple */}
        {/*    id="filter-statuses" */}
        {/*    options={statusOptions} */}
        {/*    getOptionLabel={(option) => option?.placeholder || ''} */}
        {/*    className={classes.autocomplete} */}
        {/*    value={selectedStatusLocal} */}
        {/*    onChange={(_event, newValue) => { */}
        {/*      setSelectedStatusLocal(newValue || undefined); */}
        {/*    }} */}
        {/*    renderInput={(params) => ( */}
        {/*      <TextField */}
        {/*        {...params} */}
        {/*        placeholder="Status" */}
        {/*      /> */}
        {/*    )} */}
        {/*  /> */}
        {/* </Box> */}
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Bit Depth
          </Typography>
          {
              table === tableType.COMPARATIVE
                ? (
                  <TextField
                    id={`${tableType.COMPARATIVE}-filter-bit-depth`}
                    select
                    className={classes.comparativeSelect}
                    value={comparativeSelectedBitDepthLocal}
                    onChange={(_event) => {
                      setComparativeSelectedBitDepthLocal(_event.target.value);
                    }}
                  >
                    {comparativeBitDepthOptions.map((option) => (
                      <MenuItem className={classes.selectOption} key={option.label} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )
                : (
                  <Autocomplete
                    multiple
                    id={`${tableType.RECORDINGS}-filter-bit-depth`}
                    options={recordingsBitDepthOptions}
                    getOptionLabel={(option) => option || ''}
                    className={classes.autocomplete}
                    value={recordingsSelectedBitDepthLocal}
                    onChange={(_event, newValue) => {
                      setRecordingsSelectedBitDepthLocal(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Bit Depth"
                      />
                    )}
                  />
                )
          }
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Loudness
          </Typography>
          <Box className={classes.sliderContainer}>
            <Box sx={{ width: '100%', marginRight: '30px' }}>
              <InvertibleSlider
                max={loudnessMax}
                min={loudnessMin}
                value={selectedLoudnessLocal}
                setValue={setSelectedLoudnessLocal}
                isInverted={loudnessTrack}
              />
            </Box>
            <Box>
              <Typography variant="body2">Invert</Typography>
              <Checkbox value={loudnessTrack} onChange={() => setLoudnessTrack(!loudnessTrack)} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Peak Level
          </Typography>
          <Box className={classes.sliderContainer}>
            <Box sx={{ width: '100%', marginRight: '30px' }}>
              <InvertibleSlider
                max={peakLevelMax}
                min={peakLevelMin}
                value={selectedPeakLevelLocal}
                setValue={setSelectedPeakLevelLocal}
                isInverted={peakLevelTrack}
              />
            </Box>
            <Box>
              <Typography variant="body2">Invert</Typography>
              <Checkbox value={loudnessTrack} onChange={() => setPeakLevelTrack(!peakLevelTrack)} />
            </Box>
          </Box>
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Sample Rate
          </Typography>
          {
            table === tableType.COMPARATIVE
              ? (
                <TextField
                  id={`${tableType.COMPARATIVE}-filter-sample-rate`}
                  select
                  className={classes.comparativeSelect}
                  value={comparativeSelectedSampleRateLocal}
                  onChange={(_event) => {
                    setComparativeSelectedSampleRateLocal(_event.target.value);
                  }}
                >
                  {comparativeSampleRateOptions.map((option) => (
                    <MenuItem className={classes.selectOption} key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )
              : (
                <Autocomplete
                  multiple
                  id={`${tableType.RECORDINGS}-filter-sample-rate`}
                  options={recordingsSampleRateOptions}
                  className={classes.autocomplete}
                  value={recordingsSelectedSampleRateLocal}
                  onChange={(_event, newValue) => {
                    setRecordingsSelectedSampleRateLocal(newValue || undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Sample Rate"
                    />
                  )}
                />
              )
          }
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Channels
          </Typography>
          {
            table === tableType.COMPARATIVE
              ? (
                <TextField
                  id={`${tableType.COMPARATIVE}-filter-channel`}
                  select
                  className={classes.comparativeSelect}
                  value={comparativeSelectedChannelLocal}
                  onChange={(_event) => {
                    setComparativeSelectedChannelLocal(_event.target.value);
                  }}
                >
                  {comparativeChannelOptions.map((option) => (
                    <MenuItem className={classes.selectOption} key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )
              : (
                <Autocomplete
                  multiple
                  id={`${tableType.RECORDINGS}-filter-channel`}
                  options={recordingsChannelOptions}
                  className={classes.autocomplete}
                  value={recordingsSelectedChannelLocal}
                  onChange={(_event, newValue) => {
                    setRecordingsSelectedChannelLocal(newValue || undefined);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Channel"
                    />
                  )}
                />
              )
          }
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Padding Front
          </Typography>
          <Box className={classes.sliderContainer}>
            <Box sx={{ width: '100%', marginRight: '30px' }}>
              <InvertibleSlider
                max={paddingMax}
                min={paddingMin}
                value={selectedPaddingFrontLocal}
                setValue={setSelectedPaddingFrontLocal}
                isInverted={paddingFrontTrack}
              />
            </Box>
            <Box>
              <Typography variant="body2">Invert</Typography>
              <Checkbox
                value={paddingFrontTrack}
                onChange={() => setPaddingFrontTrack(!paddingFrontTrack)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Padding Back
          </Typography>
          <Box className={classes.sliderContainer}>
            <Box sx={{ width: '100%', marginRight: '30px' }}>
              <InvertibleSlider
                max={paddingMax}
                min={paddingMin}
                value={selectedPaddingBackLocal}
                setValue={setSelectedPaddingBackLocal}
                isInverted={paddingBackTrack}
              />
            </Box>
            <Box>
              <Typography variant="body2">Invert</Typography>
              <Checkbox
                value={paddingBackTrack}
                onChange={() => setPaddingBackTrack(!paddingBackTrack)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.autocompleteContainer}>
          <Typography variant="body1" sx={{ marginBottom: '10px' }}>
            Length
          </Typography>
          <Box className={classes.sliderContainer}>
            <Box sx={{ width: '100%', marginRight: '30px' }}>
              <InvertibleSlider
                max={lengthMax}
                min={lengthMin}
                value={selectedLengthLocal}
                setValue={setSelectedLengthLocal}
                isInverted={lengthTrack}
              />
            </Box>
            <Box>
              <Typography variant="body2">Invert</Typography>
              <Checkbox value={lengthTrack} onChange={() => setLengthTrack(!lengthTrack)} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonContainer}>
        <StyledButton
          label="Apply"
          variant="contained"
          action={applyFilter}
          margin="0px 0px 10px 0px"
        />
        <StyledButton
          label="Reset"
          variant="outlined"
          action={clearFilter}
          margin="0px 0px 32px 0px"
        />
      </Box>
    </Paper>
  );
};
export default RecordingsFilters;
