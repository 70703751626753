import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Checkbox, Dialog, FormControlLabel, FormLabel, IconButton, TextField, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IEditUserDialogProps } from './types';
import { useStyles } from './styles';
import { UserContext } from '../../../../context/UserContext/UserContext';
import { StyledButton } from '../../../../common/StyledButton';
import { getPermissionGroupsForProject } from '../../../../graphql/queries/__generated__/getPermissionGroupsForProject';
import { GET_PERMISSION_GROUPS_FOR_PROJECT, GET_USERS_AND_PERMISSIONS } from '../../../../graphql/queries';
import {
  assignPermissionGroupListToUser,
} from '../../../../graphql/mutations/__generated__/assignPermissionGroupListToUser';
import { ADMIN_RESET_PASSWORD, ASSIGN_PERMISSION_GROUP_LIST_TO_USER } from '../../../../graphql/mutations';
import { adminPWResetPassword } from '../../../../graphql/mutations/__generated__/adminPWResetPassword';

const EditUserDialog: React.FC<IEditUserDialogProps> = ({ open, setOpen, editableUserData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const { data: permissionGroups } = useQuery<getPermissionGroupsForProject>(GET_PERMISSION_GROUPS_FOR_PROJECT, {
    variables: {
      projectId: userContext?.projectId,
    },
    skip: !userContext?.projectId,
  });

  const [assignPermissionsToUser,
    {
      data: assignUserData, loading: assignUserLoading,
    }] = useMutation<assignPermissionGroupListToUser>(
      ASSIGN_PERMISSION_GROUP_LIST_TO_USER,
      {
        refetchQueries: [
          { query: GET_USERS_AND_PERMISSIONS, variables: { projectId: userContext?.projectId } },
        ],
      },
    );

  const [adminResetPassword,
    {
      data: adminResetPasswordData, loading: adminResetPasswordLoading,
    }] = useMutation<adminPWResetPassword>(ADMIN_RESET_PASSWORD);

  const {
    handleSubmit, register, reset, formState: { errors },
  } = useForm({
    defaultValues: {
      permissionSets: editableUserData?.permissionSets,
    } as { permissionSets: string[] },
  });

  const onSubmit = async (data: {permissionSets: string[]}) => {
    await assignPermissionsToUser({
      variables: {
        assignUserToPermissionGroups: {
          projectId: userContext?.projectId,
          email: editableUserData?.email,
          permissionGroupIds: data.permissionSets,
        },
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    reset({ permissionSets: [] });
  };

  const handleResetPassword = async (id: string) => {
    await adminResetPassword({
      variables: {
        userResetData: { id },
      },
    });
  };

  useEffect(() => {
    if (!assignUserLoading && assignUserData?.assignPermissionGroupsToUser?.ok) {
      handleClose();
    }
  }, [assignUserData, assignUserLoading]);

  const handleCopyButton = async (value: string) => {
    await navigator.clipboard.writeText(value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogWrapper}>
        <IconButton
          className={classes.closeDialogIconButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Typography fontWeight={600}>
          {t('USER_MANAGEMENT_PAGE.DIALOG.UPDATE_USER')}
          :
          {' '}
          {editableUserData?.email}
        </Typography>

      </Box>
      <Box>
        <form className={classes.formWrapper}>
          <Box className={classes.permissionSetsWrapper}>
            <Typography>{t('USER_MANAGEMENT_PAGE.DIALOG.PERMISSION_SETS')}</Typography>
            <FormLabel htmlFor="permissionSets-field" />

            {permissionGroups?.getPermissionGroupsForProject?.edges.map((edge) => (
              <div key={edge?.node?.id}>
                <FormControlLabel
                  className={classes.checkboxWrapper}
                  control={(
                    <Checkbox
                      className={classes.checkbox}
                      {...register('permissionSets', { required: true })}
                      value={edge?.node?.id}
                      defaultChecked={editableUserData?.permissionSets.includes(edge?.node?.id || '')}
                    />
                      )}
                  label={(
                    <Box>
                      <p style={{ fontWeight: 'bold' }}>{edge?.node?.label}</p>
                      <p>{edge?.node?.description}</p>
                    </Box>
                      )}
                />
              </div>
            ))}
            {errors.permissionSets && (
            <Typography variant="body2" color="error">
              {t('USER_MANAGEMENT_PAGE.DIALOG.PLEASE_SELECT_PERMISSION')}
            </Typography>
            )}
          </Box>

          {
            adminResetPasswordData?.adminPasswordReset?.TempPassword && !adminResetPasswordLoading
              && (
              <Box className={classes.resultItemWrapper}>
                <Box className={classes.resultFieldWrapper}>
                  <TextField
                    id="outlined-read-only-input"
                    label={t('USER_MANAGEMENT_PAGE.DIALOG.TEMPORARY_PASSWORD')}
                    defaultValue={adminResetPasswordData.adminPasswordReset.TempPassword}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                  <IconButton
                    onClick={() => handleCopyButton(adminResetPasswordData?.adminPasswordReset?.TempPassword || '')}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              </Box>
              )
          }

          <Box className={classes.buttonWrapper}>
            <StyledButton
              variant="outlined"
              width="150px"
              margin="0 10px"
              label={t('BUTTON.RESET_PASSWORD')}
              action={() => handleResetPassword(editableUserData?.id || '')}
            />

            <StyledButton
              variant="outlined"
              width="150px"
              margin="0 10px"
              label={t('BUTTON.CANCEL')}
              action={handleClose}
            />

            <StyledButton
              variant="contained"
              width="150px"
              margin="0 10px"
              label={t('BUTTON.SUBMIT')}
              action={handleSubmit(onSubmit)}
            />
          </Box>
        </form>
      </Box>

    </Dialog>
  );
};

export default EditUserDialog;
