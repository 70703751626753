import React, { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { IDeleteSectionDialogProps } from './types';
import { DELETE_SECTION } from '../../../graphql/mutations';
import { GET_ALL_SECTIONS, GET_SECTIONS_COUNT } from '../../../graphql/queries';
import { useStyles } from './styles';
import { deleteSection as deleteSectionProps } from '../../../graphql/mutations/__generated__/deleteSection';
import { SectionContext } from '../../../context/SectionContext/SectionContext';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const DeleteSectionDialog: React.FC<IDeleteSectionDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const context = useContext(SectionContext);
  const classes = useStyles();

  const [deleteSection] = useMutation<deleteSectionProps>(DELETE_SECTION, {
    refetchQueries: [
      {
        query: GET_ALL_SECTIONS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_SECTIONS_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allSections',
    ],
  });

  const handleDeleteSection = (id:string | undefined) => {
    deleteSection({ variables: { sectionDeletionData: { id } } });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent className={classes.disableHorizontalPadding}>
          <Typography>
            Are you sure that you want to delete
            {' '}
            <b>{context?.sectionData.name}</b>
            ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorizontalPadding}>
          <StyledButton
            width="210px"
            variant="outlined"
            label="Yes"
            action={() => handleDeleteSection(context?.sectionData.id)}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label="No"
            action={handleDialogChange}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteSectionDialog;
