import React from 'react';
import { Box } from '@mui/material';
import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';

const Script: React.FC<{section: JSX.Element}> = ({ section }) => (
  <>
    <Header />
    <Box sx={{ display: 'flex', marginTop: '20px' }}>
      <NavBar drawerList={<MenuList />} drawerWidth={230} />
      <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
        {section}
      </Box>
    </Box>
  </>
);
export default Script;
