import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  fileDropzone: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '322px',
    maxWidth: '508px',
    height: '48px',
    border: '1px dashed #656A73',
    padding: '45px 45px 45px 45px',
    borderRadius: '12px',
    cursor: 'pointer',
  },
});
