import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
  Autocomplete,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';

import { ToastContainer, toast } from 'react-toastify';
import { StyledButton } from '../../../common/StyledButton';
import { ICreateLineDialogProps } from './types';
import { useStyles } from './styles';
import { base64Decoding } from '../../../helpers';
import 'react-toastify/dist/ReactToastify.css';

import { CREATE_LINE } from '../../../graphql/mutations';
import { createLine as createLineProps } from '../../../graphql/mutations/__generated__/createLine';
import {
  GET_ALL_CHARACTERS,
  GET_ALL_LINES,
  GET_ALL_SECTIONS, GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
// eslint-disable-next-line camelcase
import { allCharacters, allCharacters_allCharacters_edges } from '../../../graphql/queries/__generated__/allCharacters';
// eslint-disable-next-line camelcase
import { allSections, allSections_allSections_edges } from '../../../graphql/queries/__generated__/allSections';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const CreateLineDialog: React.FC<ICreateLineDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
  initialSection,
  initialCharacter,
  getLinesVariables,
}) => {
  const [lineIdValue, setLineIdValue] = useState('');

  const [
    selectedCharacterLocal,
    setSelectedCharacterLocal,
    // eslint-disable-next-line camelcase
  ] = useState<(allCharacters_allCharacters_edges | undefined)>(initialCharacter);

  const [
    selectedSectionLocal,
    setSelectedSectionLocal,
    // eslint-disable-next-line camelcase
  ] = useState<(allSections_allSections_edges | undefined)>(initialSection);

  const createLineNotification = () => toast.success('Line was created successfully!');

  const errorNotification = () => toast.error('Something went wrong! Please try again.');

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(
    GET_ALL_CHARACTERS,
    {
      variables: {
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
      },
    },
  );

  const { data: scriptIdData, loading: scriptIdLoading } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const scriptId = scriptIdData?.project?.scriptSet?.edges[0]?.node?.id;

  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [createLine] = useMutation<createLineProps>(CREATE_LINE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    refetchQueries: [
      { query: GET_ALL_LINES, variables: { ...getLinesVariables, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
    ],
    onCompleted: () => {
      createLineNotification();
      setOpen(!open);
      setLineIdValue('');
    },
    onError: () => {
      errorNotification();
      setOpen(!open);
      setLineIdValue('');
    },
  });

  const classes = useStyles();

  const handleLineIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLineIdValue(event.target.value);
  };

  const handleCreateLine = () => {
    if (!lineIdValue) {
      return;
    }
    createLine({
      variables: {
        lineCreationData: {
          stringId: lineIdValue,
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          script: base64Decoding(scriptId || ''),
          character: base64Decoding(selectedCharacterLocal?.node?.id || ''),
          section: base64Decoding(selectedSectionLocal?.node?.id || ''),
        },
      },
    });
  };

  if (sectionsLoading || charactersLoading || scriptIdLoading) {
    return <PageLoader />;
  }

  return (
    <Dialog open={open} onClose={handleDialogChange} fullWidth maxWidth="md">
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Typography className={classes.subtitle} variant="body2">
              Line ID
            </Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                value={lineIdValue}
                placeholder="Line ID"
                onChange={handleLineIdChange}
                required
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body2">
              Character
            </Typography>
            <Autocomplete
              id="tags-outlined"
              options={charactersData?.allCharacters?.edges || []}
              getOptionLabel={(option) => option?.node?.name || ''}
              isOptionEqualToValue={(option, val) => option?.node?.name === val?.node?.name}
              disableClearable
             // className={classes.autocomplete}
              filterSelectedOptions
              value={selectedCharacterLocal}
              onChange={(_event, newValue) => {
                setSelectedCharacterLocal(newValue || undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Character"
                />
              )}
            />
            <Typography className={classes.subtitle} variant="body2">
              Section
            </Typography>
            <Autocomplete
              id="tags-outlined"
              options={sectionsData?.allSections?.edges || []}
              getOptionLabel={(option) => option?.node?.name || ''}
              disableClearable
              isOptionEqualToValue={(option, val) => option?.node?.name === val?.node?.name
              || val?.node?.name === ''}
              // className={classes.autocomplete}
              filterSelectedOptions
              value={selectedSectionLocal}
              onChange={(_event, newValue) => {
                setSelectedSectionLocal(newValue || undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Section"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between' }}
          className={classes.disableHorisontalPadding}
        >
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={handleDialogChange}
          />

          <StyledButton
            variant="contained"
            width="210px"
            label="Add"
            disabled={!lineIdValue}
            action={handleCreateLine}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateLineDialog;
