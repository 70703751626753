import React, { useCallback, useMemo, useState } from 'react';
import { IAuthContextProps } from './types';

export const AuthContext = React.createContext<IAuthContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsLoggedIn: () => {},
  isLoggedIn: false,
  isVerfied: false,
  currentProjectId: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentProjectId: () => {},
});

const AuthContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isVerfied, setIsVerified] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState('');

  const handleLogIn = useCallback((flag: boolean) => {
    setIsLoggedIn(flag);
    setIsVerified(true);
  }, []);

  const contextValues = useMemo(() => ({
    isLoggedIn,
    isVerfied,
    currentProjectId,
    setCurrentProjectId,
    setIsLoggedIn: handleLogIn,
  }), [isLoggedIn, isVerfied, handleLogIn]);

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
