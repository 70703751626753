import React, { useContext } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { IDeleteCharacterDialogProps } from './types';
import { DELETE_CHARACTER } from '../../../graphql/mutations';
import { GET_ALL_CHARACTERS, GET_CHARACTERS_COUNT } from '../../../graphql/queries';
import { useStyles } from './styles';
import { deleteCharacter as deleteCharacterProps } from '../../../graphql/mutations/__generated__/deleteCharacter';
import { CharContext } from '../../../context/CharContext/CharContext';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const DeleteCharacterDialog: React.FC<IDeleteCharacterDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const classes = useStyles();
  const context = useContext(CharContext);

  const [deleteCharacter] = useMutation<deleteCharacterProps>(DELETE_CHARACTER, {
    refetchQueries: [
      {
        query: GET_ALL_CHARACTERS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_CHARACTERS_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allCharacters',
    ],
  });

  const handleDeleteCharacter = (id:string | undefined) => {
    deleteCharacter({ variables: { characterDeletionData: { id } } });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent className={classes.disableHorizontalPadding}>
          <Typography>
            Are you sure that you want to delete
            {' '}
            <b>{context?.characterData.name}</b>
            ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorizontalPadding}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="Yes"
            action={() => handleDeleteCharacter(context?.characterData.id)}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label="No"
            action={handleDialogChange}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteCharacterDialog;
