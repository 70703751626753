import React from 'react';
import {
  Box, IconButton, TextField, Typography,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { IAddUserResultDialogProps } from './types';
import { useStyles } from './styles';

const AddUserResultDialog: React.FC<IAddUserResultDialogProps> = ({ userEmail, userPassword }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCopyButton = async (value: string) => {
    await navigator.clipboard.writeText(value);
  };
  return (
    <Box className={classes.resultDialogWrapper}>
      <Typography marginBottom="15px">Please share these details with the new user so that they can log in</Typography>

      <Box className={classes.resultItemWrapper}>
        <TextField
          id="outlined-read-only-input"
          defaultValue={userEmail}
          label={t('USER_MANAGEMENT_PAGE.TABLE.EMAIL')}
          InputProps={{
            readOnly: true,
          }}
        />
        <IconButton onClick={() => handleCopyButton(userEmail)}><ContentCopyIcon /></IconButton>
      </Box>
      <Box className={classes.resultItemWrapper}>
        <TextField
          id="outlined-read-only-input"
          label={t('USER_MANAGEMENT_PAGE.DIALOG.TEMPORARY_PASSWORD')}
          defaultValue={userPassword}
          InputProps={{
            readOnly: true,
          }}
        />
        <IconButton onClick={() => handleCopyButton(userPassword)}><ContentCopyIcon /></IconButton>
      </Box>

    </Box>
  );
};

export default AddUserResultDialog;
