import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconButton, TableCell, TableRow, TextField, Typography, MenuItem,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import {
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { MAX_CHARACTERS, statusOptions } from '../../../constants/revisions';
import { useStyles } from './styles';
import { IRevisionRowProps } from './types';
import { useAswFileLink } from '../../../hooks';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import {
  updateLocalisedAssetRevision as updateLocalisedAssetRevisionProps,
} from '../../../graphql/mutations/__generated__/updateLocalisedAssetRevision';
import { UPDATE_REVISION } from '../../../graphql/mutations';
import { CustomAudioPlayer } from '../../../common/CustomAudioPlayer';
import { ReactComponent as TranslateIcon } from '../../../assets/icons/translate-icon.svg';
import { ReactComponent as InstallIcon } from '../../../assets/icons/install-icon.svg';
import { LocalisedAssetRevisionStatus } from '../../../../__generated__/globalTypes';
import ShowMoreButton from '../../../common/ShowMoreButton/ShowMoreButton';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

export const RevisionRow: React.FC<IRevisionRowProps> = ({ localLine, primaryLanguageName }) => {
  const classes = useStyles();
  const { awsResponse, fileExists } = useAswFileLink(localLine?.node?.currentSelect?.soundFile?.soundFilePath || '');
  const [isShowMore, setIsShowMore] = useState(false);
  const [statusValue, setStatusValue] = useState(localLine?.node?.currentSelect?.status);

  const [updateLocalisedAssetRevision] = useMutation<updateLocalisedAssetRevisionProps>(UPDATE_REVISION, {
    refetchQueries: [
      { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
      { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
    ],
  });

  const handleUpdateRevision = useCallback(async (
    revisionId: string | undefined,
    status: string | undefined,
    text: string | undefined,
    revisionDate: Date,
    // eslint-disable-next-line camelcase
    sound_file_id: string | undefined,
    makeCurrent: boolean,
  ) => {
    await updateLocalisedAssetRevision({
      variables: {
        localisedAssetRevisionUpdateData: {
          id: revisionId,
          status,
          text,
          revisionDate,
          // eslint-disable-next-line camelcase
          sound_file_id,
        },
        makeCurrent,
      },
    });
  }, []);

  const statusInputHandler = useCallback((_event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setStatusValue(_event.target.value as LocalisedAssetRevisionStatus);
    handleUpdateRevision(
      localLine?.node?.currentSelect?.id,
      _event.target.value,
      localLine?.node?.currentSelect?.text,
      new Date(),
      undefined,
      false,
    );
  }, []);

  return (
    <TableRow key={uuidv4()}>
      <TableCell width={120}>
        <Typography
          variant="subtitle2"
          display="inline"
          className={classes.languageCell}
          sx={{ fontWeight: primaryLanguageName === localLine?.node?.language?.name ? 'bold' : 'normal' }}
        >
          <FlagVisualisation optionFlag={localLine?.node?.language?.languageFlag || ''} />
          {localLine?.node?.language?.languageCode}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="subtitle2"
          display="inline"
          className={classes.tableText}
        >
          {(localLine?.node?.currentSelect?.text?.length || 0) <= MAX_CHARACTERS
            ? (localLine?.node?.currentSelect?.text)
            : (
              <>
                {isShowMore
                  ? localLine?.node?.currentSelect?.text
                  : `${localLine?.node?.currentSelect?.text.substring(0, MAX_CHARACTERS)}`}
                <ShowMoreButton isShowMore={isShowMore} setState={setIsShowMore} />
              </>
            )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="subtitle2"
          display="inline"
          className={classes.tableText}
        >
          { localLine?.node?.currentSelect?.soundFile?.soundFilePath
              && <CustomAudioPlayer awsFileLink={awsResponse} fileExists={fileExists} /> }
        </Typography>
      </TableCell>
      <TableCell className={classes.tableText}>
        <TranslateIcon className={classes.iconMargin} />
        <TextField
          id="revision-statuses"
          select
          sx={{ width: '85%' }}
          value={statusValue}
          onChange={statusInputHandler}
          variant="standard"
          size="small"
        >
          {statusOptions.map((statusOption) => (
            <MenuItem key={statusOption.value} value={statusOption.value}>
              {statusOption.placeholder}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <Typography
          variant="subtitle2"
          display="inline"
          className={classes.tableText}
        >
          {localLine?.node?.localisedassetrevisionSet.totalCount}
        </Typography>
      </TableCell>
      <TableCell>
        {fileExists && awsResponse && (
          <a href={awsResponse} download rel="noreferrer" target="_blank">
            <IconButton>
              <InstallIcon />
            </IconButton>
          </a>
        )}
      </TableCell>
    </TableRow>
  );
};
