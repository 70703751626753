import { useQuery } from '@apollo/client';
import { LOCALE_STORAGE_PROJECT_ID } from '../constants/localStorage';
import { GET_PROJECT } from '../graphql/queries';
import { project } from '../graphql/queries/__generated__/project';

const useCurrentProject = () => {
  const { data: projectData, loading: projectLoading } = useQuery<project>(GET_PROJECT, {
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    variables: { id: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
  });

  return { data: projectData, loading: projectLoading };
};

export default useCurrentProject;
