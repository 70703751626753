import React, { useContext, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, TablePagination, TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import { LineTable } from '../LineTable';
import { allLines } from '../../../graphql/queries/__generated__/allLines';
import { GET_ALL_CHARACTERS, GET_ALL_LINES, GET_ALL_SECTIONS } from '../../../graphql/queries';
import { Order } from './types';
import { StyledButton } from '../../../common/StyledButton';
import { Filters } from '../../../common/Filters';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { useStyles } from './styles';
import { DeleteLineDialog } from '../DeleteLineDialog';
import { LineContext } from '../../../context/LineContext/LineContext';
import { CreateLineDialog } from '../CreateLineDialog';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const LineTableContainer = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('stringId');
  const [order, setOrder] = useState<Order>('asc');
  const [isOpen, setIsOpen] = useState(false);
  const [searchStringValue, setSearchStringValue] = useState('');
  const [searchStringId, setSearchStringId] = useState('');
  const [selectedCharacter, setSelectedCharacter] = useState<(string | undefined)[]>([]);
  const [selectedSection, setSelectedSection] = useState<(string | undefined)[]>([]);
  const [lineOpen, setLineOpen] = useState(false);

  const noCharacterOrSectionNotification = () => toast.warning('Please first create at least one character and one section');

  const handleLineDialogChange = () => {
    setLineOpen(!lineOpen);
  };

  const classes = useStyles();

  const getAllLinesVariables = {
    offset: page * pageSize,
    first: pageSize,
    orderBy,
    stringId_Icontains: searchStringId,
    character_Id: selectedCharacter,
    section_Id: selectedSection,
    projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  };

  const { data, loading, fetchMore } = useQuery<allLines>(GET_ALL_LINES, {
    variables: getAllLinesVariables,
    fetchPolicy: 'cache-and-network',
  });

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(
    GET_ALL_CHARACTERS,
    {
      variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(
    GET_ALL_SECTIONS,
    {
      variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
      fetchPolicy: 'cache-and-network',
    },
  );

  const lineContext = useContext(LineContext);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStringValue(event.target.value);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
  const handleSearch = () => {
    setSearchStringId(searchStringValue);
  };

  const handleFiltersOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Filters
        isOpen={isOpen}
        characterOptions={charactersData?.allCharacters?.edges}
        sectionOptions={sectionsData?.allSections?.edges}
        setIsOpen={setIsOpen}
        setSelectedCharacter={setSelectedCharacter}
        setSelectedSection={setSelectedSection}
      />
      <Box
        className={classes.actionsContainer}
      >
        <Box className={classes.searchContainer}>
          <TextField
            sx={{ marginRight: '30px' }}
            value={searchStringValue}
            onChange={handleSearchChange}
            fullWidth
            size="small"
            label="Search..."
          />
          <StyledButton variant="contained" action={handleSearch} label={t('LINES_PAGE.SEARCH_BUTTON')} />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ marginRight: '10px' }}>
            <StyledButton action={handleFiltersOpen} variant="outlined" label={t('LINES_PAGE.FILTERS_BUTTON')} />
          </Box>
          <Box>
            <StyledButton
              variant="contained"
              action={() => {
                if (!charactersData?.allCharacters?.edges?.length || !sectionsData?.allSections?.edges?.length) {
                  noCharacterOrSectionNotification();
                } else {
                  setLineOpen(true);
                }
              }}
              label={t('LINES_PAGE.ADD_LINE_BUTTON')}
            />
          </Box>
        </Box>
      </Box>
      {data?.allLines?.edges && !loading && !charactersLoading && !sectionsLoading ? (
        <LineTable
          data={data?.allLines?.edges}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      ) : (
        <PageLoader />
      )}
      {data?.allLines?.totalCount ? (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          labelRowsPerPage=""
          component="div"
          count={data?.allLines?.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onMouseOver={() => fetchMore({ variables: { offset: (page + 1) * pageSize, first: pageSize, orderBy } })}
        />
      ) : null}
      {lineContext && (
      <DeleteLineDialog
        open={lineContext?.open}
        setOpen={lineContext?.setOpen}
        handleDialogChange={lineContext?.handleDialogChange}
      />
      )}
      { charactersData?.allCharacters?.edges?.length && sectionsData?.allSections?.edges?.length ? (
        <CreateLineDialog
          open={lineOpen}
          setOpen={setLineOpen}
          handleDialogChange={handleLineDialogChange}
          initialSection={{ __typename: 'SectionNodeEdge', node: sectionsData?.allSections?.edges[0]?.node || null }}
          initialCharacter={{ __typename: 'CharacterNodeEdge', node: charactersData?.allCharacters?.edges[0]?.node || null }}
          getLinesVariables={getAllLinesVariables}
        />
      ) : null}
      <ToastContainer />

    </>
  );
};
export default LineTableContainer;
