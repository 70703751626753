import React, { useState } from 'react';
import {
  Box, TablePagination, Typography,
} from '@mui/material';
import { useQuery } from '@apollo/client';

import CharSectTable from '../CharSectTable/CharSectTable';
import { GET_ALL_CHARACTERS } from '../../../graphql/queries';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';
import { CreateCharacterDialog } from '../CreateCharacterDialog';
import { DeleteCharacterDialog } from '../DeleteCharacterDialog';
import { Order } from './types';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import PageLoader from '../../../common/PageLoader/PageLoader';

const CharacterTableContainer = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');

  const { loading, data, fetchMore } = useQuery<allCharacters>(
    GET_ALL_CHARACTERS,
    {
      variables: {
        offset: page * pageSize, first: pageSize, orderBy, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const classes = useStyles();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleDialogChange = () => {
    setOpen(!open);
  };

  const handleDeleteDialogChange = () => {
    setDeleteOpen(!deleteOpen);
  };

  if (loading) {
    return <PageLoader />;
  }
  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h5">Characters</Typography>
        <StyledButton
          variant="contained"
          label="Add Character"
          action={handleDialogChange}
          cyTestId="open-add-character-button"
        />
      </Box>
      { data?.allCharacters?.edges
        ? (
          <CharSectTable
            data={data.allCharacters.edges}
            setOpen={setOpen}
            setDeleteOpen={setDeleteOpen}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
          />
        ) : null}
      { data?.allCharacters?.totalCount
        ? (
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            labelRowsPerPage=""
            component="div"
            count={data?.allCharacters?.totalCount}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            onMouseOver={() => fetchMore({ variables: { offset: (page + 1) * pageSize, first: pageSize } })}
          />
        ) : null}
      <CreateCharacterDialog
        open={open}
        setOpen={setOpen}
        handleDialogChange={handleDialogChange}
      />
      <DeleteCharacterDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleDialogChange={handleDeleteDialogChange}
      />
    </>
  );
};
export default CharacterTableContainer;
