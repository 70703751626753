import React from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import { HeadCell, ICharSectTableProps } from './types';
import { CharSectRow } from '../CharSectRow';
import { ReactComponent as TopRhombIcon } from '../../../assets/icons/top-rhomb-icon.svg';
import { ReactComponent as BottomRhombIcon } from '../../../assets/icons/bottom-rhomb-icon.svg';

const CharSectTable: React.FC<ICharSectTableProps> = ({
  data, setOpen, setDeleteOpen, isSection, order, setOrder, orderBy, setOrderBy,
}) => {
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [

    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      sortingField: 'name',
      label: isSection ? t('SECTIONS_PAGE.SECTION_COLUMN') : t('CHARACTERS_PAGE.CHARACTER_COLUMN'),
      width: '20%',
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      sortingField: 'name',
      label: t('CHARACTERS_PAGE.DESCRIPTION_COLUMN'),
      width: '35%',
    },
    {
      id: 'notes',
      numeric: false,
      disablePadding: false,
      sortingField: 'name',
      label: t('CHARACTERS_PAGE.NOTES_COLUMN'),
      width: '35%',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      sortingField: 'name',
      label: t('CHARACTERS_PAGE.ACTIONS_COLUMN'),
      width: '10%',
    },
  ];

  const handleRequestSort = (
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(isAsc ? `-${property}` : property);
  };

  const sortTable = (property: string) => {
    handleRequestSort(property);
  };

  return (
    <div>
      <Table
        aria-labelledby="tableTitle"
        size="small"
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                padding="normal"
                sx={{ width: headCell.width }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  { headCell.id === 'name'
                    ? (
                      <Button
                        onClick={() => {
                          sortTable(headCell.id);
                        }}
                        sx={{ textTransform: 'none' }}
                      >
                        <Typography variant="body2" sx={{ marginRight: '10px' }}>
                          {isSection ? 'Section' : headCell.label}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <TopRhombIcon />
                          <BottomRhombIcon />
                        </Box>
                      </Button>
                    ) : (
                      <Typography variant="body2" sx={{ marginRight: '10px' }}>
                        {headCell.label}
                      </Typography>
                    )}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index: number) => (
            <CharSectRow
              item={item?.node}
              key={uuidv4()}
              isEven={index % 2 === 0}
              setOpen={setOpen}
              setDeleteOpen={setDeleteOpen}
              isSection={isSection}
            />
          ))}
        </TableBody>
      </Table>

    </div>
  );
};
export default CharSectTable;
