import { FileUploader } from 'react-drag-drop-files';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { lightGray, secondary } from '../../constants/colors';
import { useStyles } from './styles';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload-icon-orange.svg';
import { IFileDropzoneProps } from './types';

const FileDropzone: React.FC<IFileDropzoneProps> = ({
  handleDnD, typeArr, droppedFileName, maxSize,
}) => {
  const classes = useStyles();
  return (
    <FileUploader
      handleChange={handleDnD}
      types={typeArr}
      maxSize={maxSize}
    >
      <Box className={classes.fileDropzone}>
        <UploadIcon />
        {
            !droppedFileName
              ? (
                <>
                  <Typography sx={{ marginLeft: '14px' }} fontWeight="bold" color={secondary}>
                    Upload&nbsp;
                  </Typography>
                  <Typography color={lightGray} fontWeight="bold">
                    or Drag and drop your
                    {' '}
                    {typeArr.join(', ')}
                    {' '}
                    files
                  </Typography>
                </>
              )
              : (
                <>
                  <Typography sx={{ marginLeft: '14px' }} color={secondary} fontWeight="bold">
                    {droppedFileName}
                        &nbsp;
                  </Typography>
                  <Typography color={lightGray} fontWeight="bold">
                    - file uploaded
                  </Typography>
                </>
              )
          }
      </Box>
    </FileUploader>
  );
};

export default FileDropzone;
