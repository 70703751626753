import React, { useCallback, useState } from 'react';
import {
  Autocomplete, Box, Checkbox, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { StyledButton } from '../../../common/StyledButton';
import { IUploadRecordingFormProps } from './types';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { useStyles } from './styles';
import { dangerous } from '../../../constants/colors';
import { maxRecordingSize, RECORD_FILE_TYPES } from '../../../constants/recordings';
import { FileDropzone } from '../../../common/FileDropzone';

const UploadRecordingForm: React.FC<IUploadRecordingFormProps> = ({
  selectedLanguages,
  setSelectedLanguages,
  selectedFileTypes,
  setSelectedFileTypes,
  droppedFileName,
  languagesList,
  handleDnD,
  handleUploadRecording,
}) => {
  const { t } = useTranslation();

  const [isWarnMissingLine, setIsWarnMissingLine] = useState<boolean>(false);
  const [isDeletePreviousAssets, setIsDeletePreviousAssets] = useState<boolean>(false);

  const classes = useStyles();

  const handleMultiSelectChange = useCallback((event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event;
    setSelectedFileTypes(typeof value === 'string' ? value.split(',') : value);
  }, []);

  return (
    <Box className={classes.uploadScriptFormWrapper}>
      <Box
        className={classes.dropzoneWrapper}
        sx={{ marginBottom: '16px' }}
      >
        <FileDropzone
          handleDnD={handleDnD}
          typeArr={RECORD_FILE_TYPES}
          droppedFileName={droppedFileName}
          maxSize={maxRecordingSize}
        />
      </Box>
      <Box className={classes.inputsWrapper}>
        <Box className={classes.selectsWrapper}>
          <Box>
            <Typography>Languages</Typography>

            <InputLabel htmlFor="selected-languages" />
            <Autocomplete
              multiple
              id="filter-language-list"
              className={classes.select}
              options={languagesList || []}
              getOptionLabel={(option) => option?.node?.name || ''}
              renderOption={(props, option) => (
                <MenuItem
                  key={option?.node?.languageCode}
                  value={option?.node?.id}
                  {...props}
                >
                  <FlagVisualisation
                    style={{ marginRight: '6px' }}
                    key={option?.node?.languageCode}
                    optionFlag={option?.node?.languageFlag || ''}
                  />
                  {option?.node?.name}
                </MenuItem>
              )}
              sx={{ width: '330px' }}
              value={selectedLanguages}
              onChange={(_event, newValue) => setSelectedLanguages(newValue)}
              renderInput={(params) => (
                <TextField
                  placeholder="Select"
                  {...params}
                />
              )}
            />
          </Box>

          <Box>
            <Typography>Filetype</Typography>
            <InputLabel htmlFor="selected-file-type" />

            <Select
              id="selected-file-type"
              multiple
              value={selectedFileTypes}
              onChange={(e) => handleMultiSelectChange(e)}
              className={classes.select}
              sx={{ width: '163px' }}
            >
              {RECORD_FILE_TYPES?.map((fileType) => (
                <MenuItem key={fileType} value={fileType}>
                  {fileType}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px' }}>

          {/* <Tooltip title={t('UPLOAD_RECORDINGS_PAGE.WARN_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={isWarnMissingLine}
                onChange={() => setIsWarnMissingLine((prevValue) => !prevValue)}
              />
              <span>
                {t('UPLOAD_RECORDINGS_PAGE.WARN_CHECKBOX')}
              </span>
            </Typography>
          </Tooltip> */}

          {/* <Tooltip title={t('UPLOAD_RECORDINGS_PAGE.DELETE_CHECKBOX_TOOLTIP')} placement="left-end">
            <Typography sx={{ fontSize: '16px' }}>
              <Checkbox
                className={classes.checkbox}
                checked={isDeletePreviousAssets}
                onChange={() => setIsDeletePreviousAssets((prevValue) => !prevValue)}
              />
              <span>
                {t('UPLOAD_RECORDINGS_PAGE.DELETE_CHECKBOX')}
              </span>
              <span style={{ color: dangerous, fontWeight: 'bold' }}>
                {' '}
                {t('UPLOAD_RECORDINGS_PAGE.DELETE_DANGEROUS_MESSAGE')}
              </span>
            </Typography>
          </Tooltip> */}
        </Box>
        <Box className={classes.buttonsWrapper} sx={{ display: 'flex', justifyContent: 'flex-begin', width: '100%' }}>
          <StyledButton
            width="210px"
            variant="contained"
            action={handleUploadRecording}
            label={t('UPLOAD_RECORDINGS_PAGE.UPLOAD_BUTTON')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UploadRecordingForm;
