import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  flexAlign: {
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
});
