import { useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { GET_PROJECT } from '../../graphql/queries';
import ProjectDetailsContainer from '../../components/Project/ProjectDetailsContainer/ProjectDetailsContainer';
import { project } from '../../graphql/queries/__generated__/project';
import { PageLoader } from '../../common/PageLoader';

const ProjectDetails = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const { data, loading } = useQuery<project>(GET_PROJECT, { variables: { id } });

  if (loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />
        <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
            <Typography
              variant="h3"
              sx={{
                marginTop: '50px',
                marginBottom: '28px',
                alignSelf: 'flex-start',
              }}
            >
              {t('PROJECT_PAGE.PROJECT_DETAILS')}
            </Typography>

            <ProjectDetailsContainer
              projectName={data?.project?.name || ''}
              companyName={data?.project?.organisation?.name || ''}
              startDate={data?.project?.startDate || ''}
              endDate={data?.project?.endDate || ''}
              licenseKey={data?.project?.licenseKey || ''}
              description={data?.project?.description || ''}
              logoUrl={data?.project?.logoUrl || ''}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ProjectDetails;
