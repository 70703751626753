import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { TOKEN_AUTH } from '../../graphql/mutations';
import { AuthContext } from '../../context/AuthContext/AuthContext';
import { StyledButton } from '../StyledButton';
import { white } from '../../constants/colors';
import { useStyles } from './styles';
import { GET_CURRENT_USER_PROJECTS } from '../../graphql/queries';
import { LOCALE_STORAGE_PROJECT_ID } from '../../constants/localStorage';
import { emailRegEx } from '../../constants/user';

const LoginForm = () => {
  const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues: { email: '', password: '' } });
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenAuth] = useMutation(TOKEN_AUTH);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const classes = useStyles();

  // eslint-disable-next-line
  const [getUserProjects] = useLazyQuery<any>(GET_CURRENT_USER_PROJECTS);

  const onSubmit = async (data: { email: string; password: string }) => {
    await tokenAuth({
      variables: { email: data.email, password: data.password },
      onError: () => {
        setErrorMessage('Invalid email or password');
      },
      onCompleted: () => {
        getUserProjects({
          // eslint-disable-next-line
          onCompleted: (userProjectsData: any) => {
            const projectId = userProjectsData?.currentUser?.projects?.edges[0]?.node?.id;

            if (projectId) {
              localStorage.setItem(LOCALE_STORAGE_PROJECT_ID, projectId);
            }

            context?.setIsLoggedIn(true);
            navigate('/dashboard');
          },
        });
      },
    });
  };

  return (
    <Box className={classes.container}>
      <Typography
        variant="h2"
        color={white}
        sx={{
          textAlign: 'center',
          marginBottom: '35px',
        }}
      >
        SIGN IN
      </Typography>
      <form style={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Email required',
              pattern: {
                value: emailRegEx,
                message: 'Invalid email address',
              },
            }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                label="Email"
                type="email"
                inputProps={{
                  style: {
                    backgroundColor: white,
                    outline: 'none',
                    borderRadius: '8px',
                  },
                }}
                variant="filled"
              />
            )}
          />
          <Typography sx={{ color: '#D40000' }}>{errors.email?.message}</Typography>
        </Box>
        <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
          <Controller
            name="password"
            control={control}
            rules={{ required: 'Password required' }}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                inputProps={{
                  style: {
                    backgroundColor: white,
                    outline: 'none',
                    borderRadius: '8px',
                  },
                }}
                value={value}
                label="Password"
                type="password"
                variant="filled"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(onSubmit)();
                  }
                }}
              />
            )}
          />
          <Typography sx={{ color: '#D40000' }}>{errors.password?.message}</Typography>
          {errorMessage && <Typography sx={{ color: '#D40000' }}>{errorMessage}</Typography>}

        </Box>
        <Box className={classes.actionsContainer}>
          <StyledButton
            variant="contained"
            width="200px"
            label="Sign in"
            margin="0px 50px 0px 0px"
            action={handleSubmit(onSubmit)}
            cyTestId="login-button"
          />
          <Typography variant="subtitle2" color={white} sx={{ marginRight: '5px' }}>
            Forgot password?
          </Typography>
          <a href="#/" className={classes.link}>
            Click here
          </a>
        </Box>
        <Box className={classes.textContainer}>
          <Typography variant="subtitle2" color={white} sx={{ marginRight: '5px' }}>
            For new clients, please contact
          </Typography>
          <a href="#/" className={classes.link}>
            info@jellyfish.com
          </a>
        </Box>
        <Box className={classes.textContainer}>
          <Typography variant="subtitle2" color={white}>
            On a company license? Ask your project manager to add you
          </Typography>
        </Box>
      </form>
    </Box>
  );
};
export default LoginForm;
