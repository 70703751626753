import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  loaderWrapper: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    marginTop: '80px',
  },
  textWrapper: {
    fontWeight: 'bold',
    margin: '15px 0',
  },
});
