import React, { useCallback } from 'react';
import {
  Box,
  TableCell,
  TableRow,
  Typography,
  IconButton, Checkbox,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from './styles';
import { IRecordingsTableRowProps } from './types';
import { ReactComponent as InstallIcon } from '../../../assets/icons/install-icon.svg';
import { CustomAudioPlayer } from '../../../common/CustomAudioPlayer';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { useAswFileLink } from '../../../hooks';
import { convertBitDepthValue } from '../../../helpers';
import {
  // eslint-disable-next-line camelcase
  allCurrentLocalisedAssetRevisions_allCurrentLocalisedAssetRevisions_edges_node,
} from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetRevisions';

const RecordingsTableRow: React.FC<IRecordingsTableRowProps> = ({
  data,
  isEven,
  selectedRevisions,
  setSelectedRevisions,
}) => {
  const classes = useStyles();
  const { awsResponse, fileExists } = useAswFileLink(data?.soundFile?.soundFilePath || '');

  const isTableItemActive = useCallback(
    (itemId: string | undefined): boolean => !!selectedRevisions.find(({ revisionId }) => revisionId === itemId),
    [selectedRevisions],
  );

  const handleTableItem = useCallback((
    // eslint-disable-next-line camelcase
    revision: allCurrentLocalisedAssetRevisions_allCurrentLocalisedAssetRevisions_edges_node | null | undefined,
  ) => {
    setSelectedRevisions((prevState) => {
      if (revision?.id && revision.soundFile?.id) {
        return isTableItemActive(revision.id)
          ? prevState.filter(({ revisionId }) => revision.id !== revisionId)
          : [...prevState, { revisionId: revision.id, soundFileId: revision.soundFile.id }];
      }
      return prevState;
    });
  }, [selectedRevisions]);

  return (
    <React.Fragment key={uuidv4()}>
      <TableRow className={isEven ? classes.lightBlueRow : classes.whiteRow}>
        <TableCell className={classes.tableText} sx={{ minWidth: '150px' }}>
          <Checkbox
            className={classes.checkbox}
            checked={isTableItemActive(data?.id)}
            onClick={() => handleTableItem(data)}
          />
          <Typography variant="subtitle2" display="inline">
            {data?.localisedAsset?.line.stringId}
          </Typography>
        </TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <FlagVisualisation optionFlag={data?.localisedAsset?.language?.languageFlag || ''} />
            <Typography
              variant="subtitle2"
              display="inline"
              className={classes.tableText}
              sx={{ marginLeft: '5px' }}
            >
              {data?.localisedAsset?.language?.languageCode}
            </Typography>
          </Box>
        </TableCell>
        <TableCell padding="none">
          <CustomAudioPlayer
            awsFileLink={awsResponse}
            fileExists={fileExists}
            isControlsDisabled
            isTimeIndicatorsDisabled
            isMinimized
          />
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(data?.soundFile?.soundMetadata?.length)
              / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {data?.soundFile?.soundMetadata?.sampleRate}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {data?.soundFile?.soundMetadata?.numberOfChannels}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {convertBitDepthValue(data?.soundFile?.soundMetadata?.bitDepth)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {data?.soundFile?.soundMetadata?.perceivedLoudness.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {data?.soundFile?.soundMetadata?.peakLevel.toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(data?.soundFile?.soundMetadata?.trailingSilenceFront)
              / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            {(Number(data?.soundFile?.soundMetadata?.trailingSilenceBack)
              / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.tableText}
          >
            .
            {data?.soundFile?.soundFilePath?.split('.')[1]}
          </Typography>
        </TableCell>
        <TableCell>
          {
              fileExists && awsResponse && (
              <a href={awsResponse} download rel="noreferrer" target="_blank">
                <IconButton>
                  <InstallIcon />
                </IconButton>
              </a>
              )
          }
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default RecordingsTableRow;
