import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  dialogContainer: {
    paddingLeft: '26px',
    paddingRight: '26px',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  disableHorisontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
