import { useLazyQuery } from '@apollo/client';
import {
  useCallback, useEffect, useState,
} from 'react';
import { GET_PRESIGNED_SOUNDFILE_URL_FOR_UPLOAD } from '../graphql/queries';
import { uploadFileToS3 } from '../helpers';
import { getPresignedSoundfileUrlUpload } from '../graphql/queries/__generated__/getPresignedSoundfileUrlUpload';
import { LOCALE_STORAGE_PROJECT_ID } from '../constants/localStorage';

const useUploadSoundFileToS3 = (open: boolean) => {
  const [droppedFile, setDroppedFile] = useState<File | null>(null);
  const [soundFileId, setSoundFileId] = useState<string | null | undefined>(null);
  const [
    getPresignedUrl,
    {
      loading: presignedUrlLoading,
      data: presignedUrlData,
      refetch: refetchPresignedUrl,
    },
  ] = useLazyQuery<getPresignedSoundfileUrlUpload>(
    GET_PRESIGNED_SOUNDFILE_URL_FOR_UPLOAD,
    { fetchPolicy: 'network-only' },
  );

  const handleDnD = useCallback((file: File) => {
    if (open) {
      const regEx = /\.[^.]+$/;
      const match = file.name.match(regEx);
      if (match) {
        setDroppedFile(file);
        refetchPresignedUrl({
          variables: {
            projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
            extension: match[0].replace('.', '') || '',
          },
        }).then();
      }
    }
  }, [open]);

  useEffect(() => {
    if (!presignedUrlLoading && presignedUrlData && droppedFile) {
      uploadFileToS3(droppedFile, presignedUrlData.getPresignedSoundfileUrlUpload?.awsResponse).then();
      setSoundFileId(presignedUrlData.getPresignedSoundfileUrlUpload?.fileId);
    }
  }, [presignedUrlData, presignedUrlLoading, droppedFile]);

  return {
    presignedUrlData,
    presignedUrlLoading,
    handleDnD,
    droppedFileName: droppedFile?.name,
    soundFileId,
    setDroppedFile,
  };
};

export default useUploadSoundFileToS3;
