import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  dialogContainer: {
    paddingLeft: '120px',
    paddingRight: '120px',
    paddingTop: '54px',
    paddingBottom: '54px',
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialogHeading: {
    marginBottom: '6px',
  },
  disableHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '24px',
  },
  selectField: {
    width: '100%',
    backgroundColor: '#fff',
    outline: 'none',
    borderRadius: '8px',
  },
}));
