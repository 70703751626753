import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import {
  CREATE_CHARACTER, UPDATE_CHARACTER,
} from '../../../graphql/mutations';
import { GET_ALL_CHARACTERS, GET_CHARACTERS_COUNT, GET_SCRIPT_ID } from '../../../graphql/queries';
import { base64Decoding } from '../../../helpers';
import { ICreateCharacterDialogProps } from './types';
import { CharContext } from '../../../context/CharContext/CharContext';
import { createCharacter as createCharacterProps } from '../../../graphql/mutations/__generated__/createCharacter';
import { defaultCharacterData } from '../../../constants/characters';
import { useStyles } from './styles';
import { updateCharacter as updateCharacterProps } from '../../../graphql/mutations/__generated__/updateCharacter';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const CreateCharacterDialog: React.FC<ICreateCharacterDialogProps> = ({
  handleDialogChange, open, setOpen,
}) => {
  const context = useContext(CharContext);
  const [charNameValue, setCharNameValue] = useState(context?.characterData.name);
  const [descriptionValue, setDescriptionValue] = useState(context?.characterData?.description);
  const [notesValue, setNotesValue] = useState(context?.characterData.notes);

  const { data: scriptIdData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [createCharacter] = useMutation<createCharacterProps>(CREATE_CHARACTER, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    refetchQueries: [
      {
        query: GET_ALL_CHARACTERS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_CHARACTERS_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allCharacters',
    ],
  });

  const [updateCharacter] = useMutation<updateCharacterProps>(UPDATE_CHARACTER, {
    refetchQueries: [
      {
        query: GET_ALL_CHARACTERS,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allCharacters',
    ],
  });

  const classes = useStyles();

  const handleCreateCharacter = (
    name: string| undefined,
    description: string| undefined,
    notes: string | undefined,
    scriptId: string,
  ) => {
    createCharacter({
      variables: {
        characterCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          name,
          description,
          notes,
          script: base64Decoding(scriptId),
        },
      },
    });

    context?.setCharacterData(defaultCharacterData);
    setOpen(!open);
  };

  const handleUpdateCharacter = (
    id: string | undefined,
    name: string| undefined,
    description: string| undefined,
    notes: string | undefined,
  ) => {
    updateCharacter({
      variables: {
        characterUpdateData: {
          id,
          name,
          description,
          notes,
        },
      },
    });

    context?.setCharacterData(defaultCharacterData);
    setOpen(!open);
  };

  const handleCharNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCharNameValue(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionValue(event.target.value);
  };
  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesValue(event.target.value);
  };

  useEffect(() => {
    if (context) {
      setCharNameValue(context.characterData.name);
      setDescriptionValue(context.characterData.description);
      setNotesValue(context.characterData.notes);
    }
  }, [open]);
  return (

    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Typography
              className={classes.subtitle}
              variant="body2"
            >
              Character
            </Typography>
            <FormControl className={classes.input} sx={{ width: '80%' }}>
              <TextField
                value={charNameValue}
                placeholder="John Doe"
                error={charNameValue === ''}
                helperText={charNameValue === '' ? 'Character name is required' : ''}
                onChange={handleCharNameChange}
                data-cy="character-name-field"
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body2">Description</Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                multiline
                rows={4}
                value={descriptionValue}
                placeholder="Character description..."
                onChange={handleDescriptionChange}
              />
            </FormControl>
            <Typography className={classes.subtitle} variant="body2">Notes</Typography>
            <FormControl className={classes.input} fullWidth>
              <TextField
                multiline
                rows={4}
                value={notesValue}
                placeholder="Character notes..."
                onChange={handleNotesChange}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }} className={classes.disableHorisontalPadding}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="Cancel"
            action={handleDialogChange}
          />
          <StyledButton
            variant={charNameValue === '' ? 'outlined' : 'contained'}
            width="210px"
            disabled={charNameValue === ''}
            label={context?.isEditMode ? 'OK' : 'Add'}
            cyTestId={context?.isEditMode ? 'update-character-button' : 'add-character-button'}
            action={context?.isEditMode ? () => handleUpdateCharacter(
              context?.characterData.id,
              charNameValue,
              descriptionValue,
              notesValue,
            ) : () => {
              if (scriptIdData?.project?.scriptSet.edges[0]?.node?.id) {
                handleCreateCharacter(
                  charNameValue,
                  descriptionValue,
                  notesValue,
                  scriptIdData?.project?.scriptSet.edges[0]?.node?.id,
                );
              }
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateCharacterDialog;
