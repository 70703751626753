import React from 'react';
import { Typography, Box } from '@mui/material';
import { Header } from '../../common/Header';
import { useStyles } from './styles';
import JellyfishWithoutFlags from '../../assets/images/JellyfishWithoutFlags.svg';

const NotFound: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Box className={classes.pageWrapper}>
        <Box component="main" className={classes.mainWrapper}>
          <Typography className={classes.errorCodeWrapper}>404</Typography>
          <Typography className={classes.textWrapper}>Oops... this page doesn&apos;t seems to exist.</Typography>
          <img
            alt="jellyfish"
            src={JellyfishWithoutFlags}
            className={classes.imageWrapper}
          />
        </Box>
      </Box>
    </>
  );
};

export default NotFound;
