/* eslint-disable camelcase */
import axios from 'axios';

import { useQuery } from '@apollo/client';

import { Buffer } from 'buffer';
// eslint-disable-next-line camelcase
import { allLines_allLines_edges_node_localisedassetSet_edges } from '../graphql/queries/__generated__/allLines';
import { allLanguages_allLanguages_edges } from '../graphql/queries/__generated__/allLanguages';
// eslint-disable-next-line camelcase
import { line_line_localisedassetSet_edges } from '../graphql/queries/__generated__/line';

import { GET_PRESIGNED_SOUNDFILE_URL_FOR_UPLOAD } from '../graphql/queries';

// eslint-disable-next-line camelcase
import { getPresignedSoundfileUrlUpload } from '../graphql/queries/__generated__/getPresignedSoundfileUrlUpload';
import { ParentNavItemData } from '../common/MenuListItem/types';
import { BIT_DEPTH_STRING_INDEX, BIT_DEPTH_VIEW_VALUES } from '../constants/revisions';
import { SoundFileComputedMetaDataBitDepth } from '../../__generated__/globalTypes';
import { admin } from '../constants/user';
import {
  getTextStatusByLanguage_textStatusByLanguage,
} from '../graphql/queries/__generated__/getTextStatusByLanguage';
import {
  getRecordingStatusByLanguage_recordingStatusByLanguage,
} from '../graphql/queries/__generated__/getRecordingStatusByLanguage';
import { ICurrentUser } from '../context/UserContext/types';

export const base64Decoding = (data: string) => {
  const decoded = Buffer.from(data, 'base64');
  return decoded.toString('utf-8').split(':')[1];
};

export const replaceValueByInf = (arr: number[], min: number, max: number) => {
  if (arr[0] === min && arr[1] === max) {
    return '-inf,inf';
  } if (arr[0] === min) {
    return `-inf,${arr[1]}`;
  } if (arr[1] === max) {
    return `${arr[0]},inf`;
  }
  return `${arr[0]},${arr[1]}`;
};

export const replaceValueForPadding = (arr: number[], min: number, max: number) => {
  if (arr[0] === min && arr[1] === max) {
    return '-inf,inf';
  } if (arr[0] === min) {
    return `-inf,${arr[1] / 48000}`;
  } if (arr[1] === max) {
    return `${arr[0] / 48000},inf`;
  }
  return `${arr[0] / 48000},${arr[1] / 48000}`;
};

export const addInvertedValue = (str: string, isInverted: boolean) => `${str},${isInverted}`;

export const makeFilteringString = (arr: (string | number | null)[]) => arr.join();

// eslint-disable-next-line camelcase
export const getDifference = (
  // eslint-disable-next-line camelcase
  allLanguages: (allLanguages_allLanguages_edges | null)[],
  // eslint-disable-next-line camelcase
  lineLanguages: (line_line_localisedassetSet_edges | null)[],
) => {
  const unique = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allLanguages.length; i++) {
    let found = false;

    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < lineLanguages.length; j++) {
      if (allLanguages[i]?.node?.id === lineLanguages[j]?.node?.language?.id) {
        found = true;

        break;
      }
    }
    if (found === false) {
      unique.push(allLanguages[i]?.node);
    }
  }
  return unique;
};

export const getFileUuidFromPath = (filePath: string): string => {
  const fileSlug = filePath.split('/');
  return fileSlug[fileSlug.length - 1].replace(/\.[^.]+$/, '');
};

export const uploadSoundfileFile = async (file: File | null) => {
  const { data: getPresignedSoundfileUrlUploadQuery } = await useQuery<getPresignedSoundfileUrlUpload>(GET_PRESIGNED_SOUNDFILE_URL_FOR_UPLOAD, {});

  if (getPresignedSoundfileUrlUploadQuery?.getPresignedSoundfileUrlUpload?.awsResponse) {
    const queryResult = JSON.parse(getPresignedSoundfileUrlUploadQuery.getPresignedSoundfileUrlUpload.awsResponse);
    const fieldsObject = new Map(Object.entries(queryResult.fields));
    const fields = Object.keys(fieldsObject).map((key: string) => `${key}=${fieldsObject.get(key)}`).join('&');
    console.log(getPresignedSoundfileUrlUploadQuery.getPresignedSoundfileUrlUpload);
    const putUrl = `${queryResult.url}?${fields}`;
    axios.post(putUrl, file?.webkitRelativePath, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    }).then((result) => {
      console.log(result);
    });
  }
};

export const downloadFile = async (downloadUrl: string): Promise<any> => {
  try {
    return await axios.get(downloadUrl, {
      headers: { 'Access-Control-Allow-Origin': '*' },
      responseType: 'blob',
    });
  } catch (e) {
    console.log('Error', e);
    return e;
  }
};

export const uploadFileToS3 = async (file: File | null, awsResponse: string | null | undefined) => {
  if (file && awsResponse) {
    const formData = new FormData();
    const s3UploadData = JSON.parse(awsResponse);

    Object.entries(s3UploadData.fields).forEach(([key, value]) => {
      formData.append(key, value as string);
    });
    formData.append('file', file as Blob); // The file has be the last element
    try {
      await axios.post(s3UploadData.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (e) {
      console.log('Error', e);
    }
  }
};

export const getIsChildActive = (navItem: ParentNavItemData): boolean => {
  const isChildActive = navItem.children.find((item) => item.url === window.location.pathname);
  return !!isChildActive;
};

export const convertBitDepthValue = (bitDepthValue: number | SoundFileComputedMetaDataBitDepth | null | undefined) => {
  if (typeof bitDepthValue === 'string' && bitDepthValue.startsWith(BIT_DEPTH_STRING_INDEX)) {
    const bitDepthViewValueIndex = Number(bitDepthValue.replace(BIT_DEPTH_STRING_INDEX, ''));
    if (bitDepthViewValueIndex) {
      return BIT_DEPTH_VIEW_VALUES[bitDepthViewValueIndex];
    }
  }
  if (typeof bitDepthValue === 'number') {
    return BIT_DEPTH_VIEW_VALUES[bitDepthValue];
  }
  return BIT_DEPTH_VIEW_VALUES[0];
};

export const sortLocalisedAssetLanguagesInAlphabeticalOrder = (edges?: (allLines_allLines_edges_node_localisedassetSet_edges | null)[] | undefined, primaryLanguageName?: string) => {
  if (edges) {
    const sortedArray = [...edges].sort((a, b) => {
      if (a?.node?.language?.name && b?.node?.language?.name) {
        return a?.node?.language?.name.localeCompare(b?.node?.language?.name);
      }
      return 0;
    });
    if (primaryLanguageName) {
      const primaryLanguageIndex = sortedArray.findIndex((item) => item?.node?.language?.name === primaryLanguageName);
      if (primaryLanguageIndex !== -1) {
        const primaryLanguage = sortedArray.splice(primaryLanguageIndex, 1);
        sortedArray.unshift(primaryLanguage[0]);
      }
    }
    return sortedArray;
  }
  return [];
};

export const sortLanguagesInAlphabeticalOrder = (edges?: (allLanguages_allLanguages_edges | null)[] | undefined, primaryLanguageName?: string) => {
  if (edges) {
    const sortedArray = [...edges].sort((a, b) => {
      if (a?.node?.name && b?.node?.name) {
        return a?.node?.name.localeCompare(b?.node?.name);
      }
      return 0;
    });
    if (primaryLanguageName) {
      const primaryLanguageIndex = sortedArray.findIndex((item) => item?.node?.name === primaryLanguageName);
      if (primaryLanguageIndex !== -1) {
        const primaryLanguage = sortedArray.splice(primaryLanguageIndex, 1);
        sortedArray.unshift(primaryLanguage[0]);
      }
    }
    return sortedArray;
  }
  return [];
};

export const sortTextStatusByLanguageInAlphabeticalOrder = (edges?: (getTextStatusByLanguage_textStatusByLanguage | null)[] | undefined | null, primaryLanguageName?: string) => {
  if (edges) {
    const sortedArray = [...edges].sort((a, b) => {
      if (a?.language?.name && b?.language?.name) {
        return a?.language?.name.localeCompare(b?.language?.name);
      }
      return 0;
    });
    if (primaryLanguageName) {
      const primaryLanguageIndex = sortedArray.findIndex((item) => item?.language?.name === primaryLanguageName);
      if (primaryLanguageIndex !== -1) {
        const primaryLanguage = sortedArray.splice(primaryLanguageIndex, 1);
        sortedArray.unshift(primaryLanguage[0]);
      }
    }
    return sortedArray;
  }
  return [];
};

export const sortRecordingsByLanguageInAlphabeticalOrder = (edges?: (getRecordingStatusByLanguage_recordingStatusByLanguage | null)[] | undefined | null, primaryLanguageName?: string) => {
  if (edges) {
    const sortedArray = [...edges].sort((a, b) => {
      if (a?.language?.name && b?.language?.name) {
        return a?.language?.name.localeCompare(b?.language?.name);
      }
      return 0;
    });
    if (primaryLanguageName) {
      const primaryLanguageIndex = sortedArray.findIndex((item) => item?.language?.name === primaryLanguageName);
      if (primaryLanguageIndex !== -1) {
        const primaryLanguage = sortedArray.splice(primaryLanguageIndex, 1);
        sortedArray.unshift(primaryLanguage[0]);
      }
    }
    return sortedArray;
  }
  return [];
};

export const isUserAdmin = (user: ICurrentUser | null | undefined): boolean => {
  let isAdmin = false;
  user?.permissions?.forEach((item) => {
    if (item?.name === admin) {
      isAdmin = true;
    }
  });
  return isAdmin;
};

export const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
