import React, { useState } from 'react';

import { defaultLanguageData } from '../../constants/languages';
import { ILangContextProps } from './types';

export const LangContext = React.createContext<ILangContextProps | null>(null);

const LangContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [languageData, setLanguageData] = useState(defaultLanguageData);
  const [isEditMode, setIsEditMode] = useState(false);

  return (
    <LangContext.Provider value={{
      languageData,
      setLanguageData,
      isEditMode,
      setIsEditMode,
    }}
    >
      {children}
    </LangContext.Provider>
  );
};

export default LangContextProvider;
