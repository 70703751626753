import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Box, CircularProgress, Link, TablePagination, TextField,
} from '@mui/material';

import {
  GET_ALL_CHARACTERS,
  GET_ALL_CURRENT_REVISIONS, GET_ALL_CURRENT_REVISIONS_IDS,
  GET_ALL_LANGUAGES,
  GET_ALL_SECTIONS,
} from '../../../graphql/queries';
import { Order, ISelectedRevision } from './types';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';
import { allCurrentLocalisedAssetRevisions }
  from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetRevisions';
import { RecordingsTable } from '../RecordingsTable';
import { RecordingsFilters } from '../../../common/RecordingsFilters';
import { allCharacters } from '../../../graphql/queries/__generated__/allCharacters';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { initialSliderValue, MAX_RECORDING_IDS, tableType } from '../../../constants/recordings';
import { FixRevisionContext } from '../../../context/FixRevisionContext/FixRevisionContext';
import FixRecordingsDialog from '../../../common/FixRecordingsDialog/FixRecordingsDialog';
import {
  allCurrentLocalisedAssetRevisionsIds,
} from '../../../graphql/queries/__generated__/allCurrentLocalisedAssetRevisionsIds';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const GeneralRecordingsContainer = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('localised_asset__line__string_id');
  const [order, setOrder] = useState<Order>('asc');
  const [isOpen, setIsOpen] = useState(false);
  const [searchStringValue, setSearchStringValue] = useState('');
  const [searchStringId, setSearchStringId] = useState('');
  const [selectedCharacter, setSelectedCharacter] = useState<(string | undefined)[]>([]);
  const [selectedSection, setSelectedSection] = useState<(string | undefined)[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<(string | undefined)[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<(string | undefined)[]>([]);
  const [selectedLoudness, setSelectedLoudness] = useState(initialSliderValue);
  const [selectedSampleRate, setSelectedSampleRate] = useState('');
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedPaddingFront, setSelectedPaddingFront] = useState(initialSliderValue);
  const [selectedPaddingBack, setSelectedPaddingBack] = useState(initialSliderValue);
  const [selectedLength, setSelectedLength] = useState(initialSliderValue);
  const [selectedBitDepth, setSelectedBitDepth] = useState('');
  const [selectedPeakLevel, setSelectedPeakLevel] = useState(initialSliderValue);
  const [selectedRevisions, setSelectedRevisions] = useState<Array<ISelectedRevision>>([]);

  const classes = useStyles();

  const context = useContext(FixRevisionContext);

  const { data, loading, fetchMore } = useQuery<allCurrentLocalisedAssetRevisions>(
    GET_ALL_CURRENT_REVISIONS,
    {
      variables: {
        offset: page * pageSize,
        first: pageSize,
        localisedAsset_Line_StringId_Icontains: searchStringId,
        characterId: selectedCharacter,
        sectionId: selectedSection,
        bitDepthFilter: selectedBitDepth,
        channelsFilter: selectedChannel,
        languageId: selectedLanguage,
        lengthFilter: selectedLength,
        perceivedLoudnessFilter: selectedLoudness,
        sampleRateFilter: selectedSampleRate,
        trailingSilenceBackFilter: selectedPaddingBack,
        trailingSilenceFrontFilter: selectedPaddingFront,
        peakLevelFilter: selectedPeakLevel,
        orderBy,
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
      },
      onCompleted: () => setSelectedRevisions([]),
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: charactersLoading, data: charactersData } = useQuery<allCharacters>(
    GET_ALL_CHARACTERS,
    {
      variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    },
  );

  const { loading: sectionsLoading, data: sectionsData } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: { offset: page * pageSize, first: pageSize, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
  });

  const { data: languagesData, loading: languagesLoading } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const [getAllRevisionsIds, {
    data: allRevisionsIdsData,
    loading: allRevisionsIdsLoading,
  }] = useLazyQuery<allCurrentLocalisedAssetRevisionsIds>(GET_ALL_CURRENT_REVISIONS_IDS, {
    onCompleted: async (resData) => {
      if (resData.allCurrentLocalisedAssetRevisions?.pageInfo.hasNextPage) {
        await getAllRevisionsIds({
          variables: {
            offset: MAX_RECORDING_IDS,
            first: MAX_RECORDING_IDS,
            localisedAsset_Line_StringId_Icontains: searchStringId,
            characterId: selectedCharacter,
            sectionId: selectedSection,
            bitDepthFilter: selectedBitDepth,
            channelsFilter: selectedChannel,
            languageId: selectedLanguage,
            lengthFilter: selectedLength,
            perceivedLoudnessFilter: selectedLoudness,
            sampleRateFilter: selectedSampleRate,
            trailingSilenceBackFilter: selectedPaddingBack,
            trailingSilenceFrontFilter: selectedPaddingFront,
            peakLevelFilter: selectedPeakLevel,
            projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          },
        });
      }
    },
  });

  const sortRevisionsIds = useCallback((revisionsArray: Array<any>): Array<ISelectedRevision> => revisionsArray.reduce((filtered, edge) => {
    if (edge?.node?.id && edge?.node?.soundFile?.id) {
      filtered.push({
        revisionId: edge.node.id,
        soundFileId: edge?.node?.soundFile?.id,
      });
    }
    return filtered;
  }, [] as Array<ISelectedRevision>), []);

  const getAndSetAllRevisionsIds = async () => {
    const res = await getAllRevisionsIds({
      variables: {
        first: MAX_RECORDING_IDS,
        localisedAsset_Line_StringId_Icontains: searchStringId,
        characterId: selectedCharacter,
        sectionId: selectedSection,
        bitDepthFilter: selectedBitDepth,
        channelsFilter: selectedChannel,
        languageId: selectedLanguage,
        lengthFilter: selectedLength,
        perceivedLoudnessFilter: selectedLoudness,
        sampleRateFilter: selectedSampleRate,
        trailingSilenceBackFilter: selectedPaddingBack,
        trailingSilenceFrontFilter: selectedPaddingFront,
        peakLevelFilter: selectedPeakLevel,
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        // orderBy,
      },
    });
    const revisions = sortRevisionsIds(res.data?.allCurrentLocalisedAssetRevisions?.edges || []);
    setSelectedRevisions(revisions || []);
  };
  const handleSelectAll = async () => {
    if (!allRevisionsIdsData) {
      await getAndSetAllRevisionsIds();
    } else {
      const revisions = sortRevisionsIds(allRevisionsIdsData?.allCurrentLocalisedAssetRevisions?.edges || []);
      setSelectedRevisions(revisions || []);
    }
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchStringValue(event.target.value);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };
  const handleSearch = () => {
    setSearchStringId(searchStringValue);
  };

  const handleFiltersOpen = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    if (allRevisionsIdsData) {
      getAndSetAllRevisionsIds().then();
    }
  }, [
    searchStringId,
    selectedCharacter,
    selectedSection,
    selectedBitDepth,
    selectedChannel,
    selectedLanguage,
    selectedLength,
    selectedLoudness,
    selectedSampleRate,
    selectedPaddingBack,
    selectedPaddingFront,
    selectedPeakLevel,
  ]);

  useEffect(() => {
    if (!context?.open) {
      fetchMore({ variables: { offset: page * pageSize, first: pageSize, orderBy } }).then();
    }
  }, [context?.open]);

  return (
    <>
      <RecordingsFilters
        table={tableType.RECORDINGS}
        isOpen={isOpen}
        characterOptions={charactersData?.allCharacters?.edges}
        sectionOptions={sectionsData?.allSections?.edges}
        languageOptions={languagesData?.allLanguages?.edges}
        setIsOpen={setIsOpen}
        setSelectedCharacter={setSelectedCharacter}
        setSelectedSection={setSelectedSection}
        setSelectedLanguage={setSelectedLanguage}
        setSelectedStatus={setSelectedStatus}
        setSelectedLoudness={setSelectedLoudness}
        setSelectedSampleRate={setSelectedSampleRate}
        setSelectedChannel={setSelectedChannel}
        setSelectedPaddingFront={setSelectedPaddingFront}
        setSelectedPaddingBack={setSelectedPaddingBack}
        setSelectedLength={setSelectedLength}
        setSelectedBitDepth={setSelectedBitDepth}
        setSelectedPeakLevel={setSelectedPeakLevel}
      />
      <Box className={classes.actionsContainer}>
        <Box className={classes.searchContainer}>
          <TextField
            sx={{ marginRight: '30px' }}
            value={searchStringValue}
            onChange={handleSearchChange}
            fullWidth
            size="small"
            label="Search..."
          />
          <StyledButton variant="contained" action={handleSearch} label="Search" />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <StyledButton action={handleFiltersOpen} variant="contained" label="Filters" />
        </Box>
      </Box>
      <Box className={classes.fixSection}>
        {
            allRevisionsIdsLoading
              ? <CircularProgress className={classes.circularProgressWrapper} />
              : (
                /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
                <Link
                  component="button"
                  className={classes.linkButton}
                  onClick={handleSelectAll}
                >
                  Select all
                </Link>
              )
          }
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          className={classes.linkButton}
          onClick={() => (setSelectedRevisions([]))}
        >
          Unselect all
        </Link>
        <StyledButton
          variant="contained"
          disabled={!selectedRevisions.length}
          label={`Process ${selectedRevisions.length} ${selectedRevisions.length === 1 ? 'line' : 'lines'}`}
          action={() => {
            context?.setOpen(true);
            context?.setIsGeneralView(true);
          }}
        />
      </Box>
      {data?.allCurrentLocalisedAssetRevisions?.edges
        && !loading
        && !charactersLoading
        && !sectionsLoading
        && !languagesLoading ? (
          <RecordingsTable
            data={data?.allCurrentLocalisedAssetRevisions?.edges}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            selectedRevisions={selectedRevisions}
            setSelectedRevisions={setSelectedRevisions}
          />
        ) : (
          <PageLoader />
        )}
      {data?.allCurrentLocalisedAssetRevisions?.totalCount && (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          labelRowsPerPage=""
          component="div"
          count={data?.allCurrentLocalisedAssetRevisions?.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onMouseOver={() => fetchMore({ variables: { offset: (page + 1) * pageSize, first: pageSize, orderBy } })}
        />
      )}
      {!!selectedRevisions.length && context?.open && <FixRecordingsDialog selectedRevisions={selectedRevisions} />}
    </>
  );
};
export default GeneralRecordingsContainer;
