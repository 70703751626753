import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  dialogContainer: {
    paddingLeft: '26px',
    paddingRight: '26px',
    paddingTop: '40px',
    paddingBottom: '40px',
  },
  disableHorisontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  subtitle: {
    marginBottom: '6px',
  },
  input: {
    marginBottom: '20px',
  },
}));
