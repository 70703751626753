import React, {
  useState, useContext, useCallback, useEffect,
} from 'react';
import {
  TableCell, TableRow, Typography, Box, IconButton, Radio, Collapse, CircularProgress, Tooltip,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@apollo/client';
import { useStyles } from './styles';
import { IRevisionTableRowProps } from './types';
import { ReactComponent as InstallIcon } from '../../../assets/icons/install-icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-icon.svg';
import { ReactComponent as RobovoiceIcon } from '../../../assets/icons/robovoice-icon.svg';
import { CustomAudioPlayer } from '../../../common/CustomAudioPlayer';
import { StyledButton } from '../../../common/StyledButton';
import { RevisionContext } from '../../../context/RevisionContext/RevisionContext';
import { MAX_CHARACTERS, statusOptions } from '../../../constants/revisions';
import ShowMoreButton from '../../../common/ShowMoreButton/ShowMoreButton';
import { useAswFileLink } from '../../../hooks';
import { convertBitDepthValue } from '../../../helpers';
import { GENERATE_ROBOVOICE } from '../../../graphql/mutations';
import { generateRobovoice as generateRobovoiceType } from '../../../graphql/mutations/__generated__/generateRobovoice';
import {
  robovoiceGenerationRequestStatus,
} from '../../../graphql/queries/__generated__/robovoiceGenerationRequestStatus';
import { ROBOVOICE_GENERATION_REQUEST_STATUS } from '../../../graphql/queries';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const RevisionTableRow: React.FC<IRevisionTableRowProps> = ({
  data, currentRevisionId, localisedAssetId, onCurrentRevisionChange, setShouldRefetch,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const [isPolling, setIsPolling] = useState(false);

  const context = useContext(RevisionContext);
  const classes = useStyles();

  const [
    generateRobovoice,
    {
      loading: generatedRobovoiceLoading,
      data: generatedRobovoiceData,
    },
  ] = useMutation<generateRobovoiceType>(GENERATE_ROBOVOICE);

  const {
    data: recordingExportData, loading: recordingExportLoading, startPolling, stopPolling,
  } = useQuery<robovoiceGenerationRequestStatus>(ROBOVOICE_GENERATION_REQUEST_STATUS, {
    variables: {
      id: generatedRobovoiceData?.generateRobovoice?.request?.id,
    },
    skip: !generatedRobovoiceData?.generateRobovoice?.request?.id,
  });

  const getStatusText = useCallback(
    (status: string) => statusOptions.find((statusOption) => statusOption.value === status)?.placeholder,
    [],
  );

  const { awsResponse, fileExists } = useAswFileLink(data?.soundFile?.soundFilePath || '');

  const handleSetContext = (isCreating: boolean, isUpload: boolean) => {
    if (context) {
      const selectedStatusIndex = statusOptions.map((statusOption) => statusOption.value).indexOf(data?.status || '');
      context.setRevisionData({
        ...context.revisionData,
        text: data?.text || '',
        notes: data?.notes || '',
        status: statusOptions[selectedStatusIndex],
        id: data?.id || '',
        localised_asset: localisedAssetId,
        revisionIndex: data?.revisionIndex || 0,
        isSoundFileExist: !!data?.soundFile,
        soundFilePath: data?.soundFile?.soundFilePath || '',
        soundFileId: data?.soundFile?.id || '',
      });
      if (isCreating) {
        context?.setOpen(true);
        context.setIsEditMode(true);
      } else if (isUpload) {
        context.setUploadSoundFileOpen(true);
      } else {
        context?.setDeleteOpen(true);
      }
    }
  };

  const handleRobovoiceButton = async () => {
    await generateRobovoice({
      variables: {
        robovoiceCreationData: {
          localisedAssetRevisionId: data?.id,
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
    });
  };

  useEffect(() => {
    if (!generatedRobovoiceLoading && generatedRobovoiceData) {
      startPolling(1000);
      setIsPolling(true);
    }
    return () => {
      stopPolling();
      setIsPolling(false);
    };
  }, [generatedRobovoiceLoading, generatedRobovoiceData]);

  useEffect(() => {
    if (!recordingExportLoading && recordingExportData?.robovoiceGenerationRequestStatus?.isFinished) {
      stopPolling();
      setIsPolling(false);
      setShouldRefetch(true);
    }
  }, [recordingExportLoading, recordingExportData]);

  return (
    <React.Fragment key={uuidv4()}>
      <TableRow className={classes.whiteRow}>
        <TableCell padding="none">
          <Radio
            checked={currentRevisionId === data?.id}
            value={data?.id}
            className={classes.radio}
            onChange={onCurrentRevisionChange}
          />
        </TableCell>
        <TableCell className={classes.tableText}>
          <Typography variant="subtitle2" display="inline">
            {data?.revisionIndex}
          </Typography>
        </TableCell>
        <TableCell padding="none" sx={{ maxWidth: '15vw' }}>
          <Typography
            variant="subtitle2"
            display="inline"
            className={classes.tableText}
            sx={{
              wordWrap: 'break-word',
            }}
          >
            {(data?.text?.length || 0) <= MAX_CHARACTERS
              ? (data?.text)
              : (
                <>
                  {isShowMore ? data?.text : data?.text.substring(0, MAX_CHARACTERS)}
                  <ShowMoreButton isShowMore={isShowMore} setState={setIsShowMore} />
                </>
              )}
          </Typography>
        </TableCell>
        <TableCell padding="none">
          <Typography variant="subtitle2" display="inline" className={classes.tableText}>
            {getStatusText(data?.status || '')}
          </Typography>
        </TableCell>
        <TableCell padding="none">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '5px' }}>
              <CustomAudioPlayer
                isControlsDisabled
                isTimeIndicatorsDisabled
                awsFileLink={awsResponse}
                fileExists={!!data?.soundFile}
              />
            </Box>
            {data?.soundFile?.isRobovoice && data?.soundFile && <RobovoiceIcon style={{ stroke: '#1F224C' }} />}
          </Box>
        </TableCell>
        <TableCell padding="none">
          <Typography variant="subtitle2" display="inline" className={classes.tableText}>
            {format(new Date(data?.revisionDate), 'MM/dd/yyyy')}
          </Typography>
        </TableCell>
        <TableCell padding="none">
          { context && (
          <Box>
            <Tooltip title={t('LINES_DETAILS_PAGE.EDIT_LINE_REVISION')} placement="top">
              <IconButton onClick={() => handleSetContext(true, false)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {
              data?.soundFile
                ? fileExists && awsResponse && (
                <a href={awsResponse} download rel="noreferrer" target="_blank">
                  <Tooltip title={t('LINES_DETAILS_PAGE.DOWNLOAD_REVISION_FILE')} placement="top">
                    <IconButton>
                      <InstallIcon />
                    </IconButton>
                  </Tooltip>
                </a>
                )
                : (
                  <Tooltip title={t('LINES_DETAILS_PAGE.UPLOAD_REVISION_FILE')} placement="top">
                    <IconButton onClick={() => handleSetContext(false, true)}>
                      <UploadIcon />
                    </IconButton>
                  </Tooltip>
                )
            }
            {
                data?.text && !data?.soundFile && (
                  <Tooltip title={t('LINES_DETAILS_PAGE.GENERATE_ROBOVOICE')} placement="top">
                    <IconButton onClick={() => handleRobovoiceButton()}>
                      {
                        isPolling || generatedRobovoiceLoading
                          ? <CircularProgress className={classes.circularProgressWrapper} />
                          : <RobovoiceIcon style={{ stroke: '#1F224C' }} />
                      }
                    </IconButton>
                  </Tooltip>
                )
            }
            <Tooltip title={t('LINES_DETAILS_PAGE.DELETE_REVISION')} placement="top">
              <IconButton onClick={() => handleSetContext(false, false)}>
                <TrashIcon style={{ stroke: '#1F224C' }} />
              </IconButton>
            </Tooltip>
          </Box>
          )}
        </TableCell>
      </TableRow>
      <TableRow className={classes.lightBlueRow}>
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none">
          <Box className={classes.collapsedContainer}>
            <Box className={classes.metaDataContainer}>
              <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'light' }}>
                {(data?.notes && data?.notes !== '') ? data?.notes : t('LINES_PAGE.NO_NOTES')}
              </Typography>
            </Box>
          </Box>
        </TableCell>
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none" />
      </TableRow>
      <TableRow className={classes.lightBlueRow}>
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none">
          <Box className={classes.collapsedContainer}>
            <StyledButton
              label="Metadata"
              action={() => setOpen(!open)}
              variant="text"
              textColor="black"
              startIcon={open ? <ArrowIcon className={classes.arrowDownIcon} /> : <ArrowIcon />}
            />
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ marginLeft: '30px' }}>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Loudness:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.perceivedLoudness ? (
                    <>
                      {data?.soundFile?.soundMetadata?.perceivedLoudness.toFixed(2)}
                      { ' ' }
                      LKFS
                    </>
                  ) : ('--')}

                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Sample rate:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.sampleRate ? (
                    <>
                      {data?.soundFile?.soundMetadata?.sampleRate}
                      { ' ' }
                      Hz
                    </>

                  ) : ('--')}

                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Channels:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.numberOfChannels || '--'}
                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Length:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.length ? (
                    <>
                      {(Number(data?.soundFile?.soundMetadata?.length)
                            / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
                      { ' ' }
                      s
                    </>
                  ) : ('--')}

                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Padding front:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.trailingSilenceFront ? (
                    <>
                      {(Number(data?.soundFile?.soundMetadata?.trailingSilenceFront)
                          / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
                      { ' ' }
                      s
                    </>
                  ) : ('--')}

                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Padding back:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.trailingSilenceBack ? (
                    <>
                      {(Number(data?.soundFile?.soundMetadata?.trailingSilenceBack)
                            / Number(data?.soundFile?.soundMetadata?.sampleRate)).toFixed(2)}
                      { ' ' }
                      s
                    </>
                  ) : ('--')}
                </Typography>
              </Box>
              <Box className={classes.metaDataContainer}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  File type:
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundFilePath ? (
                    <>
                      .
                      {data?.soundFile?.soundFilePath?.split('.')[1].toUpperCase()}
                    </>
                  ) : (
                    '--'
                  )}
                </Typography>
              </Box>
              <Box className={classes.metaDataContainer} sx={{ marginBottom: '10px' }}>
                <Typography variant="body2" className={classes.metaDataText} sx={{ fontWeight: 'bold' }}>
                  Bit Depth
                </Typography>
                <Typography variant="body2">
                  {data?.soundFile?.soundMetadata?.bitDepth ? (
                    <>
                      {convertBitDepthValue(data?.soundFile?.soundMetadata?.bitDepth)}
                    </>
                  ) : (
                    '--'
                  )}
                </Typography>
              </Box>
            </Collapse>
          </Box>
        </TableCell>
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none" />
        <TableCell padding="none" />
      </TableRow>
    </React.Fragment>
  );
};
export default RevisionTableRow;
