import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  dialogContainer: {
    paddingLeft: '26px',
    paddingRight: '26px',
    paddingTop: '54px',
    paddingBottom: '54px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
  },
  dialogContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dialogHeading: {
    marginBottom: '6px',
  },
  dialogDisableHorizontalPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));
