import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { StyledButton } from '../../../../common/StyledButton';
import { CREATE_REVISION, CREATE_SOUND_FILE } from '../../../../graphql/mutations';
import {
  GET_LINE,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../../graphql/queries';
import { ICreateRevisionDialogProps } from './types';
import { defaultRevisionData, statusOptions } from '../../../../constants/revisions';
import { useStyles } from './styles';
import { createLocalisedAssetRevision as createLocalisedAssetRevisionProps }
  from '../../../../graphql/mutations/__generated__/createLocalisedAssetRevision';
import { base64Decoding } from '../../../../helpers';
import FileDropzone from '../../../../common/FileDropzone/FileDropzone';
import { audioFileExtension, MAX_SOUND_FILE_SIZE } from '../../../../constants/audioFile';
import { RevisionContext } from '../../../../context/RevisionContext/RevisionContext';
import useUploadSoundFileToS3 from '../../../../hooks/useUploadSoundFileToS3';
import { createSoundFile as createSoundFileType } from '../../../../graphql/mutations/__generated__/createSoundFile';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../../constants/localStorage';

const CreateRevisionDialog: React.FC<ICreateRevisionDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const classes = useStyles();

  const context = useContext(RevisionContext);
  const [statusValue, setStatusValue] = useState(context?.revisionData.status.value);
  const [textValue, setTextValue] = useState(context?.revisionData.text);
  const [notesValue, setNotesValue] = useState(context?.revisionData.notes);
  const [createLocalisedAssetRevision, { data, loading }] = useMutation<createLocalisedAssetRevisionProps>(
    CREATE_REVISION,
    {
      variables: {
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
      },
      refetchQueries: [{ query: GET_LINE, variables: { id } },
        { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        'line'],
    },
  );

  const [creteSoundFile] = useMutation<createSoundFileType>(
    CREATE_SOUND_FILE,
    {
      variables: {
        projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
      },
      refetchQueries: [{ query: GET_LINE, variables: { id } }, 'line'],
    },
  );

  const {
    handleDnD,
    droppedFileName,
    soundFileId,
    setDroppedFile,
  } = useUploadSoundFileToS3(open);

  const handleCreateRevision = async (
    status: string | undefined,
    text: string | undefined,
    notes: string | undefined,
    // eslint-disable-next-line camelcase
    localised_asset: string | undefined,
    revisionDate: Date,
    // eslint-disable-next-line camelcase
    sound_file_id: string | undefined,
    makeCurrent: boolean,
  ) => {
    createLocalisedAssetRevision({
      variables: {
        localisedAssetRevisionCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          status,
          text,
          notes,
          // eslint-disable-next-line camelcase
          localised_asset,
          revisionDate,
          // eslint-disable-next-line camelcase
          sound_file_id,
        },
        makeCurrent,
      },
    }).then();
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(event.target.value);
  };

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotesValue(event.target.value);
  };

  useEffect(() => {
    if (context) {
      setDroppedFile(null);
      setStatusValue(context.revisionData.status.value);
      setTextValue(context.revisionData.text);
      setNotesValue(context.revisionData.notes);
    }
  }, [open]);

  useEffect(() => {
    if (!loading && data?.createLocalisedAssetRevision?.localisedAssetRevision?.id) {
      if (droppedFileName && soundFileId) {
        creteSoundFile({
          variables: {
            createSoundFile: {
              id: soundFileId,
              localised_asset_revision: base64Decoding(data.createLocalisedAssetRevision.localisedAssetRevision.id),
              isRobovoice: false,
            },
          },
        }).then();
      }

      context?.setRevisionData(defaultRevisionData);
      setOpen(!open);
    }
  }, [data, loading]);

  return (
    <Dialog open={open} onClose={handleDialogChange} fullWidth maxWidth="md">
      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.disableHorisontalPadding}>
          <Box>
            <Box>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.STATUS_DESCRIPTION')}
              </Typography>
              <TextField
                id="revision-statuses"
                select
                sx={{ width: '100%' }}
                value={statusValue}
                onChange={(
                  _event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
                ) => setStatusValue(_event.target.value)}
                variant="outlined"
                size="small"
              >
                {statusOptions.map((statusOption) => (
                  <MenuItem key={statusOption.value} value={statusOption.value}>
                    {statusOption.placeholder}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.TEXT_DESCRIPTION')}
              </Typography>
              <FormControl className={classes.input} fullWidth>
                <TextField
                  multiline
                  rows={4}
                  value={textValue}
                  placeholder={t('LINES_PAGE.REVISION_PLACEHOLDER') || ''}
                  onChange={handleTextChange}
                />
              </FormControl>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography className={classes.subtitle} variant="body2">
                {t('LINES_PAGE.NOTES_DESCRIPTION')}
              </Typography>
              <FormControl className={classes.input} fullWidth>
                <TextField
                  multiline
                  rows={4}
                  value={notesValue}
                  placeholder={t('LINES_PAGE.NO_NOTES') || ''}
                  onChange={handleNotesChange}
                />
              </FormControl>
            </Box>
          </Box>
          <FileDropzone
            handleDnD={handleDnD}
            maxSize={MAX_SOUND_FILE_SIZE}
            typeArr={[audioFileExtension]}
            droppedFileName={droppedFileName}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between' }}
          className={classes.disableHorisontalPadding}
        >
          <StyledButton
            variant="outlined"
            width="210px"
            label={t('LINES_PAGE.CANCEL')}
            action={handleDialogChange}
          />
          <StyledButton
            variant="outlined"
            width="210px"
            label={t('LINES_PAGE.YES_AND_DONT_MAKE_CURRENT_REVISION')}
            action={
               () => handleCreateRevision(
                 statusValue,
                 textValue,
                 notesValue,
                 base64Decoding(context?.revisionData?.localised_asset || ''),
                 new Date(),
                 undefined,
                 false,
               )
            }
          />
          <StyledButton
            variant="contained"
            width="210px"
            label={t('LINES_PAGE.YES_AND_MAKE_CURRENT_REVISION')}
            action={
            () => handleCreateRevision(
              statusValue,
              textValue,
              notesValue,
              base64Decoding(context?.revisionData?.localised_asset || ''),
              new Date(),
              undefined,
              true,
            )
            }
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateRevisionDialog;
