import React, { useState } from 'react';

import { defaultRevisionData } from '../../constants/revisions';
import { IRevisionContextProps } from './types';

export const RevisionContext = React.createContext<IRevisionContextProps | null>(null);

const RevisionContextProvider: React.FC<{children:JSX.Element}> = ({ children }) => {
  const [revisionData, setRevisionData] = useState(defaultRevisionData);
  const [isEditMode, setIsEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadSoundFileOpen, setUploadSoundFileOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDialogChange = () => {
    setOpen(!open);
  };
  const handleDeleteDialogChange = () => {
    setDeleteOpen(!deleteOpen);
  };
  const handleUploadSoundFileDialogChange = () => {
    setUploadSoundFileOpen(!uploadSoundFileOpen);
  };

  return (
    <RevisionContext.Provider value={{
      revisionData,
      setRevisionData,
      isEditMode,
      setIsEditMode,
      open,
      setOpen,
      deleteOpen,
      setDeleteOpen,
      uploadSoundFileOpen,
      setUploadSoundFileOpen,
      handleDialogChange,
      handleDeleteDialogChange,
      handleUploadSoundFileDialogChange,
    }}
    >
      {children}
    </RevisionContext.Provider>
  );
};

export default RevisionContextProvider;
