import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Grid } from '@mui/material';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { useTranslation } from 'react-i18next';

import { NoProjectDialog } from '../NoProjectDialog';
import {
  GET_ALL_TODOS,
  GET_CHARACTERS_COUNT, GET_CURRENT_USER_PROJECTS,
  GET_LANGUAGES_COUNT,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_SECTIONS_COUNT,
} from '../../../graphql/queries';
import { allCharactersCount } from '../../../graphql/queries/__generated__/allCharactersCount';
import { allLanguagesCount } from '../../../graphql/queries/__generated__/allLanguagesCount';
import { allSectionsCount } from '../../../graphql/queries/__generated__/allSectionsCount';
import { TotalLinesCard } from '../TotalLinesCard';
import { TotalScriptsSectionsLanguagesCard } from '../TotalScriptsSectionsLanguagesCard';
import { ProjectOverviewCard } from '../ProjectOverviewCard';
import { getAllTodos } from '../../../graphql/queries/__generated__/getAllTodos';
import { TodoListWidget } from '../../TodoList/TodoListWidget';
import { getRecordingsAndTextData } from '../../../graphql/queries/__generated__/getRecordingsAndTextData';
import DashboardStatsCard from '../DashboardStatsCard/DashboardStatsCard';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';
import { base64Decoding } from '../../../helpers';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardContainer = () => {
  const { t } = useTranslation();

  const [NoProjectDialogOpen, setNoProjectDialogOpen] = useState<boolean>(false);

  const { data: charactersData, loading: charactersLoading } = useQuery<allCharactersCount>(GET_CHARACTERS_COUNT, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });
  const { data: sectionsData, loading: sectionsLoading } = useQuery<allSectionsCount>(GET_SECTIONS_COUNT, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });
  const { data: languagesData, loading: languagesLoading } = useQuery<allLanguagesCount>(GET_LANGUAGES_COUNT, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });
  const { data: userProjectsData, loading: loadingProjects } = useQuery<any>(GET_CURRENT_USER_PROJECTS);

  const checkCurrentProject = (userProjects: any[]) => {
    const isUserProjectsEmpty = !userProjects || userProjects?.length === 0;
    const isLocalStorageEmpty = !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID);
    if (isUserProjectsEmpty && isLocalStorageEmpty) {
      setNoProjectDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!loadingProjects) {
      checkCurrentProject(
        userProjectsData.currentUser?.projects?.edges,
      );
    }
  }, [userProjectsData, loadingProjects]);

  const {
    data: allTodosData,
    loading: allTodosLoading,
  } = useQuery<getAllTodos>(GET_ALL_TODOS, { variables: { orderBy: '-dueDate', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } });

  const {
    data: translationData,
  } = useQuery<getRecordingsAndTextData>(GET_RECORDINGS_AND_TEXT_DATA, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  if (
    charactersLoading
      && sectionsLoading
      && languagesLoading
      && allTodosLoading
      && loadingProjects
  ) {
    return <PageLoader />;
  }

  return (
    <Box sx={{ marginTop: '20px' }}>
      <Box>
        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item xs={4}>
            <TotalLinesCard linesCount={translationData?.countTotalLines} />
          </Grid>
          <Grid item xs={4}>
            <DashboardStatsCard
              withFlag
              iconType="file"
              subtitle={t('DASHBOARD.COMPLETED_TEXTS') || ''}
              completedAmount={translationData?.countTotalFinishedLinesReferenceLanguageText}
              totalAmount={translationData?.countTotalLines}
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardStatsCard
              withFlag
              iconType="recordings"
              subtitle={t('DASHBOARD.COMPLETED_RECORDINGS') || ''}
              completedAmount={translationData?.countTotalFinishedLinesReferenceLanguageRecordings}
              totalAmount={translationData?.countTotalLinesReferenceLanguageRecordings}
            />
          </Grid>
          <Grid item xs={4}>
            <TotalScriptsSectionsLanguagesCard
              languagesCount={languagesData?.allLanguages?.totalCount}
              charactersCount={charactersData?.allCharacters?.totalCount}
              sectionsCount={sectionsData?.allSections?.totalCount}
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardStatsCard
              title={t('DASHBOARD.TRANSLATIONS') || ''}
              iconType="file"
              subtitle={t('DASHBOARD.COMPLETED_TRANSLATIONS') || ''}
              completedAmount={translationData?.countTotalFinishedLinesTranslationsText}
              totalAmount={translationData?.countTotalLinesTranslationsText}
            />
          </Grid>
          <Grid item xs={4}>
            <DashboardStatsCard
              title={t('DASHBOARD.RECORDINGS') || ''}
              iconType="recordings"
              subtitle={t('DASHBOARD.COMPLETED_RECORDINGS') || ''}
              completedAmount={translationData?.countTotalFinishedLinesTranslationsRecordings}
              totalAmount={translationData?.countTotalLinesTranslationsRecordings}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          spacing={2}
          sx={{ marginTop: '6px' }}
        >
          <Grid item container display="flex" direction="row" spacing={2} xs={8}>
            <Grid item xs={12}>
              <ProjectOverviewCard />
            </Grid>

          </Grid>
          <Grid item xs={4}>
            {allTodosData?.allToDoItems?.edges && <TodoListWidget todos={allTodosData.allToDoItems.edges} />}
          </Grid>
        </Grid>
      </Box>
      <NoProjectDialog open={NoProjectDialogOpen} />
    </Box>
  );
};
export default DashboardContainer;
