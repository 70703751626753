import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({

  imageColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
    alignItems: 'center',
  },
  fieldContainer: {
    marginBottom: '16px',
  },
  imageContainer: {
    height: '151px',
    width: '151px',
    borderRadius: '20px',
    marginBottom: '42px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'relative',
    '&:hover': {
      '& $imageHoverContainer': {
        opacity: '1',
      },
    },
  },
  imageHoverContainer: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  multilineFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '50vw',
  },
  fieldsColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '30vw',
  },
  imageFieldsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '50vw',
    marginBottom: '24px',
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
