import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  dialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '20px',
    borderRadius: '12px',
  },
  closeDialogIconButton: {
    alignSelf: 'flex-end',
    color: '#000',
  },
  errorText: {
    color: 'primary',
    marginBottom: '20px',
    alignSelf: 'center',
  },
  canselDialogButton: {
    alignSelf: 'flex-end',
    padding: '0',
  },
  closeDialogButton: {
    alignSelf: 'center',
  },
  spinnerWrapper: {
    marginTop: '30px',
    marginBottom: '30px',
    padding: '0 20px',
    display: 'flex',
    flexFlow: 'wrap row',
    justifyContent: 'space-evenly',
  },
});
