import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

const Spinner = () => {
  const classes = useStyles();

  return (
    <Box className={classes.spinnerWrapper}>
      {
            [...Array(5)].map(() => (
              <div key={uuidv4()} className={classes.spinner}>
                <div className={classes.spinnerItem} />
                <div className={classes.spinnerItem} />
                <div className={classes.spinnerItem} />
                <div className={classes.spinnerItem} />
              </div>
            ))
        }
    </Box>
  );
};

export default Spinner;
