import { gql } from '@apollo/client';

export const GET_ALL_ORGANISATIONS = gql`
  query allOrganisations(
    $projectId: ID!
  ) {
    allOrganisations(projectId: $projectId) {
      totalCount
    }
  }
`;

export const GET_ALL_LINES = gql`
query allLines(
  $offset: Int, 
  $first: Int, 
  $orderBy: String, 
  $stringId_Icontains: String, 
  $projectId: ID!,
  $section_Id: [ID], 
  $character_Id: [ID]
  ) {
  allLines(
    offset: $offset
    first: $first
    orderBy: $orderBy
    stringId_Icontains: $stringId_Icontains
    projectId: $projectId
    section_Id: $section_Id
    character_Id: $character_Id
  ) {
    totalCount
    edges {
      node {
        id
        stringId
        character {
          name
          id
          description
          notes
        }
        script {
          name
        }
        section {
          name
          id
          description
          notes
        }
        localisedassetSet {
          edges {
            node {
              line {
                stringId
              }
              language {
                languageFlag
                languageCode
                name
              }
              currentSelect {
                text
                status
                revisionIndex
                soundFile {
                  soundFilePath
                  soundMetadata {
                    length
                  }
                }
                id
              }
              localisedassetrevisionSet {
                totalCount
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_ALL_CHARACTERS = gql`
  query allCharacters($offset: Int, $first: Int, $orderBy: String, $projectId: ID!) {
    allCharacters(offset: $offset, first: $first, orderBy: $orderBy, projectId: $projectId) {
      edges {
        node {
          id
          name
          description
          notes
        }
      }
      totalCount
    }
  }
`;

export const GET_ALL_SECTIONS = gql`
  query allSections($offset: Int, $first: Int, $orderBy: String, $projectId: ID!) {
    allSections(offset: $offset, first: $first, orderBy: $orderBy, projectId: $projectId) {
      edges {
        node {
          id
          name
          description
          notes
        }
      }
      totalCount
    }
  }
`;

export const GET_ALL_LANGUAGES = gql`
  query allLanguages($orderBy: String, $projectId: ID!) {
    allLanguages(orderBy: $orderBy, projectId: $projectId) {
      edges {
        node {
          id
          name
          languageCode
          textOnly
          languageFlag
        }
      }
    }
  }
`;

export const GET_SCRIPT_ID = gql`
  query getScriptId ($projectId: ID!) {
    project (id: $projectId) {
          scriptSet {
            edges {
              node {
                id
              }
            }
          }
    }
  }
`;

export const GET_PRIMARY_LANGUAGE = gql`
  query projectPrimaryLanguage($projectId: ID!) {
    project(id: $projectId) {
      currentScript {
        primaryLanguage {
          id
        }
      }
    }
  }
`;

export const GET_LINE = gql`
query line($id: ID!) {
  line(id: $id) {
    stringId
    character {
      name
      id
      description
      notes
    }
    section {
      name
      id
      description
      notes
    }
    localisedassetSet {
      edges {
        node {
          id
          currentSelect {
            id
          }
          language {
            id
            name
            languageFlag
          }
          localisedassetrevisionSet {
            edges {
              node {
                id
                revisionIndex
                text
                status
                notes
                revisionDate
                soundFile {
                  id
                  soundFilePath
                  isRobovoice
                  soundMetadata {
                    sampleRate
                    bitDepth
                    numberOfChannels
                    length
                    trailingSilenceFront
                    trailingSilenceBack
                    peakLevel
                    perceivedLoudness
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_LINES_COUNT = gql`
  query allLinesCount($projectId: ID!) {
    allLines(projectId: $projectId) {
      totalCount
    }
  }
`;

export const GET_LANGUAGES_COUNT = gql`
  query allLanguagesCount($projectId: ID!) {
    allLanguages(projectId: $projectId) {
      totalCount
    }
  }
`;

export const GET_SECTIONS_COUNT = gql`
  query allSectionsCount($projectId: ID!) {
    allSections(projectId: $projectId) {
      totalCount
    }
  }
`;

export const GET_CHARACTERS_COUNT = gql`
  query allCharactersCount($projectId: ID!) {
    allCharacters(projectId: $projectId) {
      totalCount
    }
  }
`;

export const GET_ALL_CURRENT_REVISIONS = gql`
  query allCurrentLocalisedAssetRevisions(
    $after: String
    $before: String
    $bitDepthFilter: String
    $channelsFilter: String
    $characterId: [ID]
    $first: Int
    $languageId: [ID]
    $last: Int
    $lengthFilter: String
    $localisedAsset_Line_StringId_Icontains: String
    $offset: Int
    $orderBy: String
    $peakLevelFilter: String
    $perceivedLoudnessFilter: String
    $sampleRateFilter: String
    $sectionId: [ID]
    $trailingSilenceBackFilter: String
    $trailingSilenceFrontFilter: String
    $projectId: ID!
  ) {
    allCurrentLocalisedAssetRevisions(
      after: $after
      before: $before
      bitDepthFilter: $bitDepthFilter
      channelsFilter: $channelsFilter
      characterId: $characterId
      first: $first
      languageId: $languageId
      last: $last
      lengthFilter: $lengthFilter
      localisedAsset_Line_StringId_Icontains: $localisedAsset_Line_StringId_Icontains
      offset: $offset
      orderBy: $orderBy
      peakLevelFilter: $peakLevelFilter
      perceivedLoudnessFilter: $perceivedLoudnessFilter
      sampleRateFilter: $sampleRateFilter
      sectionId: $sectionId
      trailingSilenceBackFilter: $trailingSilenceBackFilter
      trailingSilenceFrontFilter: $trailingSilenceFrontFilter
      projectId: $projectId
    ) {
      edges {
        node {
          id
          localisedAsset {
            line {
              stringId
            }
            language {
              languageCode
              languageFlag
            }
          }
          soundFile {
            id
            soundFilePath
            soundMetadata {
              sampleRate
              bitDepth
              numberOfChannels
              length
              trailingSilenceBack
              trailingSilenceFront
              peakLevel
              perceivedLoudness
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_PROJECT = gql`
query project($id: ID!) {
  project(id: $id) {
    id
    name
    description
    projectNotes
    licenseKey
    startDate
    endDate
    logoUrl
    organisation {
      name
    }
    scriptSet {
      edges {
        node {
          id
        }
      }
    }
  }
}
`;

export const GET_ALL_TODOS = gql`
        query getAllTodos(
        $after: String,
        $before: String,
        $first: Int,
        $last: Int,
        $offset: Int,
        $orderBy: String,
        $projectId: ID!
        ) {
    allToDoItems(
          after: $after,
          before: $before,
          first: $first,
          last: $last,
          offset: $offset,
          orderBy: $orderBy,
          projectId: $projectId
          ) {
      edges {
        node {
          id
          title
          dueDate
          isDone
          description
        }
      }
    }
  }
`;

export const GET_PRESIGNED_SOUNDFILE_URL_FOR_UPLOAD = gql`
query getPresignedSoundfileUrlUpload($projectId: ID!) {
  getPresignedSoundfileUrlUpload(projectId: $projectId) {
    awsResponse
    fileExists
    fileId
  }
}
`;

export const GET_RECORDINGS_AND_TEXT_DATA = gql`
  query getRecordingsAndTextData($projectId: ID!) {
  countTotalLines(projectId: $projectId)
  countTotalFinishedLinesReferenceLanguageText(projectId: $projectId)
  countTotalLinesReferenceLanguageRecordings(projectId: $projectId)
  countTotalFinishedLinesReferenceLanguageRecordings(projectId: $projectId)
  countTotalLinesTranslationsText(projectId: $projectId)
  countTotalFinishedLinesTranslationsText(projectId: $projectId)
  countTotalLinesTranslationsRecordings(projectId: $projectId)
  countTotalFinishedLinesTranslationsRecordings(projectId: $projectId)
}
`;

export const GET_PRESIGNED_SOUNDFILE_DOWNLOAD_URI = gql`
query getPresignedSoundfileUrlDownload($uuid: String!, $projectId: ID! ) {
  getPresignedSoundfileUrlDownload(uuid: $uuid, projectId: $projectId) {
    awsResponse
    fileExists
    fileId
  }
}
`;

export const GET_ALL_COMPARATIVE_REVISIONS = gql`
query allCurrentLocalisedAssetComparativeRevisions(
    $after: String,
    $before: String, 
    $characterId: [ID], 
    $first: Int, 
    $languageId: [ID], 
    $last: Int, 
    $localisedAsset_Line_StringId_Icontains: String, 
    $offset: Int, 
    $orderBy: String, 
    $sectionId: [ID], 
    $bitDepthEqualityFilter: Boolean, 
    $channelEqualityFilter: Boolean, 
    $sampleRateEqualityFilter: Boolean, 
    $lengthDifferenceFilter: String, 
    $peakLevelDifferenceFilter: String, 
    $perceivedLoudnessDifferenceFilter: String, 
    $trailingSilenceBackDifferenceFilter: String, 
    $trailingSilenceFrontDifferenceFilter: String
    $projectId: ID!
    ) {
  allCurrentLocalisedAssetComparativeRevisions(
    after: $after
    before: $before
    characterId: $characterId
    first: $first
    languageId: $languageId
    last: $last
    offset: $offset
    orderBy: $orderBy
    sectionId: $sectionId
    localisedAsset_Line_StringId_Icontains: $localisedAsset_Line_StringId_Icontains
    bitDepthEqualityFilter: $bitDepthEqualityFilter
    channelEqualityFilter: $channelEqualityFilter
    lengthDifferenceFilter: $lengthDifferenceFilter
    peakLevelDifferenceFilter: $peakLevelDifferenceFilter
    perceivedLoudnessDifferenceFilter: $perceivedLoudnessDifferenceFilter
    sampleRateEqualityFilter: $sampleRateEqualityFilter
    trailingSilenceBackDifferenceFilter: $trailingSilenceBackDifferenceFilter
    trailingSilenceFrontDifferenceFilter: $trailingSilenceFrontDifferenceFilter
    projectId: $projectId
  ) {
    edges {
      node {
        text
        status
        referenceAsset {
          id
          status
          revisionIndex
          revisionDate
          text
          localisedAsset {
            line {
              stringId
            }
            language {
              languageCode
              languageFlag
            }
            currentSelect {
              soundFile {
                soundFilePath
              }
            }
          }
        }
        id
        revisionIndex
        numberOfChannelsEqual
        peakLevelDifference
        perceivedLoudnessDifference
        referenceBitDepth
        referenceLength
        referenceNumberOfChannels
        referencePeakLevel
        referencePerceivedLoudness
        referenceTrailingSilenceBack
        referenceTrailingSilenceFront
        referenceSampleRate
        referenceRootMeanSquareLoudness
        soundFile {
          soundFilePath
          soundMetadata {
            peakLevel
            numberOfChannels
            length
            bitDepth
            perceivedLoudness
            sampleRate
            trailingSilenceBack
            trailingSilenceFront
          }
          md5Hash
          id
        }
        localisedAsset {
          id
          line {
            stringId
            script {
              id
            }
          }
          language {
            languageCode
            languageFlag
          }
        }
      }
    }
    totalCount
  }
}
`;

export const SCRIPT_EXPORT_REQUEST_STATUS = gql`
query scriptExportRequestStatus($id: ID!) {
  scriptExportRequestStatus(id: $id) {
    createdAt
    hasFailed
    id
    isFinished
    s3DownloadLink
    s3FileExists
  }
}
`;

export const RECORDING_EXPORT_REQUEST_STATUS = gql`
query recordingExportRequestStatus($id: ID!) {
  recordingExportRequestStatus(id: $id) {
    createdAt
    id
    hasFailed
    isFinished
    nProcessedRecordings
    nTotalRecordings
    s3DownloadLink
    s3FileExists
    updatedAt
  }
}
`;

export const GET_PRESIGNED_URL_FOR_IMPORT = gql`
query getPresignedUrlForImport($extension: String! $projectId: ID!) {
  getPresignedUrlForImport(extension: $extension, projectId: $projectId) {
    awsResponse
    importRequestId
  }
}
`;

export const SCRIPT_IMPORT_REQUEST_STATUS = gql`
query scriptImportRequestStatus($id: ID!) {
  scriptImportRequestStatus(id: $id) {
    createdAt
    hasFailed
    id
    isFinished
    nRevisionsAdded
    updatedAt
    progressText
    errorMessage
  }
}
`;

export const RECORDING_IMPORT_REQUEST_STATUS = gql`
query recordingImportRequestStatus($id: ID!) {
  recordingImportRequestStatus(id: $id) {
    errorMessage
    hasFailed
    isFinished
    id
    nProcessedLines
    nTotalRecordings
    progressText
  }
}
`;

export const GET_URL_FOR_UPLOADING_LOGO = gql`
query getUrlForUploadingLogo($extension: String!, $projectId: ID!) {
    getLogoPresignedUrlForUpload(extension: $extension, projectId: $projectId) {
        awsResponse
        extension
        logoId
    }
}
`;

export const FIX_UP_REQUEST_STATUS = gql`
query fixUpRequestStatus($id: ID!) {
  fixUpRequestStatus(id: $id) {
    errorMessage
    hasFailed
    id
    isFinished
    nFailedFiles
    nProcessedFiles
    nSucceededFiles
    progressText
    nTotalFilesToProcess
  }
}
`;

export const GET_ALL_CURRENT_REVISIONS_IDS = gql`
query allCurrentLocalisedAssetRevisionsIds(
    $after: String
    $before: String
    $bitDepthFilter: String
    $channelsFilter: String
    $characterId: [ID]
    $first: Int
    $languageId: [ID]
    $last: Int
    $lengthFilter: String
    $localisedAsset_Line_StringId_Icontains: String
    $offset: Int
    $orderBy: String
    $peakLevelFilter: String
    $perceivedLoudnessFilter: String
    $sampleRateFilter: String
    $sectionId: [ID]
    $trailingSilenceBackFilter: String
    $trailingSilenceFrontFilter: String
    $projectId: ID!
  ) {
    allCurrentLocalisedAssetRevisions(
      after: $after
      before: $before
      bitDepthFilter: $bitDepthFilter
      channelsFilter: $channelsFilter
      characterId: $characterId
      first: $first
      languageId: $languageId
      last: $last
      lengthFilter: $lengthFilter
      localisedAsset_Line_StringId_Icontains: $localisedAsset_Line_StringId_Icontains
      offset: $offset
      orderBy: $orderBy
      peakLevelFilter: $peakLevelFilter
      perceivedLoudnessFilter: $perceivedLoudnessFilter
      sampleRateFilter: $sampleRateFilter
      sectionId: $sectionId
      trailingSilenceBackFilter: $trailingSilenceBackFilter
      trailingSilenceFrontFilter: $trailingSilenceFrontFilter
      projectId: $projectId
    )  {
    edges {
      node {
        id
        soundFile {
          id
        }
      }
    }
    pageInfo {
      hasNextPage
    }
    totalCount
  }
}
`;
export const GET_ALL_CURRENT_COMPARATIVE_REVISIONS_IDS = gql`
query allCurrentLocalisedAssetComparativeRevisionsIds(
    $after: String,
    $before: String, 
    $characterId: [ID], 
    $first: Int, 
    $languageId: [ID], 
    $last: Int, 
    $localisedAsset_Line_StringId_Icontains: String, 
    $offset: Int, 
    $orderBy: String, 
    $sectionId: [ID], 
    $bitDepthEqualityFilter: Boolean, 
    $channelEqualityFilter: Boolean, 
    $sampleRateEqualityFilter: Boolean, 
    $lengthDifferenceFilter: String, 
    $peakLevelDifferenceFilter: String, 
    $perceivedLoudnessDifferenceFilter: String, 
    $trailingSilenceBackDifferenceFilter: String, 
    $trailingSilenceFrontDifferenceFilter: String,
    $projectId: ID!
    ) {
  allCurrentLocalisedAssetComparativeRevisions(
    after: $after
    before: $before
    characterId: $characterId
    first: $first
    languageId: $languageId
    last: $last
    offset: $offset
    orderBy: $orderBy
    sectionId: $sectionId
    localisedAsset_Line_StringId_Icontains: $localisedAsset_Line_StringId_Icontains
    bitDepthEqualityFilter: $bitDepthEqualityFilter
    channelEqualityFilter: $channelEqualityFilter
    lengthDifferenceFilter: $lengthDifferenceFilter
    peakLevelDifferenceFilter: $peakLevelDifferenceFilter
    perceivedLoudnessDifferenceFilter: $perceivedLoudnessDifferenceFilter
    sampleRateEqualityFilter: $sampleRateEqualityFilter
    trailingSilenceBackDifferenceFilter: $trailingSilenceBackDifferenceFilter
    trailingSilenceFrontDifferenceFilter: $trailingSilenceFrontDifferenceFilter
    projectId: $projectId
  ) {
    edges {
      node {
        id
        localisedAsset {
          line {
            script {
              id
            }
          }
        }
      }
    }
    totalCount
    pageInfo {
      hasNextPage
    }
  }
}
`;

export const GET_TEXT_STATUS_BY_LANGUAGE = gql`
query getTextStatusByLanguage($projectId: ID!) {
    textStatusByLanguage(projectId: $projectId) {
        language {
            name
       }
       nTextReady
       nTextPlaceholder
       nTextNone
    }
}
`;

export const GET_RECORDING_STATUS_BY_LANGUAGE = gql`
query getRecordingStatusByLanguage($projectId: ID!) {
    recordingStatusByLanguage(projectId: $projectId) {
        language {
            name
       }
       nRecordingsPlaceholder
       nRecordingsFinished
       nRecordingsNone
    }
}
`;

export const GET_CURRENT_USER_PROJECTS = gql`
query getCurrentUserProjects {
  currentUser {
    projects {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
`;

export const ROBOVOICE_GENERATION_REQUEST_STATUS = gql`
query robovoiceGenerationRequestStatus($id: ID!) {
  robovoiceGenerationRequestStatus(id: $id) {
    errorMessage
    hasFailed
    id
    isFinished
    nFailedFiles
    nProcessedFiles
    nSucceededFiles
    nTotalFilesToProcess
    progressText
  }
}
`;

export const RECORDING_STATUS_BY_LANGUAGE = gql`
query recordingStatusByLanguage($projectId: ID!) {
  recordingStatusByLanguage(projectId: $projectId) {
    language {
      languageCode
      languageFlag
      id
      name
      textOnly
    }
    nRecordingsFinished
    nRecordingsNone
    nRecordingsPlaceholder
  }
}
`;

export const GET_CURRENT_USER = gql`
query getUserQuery {
 currentUser {
    requiresPasswordReset
    isActive
    id
    email
  }
}
`;

export const GET_USERS_AND_PERMISSIONS = gql`
query getUsersAndPermissionsForProject($projectId: ID!) {
  project(id: $projectId) {
    users {
      edges {
        node {
          permissionAttributeGroups {
            edges {
              node {
                name
                label
                description
                id
              }
            }
          }
          email
          id
          isActive
          requiresPasswordReset
        }
      }
    }
  }
}
`;

export const GET_PERMISSION_GROUPS_FOR_PROJECT = gql`
query getPermissionGroupsForProject($projectId: ID!) {
  getPermissionGroupsForProject(projectId: $projectId) {
    edges {
      node {
        id
        label
        name
        description
      }
    }
  }
}
`;

export const GET_CURRENT_USER_PERMISSIONS = gql`
query currentUserProjectPermissions($projectId: ID!) {
  currentUserProjectPermissions(projectId: $projectId) {
    id
    label
    name
    description
  }
}
`;
