import React, { useMemo } from 'react';
import {
  Box, IconButton, TableCell, TableRow, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';

import { useStyles } from './styles';
import { IUserManagementRowProps } from './types';
import {
  getUsersAndPermissionsForProject_project_users_edges_node_permissionAttributeGroups_edges,
} from '../../../graphql/queries/__generated__/getUsersAndPermissionsForProject';
import { admin } from '../../../constants/user';
import { capitalizeFirstLetter } from '../../../helpers';

const UserManagementRow: React.FC<IUserManagementRowProps> = ({
  data,
  adminsCount = 0,
  setIsEditDialogOpen,
  setEditableUserData,
  setIsDeleteDialogOpen,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const permissionGroups = data?.permissionAttributeGroups.edges;

  const isAdmin = useMemo(() => !!permissionGroups?.filter((item) => (item?.node?.name === admin)).length, [data]);

  const handleEditUser = (
    email: string,
    id: string,
    permissions: (getUsersAndPermissionsForProject_project_users_edges_node_permissionAttributeGroups_edges | null)[],
  ) => {
    setIsEditDialogOpen(true);
    setEditableUserData({ email, id, permissionSets: permissions.map((item) => item?.node?.id || '') });
  };

  const handleDeleteUser = (
    email: string,
    id: string,
    permissions: (getUsersAndPermissionsForProject_project_users_edges_node_permissionAttributeGroups_edges | null)[],
  ) => {
    setIsDeleteDialogOpen(true);
    setEditableUserData({ email, id, permissionSets: permissions.map((item) => item?.node?.id || '') });
  };

  return (
    <TableRow>
      <TableCell className={classes.alignItems}>
        <Typography sx={{ marginLeft: '5px' }}>
          {data?.email || ''}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Typography>
          {
            permissionGroups?.map((item) => (item?.node?.name !== admin
              ? t('USER_MANAGEMENT_PAGE.TABLE.MEMBER')
              : capitalizeFirstLetter(item?.node?.name)))
              .join(', ')
          }
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Typography>
          {permissionGroups?.map((item) => item?.node?.label || '').join(', ')}
        </Typography>
      </TableCell>
      <TableCell className={classes.topAndBottomPadding}>
        <Box>
          <IconButton onClick={() => handleEditUser(
            data?.email || '',
            data?.id || '',
            permissionGroups || [],
          )}
          >
            <EditIcon />
          </IconButton>
          {
            isAdmin
              ? (adminsCount > 1
            && (
            <IconButton onClick={() => handleDeleteUser(
              data?.email || '',
              data?.id || '',
              permissionGroups || [],
            )}
            >
              <TrashIcon style={{ stroke: '#1F224C' }} />
            </IconButton>
            ))
              : (
                <IconButton onClick={() => handleDeleteUser(
                  data?.email || '',
                  data?.id || '',
                  permissionGroups || [],
                )}
                >
                  <TrashIcon style={{ stroke: '#1F224C' }} />
                </IconButton>
              )
          }
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default UserManagementRow;
