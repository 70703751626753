import React, { useContext } from 'react';
import {
  Box, Dialog, DialogActions, DialogContent, Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { StyledButton } from '../../../../common/StyledButton';
import { IDeleteRevisionDialogProps } from './types';
import { DELETE_REVISION } from '../../../../graphql/mutations';
import {
  GET_LINE,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_RECORDINGS_AND_TEXT_DATA,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../../graphql/queries';
import { useStyles } from './styles';
import { deleteLocalisedAssetRevision as deleteLocalisedAssetRevisionProps }
  from '../../../../graphql/mutations/__generated__/deleteLocalisedAssetRevision';
import { RevisionContext } from '../../../../context/RevisionContext/RevisionContext';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../../constants/localStorage';

const DeleteRevisionDialog: React.FC<IDeleteRevisionDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const { id } = useParams();
  const revisionContext = useContext(RevisionContext);

  const classes = useStyles();

  const [deleteLocalisedAssetRevision] = useMutation<deleteLocalisedAssetRevisionProps>(
    DELETE_REVISION,
    {
      refetchQueries: [{ query: GET_LINE, variables: { id } },
        { query: GET_TEXT_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDING_STATUS_BY_LANGUAGE, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        { query: GET_RECORDINGS_AND_TEXT_DATA, variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
        'line'],
    },
  );

  const handleDeleteRevision = async (revisionId: string | undefined) => {
    await deleteLocalisedAssetRevision({
      variables:
        { localisedAssetRevisionDeletionData: { id: revisionId } },
    });
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleDialogChange();
      }}
      fullWidth
      maxWidth="md"
    >
      <Box className={classes.dialogContainer}>
        <DialogContent>
          <Typography>
            Are you sure you want to delete revision
            {' '}
            {revisionContext?.revisionData.revisionIndex}
            {' '}
            and all its contents?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <StyledButton
            variant="outlined"
            width="210px"
            label="No"
            action={() => {
              handleDialogChange();
            }}
          />
          <StyledButton
            width="210px"
            variant="contained"
            label="Yes"
            action={async () => handleDeleteRevision(revisionContext?.revisionData.id)}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteRevisionDialog;
