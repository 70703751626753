import { makeStyles } from '@mui/styles';
import { secondary, secondaryLight } from '../../../constants/colors';

export const useStyles = makeStyles({

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30vw',
  },
  fixSection: {
    alignSelf: 'self-end',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '28px',
    gap: '10px',
  },
  linkButton: {
    color: secondaryLight,
    textDecoration: 'none',
  },
  circularProgressWrapper: {
    color: secondary,
    width: '30px !important',
    height: '30px !important',
    margin: '0 10px !important',
  },
});
