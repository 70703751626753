import { makeStyles } from '@mui/styles';
import { darkBlue, lightBlue, white } from '../../../constants/colors';

export const useStyles = makeStyles(({
  tableText: {
    paddingTop: '16px',
    paddingBottom: '18px',
  },
  whiteRow: {
    backgroundColor: white,
  },
  lightBlueRow: {
    backgroundColor: lightBlue,
  },
  arrowDownIcon: {
    transform: 'rotate(180deg)',
  },
  darkBlueRow: {
    backgroundColor: darkBlue,
  },
  iconMargin: {
    marginRight: '8px',
  },
}));
