import React, { useContext } from 'react';
import { Box } from '@mui/material';

import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { DashboardContainer } from '../../components/Dashboard/DashboardContainer';
import { TodoContext } from '../../context/TodoContext/TodoContext';
import { AddTodoDialog } from '../../components/TodoList/AddTodoDialog';

const Dashboard: React.FC = () => {
  document.body.style.background = '#fff';

  const context = useContext(TodoContext);

  return (
    <>
      <Header />
      <Box sx={{ display: 'flex' }}>
        <NavBar drawerList={<MenuList />} drawerWidth={230} />
        <Box component="main" sx={{ flexGrow: 1, p: '80px 10px 10px 10px' }}>
          <DashboardContainer />
        </Box>
      </Box>
      {context
      && (
      <AddTodoDialog
        open={context?.open}
        setOpen={context?.setOpen}
        handleDialogChange={context?.handleDialogChange}
      />
      )}
    </>
  );
};
export default Dashboard;
