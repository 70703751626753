import { makeStyles } from '@mui/styles';
import { secondary } from '../../../constants/colors';

export const useStyles = makeStyles({
  fieldsWrapper: {
    display: 'flex',
    flexFlow: 'wrap column',
    alignItems: 'start',
  },
  tableOperationSectionWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectsWrapper: {
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'baseline',
    justifyContent: 'start',
    marginTop: '36px',
  },
  select: {
    margin: '15px 40px 0 0',
  },
  tablesWrapper: {
    marginTop: '48px',
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'center',
  },
  tableSection: {
    marginRight: '41px',
    marginBottom: '31px',
  },
  flexCenterAlign: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
  },
  checkbox: {
    marginLeft: '-9px',
    '&.Mui-checked': {
      color: secondary,
    },
  },
});
