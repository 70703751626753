import { makeStyles } from '@mui/styles';
import { info, secondary } from '../../constants/colors';

export const useStyles = makeStyles(({
  listItemButton: {
    color: info,
    paddingBottom: '14px',
    paddingTop: '14px',
    '&.Mui-selected': {
      color: secondary,
      backgroundColor: 'transparent',
    },
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  listItemIcon: {
    fill: info,
    // stroke: info,
    minWidth: 'auto',
    marginRight: '16px',
  },
  listItemIconSelected: {
    minWidth: 'auto',
    fill: secondary,
    // stroke: secondary,
    marginRight: '20px',
  },
  iconUpload: {
    stroke: info,
    minWidth: 'auto',
    marginRight: '20px',
  },
  iconUploadSelected: {
    stroke: secondary,
    minWidth: 'auto',
    marginRight: '20px',
  },
  collapseBox: {
    display: 'flex',
    flexFlow: 'wrap row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  childNavList: {
    padding: '0 15px',
  },
  childListItemButton: {
    color: info,
    padding: '6px, 8px',
    borderRadius: '8px',
    '&.Mui-selected': {
      color: secondary,
      backgroundColor: 'rgba(255, 107, 64, 0.12)',
    },
  },
}));
