import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  dialogWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    padding: '30px',
    borderRadius: '12px',
  },
  closeDialogIconButton: {
    alignSelf: 'flex-end',
    color: '#000',
  },
  listWrapper: {
    margin: '0',
  },
  passwordInputsWrapper: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    margin: '20px 0',
    '&>div': {
      margin: '10px 0',
      width: '80%',
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
