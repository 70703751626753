import React from 'react';
import { Box, Typography } from '@mui/material';
import { IFixDialogResultProps } from './types';
import { lightGray } from '../../../constants/colors';
import { StyledButton } from '../../StyledButton';
import { Spinner } from '../../Spinner';
import { useStyles } from './styles';

const FixDialogResult: React.FC<IFixDialogResultProps> = ({
  handleClose,
  fixUpStatusData,
}) => {
  const classes = useStyles();

  if (fixUpStatusData?.fixUpRequestStatus?.hasFailed) {
    return (
      <Box className={classes.resultDialogWrapper}>
        <Typography variant="h3" className={classes.text}>Fixing is finished</Typography>
        <Typography className={classes.text} color={lightGray}>
          {fixUpStatusData?.fixUpRequestStatus?.nSucceededFiles}
          /
          {fixUpStatusData?.fixUpRequestStatus?.nProcessedFiles}
          {fixUpStatusData?.fixUpRequestStatus?.nSucceededFiles === 1 ? ' file' : ' files'}
          {' '}
          processed
        </Typography>
        <Typography className={classes.text} color={lightGray}>
          {fixUpStatusData?.fixUpRequestStatus?.nFailedFiles}
          /
          {fixUpStatusData?.fixUpRequestStatus?.nProcessedFiles}
          {fixUpStatusData?.fixUpRequestStatus?.nFailedFiles === 1 ? ' file' : ' files'}
          {' '}
          failed
        </Typography>
        <Box className={classes.closeDialogButton}>
          <StyledButton
            width="74px"
            action={handleClose}
            variant="outlined"
            label="Close"
          />
        </Box>
      </Box>
    );
  }

  if (fixUpStatusData?.fixUpRequestStatus?.isFinished && !fixUpStatusData?.fixUpRequestStatus?.hasFailed) {
    return (
      <Box className={classes.resultDialogWrapper}>
        <Typography variant="h3" className={classes.text}>Fixing is finished</Typography>
        <Typography className={classes.text} color={lightGray}>
          {fixUpStatusData?.fixUpRequestStatus?.nSucceededFiles}
          /
          {fixUpStatusData?.fixUpRequestStatus?.nProcessedFiles}
          {fixUpStatusData?.fixUpRequestStatus?.nSucceededFiles === 1 ? ' file' : ' files'}
          {' '}
          processed
        </Typography>
        <Box className={classes.closeDialogButton}>
          <StyledButton
            width="74px"
            action={handleClose}
            variant="outlined"
            label="Close"
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.resultDialogWrapper}>
      <Typography className={classes.loadingDialogText}>Processing</Typography>
      <Box className={classes.spinnerWrapper}>
        <Spinner />
      </Box>
      <Typography className={classes.loadingDialogText}>
        {(fixUpStatusData?.fixUpRequestStatus?.nProcessedFiles || 0) + (fixUpStatusData?.fixUpRequestStatus?.nFailedFiles || 0)}
        /
        {fixUpStatusData?.fixUpRequestStatus?.nTotalFilesToProcess || '0'}
        {' '}
        files processed
      </Typography>
      <Box className={classes.canselDialogButton}>
        <StyledButton
          width="74px"
          action={handleClose}
          variant="outlined"
          label="Cancel"
        />
      </Box>
    </Box>
  );
};

export default FixDialogResult;
