import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '40px',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30vw',
  },

});
