import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  toolbar: {
    marginLeft: '10vw',
    marginRight: '7vw',
    paddingTop: '10px',
    paddingBottom: '10px',
    justifyContent: 'space-between',
  },
  headerText: {
    marginRight: '5vw',
  },
  disableLinkDecoration: {
    textDecoration: 'none',
  },
  linksWrapper: {
    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  logoImage: {
    width: '100px',
  },
  appBar: {
    background: 'linear-gradient(120.85deg, #11112E 13.86%, #232755 74.76%);',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25);',
  },

}));
