import { useQuery } from '@apollo/client';
import { getPresignedSoundfileUrlDownload } from '../graphql/queries/__generated__/getPresignedSoundfileUrlDownload';
import { GET_PRESIGNED_SOUNDFILE_DOWNLOAD_URI } from '../graphql/queries';
import { getFileUuidFromPath } from '../helpers';
import { LOCALE_STORAGE_PROJECT_ID } from '../constants/localStorage';

const useAswFileLink = (srcFile: string) => {
  const { data: fileData, error } = useQuery<getPresignedSoundfileUrlDownload>(
    GET_PRESIGNED_SOUNDFILE_DOWNLOAD_URI,
    {
      variables: { uuid: getFileUuidFromPath(srcFile), projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) },
    },
  );

  return fileData?.getPresignedSoundfileUrlDownload && !error
    ? fileData.getPresignedSoundfileUrlDownload
    : { awsResponse: '', fileExists: false };
};

export default useAswFileLink;
