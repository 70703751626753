import React, { useContext, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { IDeleteLanguageDialogProps } from './types';
import { LangContext } from '../../../context/LangContext/LangContext';
import { DELETE_LANGUAGE } from '../../../graphql/mutations';
import { deleteLanguage as deleteLanguageProps } from '../../../graphql/mutations/__generated__/deleteLanguage';
import {
  GET_ALL_LANGUAGES,
  GET_LANGUAGES_COUNT,
  GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { useStyles } from './styles';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const DeleteLanguageDialog: React.FC<IDeleteLanguageDialogProps> = ({
  handleDialogChange,
  open,
  setOpen,
}) => {
  const context = useContext(LangContext);
  const [isCompletlyDeleting, setIsCompletlyDeleting] = useState(false);

  const classes = useStyles();

  const [deleteLanguage] = useMutation<deleteLanguageProps>(DELETE_LANGUAGE, {
    refetchQueries: [
      {
        query: GET_ALL_LANGUAGES,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_LANGUAGES_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_TEXT_STATUS_BY_LANGUAGE,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_RECORDING_STATUS_BY_LANGUAGE,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allLanguages',
    ],
  });

  const handleDeleteLanguage = (id:string | undefined, deleteAssociatedAssets: boolean) => {
    deleteLanguage({ variables: { languageDeletionData: { id, deleteAssociatedAssets } } });
    setOpen(false);
    setIsCompletlyDeleting(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleDialogChange();
        setIsCompletlyDeleting(false);
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        className={classes.dialogContainer}
      >
        <DialogContent>
          {isCompletlyDeleting ? (
            <Typography>
              You are about to delete
              {' '}
              {context?.languageData.name}
              {' '}
              and all its related assets. This cannot be reversed.
              Are you sure you want to continue?

            </Typography>
          ) : (
            <Typography>
              You are about to delete
              {' '}
              {context?.languageData.name}
              {' '}
              from the project that still has assets relating
              to it. Are you absolutely sure you want to delete this language? This cannot be undone.
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          { isCompletlyDeleting ? (
            <StyledButton
              width="210px"
              variant="outlined"
              label="Yes, and delete assets"
              action={() => handleDeleteLanguage(context?.languageData.id, true)}
            />
          ) : (
            <>
              <StyledButton
                width="210px"
                variant="outlined"
                label="Yes, but keep assets"
                action={() => handleDeleteLanguage(context?.languageData.id, false)}
              />
              <StyledButton
                width="210px"
                variant="outlined"
                label="Yes, and delete assets"
                action={() => setIsCompletlyDeleting(true)}
              />
            </>
          )}
          <StyledButton
            variant="contained"
            width="210px"
            label="Cancel"
            action={() => {
              handleDialogChange();
              setIsCompletlyDeleting(false);
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default DeleteLanguageDialog;
