import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '50px',
    marginTop: '56px',
    alignItems: 'center',
  },
}));
