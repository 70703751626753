import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { StyledButton } from '../../../common/StyledButton';
import { ILineRevisionContainerProps } from './types';
import RevisionTable from '../RevisionTable/RevisionTable';
import { useStyles } from './styles';
import { RevisionContext } from '../../../context/RevisionContext/RevisionContext';
import { defaultRevisionData } from '../../../constants/revisions';
import FlagVisualisation from '../../../common/FlagVisualisation/FlagVisualisation';

const LineRevisionContainer:React.FC<ILineRevisionContainerProps> = ({ data, setShouldRefetch }) => {
  const classes = useStyles();

  const context = useContext(RevisionContext);

  return (
    <Box sx={{ marginBottom: '50px' }}>
      <Box className={classes.revisionContainer}>
        <Typography variant="h3" className={classes.language}>
          <FlagVisualisation style={{ marginRight: '8px' }} optionFlag={data?.language?.languageFlag || ''} />
          {data?.language?.name}
        </Typography>
        {context && (
        <StyledButton
          action={() => {
            context.setRevisionData({
              ...defaultRevisionData,
              localised_asset: data?.id || '',
            });
            context?.setOpen(true);
            context?.setIsEditMode(false);
          }}
          label="Add Revision"
          variant="text"
          startIcon={<AddIcon style={{ color: '#FF6B40' }} />}
        />
        )}
      </Box>
      <RevisionTable
        data={data?.localisedassetrevisionSet.edges}
        localisedAssetId={data?.id || ''}
        currentSelectId={data?.currentSelect?.id || ''}
        setShouldRefetch={setShouldRefetch}
      />
    </Box>
  );
};
export default LineRevisionContainer;
