import { makeStyles } from '@mui/styles';
import { secondary } from '../../../constants/colors';

export const useStyles = makeStyles({
  uploadScriptFormWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '36px',
  },
  dropzoneWrapper: {
    flexGrow: '2',
    marginRight: '45px',
  },
  fileDropzone: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '322px',
    maxWidth: '508px',
    height: '48px',
    border: '1px dashed #656A73',
    padding: '45px 45px 45px 45px',
    borderRadius: '12px',
    cursor: 'pointer',
  },
  inputsWrapper: {
    display: 'flex',
    flexFlow: 'wrap column',
    alignItems: 'flex-start',
    flexGrow: '2',
  },
  selectsWrapper: {
    display: 'flex',
    flexFlow: 'row column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: {
    margin: '15px 40px 0 0',
  },
  checkbox: {
    marginLeft: '-9px',
    '&.Mui-checked': {
      color: secondary,
    },
  },
  buttonsWrapper: {
    alignSelf: 'self-end',
    marginTop: '45px',
  },
});
