import React, { useState } from 'react';
import { Box, TablePagination, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';
import CharSectTable from '../../Character/CharSectTable/CharSectTable';
import { GET_ALL_SECTIONS } from '../../../graphql/queries';
import { allSections } from '../../../graphql/queries/__generated__/allSections';
import { StyledButton } from '../../../common/StyledButton';
import { useStyles } from './styles';
import { CreateSectionDialog } from '../CreateSectionDialog';
import { DeleteSectionDialog } from '../DeleteSectionDialog';
import { Order } from './types';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';
import { PageLoader } from '../../../common/PageLoader';

const SectionTableContainer = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState<Order>('asc');

  const {
    data,
    loading,
    fetchMore,
  } = useQuery<allSections>(GET_ALL_SECTIONS, {
    variables: {
      offset: page * pageSize, first: pageSize, orderBy, projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    fetchPolicy: 'cache-and-network',
  });

  const classes = useStyles();

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(+event.target.value);
    setPage(0);
  };

  const handleDialogChange = () => {
    setOpen(!open);
  };

  const handleDeleteDialogChange = () => {
    setDeleteOpen(!deleteOpen);
  };

  if (loading) {
    return <PageLoader />;
  }
  return (
    <>
      <Box className={classes.container}>
        <Typography variant="h5">Sections</Typography>
        <StyledButton variant="contained" label={t('SECTIONS_PAGE.ADD_SECTION_BUTTON')} action={handleDialogChange} />
      </Box>
      {data?.allSections?.edges ? (
        <CharSectTable
          data={data.allSections.edges}
          setOpen={setOpen}
          setDeleteOpen={setDeleteOpen}
          isSection
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          order={order}
          setOrder={setOrder}
        />
      ) : null}
      {data?.allSections?.totalCount ? (
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          labelRowsPerPage=""
          component="div"
          count={data?.allSections?.totalCount}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onMouseOver={() => fetchMore({ variables: { offset: (page + 1) * pageSize, first: pageSize } })}
        />
      ) : null}
      <CreateSectionDialog open={open} setOpen={setOpen} handleDialogChange={handleDialogChange} />
      <DeleteSectionDialog
        open={deleteOpen}
        setOpen={setDeleteOpen}
        handleDialogChange={handleDeleteDialogChange}
      />
    </>
  );
};
export default SectionTableContainer;
