import { makeStyles } from '@mui/styles';

const animationDuration = 1000;
const size = 70;
const backgroundOrange = 'linear-gradient(158.89deg, #FD9A7F 13.93%, #FF7D45 119.28%)';
const backgroundYellow = 'linear-gradient(146.2deg, #FDDAA5 18.79%, #FFCE6F 70.08%), linear-gradient(147.97deg, #FDDAA5 18.02%, #FDC65B 46.42%), linear-gradient(146.2deg, #B482FC 18.79%, #702AD6 79.95%)';

export const useStyles = makeStyles({
  spinnerWrapper: {
    display: 'flex',
    flexFlow: 'wrap row',
  },
  spinner: {
    width: `${size}px`,
    height: '83px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  '@keyframes spinner': {
    '50%': {
      transform: 'scaleY(0.25)',
    },
  },

  spinnerItem: {
    width: `${size / 8}px`,
    height: '90%',
    borderRadius: '1.5px',
    animation: `$spinner ${animationDuration}ms ease-in-out infinite`,

    '&:nth-child(1)': {
      background: backgroundOrange,
      animationDelay: `${(animationDuration / 10) * -0}ms`,
    },
    '&:nth-child(2)': {
      background: backgroundYellow,
      animationDelay: `${(animationDuration / 10) * -1}ms`,
    },
    '&:nth-child(3)': {
      background: backgroundOrange,
      animationDelay: `${(animationDuration / 10) * -2}ms`,
    },
    '&:nth-child(4)': {
      background: backgroundYellow,
      animationDelay: `${(animationDuration / 10) * -3}ms`,
    },
  },

});
