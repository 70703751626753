import React, { useContext } from 'react';
import {
  TableCell,
  TableRow,
  Typography,
  Box,
  IconButton, Tooltip,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';
import { ICharSectRowProps } from './types';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { CharContext } from '../../../context/CharContext/CharContext';
import { SectionContext } from '../../../context/SectionContext/SectionContext';

const CharSectRow: React.FC<ICharSectRowProps> = ({
  item, isEven, setOpen, setDeleteOpen, isSection,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const characterContext = useContext(CharContext);
  const sectionContext = useContext(SectionContext);

  return (
    <React.Fragment key={uuidv4()}>
      {item && (
      <TableRow className={isEven ? classes.lightBlueRow : classes.whiteRow}>
        <TableCell className={classes.tableText} sx={{ width: '20%' }}>
          <Typography variant="subtitle2" display="inline">
            {item.name}
          </Typography>
        </TableCell>
        <TableCell padding="none" sx={{ width: '35%' }}>
          <Typography
            variant="subtitle2"
            display="inline"
            className={classes.tableText}
          >
            {item.description}
          </Typography>
        </TableCell>
        <TableCell padding="normal" sx={{ width: '35%' }}>
          <Typography
            variant="subtitle2"
            display="inline"
            className={classes.tableText}
          >
            {item.notes}
          </Typography>
        </TableCell>
        <TableCell padding="none" sx={{ width: '10%' }}>
          <Box>
            <Tooltip title={isSection ? t('SECTIONS_PAGE.EDIT_SECTION_TOOLTIP') : t('CHARACTERS_PAGE.EDIT_CHARACTER_TOOLTIP')}>
              <IconButton onClick={() => {
                if (isSection) {
                  sectionContext?.setSectionData({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    notes: item.notes,
                  });
                  sectionContext?.setIsEditMode(true);
                } else {
                  characterContext?.setCharacterData({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    notes: item.notes,
                  });
                  characterContext?.setIsEditMode(true);
                }
                setOpen(true);
              }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={isSection ? t('SECTIONS_PAGE.DELETE_SECTION_TOOLTIP') : t('CHARACTERS_PAGE.DELETE_CHARACTER_TOOLTIP')}>
              <IconButton onClick={() => {
                if (isSection) {
                  sectionContext?.setSectionData({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    notes: item.notes,
                  });
                } else {
                  characterContext?.setCharacterData({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    notes: item.notes,
                  });
                }
                setDeleteOpen(true);
              }}
              >
                <TrashIcon style={{ stroke: '#1F224C' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      )}
    </React.Fragment>
  );
};
export default CharSectRow;
