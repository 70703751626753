import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  alignItems: {
    '&>p': {
      display: 'inline-block',
    },
  },
  topAndBottomPadding: {
    padding: '15px 0',
  },
});
