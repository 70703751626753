import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/client';

import { StyledButton } from '../../../common/StyledButton';
import { getScriptId } from '../../../graphql/queries/__generated__/getScriptId';
import { CREATE_LANGUAGE, UPDATE_LANGUAGE } from '../../../graphql/mutations';
import {
  GET_ALL_LANGUAGES,
  GET_LANGUAGES_COUNT, GET_RECORDING_STATUS_BY_LANGUAGE,
  GET_SCRIPT_ID,
  GET_TEXT_STATUS_BY_LANGUAGE,
} from '../../../graphql/queries';
import { createLanguage as createLanguageProps } from '../../../graphql/mutations/__generated__/createLanguage';
import { updateLanguage as updateLanguageProps } from '../../../graphql/mutations/__generated__/updateLanguage';
import { ICreateLanguageDialogProps } from './types';
import { base64Decoding } from '../../../helpers';
import { LangContext } from '../../../context/LangContext/LangContext';
import { defaultLanguageData } from '../../../constants/languages';
import { useStyles } from './styles';
import { LanguageAutocomplete } from '../../../common/LanguageAutocomplete';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const CreateLanguageDialog: React.FC<ICreateLanguageDialogProps> = ({
  handleDialogChange, open, setOpen,
}) => {
  const context = useContext(LangContext);
  const [languageValue, setLanguageValue] = useState(context?.languageData?.name);
  const [languageData, setLanguageData] = useState(context?.languageData);
  const [languageCodeValue, setLanguageCodeValue] = useState(context?.languageData?.languageCode);
  const [isTextOnly, setIsTextOnly] = useState(context?.languageData?.textOnly);

  const { data: scriptIdData } = useQuery<getScriptId>(GET_SCRIPT_ID, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
  });

  const [createLanguage] = useMutation<createLanguageProps>(CREATE_LANGUAGE, {
    variables: {
      projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
    },
    refetchQueries: [
      {
        query: GET_ALL_LANGUAGES,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_LANGUAGES_COUNT,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_TEXT_STATUS_BY_LANGUAGE,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      {
        query: GET_RECORDING_STATUS_BY_LANGUAGE,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allLanguages',
    ],
  });

  const [updateLanguage] = useMutation<updateLanguageProps>(UPDATE_LANGUAGE, {
    refetchQueries: [
      {
        query: GET_ALL_LANGUAGES,
        variables: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
        },
      },
      'allLanguages',
    ],
  });

  const classes = useStyles();

  const handleCreateLanguage = (
    language: string| undefined,
    languageCode: string| undefined,
    languageFlag: string | undefined,
    textOnly: boolean | undefined,
    scriptId: string,
  ) => {
    createLanguage({
      variables: {
        languageCreationData: {
          projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
          name: language,
          languageCode,
          languageFlag,
          textOnly,
          script: base64Decoding(scriptId),
        },
      },
    });
    context?.setLanguageData(defaultLanguageData);
    setOpen(!open);
  };

  const handleUpdateLanguage = (
    id: string | undefined,
    language: string | undefined,
    languageCode: string | undefined,
    languageFlag: string | undefined,
    textOnly: boolean | undefined,
  ) => {
    updateLanguage({
      variables: {
        languageUpdateData: {
          id,
          name: language,
          languageCode,
          languageFlag,
          textOnly,
        },
      },
    });
    context?.setLanguageData(defaultLanguageData);
    setOpen(!open);
  };

  const handleLangCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLanguageCodeValue(event.target.value);
    context?.setLanguageData({ ...context.languageData, languageCode: event.target.value });
  };
  useEffect(() => {
    if (context) {
      setLanguageValue(context.languageData.name);
      setLanguageCodeValue(context.languageData.languageCode);
      setLanguageData(context.languageData);
      setIsTextOnly(context.languageData.textOnly);
    }
  }, [open, context]);
  return (

    <Dialog
      open={open}
      onClose={handleDialogChange}
      fullWidth
      maxWidth="md"
    >

      <Box className={classes.dialogContainer}>
        <DialogContent className={classes.dialogDisableHorizontalPadding}>
          <Box className={classes.dialogContentContainer}>
            <Box sx={{ marginRight: '56px', minWidth: 210 }}>
              <Typography className={classes.dialogHeading} variant="body2">Language</Typography>
              <FormControl fullWidth>
                <LanguageAutocomplete isFlagSelection={false} value={languageData} setValue={setLanguageData} />
              </FormControl>
            </Box>

            <Box sx={{ marginRight: '70px', minWidth: 20 }}>
              <Typography className={classes.dialogHeading} variant="body2">Language Flag</Typography>
              <LanguageAutocomplete isFlagSelection value={languageData} setValue={setLanguageData} />
            </Box>
            <Box sx={{ marginRight: '70px', minWidth: 210 }}>
              <Typography className={classes.dialogHeading} variant="body2">Language Code</Typography>
              <TextField value={languageCodeValue} placeholder="EN" onChange={handleLangCodeChange} />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            >
              <Typography className={classes.dialogHeading} variant="body2">Text Only</Typography>
              <Checkbox checked={isTextOnly} onChange={() => setIsTextOnly(!isTextOnly)} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className={classes.dialogDisableHorizontalPadding} sx={{ justifyContent: 'space-between' }}>
          <StyledButton
            width="210px"
            variant="outlined"
            label="Cancel"
            action={handleDialogChange}
          />
          <StyledButton
            variant="contained"
            width="210px"
            label={context?.isEditMode ? 'OK' : 'Add'}
            action={context?.isEditMode ? () => {
              handleUpdateLanguage(
                context?.languageData.id,
                languageValue,
                languageCodeValue,
                languageData?.languageFlag,
                isTextOnly,
              );
              context.setIsEditMode(false);
            } : () => {
              if (scriptIdData?.project?.scriptSet.edges[0]?.node?.id) {
                handleCreateLanguage(
                  languageValue,
                  languageCodeValue,
                  languageData?.languageFlag,
                  isTextOnly,
                  scriptIdData?.project?.scriptSet.edges[0]?.node?.id,
                );
              }
            }}
          />
        </DialogActions>
      </Box>
    </Dialog>
  );
};
export default CreateLanguageDialog;
