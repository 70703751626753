import { gql } from '@apollo/client';

export const TOKEN_AUTH = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken {
    refreshToken {
      token
    }
  }
`;

export const VERIFY_TOKEN = gql`
  mutation verifyToken {
    verifyToken {
      payload
    }
  }
`;

export const DELETE_TOKEN = gql`
  mutation deleteToken {
    deleteTokenCookie {
      deleted
    }
  }
`;

export const DELETE_REFRESH_TOKEN = gql`
  mutation deleteRefreshToken {
    deleteRefreshTokenCookie {
      deleted
    }
  }
`;

export const CREATE_LANGUAGE = gql`
  mutation createLanguage($languageCreationData: LanguageCreateInput!) {
    createLanguage(languageCreationData: $languageCreationData) {
      language {
        id
      }
    }
  }
`;

export const DELETE_LANGUAGE = gql`
  mutation deleteLanguage($languageDeletionData: LanguageDeleteInput!) {
    deleteLanguage(languageDeletionData: $languageDeletionData) {
      ok
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($languageUpdateData: LanguageUpdateInput!) {
    updateLanguage(languageUpdateData: $languageUpdateData) {
      language {
        id
      }
    }
  }
`;

export const UPDATE_SCRIPT = gql`
  mutation updateScript($scriptUpdateData: ScriptUpdateInput!) {
    updateScript(scriptUpdateData: $scriptUpdateData) {
      script {
        id
      }
    }
  }
`;

export const CREATE_CHARACTER = gql`
  mutation createCharacter($characterCreationData: CharacterCreateInput!) {
    createCharacter(characterCreationData: $characterCreationData) {
      character {
        id
      }
    }
  }
`;

export const DELETE_CHARACTER = gql`
  mutation deleteCharacter($characterDeletionData: CharacterDeleteInput!) {
    deleteCharacter(characterDeletionData: $characterDeletionData) {
      ok
    }
  }
`;

export const UPDATE_CHARACTER = gql`
  mutation updateCharacter($characterUpdateData: CharacterUpdateInput!) {
    updateCharacter(characterUpdateData: $characterUpdateData) {
      character {
        id
      }
    }
  }
`;

export const CREATE_SECTION = gql`
  mutation createSection($sectionCreationData: SectionCreateInput!) {
    createSection(sectionCreationData: $sectionCreationData) {
      section {
        id
      }
    }
  }
`;

export const UPDATE_SECTION = gql`
  mutation updateSection($sectionUpdateData: SectionUpdateInput!) {
    updateSection(sectionUpdateData: $sectionUpdateData) {
      section {
        id
      }
    }
  }
`;

export const DELETE_SECTION = gql`
  mutation deleteSection($sectionDeletionData: SectionDeleteInput!) {
    deleteSection(sectionDeletionData: $sectionDeletionData) {
      ok
    }
  }
`;
export const UPDATE_LINE = gql`
  mutation updateLine($lineUpdateData: LineUpdateInput!) {
    updateLine(lineUpdateData: $lineUpdateData) {
      line {
        id
      }
    }
  }
`;

export const DELETE_LINE = gql`
mutation deleteLine($lineDeletionData: LineDeleteInput!){
  deleteLine(lineDeletionData: $lineDeletionData){
    ok
  }
}`;

export const CREATE_REVISION = gql`
  mutation createLocalisedAssetRevision(
    $localisedAssetRevisionCreationData: LocalisedAssetRevisionCreateInput!
    $makeCurrent: Boolean
  ) {
    createLocalisedAssetRevision(
      localisedAssetRevisionCreationData: $localisedAssetRevisionCreationData
      makeCurrent: $makeCurrent
    ) {
      localisedAssetRevision {
        id
      }
    }
  }
`;

export const UPDATE_CURRENT_REVISION = gql`
  mutation updateLocalisedAsset($localisedAssetUpdateData: LocalisedAssetUpdateInput!) {
    updateLocalisedAsset(localisedAssetUpdateData: $localisedAssetUpdateData) {
      localisedAsset {
       id
      }
    }
  }
`;

export const UPDATE_REVISION = gql`
  mutation updateLocalisedAssetRevision(
    $localisedAssetRevisionUpdateData: LocalisedAssetRevisionUpdateInput!
    $makeCurrent: Boolean
  ) {
    updateLocalisedAssetRevision(
      localisedAssetRevisionUpdateData: $localisedAssetRevisionUpdateData
      makeCurrent: $makeCurrent
    ) {
      localisedAssetRevision {
        id
      }
    }
  }
`;

export const DELETE_REVISION = gql`
  mutation deleteLocalisedAssetRevision($localisedAssetRevisionDeletionData: LocalisedAssetRevisionDeleteInput!){
    deleteLocalisedAssetRevision(localisedAssetRevisionDeletionData: $localisedAssetRevisionDeletionData){
      ok
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($projectUpdateData: ProjectUpdateInput!){
    updateProject(projectUpdateData: $projectUpdateData){
      project{
        id
      }
    }
  }
`;

export const ADD_LANGUAGE = gql`
  mutation addLanguage($localisedAssetCreationData: LocalisedAssetCreateInput!){
    createLocalisedAsset(localisedAssetCreationData: $localisedAssetCreationData){
      localisedAsset{
        id
      }
    }
  }
`;

export const CREATE_LINE = gql`
  mutation createLine($lineCreationData: LineCreateInput!){
    createLine(lineCreationData: $lineCreationData){
      line{
        id
      }
    }
  }
`;

export const CREATE_TODO = gql`
  mutation createToDoItem($toDoItemCreationData: ToDoItemCreateInput!){
    createToDoItem(toDoItemCreationData: $toDoItemCreationData){
      toDoItem{
        id
      }
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation updateToDoItem($toDoItemUpdateData: ToDoItemUpdateInput!){
    updateToDoItem(toDoItemUpdateData:$toDoItemUpdateData){
      toDoItem{
        id
      }
    }
  }
`;

export const DELETE_TODO = gql`
  mutation deleteToDoItem($toDoItemDeletionData: ToDoItemDeleteInput!){
    deleteToDoItem(toDoItemDeletionData: $toDoItemDeletionData){
      ok
    }
  }
`;

export const GENERATE_ROBOVOICE_FOR_LANGUAGES = gql`
  mutation generateRobovoiceForLanguages($robovoiceCreationData: GenerateRoboVoiceForLanguagesInput!){
    generateRobovoiceForLanguages(robovoiceCreationData: $robovoiceCreationData) {
        request {
          errorMessage  
          hasFailed
          id
          isFinished
          nFailedFiles
          nProcessedFiles
          nSucceededFiles
          nTotalFilesToProcess
          progressText
        }
    }
  }
`;

export const EXPORT_RECORDING = gql`
mutation exportRecording($exportScriptOptions: ExportRecordingInput!) {
  exportRecording(exportScriptOptions: $exportScriptOptions) {
    request {
      hasFailed
      id
      isFinished
      nProcessedRecordings
      nTotalRecordings
      s3DownloadLink
      s3FileExists
      errorMessage
      progressText
    }
  }
}
`;

export const EXPORT_SCRIPT = gql`
mutation exportScript($exportScriptOptions: ExportScriptInput!) {
  exportScript(exportScriptOptions: $exportScriptOptions) {
    request {
      createdAt
      hasFailed
      id
      isFinished
      s3DownloadLink
      updatedAt
      s3FileExists
    }
  }
}
`;
export const IMPORT_SCRIPT = gql`
mutation importScript($importScriptOptions: ImportScriptInput!) {
  importScript(importScriptOptions: $importScriptOptions) {
    request {
      hasFailed
      id
      isFinished
      progressText
    }
  }
}
`;

export const IMPORT_RECORDING = gql`
mutation importRecording($importRecordingOptions: ImportRecordingInput!) {
  importRecording(importRecordingOptions: $importRecordingOptions) {
    request {
      errorMessage
      hasFailed
      id
      isFinished
      nProcessedLines
      nTotalRecordings
      progressText
    }
  }
}
`;

export const CREATE_SOUND_FILE = gql`
mutation createSoundFile($createSoundFile: SoundFileCreateInput!) {
  createSoundFile(soundFileCreationData: $createSoundFile) {
    soundFile {
      id
    }
  }
}
`;

export const FIX_UP_FILES = gql`
mutation fixUpFiles($fixupFileCreationData: FixUpFileInput!) {
  fixUpFiles(fixupFileCreationData: $fixupFileCreationData) {
    request {
      errorMessage
      hasFailed
      id
      isFinished
      nFailedFiles
      nProcessedFiles
      nSucceededFiles
      nTotalFilesToProcess
      progressText
    }
  }
}
`;

export const UPDATE_PROJECT_LOGO = gql`
mutation updateProjectLogo($extension: String!, $logoId: ID, $projectId: ID!) {
    updateProjectLogo(extension: $extension, logoId: $logoId, projectId: $projectId) {
        project {
            id
            logoUrl
        }
    }
}
`;

export const DELETE_PROJECT_LOGO = gql`
mutation deleteProjectLogo($projectId: ID!) {
    deleteProjectLogo(projectId: $projectId) {
        project {
            id
        }
    }
}
`;

export const DELETE_SOUND_FILE = gql`
mutation deleteSoundfile($soundFileDeletionData: SoundFileDeleteInput!) {
  deleteSoundfile(soundFileDeletionData: $soundFileDeletionData) {
    ok
  }
}
`;

export const GENERATE_ROBOVOICE = gql`
mutation generateRobovoice($robovoiceCreationData: GenerateSingleRoboVoiceInput!) {
  generateRobovoice(robovoiceCreationData: $robovoiceCreationData) {
    request {
      id
      hasFailed
      errorMessage
      isFinished
      nFailedFiles
      nProcessedFiles
      nSucceededFiles
      nTotalFilesToProcess
      progressText
    }
  }
}
`;

export const FIX_UP_TO_EQUAL_FILES = gql`
mutation fixUpFilesEquate($fixupFileCreationData: FixUpFileEquateInput!) {
  fixUpFilesEquate(fixupFileCreationData: $fixupFileCreationData) {
    request {
      createdAt
      errorMessage
      hasFailed
      id
      isFinished
      nFailedFiles
      nProcessedFiles
      nSucceededFiles
      nTotalFilesToProcess
      progressText
      updatedAt
    }
  }
}
`;

export const REMOVE_USER_FROM_PROJECT = gql`
mutation removeUserFromProject($removeUserFromProjectData: UserInput!) {
  removeUserFromProject(
    removeUserFromProjectData: $removeUserFromProjectData
  ) {
    ok
  }
}
`;

export const ADD_USER_TO_PROJECT = gql`
mutation addNewUserToProject($addUserToProjectData: UserInput!) {
  addUserToProject(addUserToProjectData: $addUserToProjectData) {
    ok
    newUser
    tmpPassword
  }
}
`;

export const ASSIGN_PERMISSION_GROUP_LIST_TO_USER = gql`
mutation assignPermissionGroupListToUser($assignUserToPermissionGroups: AssignProjectUserPermissionGroupsInput!) {
  assignPermissionGroupsToUser(assignUserToPermissionGroups: $assignUserToPermissionGroups) {
    ok
    groupsAdded
  }
}
`;

export const ADMIN_RESET_PASSWORD = gql`
mutation adminPWResetPassword($userResetData: UserDeleteInput!) {
  adminPasswordReset(userResetData: $userResetData)
 {
    TempPassword
    User {
      requiresPasswordReset
      id
      email
      isActive
    }
  }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($userUpdateData: UserUpdateInput!) {
  updateUser(userUpdateData: $userUpdateData) {
    User {
      id
      requiresPasswordReset
      email
      isActive
    }
  }
}
`;
