import React from 'react';

import AuthContextProvider from './AuthContext/AuthContext';
import CharContextProvider from './CharContext/CharContext';
import LangContextProvider from './LangContext/LangContext';
import LineContextProvider from './LineContext/LineContext';
import RevisionContextProvider from './RevisionContext/RevisionContext';
import SectionContextProvider from './SectionContext/SectionContext';
import TodoContextProvider from './TodoContext/TodoContext';
import FixRevisionProvider from './FixRevisionContext/FixRevisionContext';
import UserContextProvider from './UserContext/UserContext';
import { IRootContextProps } from './types';

const RootContext: React.FC<IRootContextProps> = ({ children }) => (
  <AuthContextProvider>
    <UserContextProvider>
      <LangContextProvider>
        <CharContextProvider>
          <SectionContextProvider>
            <LineContextProvider>
              <RevisionContextProvider>
                <TodoContextProvider>
                  <FixRevisionProvider>
                    {children}
                  </FixRevisionProvider>
                </TodoContextProvider>
              </RevisionContextProvider>
            </LineContextProvider>
          </SectionContextProvider>
        </CharContextProvider>
      </LangContextProvider>
    </UserContextProvider>
  </AuthContextProvider>
);

export default RootContext;
