import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';
import Spinner from '../Spinner/Spinner';

const PageLoader: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.loaderWrapper}>
      <Typography className={classes.textWrapper}>
        Loading
      </Typography>
      <Spinner />
    </Box>
  );
};

export default PageLoader;
