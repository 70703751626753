import React from 'react';
import { Box } from '@mui/material';

import { Header } from '../../common/Header';
import { MenuList } from '../../common/MenuList';
import { NavBar } from '../../common/NavBar';
import { UserManagementContainer } from '../../components/UserManagement/UserManagementContainer';

const UserManagement: React.FC = () => (
  <>
    <Header />
    <Box sx={{ display: 'flex' }}>
      <NavBar drawerList={<MenuList />} drawerWidth={230} />
      <Box component="main" sx={{ flexGrow: 1, p: 10 }}>
        <UserManagementContainer />
      </Box>
    </Box>
  </>
);

export default UserManagement;
