import React, { useContext, useState } from 'react';
import {
  Autocomplete, Typography, Box, TextField,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation } from '@apollo/client';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { StyledButton } from '../../../common/StyledButton';
// eslint-disable-next-line camelcase
import { allSections_allSections_edges } from '../../../graphql/queries/__generated__/allSections';
// eslint-disable-next-line camelcase
import { allCharacters_allCharacters_edges } from '../../../graphql/queries/__generated__/allCharacters';
import { useStyles } from './styles';
import { ILineDetailsHeaderProps } from './types';
import { UPDATE_LINE } from '../../../graphql/mutations';
import { updateLine as updateLineProps } from '../../../graphql/mutations/__generated__/updateLine';
import { base64Decoding } from '../../../helpers';
import { LineContext } from '../../../context/LineContext/LineContext';

const LineDetailsHeader: React.FC<ILineDetailsHeaderProps> = ({
  initialLineId,
  sectionOptions,
  characterOptions,
  initialSection,
  initialCharacter,
  isNewLanguagesExist,
  setOpen,
}) => {
  const { id } = useParams();
  const [lineId, setLineId] = useState(initialLineId);
  const [
    selectedCharacterLocal,
    setSelectedCharacterLocal,
    // eslint-disable-next-line camelcase
  ] = useState<allCharacters_allCharacters_edges | undefined>(initialCharacter);

  const [
    selectedSectionLocal,
    setSelectedSectionLocal,
    // eslint-disable-next-line camelcase
  ] = useState<allSections_allSections_edges | undefined>(initialSection);

  const classes = useStyles();
  const navigate = useNavigate();
  const lineContext = useContext(LineContext);

  const [updateLine] = useMutation<updateLineProps>(UPDATE_LINE);

  const handleUpdateLine = () => {
    updateLine({
      variables: {
        lineUpdateData: {
          id,
          stringId: lineId,
          character: base64Decoding(selectedCharacterLocal?.node?.id || ''),
          section: base64Decoding(selectedSectionLocal?.node?.id || ''),
        },
      },
    });
  };
  const handleDeleteLine = () => {
    lineContext?.setLineId(id || '');
    lineContext?.setOpen(true);
  };

  return (
    <>
      <Box className={classes.detailsContainer} sx={{ marginBottom: '28px', marginTop: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <StyledButton
            label="Script"
            action={() => navigate('/script/lines')}
            startIcon={<NavigateBeforeIcon style={{ fill: '#1F224C' }} />}
            variant="text"
            textColor="#1F224C"
            fontSize="24px"
            fontWeight="600"
          />
        </Box>
        <Box>
          <StyledButton
            variant="text"
            label="Delete line"
            startIcon={<TrashIcon style={{ stroke: '#FF6B40' }} />}
            action={handleDeleteLine}
          />
        </Box>
      </Box>
      <Box className={classes.detailsContainer} sx={{ marginBottom: '40px' }}>
        <Box>
          <Typography variant="body2" className={classes.headerText}>
            Line ID
          </Typography>
          <TextField
            value={lineId}
            inputProps={{
              style: {
                padding: '12px 10px',
              },
            }}
            onBlur={handleUpdateLine}
            onChange={(e) => setLineId(e.target.value)}
          />
        </Box>
        <Box>
          <Typography variant="body2" className={classes.headerText}>
            Section
          </Typography>
          <Autocomplete
            id="line-sections"
            options={sectionOptions || []}
            getOptionLabel={(option) => option?.node?.name || ''}
            isOptionEqualToValue={(option, val) => option?.node?.name === val?.node?.name
              || val?.node?.name === ''}
            className={classes.autocomplete}
            value={selectedSectionLocal}
            disableClearable
            onChange={(_event, newValue) => {
              setSelectedSectionLocal(newValue);
            }}
            onBlur={handleUpdateLine}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Section"
              />
            )}
          />
        </Box>
        <Box>
          <Typography variant="body2" className={classes.headerText}>
            Character
          </Typography>
          <Autocomplete
            id="line-characters"
            options={characterOptions || []}
            isOptionEqualToValue={(option, val) => option?.node?.name === val?.node?.name
              || val?.node?.name === ''}
            inputValue={selectedCharacterLocal?.node?.name}
            getOptionLabel={(option) => option?.node?.name || ''}
            className={classes.autocomplete}
            value={selectedCharacterLocal}
            disableClearable
            onChange={(_event, newValue) => {
              setSelectedCharacterLocal(newValue);
            }}
            onBlur={handleUpdateLine}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Character"
              />
            )}
          />
        </Box>
      </Box>
      <Box sx={{ marginBottom: '20px' }}>
        <StyledButton
          action={() => setOpen(true)}
          variant={!isNewLanguagesExist ? 'text' : 'contained'}
          disabled={!isNewLanguagesExist}
          label="Add Language"
        />
      </Box>
    </>
  );
};
export default LineDetailsHeader;
