import React, { useContext, useState } from 'react';
import {
  Collapse,
  TableHead,
  Table,
  TableCell,
  TableRow,
  Typography,
  TableBody,
  Box,
  IconButton, Tooltip,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { allLanguages } from '../../../graphql/queries/__generated__/allLanguages';
import { projectPrimaryLanguage } from '../../../graphql/queries/__generated__/projectPrimaryLanguage';
import { GET_ALL_LANGUAGES, GET_PRIMARY_LANGUAGE } from '../../../graphql/queries';
import { sortLocalisedAssetLanguagesInAlphabeticalOrder } from '../../../helpers';
import { useStyles } from './styles';
import { ILineTableRowProps } from './types';
import { RevisionRow } from './RevisionRow';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-icon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg';
import { white } from '../../../constants/colors';
import { LineContext } from '../../../context/LineContext/LineContext';
import { FlagVisualisation } from '../../../common/FlagVisualisation';
import { LOCALE_STORAGE_PROJECT_ID } from '../../../constants/localStorage';

const LineTableRow: React.FC<ILineTableRowProps> = ({ data, isEven }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { data: languagesData } = useQuery<allLanguages>(
    GET_ALL_LANGUAGES,
    { variables: { orderBy: 'name', projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) } },
  );

  const {
    data: primaryLanguageData,
  } = useQuery<projectPrimaryLanguage>(GET_PRIMARY_LANGUAGE, {
    variables: { projectId: localStorage.getItem(LOCALE_STORAGE_PROJECT_ID) || '' },
    skip: !localStorage.getItem(LOCALE_STORAGE_PROJECT_ID),
  });

  const primaryLanguageId = primaryLanguageData?.project?.currentScript?.primaryLanguage?.id;
  const primaryLanguageName = languagesData?.allLanguages?.edges?.find(
    (edge: any) => edge?.node?.id === primaryLanguageId,
  )?.node?.name;

  const sortedLanguages = sortLocalisedAssetLanguagesInAlphabeticalOrder(
    data?.node?.localisedassetSet.edges,
    primaryLanguageName,
  );

  const classes = useStyles();
  const navigate = useNavigate();
  const lineContext = useContext(LineContext);

  return (
    <React.Fragment key={uuidv4()}>
      <TableRow className={isEven ? classes.lightBlueRow : classes.whiteRow}>
        <TableCell padding="none">
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <ArrowIcon className={classes.arrowDownIcon} /> : <ArrowIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.tableText}>
          <Typography variant="subtitle2" display="inline">
            {data?.node?.stringId}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableText}>
          <Typography
            variant="subtitle2"
            display="inline"
          >
            {data?.node?.character?.name}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableText}>
          <Typography
            variant="subtitle2"
            display="inline"
          >
            {data?.node?.section?.name}
          </Typography>
        </TableCell>
        <TableCell className={classes.tableText}>
          <Typography
            variant="body1"
            display="inline"
            key={uuidv4()}
            className={classes.languageCell}
          >
            {sortedLanguages.map((localisedEdge) => {
              if (localisedEdge?.node?.currentSelect && localisedEdge?.node?.currentSelect.text) {
                return (
                  <Tooltip title={localisedEdge?.node?.language?.name} key={uuidv4()}>
                    <span>
                      <FlagVisualisation
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                        }}
                        key={localisedEdge?.node?.language?.languageCode}
                        optionFlag={localisedEdge?.node?.language?.languageFlag || ''}
                      />
                    </span>
                  </Tooltip>
                );
              }
              return null;
            })}
          </Typography>
        </TableCell>
        <TableCell padding="none" sx={{ minWidth: '100px' }}>
          <Box>
            <Tooltip title={t('LINES_PAGE.EDIT_LINE_TOOLTIP')} placement="top">
              <IconButton onClick={() => {
                lineContext?.setStringId(data?.node?.stringId || '');
                lineContext?.setLineId(data?.node?.id || '');
                navigate(`/lines/${data?.node?.id}`);
              }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title={t('LINES_PAGE.DELETE_LINE_TOOLTIP')} placement="top">
              <IconButton onClick={() => {
                lineContext?.setStringId(data?.node?.stringId || '');
                lineContext?.setLineId(data?.node?.id || '');
                lineContext?.setOpen(true);
              }}
              >
                <TrashIcon style={{ stroke: '#1F224C' }} />
              </IconButton>
            </Tooltip>

          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow className={classes.darkBlueRow}>

                    <TableCell><Typography variant="body2" color={white}>Language</Typography></TableCell>
                    <TableCell><Typography variant="body2" color={white}>Line</Typography></TableCell>
                    <TableCell><Typography variant="body2" color={white}>Listen</Typography></TableCell>
                    <TableCell><Typography variant="body2" color={white}>Status</Typography></TableCell>
                    <TableCell><Typography variant="body2" color={white}>Revisions</Typography></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedLanguages.map((localLine) => (
                    <RevisionRow key={uuidv4()} localLine={localLine} primaryLanguageName={primaryLanguageName} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default LineTableRow;
