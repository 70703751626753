import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { IAddUserDialogProps } from './types';
import { useStyles } from './styles';
import { GET_PERMISSION_GROUPS_FOR_PROJECT, GET_USERS_AND_PERMISSIONS } from '../../../../graphql/queries';
import { UserContext } from '../../../../context/UserContext/UserContext';
import { StyledButton } from '../../../../common/StyledButton';
import { getPermissionGroupsForProject } from '../../../../graphql/queries/__generated__/getPermissionGroupsForProject';
import { ADD_USER_TO_PROJECT, ASSIGN_PERMISSION_GROUP_LIST_TO_USER } from '../../../../graphql/mutations';
import { addNewUserToProject } from '../../../../graphql/mutations/__generated__/addNewUserToProject';
import {
  assignPermissionGroupListToUser,
} from '../../../../graphql/mutations/__generated__/assignPermissionGroupListToUser';
import AddUserResultDialog from './AddUserResultDialog';
import { emailRegEx } from '../../../../constants/user';
import { PageLoader } from '../../../../common/PageLoader';

const AddUserDialog: React.FC<IAddUserDialogProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [userPassword, setUserPassword] = useState<string | null>(null);
  const [permissionSets, setPermissionSets] = useState<string[]>([]);

  const {
    data: permissionGroups,
    loading: permissionGroupsLoading,
  } = useQuery<getPermissionGroupsForProject>(GET_PERMISSION_GROUPS_FOR_PROJECT, {
    variables: {
      projectId: userContext?.projectId,
    },
    skip: !userContext?.projectId,
  });

  const [addUserToProject,
    { data: addUserData, loading: addUserLoading }] = useMutation<addNewUserToProject>(
      ADD_USER_TO_PROJECT,
      {
        onCompleted: (data) => data.addUserToProject?.ok
            && setUserPassword(data.addUserToProject?.tmpPassword),
      },
    );

  const [assignPermissionsToUser,
    {
      data: assignUserData, loading: assignUserLoading,
    }] = useMutation<assignPermissionGroupListToUser>(
      ASSIGN_PERMISSION_GROUP_LIST_TO_USER,
      {
        refetchQueries: [
          { query: GET_USERS_AND_PERMISSIONS, variables: { projectId: userContext?.projectId } },
        ],
      },
    );

  const {
    handleSubmit, control, register, reset, formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      permissionSets: [],
    } as {email: string, permissionSets: string[]},
  });

  const handleClose = () => {
    setOpen(false);
    setUserEmail(null);
    setUserPassword(null);
    setPermissionSets([]);
    reset({ permissionSets: [] });
  };

  const onSubmit = async (data: {email: string, permissionSets: string[]}) => {
    await addUserToProject({
      variables: {
        addUserToProjectData: {
          projectId: userContext?.projectId,
          email: data.email,
        },
      },
    });
    setPermissionSets(data.permissionSets);
    setUserEmail(data.email);
  };

  useEffect(() => {
    (async () => {
      if (!addUserLoading && addUserData?.addUserToProject?.ok && userEmail) {
        await assignPermissionsToUser({
          variables: {
            assignUserToPermissionGroups: {
              projectId: userContext?.projectId,
              email: userEmail,
              permissionGroupIds: permissionSets,
            },
          },
        });
      }
    })();
  }, [addUserLoading, addUserData]);

  useEffect(() => {
    if (!assignUserLoading
        && assignUserData?.assignPermissionGroupsToUser?.ok
        && !addUserData?.addUserToProject?.newUser) {
      handleClose();
    }
  }, [assignUserLoading && assignUserData]);

  if (permissionGroupsLoading) {
    return <PageLoader />;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <Box className={classes.dialogWrapper}>
        <IconButton
          className={classes.closeDialogIconButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography fontWeight={600}>{t('USER_MANAGEMENT_PAGE.DIALOG.INVITE_USER_TO_PROJECT')}</Typography>
      </Box>
      {
          userEmail && userPassword && !assignUserLoading && assignUserData?.assignPermissionGroupsToUser?.ok
            ? <AddUserResultDialog userEmail={userEmail} userPassword={userPassword} />
            : (
              <Box>
                <form className={classes.formWrapper}>
                  <Box>
                    <Typography>{t('USER_MANAGEMENT_PAGE.DIALOG.EMAIL')}</Typography>
                    <FormLabel htmlFor="email-field" />

                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: 'Email required',
                        pattern: {
                          value: emailRegEx,
                          message: t('USER_MANAGEMENT_PAGE.DIALOG.INVALID_EMAIL_ADDRESS'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <TextField
                            onChange={onChange}
                            value={value}
                            id="email-field"
                            type="email"
                            className={classes.textField}
                          />
                          <Typography variant="body2" color="error">{errors.email?.message}</Typography>
                        </>
                      )}
                    />
                  </Box>
                  <Box className={classes.permissionSetsWrapper}>

                    <Typography>{t('USER_MANAGEMENT_PAGE.DIALOG.PERMISSION_SETS')}</Typography>
                    <FormLabel htmlFor="permissionSets-field" />
                    {permissionGroups?.getPermissionGroupsForProject?.edges.map((edge) => (
                      <div key={edge?.node?.id}>
                        <FormControlLabel
                          className={classes.checkboxWrapper}
                          control={(
                            <Checkbox
                              className={classes.checkbox}
                              {...register('permissionSets', { required: true })}
                              value={edge?.node?.id}
                            />
                              )}
                          label={(
                            <Box>
                              <p style={{ fontWeight: 'bold' }}>{edge?.node?.label}</p>
                              <p>{edge?.node?.description}</p>
                            </Box>
                              )}
                        />
                      </div>
                    ))}
                    {errors.permissionSets && (
                    <Typography variant="body2" color="error">
                      {t('USER_MANAGEMENT_PAGE.DIALOG.PLEASE_SELECT_PERMISSION')}
                    </Typography>
                    )}
                  </Box>
                  <Box className={classes.buttonWrapper}>
                    <StyledButton
                      variant="outlined"
                      width="150px"
                      margin="0 10px"
                      label={t('BUTTON.CANCEL')}
                      action={handleClose}
                    />
                    <StyledButton
                      variant="contained"
                      width="150px"
                      margin="0 10px"
                      label={t('BUTTON.INVITE')}
                      action={handleSubmit(onSubmit)}
                    />
                  </Box>
                </form>
              </Box>
            )

      }
    </Dialog>
  );
};

export default AddUserDialog;
